import { ReportPDFProps } from "../../_importDocs";

import jsPDF from "jspdf";
import generateTable, {
  generateColumns,
  generateBody,
  generateFooter,
} from "./tables";

import generateHeaders from "./header";
import { getPageFooterHeight } from "./footer";

export const createPDF = (props: ReportPDFProps) => {
  const doc = new jsPDF({
    putOnlyUsedFonts: true,
    compress: true,
    orientation: "p",
  });

  const { reportData } = props;
  const { title } = reportData;

  // Defaults
  doc.setFont("Inter", "normal");

  // Setup the header
  const startY = generateHeaders(doc, reportData);

  //Setup the footer
  const bottomY = getPageFooterHeight(doc);

  return {
    doc,
    bottomY,
    startY,
  };
};

export default createPDF;

// Tables
export {
  generateHeaders,
  generateColumns,
  generateBody,
  generateFooter,
  generateTable as getTable,
};

export const PAGE_HEIGHT = 297;
export const PAGE_WIDTH = 210;
export const MARGIN = 10;
export const PTSCONVERSION = 0.352778;
