import { convertCentsToEur } from "@/data/amounts";
import classNames from "classnames";
import { Timestamp } from "firebase/firestore";
import { useField } from "formik";
import React, { useCallback, useEffect, useState } from "react";

interface FormikFieldHandler {
  label: string;
  name: string;
  type?:
    | "text"
    | "number"
    | "email"
    | "password"
    | "search"
    | "tel"
    | "url"
    | "date"
    | "datetime"
    | "currency";
  classes?: string;
  prefix?: string;
  suffix?: string;
  hideLabel?: boolean;
  required?: boolean;

  min?: string;
  max?: string;
  step?: string;
}

export const QSInputField = React.forwardRef<
  HTMLInputElement,
  FormikFieldHandler
>((props: FormikFieldHandler, ref) => {
  let { label, type, name, classes, prefix, suffix, hideLabel } = props;
  let [field, meta] = useField(props);

  let fieldType =
    type === "datetime"
      ? "datetime-local"
      : type === "currency"
      ? "number"
      : type ?? "text";

  let onChange = useCallback(
    (value: string | number) => {
      // let value = e.target.value;

      let valueToSave: string | Timestamp | number = value;

      if (typeof value === "string" && value) {
        if (type === "text") {
          if (prefix && !value.startsWith(prefix)) {
            valueToSave = prefix + valueToSave;
          }

          if (suffix && !value.endsWith(suffix)) {
            valueToSave = valueToSave + suffix;
          }
        } else if (type === "date") {
          // the value we save needs to be a Timestamp
          valueToSave = Timestamp.fromDate(new Date(value));
        } else if (type === "datetime") {
          valueToSave = Timestamp.fromDate(new Date(value));
        }
      }

      if (typeof value === "number" && value) {
        if (type === "number") {
          valueToSave = Number(value);
        } else if (type === "currency") {
          valueToSave = Math.round(Number(value) * 100);
        }
      }

      field.onChange({
        target: {
          name: field.name,
          value: valueToSave,
        },
      });
    },
    [field, suffix, prefix, type, fieldType]
  );

  // Remove Prefix and Suffix from value whilst editing
  let valueOrTimestamp: string | number | Timestamp = field.value || "";

  if (valueOrTimestamp) {
    if (typeof valueOrTimestamp === "string") {
      if (prefix && field.value.startsWith(prefix)) {
        valueOrTimestamp = field.value.slice(prefix.length);
      }
      if (suffix && field.value.endsWith(suffix)) {
        valueOrTimestamp = (valueOrTimestamp as string).slice(
          0,
          -suffix.length
        );
      }
    } else if (
      typeof valueOrTimestamp === "object" &&
      valueOrTimestamp instanceof Timestamp
    ) {
      // We only use Timestamps to and from the database
      // Convert from Timestamp to what to use in the input
      if (type === "date") {
        valueOrTimestamp = valueOrTimestamp.toDate().toISOString().slice(0, 10);
      } else if (type === "datetime") {
        valueOrTimestamp = valueOrTimestamp.toDate().toISOString().slice(0, 16);
      }
    } else if (typeof valueOrTimestamp === "number") {
      if (type === "currency") {
        valueOrTimestamp = convertCentsToEur(field.value) as string;
      }
    }
  }
  let value =
    fieldType === "number"
      ? (valueOrTimestamp as number)
      : (valueOrTimestamp as string);

  let [inputValue, setInputValue] = useState(value ?? "");
  useEffect(() => {
    setInputValue(value ?? "");
  }, [value]);

  let [isDirty, setIsDirty] = useState(false);

  // call on change if the value is dirty and hasn't changed for 2 seconds
  useEffect(() => {
    if (!isDirty) {
      return;
    }

    let timeout = setTimeout(() => {
      onChange(inputValue);
      setIsDirty(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [inputValue, isDirty, onChange]);

  return (
    <div className={classes}>
      <label
        htmlFor={name}
        className={classNames(
          hideLabel
            ? "sr-only"
            : "block text-sm font-medium leading-6 text-gray-900"
        )}
      >
        {label}
        {props.required && <span className="text-red-500 ml-.5">*</span>}
      </label>

      <div className="mt-1 flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-martEye-400">
        {prefix && (
          <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
            {prefix}
          </span>
        )}
        <input
          ref={ref}
          {...field}
          {...props}
          onBlur={(e) => {
            if (isDirty) {
              onChange(inputValue);
            }
            setIsDirty(false);
            field.onBlur(e);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (isDirty) {
                onChange(inputValue);
              }
              setIsDirty(false);
              field.onBlur(e);
            }
          }}
          onChange={(e) => {
            if (
              fieldType === "text" ||
              type === "date" ||
              type === "datetime"
            ) {
              setInputValue(e.currentTarget.value);
            } else {
              setInputValue(e.currentTarget.valueAsNumber);
            }
            setIsDirty(true);
          }}
          value={inputValue}
          type={fieldType}
          className={classNames(
            "block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 min-h-[40px]",
            prefix ? "pl-0.5" : "pl-3"
          )}
          // allow 2 decimal places
          step={type === "currency" ? "0.01" : props.step}
          min={type === "currency" ? "0.00" : props.min}
          max={type === "currency" ? "100000000.00" : props.max}
        />
        {suffix && (
          <span className="flex select-none items-center pr-3 text-gray-500 sm:text-sm">
            {suffix}
          </span>
        )}
      </div>
      {meta.error && (
        <div className="text-red-500 text-sm mt-1 text-right pr-1">
          {meta.error}
        </div>
      )}
    </div>
  );
});

export default QSInputField;
