import { doc, getDoc } from "firebase/firestore";
import { Customer } from "types";
import { FullSaleContext } from "../ExecuteInSaleContext";

/***
 * Used where we have the sale context. Will use the preloaded data if available.
 *
 * Will throw an error if the customer does not exist.
 */
export async function getOrLoadCustomer(
  ctx: FullSaleContext,
  customerId: string
): Promise<Customer> {
  let { marketId, firestore } = ctx;
  if (!customerId) {
    throw new Error(`Customer ID is required`);
  }

  let possibleCustomer = ctx.customers.find((c) => c.id === customerId);
  if (possibleCustomer) {
    return possibleCustomer;
  }

  let customerRef = doc(
    firestore,
    `/markets/${marketId}/customers/${customerId}`
  );
  let customerDoc = await getDoc(customerRef);
  if (!customerDoc.exists()) {
    throw new Error(`Customer ${customerId} does not exist`);
  }

  possibleCustomer = customerDoc.data() as Customer;
  return possibleCustomer;
}
