import { Fragment, useCallback, useRef } from "react";

//Dialog
import { Button } from "@/components/Buttons";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";

// Data
import CustomerLookupCombobox from "@/components/form/CustomerLook";
import { Field, FieldProps, Form, Formik } from "formik";
import { ClientType, Lot } from "../../../../types";

//Styles
import { pluralise } from "@/components/utils";

// Icons
import { useUrlFragmentState } from "@/components/Flyout/useUrlFragmentState";
import { CustomerItem } from "@/components/sale-page/useCustomerSearch";
import useStudio from "@/data/studio-react/useStudio";
import { XMarkIcon } from "@heroicons/react/20/solid";

interface TransferLotDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  clientType: ClientType;
  lots: Lot[] | null;
  currentUid: string | null;
}

export const TransferLotDialog = (props: TransferLotDialogProps) => {
  const { open, setOpen, clientType, lots, currentUid } = props;
  const cancelButtonRef = useRef(null);

  if (!lots || lots?.length === 0 || !currentUid) {
    return null;
  }

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-martEye-700 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform  rounded-lg bg-white text-left text-martEye-700 shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="px-4 pb-4 pt-5  sm:p-6">
                  <div className="flex items-start justify-between">
                    <DialogTitle
                      as="h3"
                      className="inline-flex items-center gap-x-1.5 text-xl font-bold"
                    >
                      Transfer To a {clientType}
                    </DialogTitle>

                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        className="rounded-full bg-martEye-500 p-1 text-martEye-100  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-martEye-400 hover:bg-martEye-400"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>

                <TransferLotDialogInner
                  clientType={clientType}
                  lots={lots}
                  close={() => setOpen(false)}
                />
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

interface InnerProps {
  clientType: ClientType;
  lots: Lot[];
  close: () => void;
}

interface initialValues {
  newCustomer: CustomerItem | null;
}

const TransferLotDialogInner = (props: InnerProps) => {
  const { clientType, lots, close } = props;
  const lotNumbers = lots.map((lot: Lot) => lot.lotNumber);

  let { updateLots } = useStudio();

  let openCheckout = useOpenCheckout();

  const initialValue: initialValues = {
    newCustomer: null,
  };

  return (
    <div className="px-6">
      <h3 className="font-bold">
        You are transferring {pluralise("Lot", lots.length)}:{" "}
        {lotNumbers.join(", ")}
      </h3>
      <p className="mb-4">
        Please select the <b>{clientType}</b> you would like to transfer the
        lots to.
      </p>

      <Formik
        initialValues={initialValue}
        onSubmit={async (values) => {
          const { newCustomer } = values;
          let isMovingToCasual = newCustomer?.isCasual === true;

          if (clientType === "Buyer") {
            // If the buyer is a casual buyer, we need to update the buyerCasual field and set the buyer id to null to avoid a conflict
            if (lots && newCustomer?.id) {
              const updates = newCustomer.isCasual
                ? { buyerCustomerId: null, buyerCasual: newCustomer.id }
                : {
                    buyerCustomerId: newCustomer.id,

                    // todo - instead of removing the casual here we should handle better when both
                    // are set in the buyer / seller tabs
                    buyerCasual: null,
                  };

              await updateLots(
                lots.map((l) => l.id),
                updates
              );
            }
          }
          if (clientType === "Seller") {
            if (lots && newCustomer?.id) {
              await updateLots(
                lots.map((l) => l.id),
                {
                  sellerCustomerId: newCustomer.id,
                }
              );
            }
          }

          if (isMovingToCasual) {
            close();
          } else {
            if (newCustomer?.id) {
              // close();

              openCheckout({
                clientType,
                userId: newCustomer.id,
                lots,
              });
            }
          }
        }}
      >
        {({ setValues }) => (
          <Form>
            <Field name="newCustomer">
              {({ field, form, meta }: FieldProps) => (
                <CustomerLookupCombobox
                  label=""
                  value={field.value}
                  onChange={(item) => {
                    setValues({
                      newCustomer: item,
                    });
                  }}
                  disallowCasual={clientType === "Buyer" ? false : false}
                />
              )}
            </Field>

            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 -mx-6 mt-4 gap-x-1.5">
              <Button type="submit" className="px-5 py-3">
                Transfer
              </Button>

              <Button
                type="button"
                onClick={close}
                variant="tertiary"
                className="px-5 py-3"
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default TransferLotDialog;

// A special function to open the checkout whilst a casual cart is open
function useOpenCheckout() {
  let [urlParamValues, setUrlParams] = useUrlFragmentState();

  let urlsParamValuesRef = useRef(urlParamValues);
  urlsParamValuesRef.current = urlParamValues;

  return useCallback(
    (options: { clientType: ClientType; userId: string; lots: Lot[] }) => {
      let { clientType, userId, lots } = options;

      let t = urlsParamValuesRef.current?.t ?? "buyers";
      let nextParams = {
        t,
        u: userId,
        ut: 3, // user tab 3 (cart)
        co: clientType,
        colot: lots.map((l) => `${l.saleId}-${l.id}`).join("_"),
      };

      let allParams = Object.keys(urlsParamValuesRef.current ?? {});
      let paramsBeingUsed = Object.keys(nextParams);
      let allOtherParams = allParams.filter(
        (p) => !paramsBeingUsed.includes(p)
      );

      setUrlParams(nextParams, allOtherParams);
    },
    []
  );
}
