import { autoId } from "@/data/ids";
import { Timestamp } from "firebase-admin/firestore";
import { serverTimestamp } from "firebase/firestore";
import { Lot, LotItem, Media } from "types";
import { FullSaleContext } from "../ExecuteInSaleContext";
import { toFirestoreUpdateObject } from "../firebase/toFirestoreUpdateObject";

/***
 * Return a new lot item that can be added to the supplied lot.
 *
 * It will take care of initialising the item and adding any default attributes
 */
export async function createLotItem(
  ctx: FullSaleContext,
  lot: Lot,

  options?: {
    attributes?: {
      [key: string]: string | number | boolean | Timestamp | Media | null;
    };

    metadata?: {
      [key: string]: any;
    };

    // if true, the lot will not be saved to firestore
    doNotWriteToFirestore?: boolean;
  }
): Promise<LotItem> {
  let { currentUid } = ctx;

  if (!currentUid) throw new Error(`No current user`);

  let itemId = autoId();

  // The default values are stored on the lot

  let newItem: LotItem = {
    id: itemId,
    createdAt: serverTimestamp() as unknown as Timestamp,
    updatedAt: serverTimestamp() as unknown as Timestamp,
    updatedBy: currentUid,
    index: calculateNextItemIndex(lot),
    attributes: {
      ...lot.itemAttributeDefaults,
      ...options?.attributes,
    },
    notes: [],
    metadata: {
      ...options?.metadata,
    },
  };

  if (options?.doNotWriteToFirestore) {
    return newItem;
  }

  return await saveLotItem(ctx, lot.id, newItem);
}

async function saveLotItem(
  ctx: FullSaleContext,
  lotId: string,
  lotItem: LotItem
) {
  let update: Partial<Lot> = {
    itemMap: {
      [lotItem.id]: lotItem,
    },
  };

  let flattenedUpdate = toFirestoreUpdateObject(update, {
    preserveEmptyObjects: true, // this keeps empty metadata and attributes fields as objects instead of nulls
  });
  let updatedLot = await ctx.studio.updateLot(lotId, flattenedUpdate);

  return updatedLot.itemMap[lotItem.id];
}

const indexSpacing = 10;
const startLotIndex = 10;
export function calculateNextItemIndex(lot: Lot) {
  let allItems = Object.values(lot.itemMap).filter((i) => "index" in i); // filter here as the QuickSheet can include deleteField() sentinel values
  let maxIndex = allItems.length
    ? Math.max(...allItems.map((i) => i.index))
    : startLotIndex;
  let nextIndex = maxIndex + indexSpacing;
  return nextIndex;
}
