import { cloudFunction } from "../helpers/cloudfunction";

interface CreateBACSRequest {
  /**
   * ISO 8601 date string
   */
  paymentDate?: string;
}

interface CreateBACSResponse {
  url: string;
}

/**
 * Create a BACS file for a market
 * @param marketId
 * @param info
 * @returns
 */
export async function createBACSFile(
  marketId: string,
  info: CreateBACSRequest
) {
  let createBACSCall = cloudFunction<
    CreateBACSRequest & { marketId: string },
    CreateBACSResponse
  >("CreateBACSCall");

  if (!info) {
    throw new Error(`No sale info to create a BACS file`);
  }

  let result = await createBACSCall({ ...info, marketId });

  return result;
}
