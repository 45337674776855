// Types
import { DraftInvoice, Invoice, InvoiceLineItem } from "types";

// JS PDF
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

export interface TableConfig {
  tagsPerRow: number;
  margin: number;
  headerFillColor: string;
  headerTextColor: string;
  stripeFillColor: string;
  stripeTextColor: string;
}

export default function Table(
  doc: jsPDF,
  invoice: Invoice | DraftInvoice,
  config: TableConfig
) {
  const { total, tagsRead, tables } = formatBodyData(invoice, config);

  // Set the tags read and total tags to global variables
  doc.tagsRead = tagsRead;
  doc.totalItems = total;

  const margin = config.margin;
  const cellWidth = (doc.internal.pageSize.getWidth() - margin * 2) / 12;
  let headerHeight = 0;
  let cellHeight = 0;

  // 1. Draw the top header table on the first page (Which will give us the height of the header)
  headerHeight = runningTableHeader(
    doc,
    config,
    cellWidth,
    doc.mainHeaderHeight
  );

  let startY = doc.lastAutoTable.finalY;
  let top = doc.runningHeaderHeight + headerHeight;

  tables.map((table: any, index: number) => {
    // We now need to work out the next startY position

    let startPageNumber = doc.getCurrentPageInfo().pageNumber;

    autoTable(doc, {
      startY,
      head: table.head,
      body: table.body,
      margin: { left: margin, right: margin, top: top, bottom: margin + 7.5 },
      headStyles: {
        fillColor: config.stripeFillColor,
        textColor: config.stripeTextColor,
        fontSize: 10,
        font: "Inter",
        fontStyle: "normal",
        cellPadding: { top: 2, bottom: 2, left: 4, right: 4 },
      },
      bodyStyles: {
        textColor: "#000000",
        fontSize: 9,
        font: "Inter",
        fontStyle: "normal",
        cellPadding: { top: 0.5, bottom: 0.5, left: 4, right: 4 },
      },
      theme: "plain",
      showHead: "everyPage",
      columnStyles: {
        0: { cellWidth: cellWidth },
        1: { cellWidth: cellWidth },
        2: { cellWidth: cellWidth },
        3: { cellWidth: cellWidth },
        4: { cellWidth: cellWidth },
        5: { cellWidth: cellWidth },
        6: { cellWidth: cellWidth },
        7: { cellWidth: cellWidth },
        8: { cellWidth: cellWidth },
        9: { cellWidth: cellWidth },
        10: { cellWidth: cellWidth },
        11: { cellWidth: cellWidth },
      },
      didDrawCell: function (data) {
        // Need to get the height of the cell
        const height = data.cell.height;
        headerHeight = height;
        cellHeight = height;
      },
      willDrawCell: function (data) {
        // If the cell is the index 0 and is the head row
        if (data.cell.section === "head" && data.column.dataKey === 0) {
          // Check if we are on the same page
          let currentPageNumber = doc.getCurrentPageInfo().pageNumber;

          if (startPageNumber !== currentPageNumber) {
            // Append the text to the end of the cell
            data.cell.text = [data.cell.text[0] + " cont."];
          }
        }
      },

      didParseCell: function (data) {
        if (data.cell.section === "body") {
          const largerPadding = 2;
          const firstRowIndex = data.table.body[0].index;
          const lastRowIndex =
            data.table.body[data.table.body.length - 1].index;

          if (firstRowIndex === lastRowIndex) {
            // Increase the cell padding on the top and bottom
            data.cell.styles.cellPadding = {
              top: largerPadding,
              bottom: largerPadding,
              left: 4,
              right: 4,
            };
          } else if (data.row.index === firstRowIndex) {
            // Increase the cell padding on the top
            data.cell.styles.cellPadding = {
              top: largerPadding,
              bottom: 0.5,
              left: 4,
              right: 4,
            };
          } else if (data.row.index === data.table.body.length - 1) {
            // Increase the cell padding on the bottom
            data.cell.styles.cellPadding = {
              top: 0.5,
              bottom: largerPadding,
              left: 4,
              right: 4,
            };
          }
        }
      },
    });

    // @ts-ignore
    startY = doc.lastAutoTable.finalY;
  });

  // We now need to add in the table header on every page

  const pageCount = doc.getNumberOfPages();

  for (var i = 2; i <= pageCount; i++) {
    // Set the current page
    doc.setPage(i);

    runningTableHeader(doc, config, cellWidth, doc.runningHeaderHeight);
  }
}

function runningTableHeader(
  doc: jsPDF,
  config: TableConfig,
  cellWidth: number,
  startY: number
) {
  let cellHeight = 0;

  autoTable(doc, {
    startY,
    head: [
      [
        {
          content: "Lot Number",
          colSpan: 2,
        },
        {
          content: "Quantity",
          colSpan: 2,
        },
        {
          content: "Description",
          colSpan: 6,
        },
        {
          content: "Total",
          colSpan: 2,
          styles: { halign: "right" },
        },
      ],
    ],
    headStyles: {
      fillColor: config.headerFillColor,
      textColor: config.headerTextColor,
      fontSize: 8,
      font: "Inter",
      fontStyle: "bold",
      cellPadding: { top: 3, bottom: 3, left: 4, right: 4 },
    },
    body: [],
    columnStyles: {
      0: { cellWidth: cellWidth },
      1: { cellWidth: cellWidth },
      2: { cellWidth: cellWidth },
      3: { cellWidth: cellWidth },
      4: { cellWidth: cellWidth },
      5: { cellWidth: cellWidth },
      6: { cellWidth: cellWidth },
      7: { cellWidth: cellWidth },
      8: { cellWidth: cellWidth },
      9: { cellWidth: cellWidth },
      10: { cellWidth: cellWidth },
      11: { cellWidth: cellWidth },
    },
    margin: { left: config.margin, right: config.margin },
    theme: "plain",
    didDrawCell: function (data) {
      // Need to get the height of the cell
      const height = data.cell.height;
      cellHeight = height;
    },
    didParseCell: function (data) {
      // Set the header content to uppercase
      if (data.section === "head") {
        data.cell.text[0] = data.cell.text[0].toUpperCase();
      }
    },
  });

  return cellHeight;
}

function formatBodyData(invoice: Invoice | DraftInvoice, config: TableConfig) {
  let tagsRead = 0;
  let total = 0;

  const tables: any = [];

  // Run through the lineItems
  invoice.lineItems.map((item: InvoiceLineItem, index: Number) => {
    // TODO if this ends up being the same for cattle and sheep then we can run off the same functions
    if (item.superType !== "Sheep" && item.superType !== "Goats") {
      return;
    }

    let attributes: {
      [key: string]: string;
    }[] = Object.values(item.metadata.itemAttributesByItemId);
    const earTags: string[] = [];

    attributes.forEach(function (item) {
      earTags.push(item["@eartag"]);
    });

    tagsRead += earTags.length;
    total += item.quantity;

    // We now need to split the tags into groups of 4 and add them to the page
    const body: any = [];

    earTags.forEach((tag: string, index: number) => {
      if (index % config.tagsPerRow === 0) {
        body.push([]);
      }

      let colSpan = 12 / config.tagsPerRow;

      body[body.length - 1].push({
        content: tag,
        colSpan: colSpan,
      });
    });

    const table = {
      head: [
        [
          {
            content: item?.metadata?.lotNumber ?? "-",
            colSpan: 2,
          },
          {
            content: item?.quantity ?? "-",
            colSpan: 2,
          },
          {
            content: item?.description ?? "",
            colSpan: 6,
          },
          {
            content: earTags.length ?? 0,
            styles: { halign: "right" },
            colSpan: 2,
          },
        ],
      ],
      body: body,
    };

    tables.push(table);
  });

  return { tables, tagsRead, total };
}
