import { classNames } from "@/data/classnames";
import { getCustomer } from "@/data/customer";
import { downloadURL } from "@/data/files";
import useStudio from "@/data/studio-react/useStudio";
import { useStudioStream } from "@/data/studio-react/useStudioStream";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import {
  ArrowDownTrayIcon,
  CheckIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/20/solid";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import {
  ArrowTopRightOnSquareIcon,
  DocumentIcon,
  EnvelopeOpenIcon,
  PrinterIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import getDocuments, { DocumentSpec } from "documents/_importDocs";
import { Timestamp } from "firebase/firestore";
import {
  Fragment,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { toast } from "sonner";
import {
  DraftInvoice,
  Invoice,
  Market,
  Payout,
  PrintJob,
  Printer,
  ProductCodeConfiguration,
  SettingsMarketDefaults,
  SuperType,
} from "types";
import { Spinner } from "../Buttons";

interface DocumentDialogProps {
  market: Market | null;
  invoice: Invoice | null | DraftInvoice;
  payouts: Payout[];
  printers: Printer[];
  close: () => void;
  isOpen: boolean;
}

interface DocumentAwarePrintJob extends PrintJob {
  document: DocumentSpec | { name: "bundle" };
}

export function DocumentDialog(props: DocumentDialogProps) {
  let { invoice, isOpen, close } = props;

  let defaultPrinterId = useMemo(() => {
    let lastPrinter = localStorage.getItem("last-used-printer");
    // ensure exists
    if (props.printers.find((p) => p.id === lastPrinter)) {
      return lastPrinter;
    }

    // Else return the first printer
    return props.printers[0]?.id || null;
  }, [props.printers]);

  return (
    <DialogChrome isOpen={isOpen} close={close}>
      {invoice && isOpen && (
        <DocumentDialogInner
          key={`${invoice.id}-${isOpen}`}
          {...props}
          defaultPrinterId={defaultPrinterId}
        />
      )}
    </DialogChrome>
  );
}

export function DocumentDialogInner(
  props: DocumentDialogProps & { defaultPrinterId: string | null }
) {
  let { invoice, payouts, printers, market, close, defaultPrinterId } = props;

  let docsToSelect = useDocumentIdsToSelect(invoice);

  switch (market?.id) {
    case "hexham":
      docsToSelect = [
        "invoice",
        "movement-notification-sheep-gb-with-tags",
        "passout",
      ];
      break;
    case "mwalton":
      docsToSelect = [
        "invoice",
        "movement-notification-sheep-gb-with-tags",
        "passout",
      ];
      break;
    case "ruthin":
      docsToSelect = [
        "invoice",
        "movement-notification-sheep-gb",
        "sheep-tag-list",
      ];
      break;
  }

  let { state, actions } = useDocumentDialog(
    market,
    invoice,
    payouts,
    printers,
    close,
    docsToSelect,
    defaultPrinterId
  );

  return (
    <div className="bg-black rounded-lg px-5 py-4">
      {/* Header */}
      <div className="flex flex-row items-center">
        <DocumentIcon className="h-6 w-6 text-martEye-400" />
        <DialogTitle
          as="h3"
          className="text-base font-bold leading-6 text-white flex-grow ml-2"
        >
          {invoice?.status === "draft" ? "Draft" : "Select"} Documents
        </DialogTitle>

        <button
          type="button"
          className="flex h-9 w-9 items-center justify-center rounded-full border-2 border-white/40 text-white  hover:bg-white/10"
          onClick={close}
        >
          <XMarkIcon className="h-4 w-4" />
        </button>
      </div>

      {/* Content */}

      <div className="flex flex-col gap-4 mt-4 max-h-96 overflow-auto">
        {/* Two columns */}
        <div className="flex flex-row flex-grow">
          <div className="flex-grow ml-1 mt-2">
            <DocumentList
              marketId={market?.id || null}
              invoiceId={invoice?.id || null}
              docs={state.docs}
              setDocuments={actions.setDocuments}
              selectedDocIds={state.selectedDocIds}
              isDraft={invoice?.status === "draft"}
            />
          </div>

          {/* Config and actions */}
          <div className="flex flex-col w-64 pl-6 py-3 border-l border-gray-200/30">
            <div className="flex flex-col flex-grow">
              <PrinterList
                label="Printer"
                printers={state.printers}
                selected={state.printer}
                setSelected={actions.setPrinter}
              />

              <div className="mt-4">
                <PrinterList
                  label="Cheque Printer"
                  printers={state.printers}
                  selected={state.chequePrinter}
                  setSelected={actions.setChequePrinter}
                  disabled={!state.chequePrinterRequired}
                />
              </div>

              <div className="mt-4">
                <div>
                  <label
                    htmlFor="copies"
                    className="block text-sm font-normal text-[#BDBDBD]"
                  >
                    Copies
                  </label>
                  <div className="mt-0.5 flex rounded-md shadow-sm">
                    <div className="relative flex flex-grow items-stretch focus-within:z-10">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <DocumentDuplicateIcon
                          className="h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        name="copies"
                        id="copies"
                        className="block w-full rounded-md border-0 py-1.5 pl-10 text-white ring-1 ring-inset ring-gray-200/30 placeholder:text-gray-100 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6 bg-transparent disabled:opacity-60"
                        value={state.copies}
                        // type="number"

                        // change this back after kelso
                        //value={"pre-set"}
                        // disabled={true}
                        onChange={(e) => {
                          // We clear this up on blur
                          actions.setCopies(
                            e.target.value as unknown as number
                          );
                        }}
                        onBlur={(e) => {
                          let value = parseInt(e.target.value);
                          if (isNaN(value) || value < 1) {
                            actions.setCopies(1);
                          } else {
                            actions.setCopies(value);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ActionButtons
              canDownload={state.canDownload}
              emailDocuments={actions.emailDocuments}
              downloadDocuments={actions.downloadDocuments}
              canPrint={state.canPrint}
              canEmail={state.canEmail}
              printDocuments={actions.printDocuments}
              isLoading={state.isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function ActionButtons(props: {
  canDownload: boolean;
  downloadDocuments: () => void;
  emailDocuments: () => void;

  canEmail: boolean;
  canPrint: boolean;
  printDocuments: () => void;
  isLoading: boolean;
}) {
  let { canDownload, downloadDocuments } = props;
  let email = (
    <button
      type="button"
      className="relative inline-flex items-center justify-center gap-x-1 rounded-lg bg-transparent border border-gray-200/30 px-5 py-3 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 disabled:cursor-not-allowed disabled:opacity-60 disabled:hover:bg-transparent"
      onClick={props.emailDocuments}
      disabled={!props.canEmail || props.isLoading}
    >
      <EnvelopeOpenIcon className="h-4 w-4" aria-hidden="true" />
      Email
    </button>
  );

  let download = (
    <button
      type="button"
      className="relative inline-flex items-center justify-center gap-x-1 rounded-lg bg-transparent border border-gray-200/30 px-5 py-3 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 disabled:cursor-not-allowed disabled:opacity-60 disabled:hover:bg-transparent"
      onClick={downloadDocuments}
      disabled={!canDownload || props.isLoading}
    >
      <ArrowDownTrayIcon className="h-4 w-4" aria-hidden="true" />
      Download
    </button>
  );

  let print = (
    <button
      type="button"
      className="relative inline-flex items-center justify-center gap-x-3 rounded-lg bg-green-600 px-5 py-3 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 disabled:cursor-not-allowed disabled:opacity-60 disabled:hover:bg-green-600"
      onClick={props.printDocuments}
      disabled={!props.canPrint || props.isLoading}
    >
      {props.isLoading && (
        <div className="absolute right-3 h-5 w-5 flex items-center justify-center">
          <Spinner />
        </div>
      )}
      <PrinterIcon className="absolute left-3 h-5 w-5" aria-hidden="true" />
      Print
    </button>
  );

  // let printAndEmail = (
  //   <button
  //     type="button"
  //     className="relative inline-flex items-center justify-center gap-x-3 rounded-lg bg-green-600 px-5 py-3 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
  //   >
  //     <PrinterIcon className="absolute left-3 h-5 w-5" aria-hidden="true" />
  //     Print & Email
  //   </button>
  // );

  return (
    <div className="flex flex-col gap-2 mt-4">
      <div className="flex justify-between">
        {email}
        {download}
      </div>
      {print}
    </div>
  );
}

function DocumentList(props: {
  marketId: string | null;
  invoiceId: string | null;
  docs: DocumentSpec[];
  setDocuments: (docIds: string[]) => void;
  selectedDocIds: string[];
  isDraft: boolean;
}) {
  let documents = props.docs;

  let selected = documents.filter((d) => props.selectedDocIds.includes(d.id));

  let headerCheckboxRef = useRef<HTMLInputElement>(null);

  if (headerCheckboxRef.current) {
    if (selected.length !== documents.length && selected.length > 0) {
      // Set to indeterminate
      headerCheckboxRef.current.indeterminate = true;
    } else if (selected.length === documents.length) {
      headerCheckboxRef.current.indeterminate = false;
    } else {
      headerCheckboxRef.current.indeterminate = false;
    }
  }

  function toggle(doc: DocumentSpec) {
    let next = [...props.selectedDocIds];
    if (props.selectedDocIds.includes(doc.id)) {
      next = next.filter((id) => id !== doc.id);
    } else {
      next.push(doc.id);
    }
    props.setDocuments(next);
  }

  function selectAll() {
    props.setDocuments(documents.map((d) => d.id));
  }

  function deselectAll() {
    props.setDocuments([]);
  }

  // Aaron - Need proper URL for printing but local url for development
  // const windowLocation = window.location.origin.includes("localhost")
  //   ? "https://beta.marteyestudio.com"
  //   : window.location.origin;
  const windowLocation = window.location.origin;

  return (
    <fieldset className="w-9/12">
      <legend className="text-base font-semibold leading-6 text-gray-900 hidden">
        Select Documents
      </legend>
      <div className="divide-y divide-gray-200/30 border-b border-gray-200/30 ">
        {documents.length > 1 ? (
          <div className="relative flex items-center pt-1 pb-1 bg-black ">
            <div className="flex h-6 items-center px-2">
              <input
                disabled={false}
                ref={headerCheckboxRef}
                id={`All`}
                name={"All"}
                type="checkbox"
                className="h-4 w-4 rounded border-[#545454] text-martEye-400 focus:ring-martEye-400 bg-[#545454] cursor-pointer  disabled:cursor-not-allowed disabled:opacity-60"
                onChange={(e) => {
                  if (e.target.checked) {
                    selectAll();
                  } else {
                    deselectAll();
                  }
                }}
                checked={
                  selected.length === documents.length && selected.length > 0
                }
              />
            </div>
            <div className="min-w-0 flex-1 flex text-sm leading-6 ml-2">
              <label
                htmlFor={"All"}
                className="select-none font-semibold text-[#CBCBCB]/70 flex-grow cursor-pointer"
              ></label>
            </div>
          </div>
        ) : null}

        {documents.map((doc, idx) => (
          <div key={doc.id} className="relative flex items-center py-2.5  px-2">
            <div className="flex h-6 items-center">
              <input
                disabled={false}
                id={`${doc.id}`}
                name={`${doc.id}`}
                type="checkbox"
                className="h-4 w-4 rounded border-[#545454] text-martEye-400 focus:ring-martEye-400 bg-[#545454] cursor-pointer disabled:cursor-not-allowed disabled:opacity-60"
                onChange={() => toggle(doc)}
                checked={selected.includes(doc)}
              />
            </div>
            <div className="min-w-0 flex-1 flex text-sm leading-6 ml-4">
              <label
                htmlFor={`${doc.id}`}
                className="select-none font-normal text-[#CBCBCB] flex-grow cursor-pointer"
              >
                {doc.name}
              </label>
            </div>
            <button
              type="button"
              className="flex flex-end items-center justify-center ml-4"
              onClick={() => {
                window.open(
                  `${windowLocation}/api/${props.marketId}/documents/${
                    doc.id
                  }/${props.invoiceId}${props.isDraft ? "?draft=true" : ""}`,
                  "_blank"
                );
              }}
            >
              <ArrowTopRightOnSquareIcon className="h-4 w-4 text-gray-400/50 hover:text-gray-400 cursor-pointer -mt-0.5" />
            </button>
          </div>
        ))}
      </div>
    </fieldset>
  );
}

interface PrinterListProps {
  label: string;
  printers: Printer[];
  selected: Printer | null;
  setSelected: (printer: Printer) => void;
  disabled?: boolean;
}

function PrinterList(props: PrinterListProps) {
  let { printers, selected, setSelected, disabled } = props;
  return (
    <Listbox value={selected} onChange={setSelected} disabled={disabled}>
      {({ open }) => (
        <>
          <Label className="block text-sm font-normal leading-6 text-[#BDBDBD] ml-1 disabled:opacity-60">
            {props.label}
          </Label>
          <div className="relative mt-0">
            <ListboxButton className="relative w-full cursor-default rounded-md bg-transparent py-1.5 pl-3 pr-10 text-left text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300/30 focus:outline-none focus:ring-2 focus:ring-green-600 sm:text-sm sm:leading-6 disabled:opacity-60 disabled:cursor-not-allowed">
              <span className="flex items-center">
                {selected ? (
                  <span
                    // aria-label={selected.online ? "Online" : "Offline"}
                    className={classNames(
                      // selected.online ? "bg-green-400" : "bg-red-400",
                      "bg-gray-300",
                      "inline-block h-2 w-2 flex-shrink-0 rounded-full"
                    )}
                  />
                ) : null}
                <span
                  className={classNames(
                    "block truncate",
                    selected ? "ml-3" : "ml-0 text-white/70"
                  )}
                >
                  {disabled
                    ? "Not required"
                    : selected?.friendlyName || "Select a printer"}
                </span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>

            <Transition
              show={open === true}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-full border border-gray-300/50 overflow-auto rounded-md bg-black py-1 text-base shadow-lg ring-1 ring-red-100 ring-opacity-5 focus:outline-none sm:text-sm">
                {printers.map((printer) => (
                  <ListboxOption
                    key={printer.id}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-green-600 text-white" : "",
                        !active ? "text-gray-100" : "",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={printer}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              // printer.online ? "bg-green-400" : "bg-red-400",
                              "bg-gray-300",
                              "inline-block h-2 w-2 flex-shrink-0 rounded-full"
                            )}
                            aria-hidden="true"
                          />
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "ml-3 block truncate"
                            )}
                          >
                            {printer.friendlyName}
                            {/* <span className="sr-only">
                              {" "}
                              is {printer.online ? "online" : "offline"}
                            </span> */}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-martEye-400",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

function DialogChrome(props: {
  isOpen: boolean;
  close: () => void;
  children: ReactNode;
}) {
  let { isOpen, close, children } = props;

  return (
    <Transition show={isOpen === true} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-60"
        initialFocus={undefined}
        onClose={close}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-60 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full max-w-2xl">
                {isOpen ? children : null}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

function useDocumentDialog(
  market: Market | null,
  invoice: Invoice | null | DraftInvoice,
  payouts: Payout[],
  printers: Printer[],
  close: () => void,
  docsToSelect: string[], // the selection to start with
  defaultPrinterId: string | null
) {
  let hasChequeToPrint =
    payouts.find((p) => p.method === "Cheque") !== undefined;
  let documents = applicableDocuments(market, invoice).filter((d) => {
    if (!hasChequeToPrint) {
      // if no cheque to print, filter out cheque documents
      return !d.requiresChequePrinter;
    }
    return true;
  });

  // Initialize state for hasEmail
  const [hasEmail, setHasEmail] = useState(false);

  // Fetch customer and determine if they have an email
  useEffect(() => {
    (async () => {
      if (market && invoice) {
        const customer = await getCustomer(market.id, invoice.customerId);
        setHasEmail(customer?.email !== undefined);
      }
    })();
  }, [market, invoice]);

  let [state, setState] = useState({
    selectedDocIds: docsToSelect,
    printerId: defaultPrinterId || null,
    chequePrinterId:
      printers
        .sort((a, b) => a.friendlyName.localeCompare(b.friendlyName))
        .find((p) => p.chequePrinter)?.id || null,
    copies: 1,
    isLoading: false,
  });

  let { print, sendInvoiceEmail } = useStudio();

  let setDocuments = (docIds: string[]) => {
    setState((prev) => ({ ...prev, selectedDocIds: docIds }));
  };
  let setPrinter = (printer: Printer) => {
    setState((prev) => ({ ...prev, printerId: printer.id }));
    // Also store in local storage
    localStorage.setItem("last-used-printer", printer.id);
  };
  let setChequePrinter = (printer: Printer) => {
    setState((prev) => ({ ...prev, chequePrinterId: printer.id }));
  };
  let setCopies = (copies: number) => {
    setState((prev) => ({ ...prev, copies }));
  };

  let setIsLoading = (isLoading: boolean) => {
    setState((prev) => {
      let update: any = { isLoading };
      if (isLoading) {
        update = { ...update };
      }
      return { ...prev, ...update };
    });
  };

  //Aaron
  // We need this so we can print to the puck but not if downloading locally
  // const windowLocation = window.location.origin.includes("localhost")
  //   ? "https://beta.marteyestudio.com"
  //   : window.location.origin;
  const windowLocation = window.location.origin;

  let selectedPrinter = printers.find((p) => p.id === state.printerId) || null;
  let chequePrinter =
    printers.find((p) => p.id === state.chequePrinterId) || null;

  let hasDocSelected = state.selectedDocIds.length > 0;
  let hasPrinterSelected = state.printerId !== null;
  let hasChequePrinterSelected = state.chequePrinterId !== null;
  let printerIsOnline = true; // we don't yet have this
  let copiesValid = state.copies > 0;

  // a doc that requires a cheque printer
  let hasChequeDocSelected = documents
    .filter((d) => state.selectedDocIds.includes(d.id))
    .some((d) => d.requiresChequePrinter);
  let chequePrinterRequired = hasChequeToPrint && hasChequeDocSelected;

  let canDownload = hasDocSelected;

  let canEmail = hasDocSelected && hasEmail;
  let canPrint =
    hasDocSelected &&
    hasPrinterSelected &&
    printerIsOnline &&
    copiesValid &&
    (chequePrinterRequired ? hasChequePrinterSelected : true);

  let isDraft = invoice?.status === "draft";

  let selectedDocs = state.selectedDocIds
    .map((docId) => {
      return documents.find((doc) => doc.id === docId);
    })
    .filter(Boolean) as DocumentSpec[];

  let urls = selectedDocs.map(
    (docId) =>
      `${windowLocation}/api/${market?.id}/documents/${docId?.id}/${
        invoice?.id
      }${isDraft ? "?draft=true" : ""}`
  );

  let emailDocuments = async () => {
    if (!market) {
      console.error("Market is required to email documents");
      return;
    }

    console.log("emailing ", urls);

    await sendInvoiceEmail(market.id, {
      customerId: invoice?.customerId || "",
      invoiceId: invoice?.id || "",
      documentIds: selectedDocs.map((d) => d.id),
      //subdomain: "beta",
    });

    toast.success(`Emailing ${selectedDocs.length} documents`);
    //  close();
  };

  let downloadDocuments = () => {
    if (!market) {
      console.error("Market is required to download documents");
      return;
    }
    console.log("downloading ", urls);

    // trigger the browser to download the documents from their URLS
    urls.forEach((url) => {
      downloadURL(url);
    });

    toast.success(`Downloading ${selectedDocs.length} documents`);
    //  close(); // we probably would prefer to keep the dialog open on email or download
  };

  let printDocuments = async () => {
    if (!market || !invoice) {
      console.error("Market and invoice are required to print documents");
      return;
    }
    if (!state.printerId) {
      console.error("Printer is required to print documents");
      return;
    }

    setIsLoading(true);
    let handle = toast.loading("Printing documents");

    let docsToPrint = state.selectedDocIds
      .map((docId) => {
        return documents.find((doc) => doc.id === docId);
      })
      .filter(Boolean) as DocumentSpec[];

    // let printJobs: DocumentAwarePrintJob[] = docsToPrint
    //   .filter((doc) => !doc.requiresChequePrinter)
    //   .map((doc) => ({
    //     id: `${doc.id}-${invoice.id}`,
    //     createdAt: Timestamp.now(),
    //     url: `${windowLocation}/api/${market.id}/documents/${doc.id}/${
    //       invoice.id
    //     }${isDraft ? "?draft=true" : ""}`,
    //     deviceId: state.printerId!,
    //     copies: state.copies,
    //     isCheque: false,
    //     document: doc,
    //   }));

    // We bunde the pdf into one doc to print so as not to hit contention at the printer with multiple jobs
    let bundleUrl = new URL(
      `${windowLocation}/api/${market.id}/documents/invoice-bundle/${
        invoice.id
      }${isDraft ? "?draft=true" : ""}`
    );

    // change after kelso
    // let docIdToCopies: { [key: string]: number } = {
    //   invoice: 1,
    //   "movement-notification-sheep-gb": 2,
    //   //passout: 2,
    //   "sheep-tag-list": 2,
    // };

    let docIdToCopies: { [key: string]: number } = {};

    switch (market?.id) {
      case "hexham":
        docIdToCopies = {
          invoice: 2,
          "movement-notification-sheep-gb-with-tags": 3,
          passout: 1,
        };
        break;
      case "mwalton":
        docIdToCopies = {
          invoice: 2,
          "movement-notification-sheep-gb-with-tags": 3,
          passout: 1,
        };
        break;
      case "ruthin":
        docIdToCopies = {
          invoice: 1,
          "movement-notification-sheep-gb": 2,
          "sheep-tag-list": 2,
        };
        break;
    }

    docsToPrint.forEach((doc) => {
      let copies = docIdToCopies[doc.id] || 1;
      for (let i = 0; i < copies; i++) {
        bundleUrl.searchParams.append("docId", doc.id);
      }
    });

    let printJobBundle: DocumentAwarePrintJob = {
      id: `${docsToPrint.map((d) => d.id).join("-")}-${invoice.id}`,
      createdAt: Timestamp.now(),
      url: bundleUrl.toString(),
      deviceId: state.printerId!,
      copies: 1,
      isCheque: false,
      document: { name: "bundle" },
    };

    let chequeDocsToPrint = docsToPrint.filter(
      (doc) => doc.requiresChequePrinter
    );
    if (chequeDocsToPrint.length > 0 && !state.chequePrinterId) {
      console.error("Cheque printer is required to print cheque documents");
      return;
    }

    let chequePrintJobs: DocumentAwarePrintJob[] = chequeDocsToPrint.flatMap(
      (doc) => {
        // We need to print a cheque job for each cheque payout
        return payouts
          .filter((p) => p.method === "Cheque")
          .map((payout) => {
            let job: DocumentAwarePrintJob = {
              id: `${doc.id}-${invoice.id}-${payout.id}`,
              createdAt: Timestamp.now(),
              url: `${windowLocation}/api/${market.id}/documents/${doc.id}/${
                invoice.id
              }?payoutId=${payout.id}${isDraft ? "&draft=true" : ""}`,
              deviceId: state.chequePrinterId!,
              copies: 1, // only ever print one cheque
              isCheque: true,
              document: doc,
              chequePayoutId: payout.id,
            };

            return job;
          });
      }
    );

    let allPrintJobs = [
      // ...printJobs,
      printJobBundle,
      ...chequePrintJobs,
    ];

    try {
      console.log("Printing", { allPrintJobs });
      let response = await print(market.id, allPrintJobs);
      toast.dismiss(handle);
      console.log("Print response", response);
      let hasErrors = response.some((r) => r.result.status === "error");

      if (hasErrors) {
        response.forEach((r) => {
          if (r.result.status === "error") {
            let job = allPrintJobs.find((j) => j.id === r.jobId);
            let message = `Unable to print ${job?.document.name}: ${r.result.message}`;
            toast.error(message);
          }
        });
        console.error(response);
      } else {
        let docCount = allPrintJobs.reduce((acc, job) => acc + job.copies, 0);
        toast.success(`Printing ${docCount} documents`);
        close();
      }
    } catch (e) {
      toast.dismiss(handle);
      let message = (e as Error).message || "An error occurred while printing";
      toast.error(message);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    state: {
      docs: documents,
      selectedDocIds: state.selectedDocIds,

      printers,
      printer: selectedPrinter,
      chequePrinter,

      hasChequeToPrint,
      chequePrinterRequired,

      copies: state.copies,

      canPrint,
      canEmail,
      canDownload,

      isLoading: state.isLoading,
    },
    actions: {
      setDocuments,
      setPrinter,
      setChequePrinter,
      setCopies,
      downloadDocuments,
      emailDocuments,
      printDocuments,
    },
  };
}

// Reutns a list of documents that are applicable to the current invoice
function applicableDocuments(
  market: Market | null,
  invoice: Invoice | null | DraftInvoice
) {
  if (!market || !invoice) {
    return [];
  }

  let allSuperTypes = Array.from(
    new Set(
      invoice.lineItems
        .flatMap((lineItem) => lineItem.superType)
        .filter(Boolean)
    )
  ) as SuperType[];

  let docs = getDocuments();

  return docs
    .filter((d) => d.objectType === "invoice")
    .filter((d) => {
      return d.isAvailableFor(market, allSuperTypes, invoice.clientType);
    });
}

/***
 * all documents are selected by default.
 *
 * The config (e.g. product codes) can be set to deselect certain documents
 *
 * E.g. FCI may not be needed for some codes
 */
function useDocumentIdsToSelect(invoice: Invoice | null | DraftInvoice) {
  let docs = getDocuments();
  let allDocIds = docs.map((d) => d.id);

  let marketDefaults = useStudioStream("sale:marketDefaultSettings");
  let productCodeConfigs = useStudioStream("sale:productCodes");

  if (!invoice) {
    return [];
  }

  let superTypes = invoice?.superTypes || [];
  let productCodes = invoice.lineItems
    .map((li) => li.lotProductCode)
    .filter(Boolean)
    .map((code) => productCodeConfigs.find((c) => c.code === code))
    .filter(Boolean) as ProductCodeConfiguration[];

  // All product codes have to ask for a document to be deselected for it to be
  // the most specific configuration wins

  let selectDocumentIdsMap =
    marketDefaults?.selectDocumentIdsMap ||
    ({} as SettingsMarketDefaults["selectDocumentIdsMap"]);

  let hasSuperType = superTypes.some(
    (superType) => superType in selectDocumentIdsMap
  );
  let superTypeSelected = Array.from(
    new Set(
      superTypes.flatMap((superType) => {
        return selectDocumentIdsMap[superType] || [];
      })
    )
  );

  let hasProductCode = productCodes.some((code) => "selectDocumentIds" in code);
  let productCodeSelected = Array.from(
    new Set(
      productCodes.flatMap((code) => {
        return code.selectDocumentIds || [];
      })
    )
  );

  if (hasProductCode) {
    return productCodeSelected;
  }

  if (hasSuperType) {
    return superTypeSelected;
  }

  // default all selected
  return allDocIds;
}
