import { useOpenTemporaryAccountFlyout } from "@/components/casualAccounts/TemporaryAccountFlyout";
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Transition,
} from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { PlusCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { AnimatePresence, motion } from "framer-motion";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { classNames } from "../../data/classnames";
import { Address, Lot } from "../../types";
import { useOpenPersonFlyout } from "../PersonFlyout/PersonFlyout";

import { usePopper } from "react-popper";

import { useShowDialog } from "@/components/ConfirmationDialogProvider";

import {
  CustomerItem,
  useCustomerSearch,
} from "@/components/sale-page/useCustomerSearch";
import { query } from "firebase/firestore";
import { Button } from "../Buttons";
import { CPHIcon, HerdIcon, SheepIcon } from "../Icons";

interface CustomerLookupComboboxProps {
  label: string;
  customerType?: string;
  icon?: any | null;
  disabled?: boolean;
  value: CustomerItem | null;
  onChange: (value: CustomerItem | null) => void;
  disallowCasual?: boolean;
  previousCustomer?: CustomerItem | null;
}

export default function CustomerLookupCombobox(
  props: CustomerLookupComboboxProps
) {
  const popperElRef = useRef(null);
  let { label, icon: LabelIcon, disabled, value, disallowCasual } = props;
  const [popperElement, setPopperElement] = useState(null);
  const [hasFetchedMore, setHasFetchedMore] = useState(false);
  const [targetElement, setTargetElement] = useState<HTMLDivElement | null>(
    null
  );

  const [searchSettings, setSearchSettings] = useState({
    query: "",
    offset: 0,
    limit: 10,
  });

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 5],
        },
      },
    ],
  });

  const searchResults = useCustomerSearch(
    searchSettings.query,
    !disallowCasual,
    {
      offset: searchSettings.offset,
      limit: searchSettings.limit,
    }
  );

  // Clear Results if query changes
  function onChangeQuery(query: string) {
    setHasFetchedMore(false);
    setSearchSettings((prev) => {
      return {
        ...prev,
        query,
        offset: 0,
        limit: 10,
      };
    });
  }

  function fetchMoreResults() {
    setSearchSettings((prev) => {
      setHasFetchedMore(true);
      return {
        ...prev,
        offset: prev.offset + 1,
        limit: 500, // We can adjust this - but Meili Will return duplicates if ranking order is lower on similar field and has a 1000 limit
      };
    });
  }

  function removeSelectedItems({ cb }: { cb?: () => void }) {
    return showDialog(
      "Remove " + props?.customerType,
      `This will unset ${props.value?.displayValue} from the lot. Are you sure?`,
      "Remove",
      () => {
        if (parentOnChange) {
          parentOnChange(null);
        }
        if (cb) {
          cb();
        }
      },
      "Cancel",
      () => {},
      "danger"
    );
  }

  let firstHit = searchResults[0] ?? null;
  disallowCasual = firstHit?._isMatchedOnBidderNumber ? true : disallowCasual;

  let filteredPeople = searchResults;
  const disabledLoadMore =
    searchSettings.limit >= 100 || searchResults.length < 10;

  let parentOnChange = props.onChange;

  let { showDialog } = useShowDialog();

  let onChange = useCallback(
    (value: CustomerItem) => {
      onChangeQuery("");
      if (parentOnChange) {
        parentOnChange(value);
      }
    },
    [parentOnChange]
  );

  let randomId = useMemo(() => {
    return Math.random().toString(36).substring(7);
  }, []);

  const previousCustomer = props.previousCustomer ?? null;
  if (previousCustomer) {
    filteredPeople = [previousCustomer, ...filteredPeople];
  }

  return (
    <div className="relative w-full">
      <label
        htmlFor={randomId}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {LabelIcon ? <LabelIcon className="mr-2 h-4 w-4" /> : null} {label}
      </label>

      <Combobox value={props.value} onChange={onChange}>
        {({ open }) => (
          <div className="w-full">
            <div
              ref={setTargetElement}
              className="block w-full rounded-md border-0 py-0 px-2 text-gray-900 shadow-sm ring-1  ring-gray-300 placeholder:text-gray-400 focus-within:ring-2 focus-within:ring-inset focus-within:ring-martEye-400 focus:outline-none focus:ring-2  focus:ring-martEye-400 sm:text-sm sm:leading-6"
            >
              <ul className="flex flex-wrap items-center gap-1 ">
                <AnimatePresence>
                  {props.value ? (
                    <SelectedCustomer
                      customer={props.value}
                      notRemovable={disabled}
                      onRemove={() => {
                        removeSelectedItems({});
                      }}
                    ></SelectedCustomer>
                  ) : null}
                </AnimatePresence>

                <li className="relative w-6 flex-grow">
                  <ComboboxInput
                    id={randomId}
                    placeholder={props.value ? "" : "Search"}
                    className="my-[2px] w-full rounded-md border-0 focus-within:outline-none focus-within:ring-0 focus:outline-none focus:ring-0 focus:ring-transparent"
                    value={
                      searchSettings.query === props.value?.displayValue
                        ? ""
                        : searchSettings.query
                    }
                    onChange={(event) => {
                      if (props.value) {
                        removeSelectedItems({
                          cb: () => {
                            onChangeQuery(event.target.value);
                          },
                        });
                      } else {
                        onChangeQuery(event.target.value);
                      }
                    }}
                    onKeyDown={(event: any) => {
                      if (event.key === "Enter") {
                        onChangeQuery("");
                      }

                      if (
                        event.key === "Backspace" &&
                        searchSettings.query === "" &&
                        props.value
                      ) {
                        removeSelectedItems({});
                      }
                    }}
                  />

                  <ComboboxButton className="absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </ComboboxButton>
                </li>
              </ul>
            </div>
            <div
              ref={popperElRef}
              style={styles.popper}
              {...attributes.popper}
              className="z-70"
            >
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => {
                  onChangeQuery("");
                  setPopperElement(null);
                }}
                beforeEnter={() => setPopperElement(popperElRef.current)}
              >
                <ComboboxOptions
                  className="z-10 mt-1 max-h-60 w-fit overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  onScrollCapture={(e: any) => {
                    const scrollPosition = e.target.scrollTop;
                    const scrollHeight = e.target.scrollHeight;
                    const clientHeight = e.target.clientHeight;
                    const totalHeight = scrollHeight - clientHeight;

                    if (hasFetchedMore) return;

                    const halfScrollPosition = totalHeight * 0.8;
                    if (scrollPosition > halfScrollPosition) {
                      fetchMoreResults();
                    }
                  }}
                >
                  {query.length > 2 &&
                    !disallowCasual &&
                    filteredPeople.every(
                      (f) =>
                        f?.displayValue?.toUpperCase() !==
                        searchSettings.query.toUpperCase()
                    ) && (
                      <ComboboxOption
                        className={({ active }) =>
                          `relative cursor-default select-none ${
                            active ? "bg-[#F1FFF6]" : "text-black"
                          }`
                        }
                        value={{
                          id: query,
                          displayValue: query,
                          isCasual: true,
                        }}
                      >
                        {({ selected, active }) => (
                          <OptionRow
                            searchQuery={searchSettings.query}
                            value={{
                              id: searchSettings.query,
                              displayValue: searchSettings.query,
                              isCasual: true,
                            }}
                            selected={selected}
                            active={active}
                            isCreating={true}
                          />
                        )}
                      </ComboboxOption>
                    )}
                  <>
                    {filteredPeople.length === 0 && query.length < 3 ? (
                      <EmptyResults
                        isEmpty={filteredPeople.length === 0}
                        query={searchSettings.query}
                      />
                    ) : (
                      <>
                        {filteredPeople.map((person, index) => (
                          <ComboboxOption
                            key={person.id + index}
                            className={({ active }) =>
                              `relative cursor-default select-none ${
                                active ? "bg-[#F1FFF6]" : "text-black"
                              }`
                            }
                            value={person}
                          >
                            {({ selected, active }) => (
                              <OptionRow
                                searchQuery={searchSettings.query}
                                value={person}
                                selected={selected}
                                active={active}
                              />
                            )}
                          </ComboboxOption>
                        ))}
                        <span className="inline-flex flex-col justify-center items-center px-4 py-2 w-full">
                          {disabledLoadMore ? (
                            <EmptyResults
                              isEmpty={filteredPeople.length === 0}
                              query={searchSettings.query}
                            />
                          ) : (
                            // Button does what scroll does but here for is scroll fetch fails for any reason
                            <Button
                              isDisabled={disabledLoadMore}
                              className="w-full font-sm"
                              onClick={fetchMoreResults}
                            >
                              Load More Results
                            </Button>
                          )}
                        </span>
                      </>
                    )}
                  </>
                </ComboboxOptions>
              </Transition>
            </div>
          </div>
        )}
      </Combobox>
    </div>
  );
}

export function EmptyResults({
  isEmpty = true,
  query,
}: {
  isEmpty: boolean;
  query: string;
}) {
  return (
    <div className="flex flex-col cursor-default select-none py-2 px-4 text-gray-700 text-[12px]">
      {isEmpty && (
        <p className="text-[16px] font-bold text-gray-500">
          No results found for <b>{query}</b>
        </p>
      )}

      <p className="text-[12px] text-gray-500">
        Refine your search or check for more accurate results or
      </p>
      <p className="text-[12px] text-gray-500">
        use <kbd>ctrl</kbd> + <kbd>k</kbd> to open the search bar.
      </p>
    </div>
  );
}

function createCustomerToolTipString(person: CustomerItem) {
  const address = person._hit?.address?.address as Address;
  const herdNumber =
    person._hit?.attributeDefaultsSeller?.herdNumberOrigin?.[0];
  const flockNumber =
    person._hit?.attributeDefaultsSeller?.flockNumberOrigin?.[0];
  const cphNumber =
    person._hit?.attributeDefaultsSeller?.cphNumberOrigin?.[0] ?? null;

  let tooltip = "";
  if (address) {
    tooltip += `${address?.address1}, ${address?.address2}, ${address?.city}, ${address?.province}, ${address?.zip}`;
  }

  if (cphNumber) {
    tooltip += `\nCPH: ${cphNumber}`;
  }

  if (herdNumber) {
    tooltip += `\nHerd Number: ${herdNumber}`;
  }

  if (flockNumber) {
    tooltip += `\nFlock Number: ${flockNumber}`;
  }

  return tooltip;
}

function HighlightText(props: {
  icon?: JSX.Element;
  text: string | undefined;
  searchQuery: string;
  className?: string;
}) {
  const displayValueRef = useRef<HTMLDivElement>(null);
  const regex = useMemo(
    () => new RegExp(`(${props.searchQuery})`, "gi"),
    [props.searchQuery]
  );

  useEffect(() => {
    if (displayValueRef.current && props.text) {
      if (!props.searchQuery?.trim()) {
        displayValueRef.current.innerHTML = props.text;
        return;
      }
      displayValueRef.current.innerHTML = props.text.replace(
        regex,
        '<span class="bg-warning-500/90 text-white rounded px-[4px] py-[2px] shadow-xl">$1</span>'
      );
    }
  }, [props.searchQuery, props.text, regex]);
  if (!props.text) {
    return null;
  }

  return (
    <div className={classNames(props.className)}>
      {props.icon}
      <span ref={displayValueRef}>{props.text}</span>
    </div>
  );
}

export function OptionRow(props: {
  searchQuery: string;
  value: CustomerItem;
  selected: boolean;
  active: boolean;
  isCreating?: boolean;
}) {
  let { value: person, selected, active, isCreating } = props;

  return (
    <div className="flex flex-col w-96 cursor-pointer items-center truncate border-b border-gray-100 py-2 px-4">
      <div
        className="flex w-full"
        title={
          person ? createCustomerToolTipString(person) : "No Address Found"
        }
      >
        <HighlightText
          text={person.displayValue}
          searchQuery={props.searchQuery}
          className=" mr-2 w-2/3 flex-grow truncate text-lg font-bold uppercase"
        />

        <div className="flex gap-1 justify-end flex-wrap flex-grow w-full">
          <HighlightText
            icon={<SheepIcon height={20} className="mr-1" />}
            text={person?._hit?.attributeDefaultsSeller?.flockNumberOrigin?.[0]}
            searchQuery={props.searchQuery}
            className="flex justify-center items-center bg-martEye-100 text-martEye-400 text-xs font-medium px-1 rounded-full border border-martEye-400 border-opacity-50 shadow-sm h-5"
          />

          <HighlightText
            icon={<HerdIcon height={20} className="mr-1" />}
            text={person?._hit?.attributeDefaultsSeller?.herdNumberOrigin?.[0]}
            searchQuery={props.searchQuery}
            className="flex justify-center items-center bg-martEye-100 text-martEye-400 text-xs font-medium px-1 rounded-full border border-martEye-400 border-opacity-50 shadow-sm h-5"
          />
          <HighlightText
            icon={<CPHIcon className="mr-1 w-5 h-5" />}
            text={person?._hit?.attributeDefaultsSeller?.cphNumberOrigin?.[0]}
            searchQuery={props.searchQuery}
            className="flex justify-center items-center bg-martEye-100 text-martEye-400 text-xs font-medium px-1 rounded-full border border-martEye-400 border-opacity-50 shadow-sm h-5"
          />
        </div>

        <>
          {isCreating && (
            <div
              className={classNames(
                `flex items-center rounded-lg bg-gray-100 py-1.5 px-4 text-sm text-gray-400`,
                (selected || active) && "bg-[#D3F2DE] text-[#3B7952]"
              )}
            >
              Casual
              <PlusCircleIcon
                className={classNames(
                  "ml-2 h-6  w-6 text-gray-300",
                  (selected || active) && "text-[#5CAD79]"
                )}
              />
            </div>
          )}
        </>
      </div>
      <div className="flex w-full items-center justify-between">
        {person.isCasual ? (
          <>
            {!isCreating && (
              <div
                className={classNames(
                  `flex items-center rounded-lg bg-gray-100 py-1.5 px-4 text-sm text-gray-400`,
                  (selected || active) && "bg-[#D3F2DE] text-[#3B7952]"
                )}
              >
                Casual
              </div>
            )}
          </>
        ) : (
          <>
            <div>
              <HighlightText
                text={person.fullName}
                searchQuery={props.searchQuery}
                className="truncate text-sm text-gray-400 italic font-bold "
              />
            </div>
            <div>
              <HighlightText
                text={person._hit?.address?.address?.address1}
                searchQuery={props.searchQuery}
                className="truncate text-sm font-normal text-gray-400 "
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function SelectedCustomer(props: {
  customer: CustomerItem;
  onRemove: (item: CustomerItem) => void;
  notRemovable?: boolean;
}) {
  let { customer, onRemove, notRemovable } = props;

  let { openPersonFlyout } = useOpenPersonFlyout();
  let { openTemporaryAccountFlyout } = useOpenTemporaryAccountFlyout();

  return (
    <motion.span
      tabIndex={0}
      className={classNames(
        "cursor-pointer inline-flex items-center gap-x-0.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-martEye-700 ring-1 ring-inset ring-gray-100"
      )}
      // If focused pushing enter or space or backspace should remove the item
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " " || e.key === "Backspace") {
          onRemove(customer);
        }
      }}
      onClick={(e) => {
        e.stopPropagation();

        if (customer.isCasual) {
          openTemporaryAccountFlyout(customer.id);
        } else {
          openPersonFlyout(customer.id);
        }
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0 } }}
    >
      {customer.displayValue}
      {!notRemovable && (
        <span
          className="cursor-pointer"
          onClick={(e) => {
            onRemove(customer);
            e.stopPropagation();
          }}
        >
          <XMarkIcon className="ml-0.5 h-5 w-5 text-gray-500" />
        </span>
      )}
    </motion.span>
  );
}

export function toBuyerCustomerItem(lots: Lot[]): CustomerItem | null {
  // Get the value from the first lot that has a value set (if any)
  let buyer = lots.find((l) => l.buyer?.isSet)?.buyer;
  let buyerCasual = lots.find((l) => l.buyerCasual)?.buyerCasual;

  // check all lots have the same value
  let allBuyersMatch = lots.every((l) => {
    if (buyer?.isSet) {
      return (
        l.buyer?.isSet &&
        l.buyer?.id === buyer?.id &&
        l.buyer?.accountNumber === buyer?.accountNumber &&
        l.buyer?.displayName === buyer?.displayName
      );
    } else {
      return l.buyerCasual === buyerCasual;
    }
  });

  if (!allBuyersMatch) {
    buyer = undefined;
    buyerCasual = undefined;

    // console.error("Buyer mismatch", lots);
  }

  if (buyerCasual) {
    return {
      id: buyerCasual,
      displayValue: buyerCasual,
      isCasual: true,
    };
  }

  if (buyer) {
    return {
      id: buyer.id ?? "missing",
      displayValue: buyer.accountNumber ?? "missing",
      fullName: buyer.displayName,
      isCasual: false,
    };
  }

  return null;
}

export function toSellerCustomerItem(lots: Lot[]): CustomerItem | null {
  // Get the value from the first lot that has a value set (if any)
  let seller = lots.find((l) => l.seller?.isSet)?.seller;

  // check all lots have the same value
  let allSellersMatch = lots.every((l) => {
    if (seller?.isSet) {
      return (
        l.seller?.isSet &&
        l.seller?.id === seller?.id &&
        l.seller?.accountNumber === seller?.accountNumber &&
        l.seller?.displayName === seller?.displayName
      );
    }
    return false;
  });

  if (!allSellersMatch) {
    seller = undefined;
  }

  if (seller) {
    return {
      id: seller.id ?? "missing",
      displayValue: seller.accountNumber ?? "missing",
      fullName: seller.displayName,
      isCasual: false,
    };
  }

  return null;
}

export function toCustomerItem(
  seller: {
    id: string | null;
    accountNumber: string | null;
    displayName: string;
  } | null
): CustomerItem | null {
  if (seller) {
    return {
      id: seller.id ?? "missing",
      displayValue: seller.accountNumber ?? "missing",
      fullName: seller.displayName,
      isCasual: false,
    };
  }
  return null;
}
