import { HerdIcon, SheepIcon } from "@/components/Icons";
import { useOpenPersonFlyout } from "@/components/PersonFlyout/PersonFlyout";
import { useOpenTemporaryAccountFlyout } from "@/components/casualAccounts/TemporaryAccountFlyout";
import { classNames } from "@/data/classnames";
import { useStudioStream } from "@/data/studio-react/useStudioStream";
import { getDisplayName } from "@/data/customerUtils";
import { ShoppingCartIcon } from "@heroicons/react/24/solid";

export interface BuyersCardProps {
  buyerCustomerId: string | null;
  showEdit?: boolean;
  classnames?: string;
  doNotOpenProfileOnClick?: boolean;
}

export const BuyersCard = ({
  classnames,
  buyerCustomerId,
  doNotOpenProfileOnClick,
}: BuyersCardProps) => {
  let marketId = useStudioStream("session:marketId");
  let { openPersonFlyout } = useOpenPersonFlyout();
  let { openTemporaryAccountFlyout } = useOpenTemporaryAccountFlyout();

  let customer = useStudioStream("customer", marketId, buyerCustomerId);

  const isTemporaryAccount =
    (buyerCustomerId ? buyerCustomerId?.length > 0 : false) && !customer;

  if (isTemporaryAccount) {
    return (
      <div
        className={classNames(
          "cursor-pointer w-full rounded-t-md bg-martEye-100  p-4 text-xs text-black",
          classnames
        )}
        onClick={() => {
          if (!doNotOpenProfileOnClick) {
            openTemporaryAccountFlyout(buyerCustomerId);
          }
        }}
      >
        <h3 className="text-base font-bold text-martEye-400">
          {buyerCustomerId}
        </h3>
        <div>Casual Account</div>
        <div className="flex justify-between rounded-b-md bg-martEye-400 p-4 text-white">
          <div className="inline-flex items-center gap-x-1.5 text-sm"></div>
          <div className="inline-flex items-center gap-x-1.5 text-sm">
            <ShoppingCartIcon height={20}></ShoppingCartIcon>Cart
          </div>
        </div>
      </div>
    );
  } else if (!customer) {
    return <></>;
  }

  let address = customer?.address?.address;
  let phoneNumbers = customer?.phoneNumber;
  let displayName = getDisplayName(customer);
  return (
    <div
      className={classNames(
        !doNotOpenProfileOnClick && "cursor-pointer",
        classnames
      )}
    >
      <div
        className={classNames(
          "w-full rounded-t-md bg-martEye-100  p-4 text-xs text-black",
          classnames
        )}
        onClick={() => {
          if (!doNotOpenProfileOnClick) {
            openPersonFlyout(buyerCustomerId, undefined, true);
          }
        }}
      >
        <h3 className="text-base font-bold text-martEye-400">{displayName}</h3>

        <address className="font-normal not-italic leading-tight ">
          {address?.address1 && (
            <>
              {address.address1}
              <br />
            </>
          )}
          {address?.address2 && (
            <>
              {address.address2}
              <br />
            </>
          )}
          {address?.city && (
            <>
              {address.city}
              <br />
            </>
          )}
          {address?.province && address?.city !== address?.province && (
            <>
              {address.province}
              <br />
            </>
          )}
          {address?.zip && (
            <>
              {address.zip}
              <br />
            </>
          )}
          {address?.country && (
            <>
              {address.country}
              <br />
            </>
          )}
        </address>
        {phoneNumbers && (
          <span className="mt-2 block font-bold">
            Tel. {phoneNumbers.phoneNumber}
          </span>
        )}
      </div>
      {["GB-ENG", "GB-SCT", "GB-WLS"].includes(address?.country ?? "") && (
        <div className="bg-martEye-500 px-4 py-2 text-white ">
          <div className="inline-flex gap-x-1.5">
            <span className="inline-flex items-center rounded-full bg-martEye-600 px-2 py-1 text-xs font-medium text-martEye-100">
              CPH
            </span>
            {customer.attributeDefaultsBuyer?.cphNumberDestination?.[0] ?? ""}
          </div>
        </div>
      )}
      <div className="flex justify-between rounded-b-md bg-martEye-400 p-4 text-white">
        {customer?.attributeDefaultsBuyer?.flockNumberDestination?.[0] && (
          <div className="inline-flex items-center gap-x-1.5 text-sm">
            <SheepIcon height={20} />{" "}
            {customer.attributeDefaultsBuyer.flockNumberDestination?.[0] ?? ""}
          </div>
        )}
        {customer?.attributeDefaultsBuyer?.herdNumberDestination?.[0] && (
          <div className="inline-flex items-center gap-x-1.5 text-sm">
            <HerdIcon height={20} />{" "}
            {customer?.attributeDefaultsBuyer?.herdNumberDestination?.[0] ?? ""}
          </div>
        )}

        <div className="inline-flex items-center gap-x-1.5 text-sm"></div>
        <div className="inline-flex items-center gap-x-1.5 text-sm">
          <ShoppingCartIcon height={20}></ShoppingCartIcon>Cart
        </div>
      </div>
    </div>
  );
};

export default BuyersCard;
