import { Firestore, doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { Sale } from "types";
import { cloudFunction } from "../helpers/cloudfunction";

interface UpdateSaleSettingsRequest {
  marketId: string;
  id: string;
  name: string;
  description: string | null;
  image: string | null;
  startsAt: string;
  recurring: null | "Weekly" | "Bi-weekly" | "Monthly";
  availableProductCodes: string[];
  location: string | null;
  saleProfileId: string | null;
  autoQueue: boolean;
}

interface UpdateSaleResponse {
  saleId: string;
  success: boolean;
  message?: string;
}

interface DeleteSaleRequest {
  marketId: string;
  saleId: string;
}

interface DeleteSaleResponse {
  success: boolean;
  message?: string;
}

/**
 * Use this function to update a sale from rostrum.
 * TBD merge with the updateSaleSettings function
 *
 */
export async function updateSale(
  firestore: Firestore,
  currentUid: string,
  marketId: string,
  saleId: string,
  values: Partial<Sale>
) {
  let docRef = doc(firestore, `markets/${marketId}/sales/${saleId}`);

  let update: Partial<Sale> = {
    ...values,
    updatedAt: serverTimestamp(),
    updatedBy: currentUid,
  };

  await updateDoc(docRef, update);
  return;
}

/**
 * Will only delete if no lots on the sale
 * @param info
 * @returns
 */
export async function deleteSale(info: DeleteSaleRequest) {
  let deleteSaleCall = cloudFunction<DeleteSaleRequest, DeleteSaleResponse>(
    "deleteSale"
  );

  if (!info) {
    throw new Error(`No sale info to deleye a sale`);
  }

  let result = await deleteSaleCall({ ...info });

  return result;
}

/**
 * This uses the cloud function to update the sale settings
 * @param info
 * @returns
 */
export async function updateSaleSettings(info: UpdateSaleSettingsRequest) {
  let updateSaleCall = cloudFunction<
    UpdateSaleSettingsRequest,
    UpdateSaleResponse
  >("updateSale");

  if (!info) {
    throw new Error(`No sale info to update a sale`);
  }

  let result = await updateSaleCall({ ...info });

  return result;
}
