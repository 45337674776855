import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { InterpolateBadge } from "@/components/Buttons";
import { classNames } from "@/data/classnames";

interface SelectFieldProps {
  className?: string;
  value: string;
  name: string;
  label: string;
  columns: number;
  options: Array<string> | Array<{ label: string; value: string }>;
  onChange: (value: string) => void;
}

export const SelectField = ({
  options,
  value,
  name,
  label,
  columns,
  onChange,
}: SelectFieldProps) => {
  const selected = value;
  const ButtonContent = ({ selected, options }: any) => {
    if (selected) {
      let selectedLabel = options.find(
        (option: any) => option.value === selected
      )?.label;

      return (
        <span className="inline-flex items-center gap-x-0.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium uppercase text-martEye-700 ring-1 ring-inset ring-gray-100">
          {selectedLabel}
        </span>
      );
    } else {
      return <span>Please select</span>;
    }
  };

  let fieldOptions = options.map((option) => {
    if (typeof option === "string") {
      return { label: option, value: option };
    }
    return option;
  });

  return (
    <>
      <div className={classNames(columns ? `col-span-${columns}` : "")}>
        <label
          htmlFor={name}
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {label}
        </label>
        <Listbox value={selected} onChange={onChange}>
          {({ open }) => (
            <>
              <div className="relative mt-1">
                <Listbox.Button className="relative h-[44px] w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-martEye-600 sm:text-sm sm:leading-6">
                  <span className="flex flex-wrap	items-center gap-1">
                    <ButtonContent selected={selected} options={fieldOptions} />
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {fieldOptions.map((option) => {
                      return (
                        <Listbox.Option
                          key={option.value}
                          className={({ active }) =>
                            classNames(
                              active
                                ? "bg-martEye-600 text-white"
                                : "text-gray-900",
                              "relative cursor-default select-none py-2 pl-3 pr-9"
                            )
                          }
                          value={option.value}
                        >
                          {({ selected, active }) => (
                            <>
                              <div className="flex items-center">
                                <span
                                  className={classNames(
                                    selected ? "font-semibold" : "font-normal",
                                    "ml-3 block truncate"
                                  )}
                                >
                                  {option.label}
                                </span>
                              </div>
                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? "text-white" : "text-martEye-600",
                                    "absolute inset-y-0 right-0 flex items-center pr-4"
                                  )}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      );
                    })}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      </div>
    </>
  );
};

export default SelectField;
