import { getAuth } from "firebase/auth";
import { initialiseFirebase } from "initFirebase";

export async function logout() {
  try {
    let app = initialiseFirebase();
    let [serverLogout, _] = await Promise.all([
      fetch(`/api/logout`),
      getAuth(app).signOut(),
    ]);

    if (!serverLogout.ok) {
      throw new Error("Logout failed");
    }
  } catch (error) {
    console.error(error);
  }
}
