import {
  Firestore,
  Timestamp,
  collection,
  deleteField,
  doc,
  getDocs,
  limit,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import seedrandom from "seedrandom";
import { Customer } from "types";
import { cloudFunction } from "../helpers/cloudfunction";

/***
 * Add a bidder number to a customer
 */
export async function setBidderNumber(
  firestore: Firestore,
  currentUid: string | null,
  marketId: string | null,
  customerId: string | null,
  params?: {
    // if not supplied one will be auto generated
    bidderNumber?: number | null;

    // the number generated is sticky to try and give customers the same number when they return
    // If you want to force a new number increment this
    attempt?: number;
  }
) {
  if (!marketId) throw new Error(`No market id`);
  if (!customerId) throw new Error(`No customer id`);

  async function write(bidderNumber: number | null) {
    let customerRef = doc(
      firestore,
      `markets/${marketId}/customers/${customerId}`
    );
    let update: Partial<Customer> = {
      bidderNumber:
        bidderNumber === null ? (deleteField() as any) : bidderNumber,
      updatedAt: Timestamp.now(),
      updatedBy: currentUid,
    };
    await updateDoc(customerRef, update);
    return bidderNumber;
  }

  let bidderNumber = params?.bidderNumber;

  if (bidderNumber === null) {
    // If the bidder number is explicitly set to null, remove it
    return await write(null);
  }

  if (bidderNumber) {
    let isUnique = await checkBidderNumberIsUnique(
      firestore,
      marketId,
      bidderNumber
    );
    if (!isUnique) {
      throw new Error(`Bidder number ${bidderNumber} is not unique`);
    }
  } else {
    let attempts = params?.attempt || 0;
    let maxAttempts = 100 + (params?.attempt || 0);
    let isUnique = false;
    while (!isUnique && attempts < maxAttempts) {
      bidderNumber = generateBidderNumber(customerId, attempts);
      isUnique = await checkBidderNumberIsUnique(
        firestore,
        marketId,
        bidderNumber
      );
      attempts++;
    }

    if (!isUnique) {
      throw new Error(
        `Failed to generate a unique bidder number after ${maxAttempts} attempts`
      );
    }
  }

  return await write(bidderNumber!);
}

/***
 * Generate a 3 digit bidder number and check that it is unique.
 *
 * Will attempt to generate the same number for the same customer id
 */
function generateBidderNumber(customerId: string, attempt = 0) {
  let rnd = seedrandom(customerId);
  // create number between 100 and 999

  let no = rnd();
  for (let i = 0; i < attempt; i++) {
    no = rnd();
  }

  let bidderNumber = Math.floor(no * 900) + 100;
  return bidderNumber;
}

export async function checkBidderNumberIsUnique(
  firestore: Firestore,
  marketId: string | null,
  bidderNumber: number
) {
  if (!marketId) throw new Error(`No market id`);

  let col = collection(firestore, `markets/${marketId}/customers`);
  let q = query(col, where("bidderNumber", "==", bidderNumber), limit(1));
  let snaps = await getDocs(q);
  return snaps.empty;
}

export async function clearAllBidderNumbers(marketId: string | null) {
  if (!marketId) throw new Error(`No market id`);

  let func = cloudFunction<
    {
      marketId: string;
    },
    {
      updated: number;
    }
  >("clearAllBidderNumbers");

  return func({ marketId });
}
