import { useCallback, useMemo } from "react";
import { useFlyout } from "../Flyout/useFlyout";

const urlParam = "invoice";
const urlParamShowDocumentDialog = "documentPicker";

export type OpenInvoiceSheetParams = {
  invoiceId: string;
  // if true will show the document print dialog when the flyout opens
  // use this after the invoice is created
  showDocumentDialog?: boolean;
};

export function useInvoiceSheet() {
  let [isFlyoutOpen, openFlyout, closeFlyout, flyoutParameters] = useFlyout([
    urlParam,
    urlParamShowDocumentDialog,
  ]);

  let openInvoiceSheet = useCallback(
    (
      info: OpenInvoiceSheetParams,
      opts: { replace: boolean } = { replace: false }
    ) => {
      let params = null as any;

      if (info.invoiceId) {
        params = {
          [urlParam]: info.invoiceId,
        };
        if (info.showDocumentDialog === true) {
          params[urlParamShowDocumentDialog] = "true";
        }
      }

      openFlyout(params, opts.replace);
    },
    [openFlyout, flyoutParameters]
  );

  return useMemo(() => {
    return {
      isInvoiceSheetOpen: isFlyoutOpen,
      openInvoiceSheet,
      closeInvoiceSheet: () => closeFlyout(false),
      invoiceId: flyoutParameters?.[urlParam] ?? null,
      showDocumentDialog:
        flyoutParameters?.[urlParamShowDocumentDialog] === "true",
    };
  }, [closeFlyout, flyoutParameters, isFlyoutOpen, openInvoiceSheet]);
}
