import { getFunctions, httpsCallable } from "firebase/functions";
import { initialiseFirebase } from "initFirebase";

/***
 * Handy builder for calling cloud functions
 */
export function cloudFunction<In, Out>(name: string) {
  return async (data: In) => {
    let func = httpsCallable(
      getFunctions(initialiseFirebase(), "europe-west2"),
      name
    );
    return (await func(data)).data as Out;
  };
}
