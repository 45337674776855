import { FullSaleContext } from "../ExecuteInSaleContext";

export function groupItemCount(ctx: FullSaleContext, groupId: string | null) {
  if (!groupId) return null;

  let allLotsInGroup = ctx.lots.filter((lot) => lot.group === groupId);

  return allLotsInGroup
    .map((l) => {
      return l.generated?.countOfItems ?? Object.keys(l.itemMap ?? {}).length;
    })
    .reduce((a, b) => a + b, 0);
}
