import { useMemo } from "react";
import { Lot, LotItem, Sale } from "../../types";
import { CellContext, Column, OnEditCellCallback, OnGetCellContentCallback } from "../datagrid/gridConfig";
import { isValid } from "date-fns";
import { Timestamp } from "firebase/firestore";
import { DatePickerCell2 } from "../datagrid/DatePickerCell";
import { LotAttributeValueType, useSaleAttributeControllers } from "@/data/attribute-definitions/attributeControls";
import { SpinnerCellType } from "@glideapps/glide-data-grid-cells";
import { BooleanCell, GridCell, GridCellKind, NumberCell, TextCell } from "@glideapps/glide-data-grid";
import { DropdownCell } from "@glideapps/glide-data-grid-cells/dist/ts/cells/dropdown-cell";

export interface ItemsGridContextType {
  lot: Lot;
  currentUid?: string;
}

// Default columns that must appear on all lot item grids
export const itemGridColumns: Column<LotItem, ItemsGridContextType>[] = [];
export const useItemsGridColumns = (sale: Sale | null) => {
  let attributeDefinitions = useSaleAttributeControllers();
  let itemColumns: Column<LotItem, ItemsGridContextType>[] = useMemo(() => {
    if (!sale) {
      return [];
    }

    let attributeColumns: Column<LotItem, ItemsGridContextType>[] =
      attributeDefinitions
        .filter((attr) => attr.definition.level === "item")
        .map((attr) => {
          let getCellContent: OnGetCellContentCallback<
            LotItem,
            ItemsGridContextType
          > = (
            context: CellContext<LotItem, ItemsGridContextType>,
            col: number,
            row: number,
            item: LotItem
          ) => {
            let lot = context.extraData.lot;
            let isReadonly = attr.isReadonly(lot, item.id);
            let shouldShowFaded = isReadonly;

            if (attr.isLoading(lot, item.id)) {
              return loadingCell() as unknown as GridCell;
            }

            // Dropdown lists
            if (
              attr.definition.allowedValues &&
              attr.definition.allowedValues.length > 0
            ) {
              let d: DropdownCell = {
                kind: GridCellKind.Custom,
                allowOverlay: true,
                copyData: (attr.getValue(lot, item.id) as string) || "",
                data: {
                  kind: "dropdown-cell",
                  allowedValues: attr.definition.allowedValues,
                  value: (attr.getValue(lot, item.id) as string) || "",
                },
              };
              return d;
            }

            if (attr.definition.type === "number") {
              let d: NumberCell = {
                kind: GridCellKind.Number,
                allowOverlay: !isReadonly,
                readonly: isReadonly,
                displayData: attr.getDisplayValue(lot, item.id),
                data: attr.getValue(lot, item.id) as number | undefined,
                style: shouldShowFaded ? "faded" : undefined,
              };
              return d;
            }

            if (attr.definition.type === "boolean") {
              let d: BooleanCell = {
                kind: GridCellKind.Boolean,
                data: attr.getValue(lot, item.id) === true,
                allowOverlay: false,
                readonly: isReadonly,
                style: shouldShowFaded ? "faded" : undefined,
              };
              return d;
            }

            if (attr.definition.type === "date") {
              let date: Date | null | undefined = undefined;

              let value = attr.getValue(lot, item.id);
              if (value && value instanceof Timestamp) {
                // these are timestamps, need to convert to date
                date = (value as Timestamp).toDate();
              }

              const cell: DatePickerCell2 = {
                kind: GridCellKind.Custom,
                allowOverlay: !isReadonly,
                readonly: isReadonly,
                copyData: attr.getCopyValue(lot, item.id),
                data: {
                  kind: "date-picker-cell-2",
                  date: date,
                  format: "date",
                  displayDate: attr.getDisplayValue(lot, item.id),
                },
                style: shouldShowFaded ? "faded" : undefined,
              };

              return cell;
            }

            if (attr.definition.type === "datetime") {
              let date: Date | null | undefined = undefined;

              let value = attr.getValue(lot, item.id);
              if (value && value instanceof Timestamp) {
                // these are timestamps, need to convert to date
                date = (value as Timestamp).toDate();
              }

              const cell: DatePickerCell2 = {
                kind: GridCellKind.Custom,
                allowOverlay: !isReadonly,
                readonly: isReadonly,
                copyData: attr.getCopyValue(lot, item.id),
                data: {
                  kind: "date-picker-cell-2",
                  date: date,
                  format: "datetime-local",
                  displayDate: attr.getDisplayValue(lot, item.id),
                },
                style: shouldShowFaded ? "faded" : undefined,
              };
              return cell;
            }

            if (attr.definition.type === "time") {
              let date: Date | null | undefined = undefined;

              let value = attr.getValue(lot, item.id);
              if (value && value instanceof Timestamp) {
                // these are timestamps, need to convert to date
                date = (value as Timestamp).toDate();
              }

              const cell: DatePickerCell2 = {
                kind: GridCellKind.Custom,
                allowOverlay: !isReadonly,
                readonly: isReadonly,
                copyData: attr.getCopyValue(lot, item.id),
                data: {
                  kind: "date-picker-cell-2",
                  date: date,
                  format: "time",
                  displayDate: attr.getDisplayValue(lot, item.id),
                },
                style: shouldShowFaded ? "faded" : undefined,
              };
              return cell;
            }

            let d: TextCell = {
              kind: GridCellKind.Text,
              allowOverlay: !isReadonly,
              readonly: isReadonly,
              displayData: attr.getDisplayValue(lot, item.id),
              data: (attr.getValue(lot, item.id) as string) ?? "",
              style: shouldShowFaded ? "faded" : undefined,
            };
            return d;
          };

          let onEdit: OnEditCellCallback<
            LotItem,
            ItemsGridContextType
          > = async (
            context: CellContext<LotItem, ItemsGridContextType>,
            col: number,
            row: number,
            newValue:
              | string
              | number
              | boolean
              | {}
              | string[]
              | null
              | undefined
              | {
                  kind: "date-picker-cell-2";
                  date: Date | null | undefined;
                }
              | {
                  kind: "dropdown-cell";
                  value: string;
                  allowedValues: string[];
                },
            lotItem: LotItem
          ) => {
            let updateValue: LotAttributeValueType | null | undefined =
              newValue as LotAttributeValueType;

            console.log("onEditItem", newValue, lotItem, attr);

            // convert dates to timestamps
            if (
              attr.definition.type === "date" ||
              attr.definition.type === "datetime" ||
              attr.definition.type === "time"
            ) {
              updateValue = null;
              if (
                newValue &&
                typeof newValue === "object" &&
                "date" in newValue
              ) {
                let date = newValue.date as Date | undefined | null;
                if (date && isValid(date)) {
                  updateValue = Timestamp.fromDate(date);
                }
              }
            }

            if (
              newValue &&
              typeof newValue === "object" &&
              "kind" in newValue
            ) {
              if (newValue.kind === "dropdown-cell") {
                updateValue = newValue.value;
              }
            }

            if (attr.definition.level !== "item") {
              throw new Error(
                `Cannot edit attribute ${attr.definition.id} at item level. This attribute is defined at ${attr.definition.level} level.`
              );
            }

            await attr.setItemValue(
              context.extraData.lot,
              lotItem.id,
              updateValue
            );
          };

          return {
            id: attr.definition.id,
            title: attr.definition.name || `MISSING-${attr.definition.id}`,

            // icon: GridColumnIcon.HeaderString,
            hasMenu: false,
            group: "attributes",
            deletable: false,
            // need to set
            initialIndex: -1,
            getCellContent,
            onEdit,
          };
        });

    return [...itemGridColumns, ...attributeColumns];
  }, [sale, attributeDefinitions]);

  return itemColumns;
};
function loadingCell() {
  const d: SpinnerCellType = {
    kind: GridCellKind.Custom,
    allowOverlay: true,
    copyData: "",
    data: {
      kind: "spinner-cell",
    },
  };
  return d;
}