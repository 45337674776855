import {
  Menu,
  MenuButton,
  MenuHeading,
  MenuItem,
  MenuItems,
  MenuSection,
  Transition,
} from "@headlessui/react";

import { ChevronDownIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import React, { Fragment } from "react";

export type ActionMenu =
  | {
      title: string;
      active: boolean;
      icon?: React.ReactNode;
      action: () => void | Promise<void>;
      section: string;
      className?: string;
      element?: never;
    }
  | {
      title?: never;
      active?: never;
      icon?: never;
      action?: never;
      section: string;
      className?: never;
      element: JSX.Element;
    };

export const menuButtonClassNames =
  "flex gap-2 px-2 py-2 text-left text-sm text-gray-700 hover:bg-gray-100  cursor-pointer";
/**
 * A Dropdown Menu based on the headless-ui menu component -https://headlessui.com/react/menu
 * TO order the Sections it will order them in the order the first one appears in the array
 * @param props The actions to display
 * @todo Programmatically open / close the menu
 */
export function GridActions(props: {
  options: ActionMenu[];
  buttonTitle?: string;
}) {
  // Group the actions by section
  const sections = props.options.reduce(
    (acc, action) => {
      const section = acc.find((s) => s.section === action.section);
      if (section) {
        section.actions.push(action);
      } else {
        acc.push({
          section: action.section,
          actions: [action],
        });
      }
      return acc;
    },
    [] as {
      section: string;
      actions: ActionMenu[];
    }[]
  );

  return (
    <Menu as="div" className="relative z-[100]">
      {/* Do not use `as` here as when on the flyout it all goes potty */}
      <MenuButton className="flex gap-1 items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-100 hover:bg-gray-50">
        {props.buttonTitle ?? "Actions"}
        <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
      </MenuButton>
      <Transition
        as={Fragment}
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems
          anchor="bottom"
          className="py-1 absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          {sections.map((section) => (
            <MenuSection key={section.section} className="py-2">
              <MenuHeading className="text-xs opacity-50 ml-2 mb-2 uppercase">
                {section.section}
              </MenuHeading>
              {section.actions.map((action) => {
                if (action.element) {
                  return <div key={action.title}>{action.element}</div>;
                }
                return (
                  <MenuItem key={action.title}>
                    <div
                      role={"button"}
                      onClick={action.action}
                      className={classNames(
                        menuButtonClassNames,
                        action.className,
                        !action.active &&
                          "opacity-50 !cursor-not-allowed hover:bg-transparent"
                      )}
                    >
                      {action.icon}
                      {action.title}
                    </div>
                  </MenuItem>
                );
              })}
            </MenuSection>
          ))}
        </MenuItems>
      </Transition>
    </Menu>
  );
}
