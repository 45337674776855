import { PrintJob } from "types";
import { cloudFunction } from "../helpers/cloudfunction";

interface PrintJobsData {
  marketId: string;
  printJobs: PrintJob[];
}

interface PrintResult {
  jobId: string;
  result: { status: "ok" } | { status: "error"; message: string };
}

export async function printJobs(marketId: string, printJobs: PrintJob[]) {
  const CreatePrintJobsCall = cloudFunction<PrintJobsData, PrintResult[]>(
    "CreatePrintJobsCall"
  );

  return CreatePrintJobsCall({ marketId, printJobs });
}
