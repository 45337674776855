export default function ExportsIcon() {
  return (
    <span className="inline-block h-8 w-8 overflow-hidden rounded-full bg-[#F9D2D2] p-2">
      <svg
        className="h-4 w-4 fill-[#A73535]"
        viewBox="0 0 14 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M2.33328 10.4C2.33328 9.51634 3.02973 8.8 3.88883 8.8C4.74794 8.8 5.44442 9.51635 5.44442 10.4C5.44442 11.2837 4.74798 12 3.88887 12C3.02977 12 2.33328 11.2837 2.33328 10.4Z" />
        <path d="M3.88883 8.16C5.09158 8.16 6.0666 9.16288 6.0666 10.4H7.93326C7.93326 9.60434 8.33658 8.90557 8.9444 8.50818V2C8.9444 0.895431 8.07385 0 6.99997 0H2.72221C1.64833 0 0.777774 0.895431 0.777774 2L0 8.4C0 9.42335 0.747236 10.2672 1.71111 10.3857C1.71856 9.15519 2.69071 8.16 3.88883 8.16Z" />
        <path d="M14 8.4C14 9.4234 13.2527 10.2673 12.2888 10.3858C12.2813 9.1552 11.3092 8.16 10.111 8.16C9.8987 8.16 9.69347 8.19125 9.49944 8.24953V2.08H10.0376C10.5803 2.08 11.0983 2.31328 11.4663 2.72344L12.1164 3.44775H11.6667C11.0223 3.44775 10.5 3.98501 10.5 4.64775V5.6C10.5 6.70457 11.3705 7.6 12.4444 7.6L14 7.6V8.4Z" />
        <path d="M9.96638 8.80682C9.80199 8.82241 9.645 8.86432 9.49944 8.9284C9.28549 9.0226 9.09622 9.16475 8.9444 9.34167C8.70235 9.62373 8.55548 9.9942 8.55548 10.4C8.55548 11.2837 9.25196 12 10.1111 12C10.9702 12 11.6666 11.2837 11.6666 10.4C11.6666 9.62679 11.1334 8.98168 10.4245 8.8325C10.3233 8.81119 10.2184 8.8 10.1111 8.8" />
        <path d="M9.96638 8.80682C10.014 8.80231 10.0623 8.8 10.1111 8.8L9.96638 8.80682Z" />
        <path d="M11.6667 4.24775H12.8343L13.4843 4.97207C13.8159 5.34152 14 5.82585 14 6.32863V6.8L12.4444 6.8C11.8001 6.8 11.2778 6.26274 11.2778 5.6V4.64775C11.2778 4.42684 11.4519 4.24775 11.6667 4.24775Z" />
      </svg>
    </span>
  );
}
