import {
  Timestamp,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { useCallback } from "react";

import { useFirestore } from "@/data/studio-react/firebase/useFirestore";
import { useCurrentUid } from "@/data/studio-react/firebase/useFirestoreAuth";
import { useMarketId } from "@/data/useMartketId";
import { autoId } from "../../data/ids";
import { CartItem, ClientType } from "../../types";

export function useCreateCartItem() {
  let firestore = useFirestore();
  let currentUid = useCurrentUid();

  let marketId = useMarketId();

  let createCartItem = useCallback(
    async (
      customerId: string,
      clientType: string,
      productId: string,
      quantity: number,
      unitPriceInCents: number,
      passthroughFundsToCustomerId: string | null
    ) => {
      let newItem: CartItem = {
        id: autoId(),
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        marketId: marketId as string,
        customerId: customerId,
        clientType: clientType as ClientType,
        productId: productId,
        quantity: quantity,
        unitPriceInCents: unitPriceInCents,
        passthroughFundsToCustomerId: passthroughFundsToCustomerId,
      };

      let docRef = doc(firestore, `markets/${marketId}/carts/${customerId}`);
      let document = await getDoc(docRef);
      if (document.exists()) {
        await updateDoc(docRef, {
          [`itemsById.${newItem.id}`]: newItem,
          updatedAt: Timestamp.now(),
        });
      } else {
        await setDoc(docRef, {
          itemsById: { [newItem.id]: newItem },
          updatedAt: Timestamp.now(),
        });
      }
    },
    [currentUid, marketId, firestore]
  );

  return createCartItem;
}
