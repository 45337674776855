import { Invoice } from "types";
import { cloudFunction } from "../helpers/cloudfunction";
import { CheckoutRequestData } from "./createDraftInvoice";

interface IssueInvoiceParameters extends CheckoutRequestData {}

export async function issueInvoice(params: IssueInvoiceParameters) {
  let func = cloudFunction<IssueInvoiceParameters, Invoice>("issueInvoiceCall");

  if (!params.marketId) {
    throw new Error(`MarketID needs to be specified`);
  }

  let result = await func(params);
  console.log("issue invoice result", result);

  return result;
}
