import { sortByLotNumber } from "@/data/lots";
import {
  useFirestore,
  useLoadFirestoreQuery,
} from "@/data/studio-react/firebase/useFirestore";
import { collectionGroup, orderBy, query, where } from "firebase/firestore";
import { useMemo } from "react";
import { Lot } from "types";

export function useAllUninvoicedLotsBoughtAndSold(
  marketId?: string | null,
  customerId?: string | null,
  excludedSaleId?: string | null,
  isTemporaryAccount?: boolean
) {
  // fetch all the lots a customerID has bought and sold but not settled yet
  let boughtLotsInfo = useFetchAllUninvoicedLotsPurchased(
    marketId,
    customerId,
    excludedSaleId,
    isTemporaryAccount
  );
  let soldLotsInfo = useFetchAllUninvoicedLotsSold(
    marketId,
    customerId,
    excludedSaleId,
    isTemporaryAccount
  );

  return useMemo(() => {
    let isLoading = soldLotsInfo.loading || boughtLotsInfo.loading;
    let boughtLots = boughtLotsInfo.data || [];
    let soldLots = soldLotsInfo.data || [];

    return {
      boughtLots: sortByLotNumber(boughtLots),
      soldLots: sortByLotNumber(soldLots),
      isLoading,
    };
  }, [boughtLotsInfo, soldLotsInfo]);
}

// Returns the lots, grouped by saleId ordered by their index in the sale
function useFetchAllUninvoicedLotsPurchased(
  marketId?: string | null,
  customerId?: string | null,
  excludedSaleId?: string | null,
  isTemporaryAccount?: boolean
) {
  let db = useFirestore();

  let buyerQuery = useMemo(() => {
    if (!customerId) {
      return null;
    }
    if (!marketId) {
      return null;
    }

    let e = excludedSaleId || null;

    return query(
      collectionGroup(db, "lots"),
      where("marketId", "==", marketId),
      where(
        isTemporaryAccount ? "buyerCasual" : "buyerCustomerId",
        "==",
        customerId
      ),
      where("buyerInvoiceId", "==", null),
      where("saleId", "!=", e),
      orderBy("saleId", "asc"),
      orderBy("index", "asc")
    );
  }, [customerId, marketId, isTemporaryAccount]);

  let lotsLoadInfo = useLoadFirestoreQuery<Lot>(buyerQuery, {
    idField: "id",
  });

  // including this as we cant filter multiple inequalities in firestore
  if (lotsLoadInfo.data) {
    lotsLoadInfo.data = lotsLoadInfo.data.filter(
      (lot) => !["Rerun", "Resold"].includes(lot.saleStatus ?? "")
    );
  }

  return lotsLoadInfo;
}

// Returns the lots, grouped by saleId ordered by their index in the sale
function useFetchAllUninvoicedLotsSold(
  marketId?: string | null,
  customerId?: string | null,
  excludedSaleId?: string | null,
  isCasual?: boolean
) {
  let db = useFirestore();

  let buyerQuery = useMemo(() => {
    if (!customerId) {
      return null;
    }
    if (!marketId) {
      return null;
    }

    let e = excludedSaleId || null;

    return query(
      collectionGroup(db, "lots"),
      where("marketId", "==", marketId),
      where(isCasual ? "sellerCasual" : "sellerCustomerId", "==", customerId),
      where("sellerInvoiceId", "==", null),
      where("saleId", "!=", e),
      orderBy("saleId", "asc"),
      orderBy("index", "asc")
    );
  }, [customerId, marketId, isCasual]);

  let lotsLoadInfo = useLoadFirestoreQuery<Lot>(buyerQuery, {
    idField: "id",
  });

  if (lotsLoadInfo.data) {
    lotsLoadInfo.data = lotsLoadInfo.data.filter(
      (lot) => !["Rerun", "Resold"].includes(lot.saleStatus ?? "")
    );
  }

  return lotsLoadInfo;
}
