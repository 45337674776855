import TextInput from "@/components/form/TextInput";
import { updateCustomer } from "@/data/customer";
import { useCurrentUid } from "@/data/studio-react/firebase/useFirestoreAuth";
import { Customer } from "../../../../types";

// TODO
// 1. Validate the sort code and account number

interface SettingsBankingTabProps {
  customer: Customer;
  marketId: string;
}

const countryOptions = [
  { value: "GB-ENG", label: "England" },
  { value: "GB-WLS", label: "Wales" },
  { value: "GB-SCT", label: "Scotland" },
  { value: "GB-NIR", label: "Northern Ireland" },
  { value: "IE", label: "Ireland" },
];

function SettingsBanking(props: SettingsBankingTabProps) {
  let { customer, marketId } = props;
  let customerId = customer?.id;
  let currentUid = useCurrentUid();

  return (
    <div className="grid lg:grid-cols-2 gap-4">
      <div>
        <div className="bg-white rounded-md w-full p-4 grid lg:grid-cols-2 gap-4">
          <TextInput
            columns={2}
            label="Sort Code"
            name="sortCode"
            type="text"
            disabled={false}
            value={customer?.bankDetails?.sortCode || ""}
            onChange={(value) => {
              let update: Partial<Customer> = {
                bankDetails: {
                  sortCode: value as string,
                  accountNumber: customer?.bankDetails?.accountNumber || "",
                },
              };
              updateCustomer(marketId, currentUid, customerId, update);
            }}
          />
          <TextInput
            columns={2}
            label="Account Number"
            name="accountNumber"
            type="text"
            disabled={false}
            value={customer?.bankDetails?.accountNumber || ""}
            onChange={(value) => {
              let update: Partial<Customer> = {
                bankDetails: {
                  accountNumber: value as string,
                  sortCode: customer?.bankDetails?.sortCode || "",
                },
              };
              updateCustomer(marketId, currentUid, customerId, update);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default SettingsBanking;
