import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";

import { classNames } from "@/data/classnames";
import { twMerge } from "tailwind-merge";
import { ClientType, Invoice } from "types";

interface InvoiceSheetHeaderProps {
  clientType?: ClientType;
  status?: Invoice["status"];
  invoiceNumber?: Invoice["invoiceNumber"];
  close: () => void;
  actionsButton?: React.ReactNode;
}

export function InvoiceSheetHeader(props: InvoiceSheetHeaderProps) {
  let { clientType, close, status, invoiceNumber } = props;

  return (
    <div
      className={`relative px-6 py-5 flex flex-col justify-start ${
        clientType === "Seller" ? "bg-blue-900" : "bg-martEye-400"
      }`}
    >
      <div className="flex flex-row items-start">
        <div className="inline-flex items-start gap-x-2 flex-col">
          <Dialog.Title
            as="h3"
            className={classNames(
              "inline-flex items-center gap-x-1.5 text-2xl font-bold text-white"
            )}
          >
            {clientType === "Buyer" ? "Invoice" : "Statement"}
            <span className={`ml-2`}>#{invoiceNumber}</span>
          </Dialog.Title>
          <div className="mt-0.5">
            <InvoiceStatus status={status} />
          </div>
        </div>

        <div className="flex flex-grow flex-row items-center justify-end space-x-4">
          {props.actionsButton}
          <button
            type="button"
            className="flex h-9 w-9 items-center justify-center rounded-full border-2 border-white/40 text-white  hover:bg-white/10"
            onClick={close}
          >
            <XMarkIcon className="h-4 w-4" />
          </button>
        </div>
      </div>
    </div>
  );
}

function InvoiceStatus({ status }: { status?: Invoice["status"] }) {
  //  "void" | "draft" | "issued" | "imported" | "paid" | undefined

  function Badge({
    className,
    children,
  }: {
    className: string[] | string;
    children: React.ReactNode;
  }) {
    return (
      <span
        className={twMerge(
          "inline-flex items-center rounded-full bg-gray-50 px-5 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10",
          className
        )}
      >
        {children}
      </span>
    );
  }

  if (status === "void") {
    return (
      <Badge className={["bg-red-500 text-white"]}>
        {status.toUpperCase()}
      </Badge>
    );
  }

  if (status === "draft") {
    return (
      <Badge className={["bg-gray-500 text-white"]}>
        {status.toUpperCase()}
      </Badge>
    );
  }

  if (status === "issued") {
    return (
      <Badge className={["bg-green-500 text-white"]}>
        {status.toUpperCase()}
      </Badge>
    );
  }

  if (status === "imported") {
    return (
      <Badge className={["bg-blue-500 text-white"]}>
        {status.toUpperCase()}
      </Badge>
    );
  }

  if (status === "paid") {
    return (
      <Badge className={["bg-green-500 text-white"]}>
        {status.toUpperCase()}
      </Badge>
    );
  }

  return null;
}
