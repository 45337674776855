export default function MovementsIcon() {
  return (
    <span className="inline-block h-8 w-8 overflow-hidden rounded-full bg-[#FFF6C6] p-1.5">
      <svg
        className="h-5 w-5 fill-[#FFD600]"
        viewBox="0 0 13 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.6793 13.9972L11.0823 13.8015C11.9784 13.75 12.7063 12.986 12.7794 12.0088C13.0688 8.1406 13.0751 5.83343 12.7885 1.99012C12.7157 1.01342 11.9879 0.250239 11.0922 0.198801L7.67918 0.00277778C6.64554 -0.0565867 5.77766 0.843151 5.77766 1.97408V6.41667H0.697649C0.39849 6.41667 0 6.71087 0 7.03304C0 7.3552 0.398433 7.58333 0.697592 7.58333H5.77766V12.0259C5.77766 13.1569 6.64562 14.0567 7.6793 13.9972ZM5.77766 7.58333H8.80334L7.56134 8.92085C7.3498 9.14866 7.3498 9.518 7.56134 9.74581C7.77288 9.97362 8.11585 9.97362 8.32738 9.74581L10.3919 7.52247C10.6599 7.23392 10.6599 6.76608 10.3919 6.47753L8.32738 4.25419C8.11585 4.02638 7.77288 4.02638 7.56134 4.25419C7.3498 4.48199 7.3498 4.85134 7.56134 5.07915L8.80334 6.41667H5.77766V7.58333Z"
        />
      </svg>
    </span>
  );
}
