import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { InterpolateBadge } from "@/components/Buttons";
import { classNames } from "@/data/classnames";
import { on } from "events";

interface CheckboxFieldProps {
  className?: string;
  value: boolean;
  name: string;
  helper?: string;
  label: string;
  columns?: number;
  onChange: (value: boolean) => void;
}

export const CheckboxField = ({
  value,
  name,
  label,
  columns,
  helper,
  onChange,
}: CheckboxFieldProps) => {
  const selected = value;

  return (
    <>
      <div className={classNames("flex", columns ? `col-span-${columns}` : "")}>
        <div className="flex items-center h-5">
          <input
            className="w-4 h-4 text-martEye-400 bg-white border-martEye-400 border-2 rounded focus:ring-martEye-500 focus:ring-2 accent-martEye-400"
            name={name}
            type="checkbox"
            checked={value}
            onChange={(e) => {
              console.log("CheckboxField onChange", e.target.checked);
              onChange(e.target.checked);
            }}
          />
        </div>

        <div className="ml-2 text-sm">
          <label htmlFor={name} className="text-sm font-medium text-gray-900">
            {label}
          </label>

          <p className="text-xs mt-1">{helper}</p>
        </div>
      </div>
    </>
  );
};

export default CheckboxField;
