import jsPDF from "jspdf";
import { DraftInvoice, Invoice } from "types";

interface FooterProps {
  margin: number;
  textFillColor: string;
}

export default function footer(
  doc: jsPDF,
  invoice: Invoice | DraftInvoice,
  config: FooterProps
) {
  const pageHeight = doc.internal.pageSize.getHeight();
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageCount = doc.getNumberOfPages();
  const superType = invoice?.superTypes?.[0] + ":" || "";

  for (var i = 1; i <= pageCount; i++) {
    // Set Page Number to the current page
    doc.setPage(i);
    doc.setFont("Inter", "normal");
    doc.setFontSize(8);
    doc.text(
      "Page " + String(i) + " of " + String(pageCount),
      pageWidth / 2,
      pageHeight - config.margin,
      {
        align: "center",
        baseline: "top",
        maxWidth: pageWidth - config.margin * 2,
      }
    );

    // Set the total tags read and total tags to the page
    doc.setFontSize(10);
    doc.setFont("Inter", "bold");
    doc.setTextColor(config.textFillColor);

    doc.text(
      `Tags Read: ${doc.tagsRead}`,
      pageWidth - config.margin,
      pageHeight - config.margin,
      {
        align: "right",
        baseline: "top",
      }
    );

    doc.text(
      `${superType} ${doc.totalItems}`,
      config.margin,
      pageHeight - config.margin,
      {
        align: "left",
        baseline: "top",
      }
    );

    // Now add a line to separate the footer from the content
    doc.setLineWidth(0.5);
    doc.setDrawColor(config.textFillColor);
    doc.line(
      config.margin,
      pageHeight - config.margin - 5,
      pageWidth - config.margin,
      pageHeight - config.margin - 5
    );
  }
}
