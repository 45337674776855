import {
  FieldValue,
  Timestamp,
  arrayRemove,
  arrayUnion,
  deleteField,
  increment,
  serverTimestamp,
} from "firebase/firestore";

/**
 *  This function converts a nested object to a flat object that can be used for
 *  merging into a Firestore document.
 *
 *  e.g. convert:
 *
 *  {
 *   lotNumber: "123",
 *   attributes: {
 *     livestockSaleType: "Store"
 *   }
 *  }
 *
 *  to:
 *
 *  {
 *    lotNumber: "123",
 *    "attributes.livestockSaleType": "Store"
 *  }
 *
 *  Note the 'attributes' object here will not clobber any other attributes on the document but instead be merged into it.
 *
 */
export function toFirestoreUpdateObject(
  obj: Record<string, any>,
  options?: {
    preserveEmptyObjects?: boolean;
  }
): Record<string, string | number | boolean | null | Timestamp | {}> {
  let result: Record<
    string,
    string | number | boolean | null | Timestamp | {}
  > = {};

  function process(unsafeKey: string, value: any, prefix: string): void {
    // replace any spaces and .s in the key with underscores
    let key = unsafeKey.replace(/ /g, "_").replace(/\./g, "_");

    if (
      value !== null &&
      typeof value === "object" &&
      !Array.isArray(value) &&
      !(value instanceof Timestamp) &&
      !deleteField().isEqual(value as FieldValue) &&
      !serverTimestamp().isEqual(value as FieldValue) &&
      !arrayUnion().isEqual(value as FieldValue) &&
      !arrayRemove().isEqual(value as FieldValue) &&
      !increment(0).isEqual(value as FieldValue)
    ) {
      if (Object.keys(value).length === 0) {
        // empty object
        if (options?.preserveEmptyObjects) {
          result[`${prefix}${key}`] = {};
          return;
        }

        result[`${prefix}${key}`] = null;
        return;
      }

      // It's a nested object, so iterate over its properties
      for (const [nestedKey, nestedValue] of Object.entries(value)) {
        process(nestedKey, nestedValue, `${prefix}${key}.`);
      }
    } else {
      // It's a primitive value or an array, so set it in the result
      if (value === undefined) {
        result[`${prefix}${key}`] = null;
      } else {
        result[`${prefix}${key}`] = value;
      }
    }
  }

  for (const [key, value] of Object.entries(obj)) {
    process(key, value, "");
  }

  return result;
}
