import { Label } from "@headlessui/react/dist/components/label/label";
import { FieldProps, ErrorMessage } from "formik";

interface TextFieldProps extends FieldProps {
  className: string;
  value: string;
  form: any;
  label: string;
  required?: boolean;
}

export const TextField = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: TextFieldProps) => (
  <div className="w-full">
    <label
      htmlFor={field.name}
      className="block text-sm font-medium leading-6 text-gray-900"
    >
      {formatLabel(props.label)}
      {props.required && <span className="sr-hidden text-danger-300">*</span>}
    </label>
    <div className="mt-1">
      <input
        {...field}
        {...props}
        className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-martEye-400 sm:text-sm sm:leading-6"
      />
      <ErrorMessage
        name={field.name}
        render={(msg) => <p className="mt-1 text-xs text-danger-400">{msg}</p>}
      />
    </div>
  </div>
);

export default TextField;

// Helper function to get the value of a field
function formatLabel(str: string) {
  // Add space between numbers and uppercase every word
  const formattedStr = str
    .replace(/(\d+)([a-zA-Z])/g, "$1 $2")
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/([a-zA-Z])(\d+)/g, "$1 $2")
    .replace(/\b\w/g, (match) => match.toUpperCase());

  // Return the formatted string
  return formattedStr;
}
