import { Timestamp } from "firebase/firestore";
import { ClientType, Invoice, Media, PayoutMethod } from "types";
import { cloudFunction } from "../helpers/cloudfunction";

export interface CheckoutRequestData {
  lotIdents: { saleId: string; lotId: string }[];
  clientType: ClientType;
  marketId: string;

  // Additional products to add to the invoice
  // if this is undefined and there are products in the cart then all the products will be added
  // if you want to add no products then pass an empty array
  cartItemIds?: string[];

  // Supply to override the name of the customer or tranding name to go on the invoice
  name?: string;

  // we can override the address
  // the address must be defined on the customer profile
  addressId?: string;

  // Supply to override the email address
  email?: string | null;

  // Supply to override the vat number
  // Will set this on the customer's profile after
  vatNumber?: string;

  // The lineitems to apply discount to. Line item ID's are stable.
  // Discounts can only be applied to lot line items (aka commission)
  discountsByLineItemInCents?:
    | { [lineItemId: string]: number }
    | null
    | undefined;

  // note this is requred for checking out a seller
  payout?: {
    method: PayoutMethod;
    // if any payout should be less any money owed to the market
    // defaults to true
    settleDebtsFirst?: boolean | undefined;

    // Defaults will be shown on the draft invoice but can be overridden here
    // For BACS
    accountName?: string;
    accountNumber?: string;
    sortCode?: string;

    // For cheque
    chequeMadePayableTo?: string;
  };

  // We may need to collect some info for the lots at checkout (e.g. destination address)
  // atrributes here will get set onto the relevant lots once the invoice is created
  attributeValues?: {
    [attributekey: string]: string | number | boolean | Timestamp | Media;
  };
}

export async function createDraftInvoice(params: CheckoutRequestData) {
  let func = cloudFunction<CheckoutRequestData, Invoice>(
    "createDraftInvoiceCall"
  );

  if (!params.marketId) {
    throw new Error(`MarketID needs to be specified`);
  }

  let result = await func(params);

  return result;
}
