import { Fragment, useState, useRef } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { FieldProps, useFormikContext, ErrorMessage } from "formik";
import { InterpolateBadge } from "@/components/Buttons";
import { classNames } from "@/data/classnames";
import { usePopper } from "react-popper";
import { Portal } from "react-portal";

interface SelectFieldProps extends FieldProps {
  className: string;
  value: string;
  label: string;
  options: OptionProps[];
  multiple?: boolean;
  required?: boolean;
  onChange?: (value: any) => void;
}

interface OptionProps {
  label: string;
  value: string;
}

export const SelectField = ({
  multiple,
  options,
  field, // { name, value, onChange, onBlur }
  ...props
}: SelectFieldProps) => {
  const selected = field.value;
  const formikProps = useFormikContext();
  const setSelected = (value: any) => {
    formikProps.setFieldValue(field.name, value);

    if (props.onChange) {
      props.onChange(value);
    }
  };

  const ButtonContent = ({ multiple, selected, options }: any) => {
    if (multiple) {
      if (selected.length) {
        return selected.map((option: any) => {
          const title = options.find(
            ({ value }: OptionProps) => value === option
          );

          return (
            <InterpolateBadge
              key={option}
              title={title.label}
              className="mr-0"
            ></InterpolateBadge>
          );
        });
      } else {
        return <span>Please select</span>;
      }
    } else {
      if (selected) {
        const title = options.find(
          ({ value }: OptionProps) => value === selected
        );

        return (
          <span className="inline-flex items-center gap-x-0.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium uppercase text-martEye-700 ring-1 ring-inset ring-gray-100">
            {title?.label}
          </span>
        );
      } else {
        return <span>Please select</span>;
      }
    }
  };

  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState<HTMLButtonElement | null>(
    null
  );
  let [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  let { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 5],
        },
      },
    ],
  });

  return (
    <>
      <Listbox value={selected} onChange={setSelected} multiple={multiple}>
        {({ open }) => (
          <>
            <div className="w-full">
              <label
                htmlFor={field.name}
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {formatLabel(props.label)}
                {props.required && (
                  <span className="sr-hidden text-danger-300">*</span>
                )}
              </label>

              <div className="relative mt-1">
                <Listbox.Button
                  ref={setTargetElement}
                  className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-martEye-600 sm:text-sm sm:leading-6"
                >
                  <span className="flex flex-wrap	items-center gap-1">
                    <ButtonContent
                      multiple={multiple}
                      selected={selected}
                      options={options}
                    />
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>

                <Portal>
                  <div
                    ref={popperElRef}
                    style={styles.popper}
                    {...attributes.popper}
                    className="relative z-[100]"
                  >
                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      beforeEnter={() => setPopperElement(popperElRef.current)}
                      afterLeave={() => setPopperElement(null)}
                    >
                      <Listbox.Options className="mt-1 h-full max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {
                          //@ts-ignore
                          options.map((option: OptionProps) => {
                            return (
                              <Listbox.Option
                                key={option.value}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "bg-martEye-600 text-white"
                                      : "text-gray-900",
                                    "relative cursor-default select-none py-2 pl-3 pr-9"
                                  )
                                }
                                value={option.value}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <div className="flex items-center">
                                      <span
                                        className={classNames(
                                          selected
                                            ? "font-semibold"
                                            : "font-normal",
                                          "ml-3 block truncate"
                                        )}
                                      >
                                        {option.label}
                                      </span>
                                    </div>
                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active
                                            ? "text-white"
                                            : "text-martEye-600",
                                          "absolute inset-y-0 right-0 flex items-center pr-4"
                                        )}
                                      >
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            );
                          })
                        }
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Portal>
              </div>
            </div>
          </>
        )}
      </Listbox>
      <ErrorMessage
        name={field.name}
        render={(msg) => <p className="mt-1 text-xs text-danger-400">{msg}</p>}
      />
    </>
  );
};

export default SelectField;

// Helper function to get the value of a field
function formatLabel(str: string) {
  // Add space between numbers and uppercase every word
  const formattedStr = str
    .replace(/(\d+)([a-zA-Z])/g, "$1 $2")
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/([a-zA-Z])(\d+)/g, "$1 $2")
    .replace(/\b\w/g, (match) => match.toUpperCase());

  // Return the formatted string
  return formattedStr;
}
