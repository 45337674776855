import React, { useState, useCallback, ChangeEvent, useEffect } from "react";
import { CurrenciesWithGuinea, Currency, LotIssue } from "types";

// UI
import { ValidationIssue, inputBorder } from "../LotFlyout/LotValidation";
import classNames from "classnames";
import { Spinner } from "@/components/Buttons";

// Data
import { Timestamp } from "firebase/firestore";
import { currencyToSymbol } from "@/data/amounts";

interface InputProps {
  id?: string;
  label: string;
  name: string;
  value: string | number | Timestamp;
  type?:
    | "text"
    | "number"
    | "currency"
    | "date"
    | "string"
    | "email"
    | "tel"
    | "datetime"
    | "time";
  onChange: (value: string | number | Timestamp) => void;
  disabled?: boolean;
  columns?: number;
  required?: boolean;
  error?: LotIssue | false;
  prefix?: string;
  suffix?: string;
  isLoadingIn?: boolean;
  currency?: CurrenciesWithGuinea;
  isPreSale?: boolean;
}

const TextInput = React.forwardRef<HTMLInputElement, InputProps>(
  function TextInputComponent(
    props: InputProps,
    ref: React.Ref<HTMLInputElement>
  ) {
    // Initial props
    const {
      id,
      type,
      name,
      value,
      disabled,
      required,
      prefix,
      suffix,
      columns,
      isLoadingIn,
      isPreSale = true,
    } = props;

    let error = props.error ?? false;
    let currency = props.currency ?? null;

    // Field Configuration
    let fieldType = type === "datetime" ? "datetime-local" : type ?? "text";

    // Remove Prefix and Suffix from value whilst editing
    let valueOrTimestamp: string | number | Timestamp = value || "";
    if (valueOrTimestamp) {
      if (typeof valueOrTimestamp === "string") {
        if (prefix && valueOrTimestamp.startsWith(prefix)) {
          valueOrTimestamp = valueOrTimestamp.slice(prefix.length);
        }
        if (suffix && valueOrTimestamp.endsWith(suffix)) {
          valueOrTimestamp = (valueOrTimestamp as string).slice(
            0,
            -suffix.length
          );
        }
      } else if (
        typeof valueOrTimestamp === "object" &&
        valueOrTimestamp instanceof Timestamp
      ) {
        // We only use Timestamps to and from the database
        // Convert from Timestamp to what to use in the input
        if (type === "date") {
          valueOrTimestamp = valueOrTimestamp
            .toDate()
            .toISOString()
            .slice(0, 10);
        } else if (type === "datetime") {
          valueOrTimestamp = valueOrTimestamp
            .toDate()
            .toISOString()
            .slice(0, 16);
        }
      }
    }
    let initialValue = valueOrTimestamp as string | number;

    useEffect(() => {
      setInputValue(initialValue);
    }, [initialValue]);

    const [isDirty, setIsDirty] = useState(false);
    const [inputValue, setInputValue] = useState(initialValue ?? "");

    const onChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        setIsDirty(true);
        setInputValue(value);
      },
      [setInputValue, setIsDirty]
    );

    const onBlur = useCallback(() => {
      if (isDirty) {
        const value = inputValue;
        let valueToSave: string | Timestamp | number = value;
        if (value) {
          if (type === "text" && typeof value === "string") {
            if (prefix && !value.startsWith(prefix)) {
              valueToSave = prefix + valueToSave;
            }

            if (suffix && !value.endsWith(suffix)) {
              valueToSave = valueToSave + suffix;
            }
          } else if (type === "date") {
            // the value we save needs to be a Timestamp
            valueToSave = Timestamp.fromDate(new Date(value));
          } else if (type === "datetime") {
            valueToSave = Timestamp.fromDate(new Date(value));
          } else if (type === "number") {
            valueToSave = Number(value);
          }
        }
        props.onChange(valueToSave);
        setIsDirty(false);
      }
    }, [isDirty, inputValue, props.onChange, type, setIsDirty, prefix, suffix]);

    // Only show error if the field has been interacted with
    if (!value && isPreSale) {
      error = false;
    }

    // Validation
    const inputBorderClasses = inputBorder({ error, disabled });
    const inputClasses = classNames(
      "w-full rounded border-0 focus-within:outline-none focus-within:ring-0 focus:outline-none focus:ring-0 focus:ring-transparent text-sm py-2 px-3 bg-transparent",
      disabled ? "cursor-not-allowed" : ""
    );

    return (
      <>
        <div className={classNames(columns ? `col-span-${columns}` : "")}>
          <label
            htmlFor={id ?? name}
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {props.label}

            {required && (
              <span className="text-red-500 ml-0.5" aria-hidden="true">
                * <span className="sr-only">Required Field</span>
              </span>
            )}
          </label>
          <div className="relative mt-1">
            <div className={inputBorderClasses}>
              <ul className="flex items-center gap-x-[2px] p-[2px] h-full w-full">
                {type === "currency" && (
                  <li className="shrink-0">
                    <span className="text-sm py-2 pl-3 -mr-3">
                      {currencyToSymbol(currency)}
                    </span>
                  </li>
                )}
                <li className="relative w-full min-w-0">
                  <input
                    ref={ref}
                    type={fieldType}
                    name={name}
                    id={id ?? name}
                    className={inputClasses}
                    value={inputValue}
                    disabled={disabled}
                    onChange={onChange}
                    onBlur={onBlur}
                    step={type === "currency" ? "0.00" : undefined}
                    min={type === "currency" ? "0.00" : undefined}
                    max={type === "currency" ? "100000000.00" : undefined}
                  />
                </li>{" "}
                {isLoadingIn && (
                  <li className="w-[30px] shrink-0 h-full rounded overflow-hidden relative">
                    <div className="flex h-full w-full items-center justify-center">
                      <Spinner className="h-4 w-4 animate-spin" />
                    </div>
                  </li>
                )}
                {error && value && (
                  <li className="w-[30px] shrink-0 h-full rounded overflow-hidden relative">
                    <ValidationIssue error={error} />
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
);
export default TextInput;
