import { autoId } from "@/data/ids";
import { deleteField, serverTimestamp } from "firebase/firestore";
import { LotIssue } from "types";
import { FullSaleContext } from "../ExecuteInSaleContext";

// only the fields we need.
// - itemId
// - path
// - code
// - description
// - severity
// - blockCheckout
type CreateLotIssueParams = Pick<
  LotIssue,
  "itemId" | "path" | "code" | "description" | "severity" | "blockCheckout"
> & {
  // optionaly provide a stable ID
  id?: string;
};

export async function createLotIssue(
  ctx: FullSaleContext,
  lotIdOrIds: string | string[],
  options: CreateLotIssueParams
): Promise<void> {
  let { currentUid } = ctx;

  let lotIds: string[];
  if (Array.isArray(lotIdOrIds)) {
    lotIds = lotIdOrIds;
  } else {
    lotIds = !!lotIdOrIds ? [lotIdOrIds] : [];
  }

  if (!currentUid) throw new Error(`No current user`);
  if (lotIds.length === 0) throw new Error(`No lot id`);
  if (!options.code) throw new Error(`No issue code`);
  if (!options.description) throw new Error(`No issue description`);

  for (let lotId of lotIds) {
    let lot = ctx.lots.find((lot) => lot.id === lotId);
    if (!lot) throw new Error(`Lot ${lotId} not found`);
  }

  let id = options.id ?? autoId();

  let newIssue: LotIssue = {
    id,
    createdAt: serverTimestamp(),
    createdBy: currentUid,
    itemId: options.itemId ?? null,
    path: options.path ?? null,
    code: options.code,
    description: options.description,
    severity: options.severity ?? "error",
    blockCheckout: options.blockCheckout ?? false,
  };

  await ctx.studio.updateLots(lotIds, {
    [`issues.${id}`]: newIssue,
  });
}

export async function resolveLotIssue(
  ctx: FullSaleContext,
  lotId: string,
  issueId: string
) {
  let lot = ctx.lots.find((lot) => lot.id === lotId);

  if (!lot) throw new Error(`Lot ${lotId} not found`);

  let originalIssue = (lot.issues || {})[issueId];

  if (!originalIssue) {
    console.warn(
      `Issue ${issueId} not found on lot ${lotId}. Unable to resolve`
    );
    return;
  }

  if (originalIssue.code === "validation-error") {
    console.warn(`Issue ${issueId} is a validation error. Unable to resolve`);
    return;
  }

  // note validation errors don't get resolved in this way.
  await ctx.studio.updateLot(lotId, {
    [`issues.${issueId}`]: deleteField(),
    [`resolvedIssues.${issueId}`]: originalIssue,
  });
}
