import * as converter from "json-2-csv";

export function downloadURL(url: string) {
  let link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", "");
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function downloadFile(filename: string, blob: Blob) {
  let url = URL.createObjectURL(blob);
  let link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", filename);
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export async function createAndDownloadCSV(filename: string, data: object[]) {
  let csv = await converter.json2csv(data, {
    emptyFieldValue: "",
  });

  let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

  downloadFile(filename, blob);
}

export async function base64ToDownload(filename: string, base64: string) {
  const raw = atob(base64);
  const blob = new Blob([raw], {
    type: "data:application/octet-stream;base64",
  });

  downloadFile(filename, blob);
}
