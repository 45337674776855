import React, { use, useEffect, useReducer, useRef, useState } from "react";

type ModifierKeyType = "Alt" | "Control";
interface KeyboardShortcutState {
  isModKeyDown: boolean;
  secondaryKeyDown: string | null;
  showHint: boolean;
  modifierKey: ModifierKeyType;
}

type KeyboardActions =
  | { type: "show_hint" }
  | { type: "hide_hint" }
  | { type: "borwser_tab_lost_focus" }
  | { type: "key_down"; key: string }
  | { type: "key_up"; key: string }
  | { type: "set_modifier_key"; modifierKey: ModifierKeyType };

const KeyboardShortcutContext = React.createContext<{
  state: KeyboardShortcutState;
  dispatch: React.Dispatch<KeyboardActions>;
} | null>(null);

const keyboardShortcutReducer = (
  state: KeyboardShortcutState,
  action: KeyboardActions
): KeyboardShortcutState => {
  switch (action.type) {
    case "key_down":
      if (action.key === state.modifierKey) {
        return { ...state, isModKeyDown: true };
      }
      if (state.isModKeyDown) {
        if (action.key !== state.secondaryKeyDown) {
          return { ...state, secondaryKeyDown: action.key };
        }
      }
      return state;
    case "key_up":
      const keyUp = action.key;
      const isKeyUpSameAsKeyDown = state.secondaryKeyDown === keyUp;
      const isModUp = action.key === state.modifierKey;

      if (isModUp) {
        return {
          ...state,
          isModKeyDown: false,
          secondaryKeyDown: null,
          showHint: false,
        };
      }
      if (isKeyUpSameAsKeyDown) {
        return { ...state, secondaryKeyDown: null };
      }
      return state;
    case "show_hint":
      return {
        ...state,
        showHint: true,
      };
    case "hide_hint":
      return {
        ...state,
        showHint: false,
      };
    case "set_modifier_key":
      return {
        ...state,
        modifierKey: action.modifierKey,
      };
    case "borwser_tab_lost_focus":
      return {
        ...state,
        secondaryKeyDown: "",
        showHint: false,
        isModKeyDown: false,
      };

    default:
      return state;
  }
};

function KeyboardShortcutProvider({ children }: { children: React.ReactNode }) {
  const os = useOs();
  const [state, dispatch] = useReducer(keyboardShortcutReducer, {
    isModKeyDown: false,
    secondaryKeyDown: null,
    showHint: false,
    modifierKey: "Alt", // Assume windows so ALT
  });
  /**
   * Set the modifier key to Control if on a mac and the Alt for Windows
   */
  useEffect(() => {
    if (os === "mac" && state.modifierKey === "Alt") {
      dispatch({ type: "set_modifier_key", modifierKey: "Control" });
    }

    if (os === "windows" && state.modifierKey === "Control") {
      dispatch({ type: "set_modifier_key", modifierKey: "Alt" });
    }
  }, [os, state.modifierKey]);

  useEffect(() => {
    const cb = (e: KeyboardEvent) => {
      // On Windows pressing the Alt Key focuses out of the document
      // Alt is not used for much in Chrome/Firefox on Windows
      if (
        (os === "windows" && e.key === state.modifierKey) ||
        state.isModKeyDown
      ) {
        e.preventDefault();
      }
      dispatch({ type: "key_down", key: e.key });
    };
    window.addEventListener("keydown", cb, true);
    return () => {
      window.removeEventListener("keydown", cb, true);
    };
  }, [state.isModKeyDown, dispatch, os]);

  // If the browser tab is hidden, we need to clear clear hot keys
  useEffect(() => {
    const cb = () => {
      if (document.visibilityState === "hidden") {
        dispatch({ type: "borwser_tab_lost_focus" });
      }
    };
    document.addEventListener("visibilitychange", cb);

    return () => {
      document.removeEventListener("visibilitychange", cb);
    };
  }, [dispatch]);

  useEffect(() => {
    const cb = (e: KeyboardEvent) => dispatch({ type: "key_up", key: e.key });
    window.addEventListener("keyup", cb, true);
    return () => {
      window.removeEventListener("keyup", cb, true);
    };
  }, [dispatch]);

  useEffect(() => {
    const cb = () => dispatch({ type: "hide_hint" });
    document.addEventListener("visibilitychange", cb, true);
    return () => {
      document.removeEventListener("visibilitychange", cb, true);
    };
  }, [dispatch]);

  /**
   * Effect to activate  the hint when the mod key is down
   */
  useEffect(() => {
    let hintTimer: NodeJS.Timeout | null = null;
    if (state.isModKeyDown) {
      hintTimer = setTimeout(() => {
        dispatch({ type: "show_hint" });
      }, 1_250);
    }

    if (!state.isModKeyDown) {
      hintTimer && clearTimeout(hintTimer);
    }
    return () => {
      hintTimer && clearTimeout(hintTimer);
    };
  }, [state.isModKeyDown, dispatch]);

  // Used as a back up to Hide the Hint, things like Alert can stop the normal flow - So this makes sure we hide it eventually
  useEffect(() => {
    let hideHintTimer: NodeJS.Timeout | null = null;
    if (state.showHint) {
      hideHintTimer = setTimeout(() => {
        dispatch({ type: "hide_hint" });
      }, 5_000);
    }
    if (state.showHint === false) {
      hideHintTimer && clearTimeout(hideHintTimer);
    }
    return () => {
      hideHintTimer && clearTimeout(hideHintTimer);
    };
  }, [state.showHint, dispatch]);
  return (
    <KeyboardShortcutContext.Provider value={{ state, dispatch }}>
      {children}
    </KeyboardShortcutContext.Provider>
  );
}

export default KeyboardShortcutProvider;

/**
 * A hook to use the keyboard shortcuts for the SaleTabs component
 * We use the Mod key to trigger the shortcuts, and the secondary key then used to trigger the callback
 */
export function useKeyboardShortcut<T extends string>(
  keys: Array<T>,
  cb: (key: T) => void
) {
  const context = React.useContext(KeyboardShortcutContext);
  if (!context) {
    throw new Error(
      "useKeyboardShortcut must be used within a KeyboardShortcutProvider"
    );
  }

  const { state } = context;

  let keysRef = useRef(keys);
  keysRef.current = keys;

  useEffect(() => {
    if (!state.secondaryKeyDown) {
      return;
    }
    if (
      (keysRef.current as string[]).includes(state.secondaryKeyDown) &&
      state.isModKeyDown
    ) {
      cb(state.secondaryKeyDown as T);
    }
  }, [state.isModKeyDown, state.secondaryKeyDown]);

  return {
    showHint: state.showHint,
    modifierKey: state.modifierKey,
  };
}

type OSType = "mac" | "windows";
/**
 * Try to determine the OS - NOTE Linux is not supported - Might need to add this in the future for Puck and E2e running on Ubuntu
 */
function useOs() {
  const [os, setOs] = useState<OSType>("windows");
  useEffect(() => {
    // Rock || Hard Place - userAgentData is Experimental and platform is depricated - So we do both - https://developer.mozilla.org/en-US/docs/Web/API/Navigator/userAgentData
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'userAgentData' does not exist on type 'Navigator'.
    const isMac = navigator?.userAgentData
      ? // @ts-expect-error ts-migrate(2339) FIXME: Property 'platform' does not exist on type 'NavigatorUAData'.
        navigator.userAgentData.platform.toUpperCase().indexOf("MAC") >= 0
      : navigator.platform.toUpperCase().indexOf("MAC") >= 0;
    if (isMac) {
      setOs("mac");
    }
  }, []);

  return os;
}
