import { Timestamp } from "firebase/firestore";
// JS PDF
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import {
  Address,
  ClientType,
  DraftInvoice,
  Invoice,
  InvoiceLineItem,
  Market,
  Sale,
  SuperType,
} from "types";
import { DocumentSpec, InvoicePDFProps } from "../_importDocs";
import {
  COLOUR_MARTEYE_500,
  MARGIN,
  PAGE_HEIGHT,
  PAGE_WIDTH,
  PTSCONVERSION,
} from "../variables";

//Sheep need lot, quantity and total quantity
//Cattle need lot, quantity, total quantity, and eartag

const FoodChainInformationSpec: DocumentSpec = {
  // must be unique
  id: "fci-cattle",

  objectType: "invoice",

  // displayed to the user
  name: "Food Chain Information (Cattle)",

  // when the PDF is downloaded, this function is called to generate the filename
  getFilename: (props) => {
    let { invoice, marketId } = props;
    let invoiceNumber =
      invoice.status === "draft" ? "Draft" : invoice.invoiceNumber;
    let filename = `fci-cattle-${marketId}-${invoiceNumber}`;
    filename = filename.replace(/ /g, "-").replace(/[^a-zA-Z0-9-]/g, "");
    return `${filename}`;
  },

  // return true if this document is available for a market to generate
  isAvailableFor: (
    market: Market,
    superTypes: SuperType[],
    clientType: ClientType
  ) => {
    if (clientType !== "Buyer") {
      return false;
    }

    // only available for livestock markets
    // We need three Fcis. For Cattle, Sheeps/Goats and pigs.
    // Leaving as single until styling is done.
    let allowedSuperTypes: SuperType[] = ["Cattle"];
    const isSuperTypeAllowed = superTypes.some((superType) =>
      allowedSuperTypes.includes(superType)
    );
    const isCountryAllowed = ["GB-ENG", "GB-SCT", "GB-WLS"].includes(
      market.countryCode
    );

    return isSuperTypeAllowed && isCountryAllowed;
  },

  // the document template
  // reactPDF: (props: {
  //   lots: Lot[];
  //   invoice: Invoice|DraftInvoice;

  //   market: Market;
  //   sales: Sale[];
  // }) => {
  //   let { lots, invoice, market, sales } = props;

  //   // We should only have one sale
  //   if (sales.length != 1) {
  //     throw new Error("Only one sale is allowed for FCI");
  //   }
  //   const sale = sales[0] ?? null;

  //   const marketAddress = market.address ? (market.address as Address) : null;

  //   let martaddress = [
  //     marketAddress?.address2 ?? "",
  //     marketAddress?.city ?? "",
  //     marketAddress?.province ?? "",
  //     marketAddress?.zip ?? "",
  //     marketAddress?.country ?? "",
  //   ];

  //   const marketCPH = market.movementLocationNumbers?.find(
  //     (cph) => cph.type === "CPH"
  //   );
  //   let usedMarketAddress = martaddress.filter((line) => line !== "");

  //   let addr = invoice.address;
  //   let customeraddress = [
  //     addr.company,
  //     addr.address1,
  //     addr.address2,
  //     addr.city,
  //     addr.province,
  //     addr.zip,
  //     addr.country,
  //   ].filter(Boolean) as string[];
  //   let usedcustomeraddressLines = customeraddress.filter(
  //     (line) => line !== ""
  //   );

  //   return (
  //     <Document>
  //       <Page size="A4" style={styles.page}>
  //         <View style={styles.section}>
  //           <Text>Food Chain Information</Text>

  //           <Text>
  //             FCI Declaration for consignments of animals from a market to a
  //             slaughterhouse
  //           </Text>

  //           <Text>Market Name: {market.name}</Text>
  //           <Text>Market Address: {usedMarketAddress.join(", ")}</Text>
  //           <Text>Market CPH: {marketCPH?.number}</Text>

  //           <Text>Customer Name: {invoice.name}</Text>
  //           <Text>Customer Address: {usedcustomeraddressLines.join(", ")}</Text>

  //           <Text>Invoice Number: {invoice.invoiceNumber}</Text>

  //           {cattleDeclaration.map((line) => (
  //             <Text style={styles.attribute}>{line}</Text>
  //           ))}

  //           {/* tables needs to be two column see figma */}
  //           {lots.map((lot) => {
  //             return (
  //               <View key={lot.id}>
  //                 <Text style={styles.lotNumber}>{lot.lotNumber}</Text>
  //                 <Text>{lot.attributes["vanNumber"]}</Text>
  //                 {Object.keys(lot.itemMap)
  //                   .map((itemId) => lot.itemMap[itemId])
  //                   .map((item) => {
  //                     return <Text>{item.attributes["@eartag"]}</Text>;
  //                   })}
  //               </View>
  //             );
  //           })}

  //           <Text>
  //             Total Quantity: Total Quantity:{" "}
  //             {lots.reduce(
  //               (acc, lot) => acc + lot.attributes["countOfItems"],
  //               0
  //             )}
  //           </Text>
  //           <Text>Signature: __________________________</Text>
  //           <Text>Print Name:__________________________</Text>
  //           <Text>
  //             Market Date: {(sale.startsAt.toDate() as Date).toDateString()}
  //           </Text>
  //         </View>
  //       </Page>
  //     </Document>
  //   );
  // },
  jsPDF: (props: InvoicePDFProps) => {
    const { market, invoice, sales } = props;

    const title = "FCI Declaration";
    const subtitle = "";
    const legalText = "";

    const doc = new jsPDF({
      putOnlyUsedFonts: true,
      compress: true,
      unit: "mm",
      format: "a4",
    });

    // Variables
    const pageWidth = PAGE_WIDTH;
    const pageHeight = PAGE_HEIGHT;
    const margin = MARGIN;

    const defaultFontSize = 12;

    // 1. Calculate the page header height and footer height so we know the safe area to work with

    // Header height (1st Page)
    let headerHeight = getPageHeaderHeight(doc, 1, title, subtitle);
    let y = headerHeight.pageOneY;

    // Footer height
    let footerHeight = getPageFooterHeight(doc, 1, legalText);

    // 2. Need to work out the safe areas so if the document is over this then it removes the element and moves it to the next page
    let maximumHeight = footerHeight - headerHeight.pageOneY;

    // 3. Add in the content
    y = getPart1(
      doc,
      y,
      true,
      invoice,
      headerHeight.pageTwoY,
      footerHeight,
      market
    );
    y = getPart2(
      doc,
      y,
      true,
      invoice,
      headerHeight.pageTwoY,
      footerHeight,
      market
    );
    y = getPart3(
      doc,
      y,
      true,
      invoice,
      headerHeight.pageTwoY,
      footerHeight,
      market,
      sales
    );

    // 4. Need to add running headers and footers
    getPageHeader(doc, true, 1, title, subtitle);
    getPageFooter(doc, true, 1, legalText);

    return doc;
  },
};

export default FoodChainInformationSpec;

// Helpers

// Helper Functions
function getPageHeaderHeight(
  doc: jsPDF,
  page: number,
  title: string,
  subtitle: string
) {
  return getPageHeader(doc, false, page, title, subtitle);
}

function getPageHeader(
  doc: jsPDF,
  output: boolean,
  page: number,
  title: string,
  subtitle: string
) {
  // Start at the top of the page 1
  doc.setPage(1);

  let y = 0;
  let fontSize = 24;
  let fontColor = COLOUR_MARTEYE_500;

  // Add intial spacing
  y += MARGIN + 5;

  let titleLines = doc
    .setFont("Inter", "bold")
    .setTextColor(fontColor)
    .setFontSize(fontSize)
    .splitTextToSize(title, (PAGE_WIDTH - 20) * 0.75);
  let titleHeight = doc.getLineHeight() * titleLines.length * PTSCONVERSION;
  if (output) doc.text(titleLines, MARGIN, y);

  // Add the title height
  y += titleHeight;

  fontSize = 10;
  fontColor = "#616161";

  let subTitleLines = doc
    .setFont("Inter", "bold")
    .setTextColor(fontColor)
    .setFontSize(fontSize)
    .splitTextToSize(subtitle, PAGE_WIDTH - 20);

  let subTitleHeight =
    doc.getLineHeight() * subTitleLines.length * PTSCONVERSION;

  if (output) doc.text(subTitleLines, MARGIN, y);

  // Add the title height
  if (subtitle) {
    y += subTitleHeight;
  }

  let pageOneY = y;
  // Page 2 Onwards

  y = 0;
  fontSize = 10;
  fontColor = COLOUR_MARTEYE_500;

  // Add intial spacing
  y += 7.5;

  titleLines = doc
    .setFont("Inter", "bold")
    .setTextColor(fontColor)
    .setFontSize(fontSize)
    .splitTextToSize(title, (PAGE_WIDTH - 20) * 0.75);
  titleHeight = doc.getLineHeight() * titleLines.length * PTSCONVERSION;

  // Add the title height
  y += titleHeight;

  const pageCount = doc.getNumberOfPages();
  for (var i = 2; i <= pageCount; i++) {
    doc.setPage(i);
    if (output) doc.text(titleLines, MARGIN, y);
  }

  let pageTwoY = y + 5;

  return {
    pageOneY: pageOneY,
    pageTwoY: pageTwoY,
  };
}

function getPageFooterHeight(doc: jsPDF, page: number, legalText: string) {
  return getPageFooter(doc, false, page, legalText);
}

function getPageFooter(
  doc: jsPDF,
  output: boolean,
  page: number,
  legalText: string
) {
  let y = PAGE_HEIGHT - 5;
  const pageCount = doc.getNumberOfPages();
  for (var i = 1; i <= pageCount; i++) {
    y = PAGE_HEIGHT - 5;
    let fontSize = 8;
    let fontColor = "#616161";

    // Set Page
    doc.setPage(i);

    let legalTextLines = doc
      .setFont("Inter", "normal")
      .setTextColor(fontColor)
      .setFontSize(fontSize)
      .splitTextToSize(legalText, PAGE_WIDTH - 20 - 30);

    let legalTextHeight =
      doc.getLineHeight() * legalTextLines.length * PTSCONVERSION;

    if (output) doc.text(legalTextLines, MARGIN, y - legalTextHeight);

    // minimum footer height
    if (i == 1) {
      y -= legalTextHeight;
    }

    if (output) {
      doc.setFontSize(8);
      doc.text(
        "Page " + String(i) + " of " + String(pageCount),
        PAGE_WIDTH - MARGIN,
        PAGE_HEIGHT - 8,
        {
          align: "right",
        }
      );
    }
    if (i == 1) {
      y -= 5;
    }
  }
  return y;
}

function getPart1(
  doc: jsPDF,
  y: number,
  output: boolean,
  invoice: Invoice|DraftInvoice,
  topY: number,
  bottomY: number,
  market: Market
) {
  // Part 2 - Movement Details

  let rowHeight = 10;
  let columnWidth = (PAGE_WIDTH - MARGIN * 2) / 3;
  let marketFlockNumber =
    market.movementLocationNumbers?.find((m) => m.type == "Flock Number")
      ?.number ?? "TBC";

  const marketAddress = market.address ? (market.address as Address) : null;

  let martaddress = [
    marketAddress?.address2 ?? "",
    marketAddress?.city ?? "",
    marketAddress?.province ?? "",
    marketAddress?.zip ?? "",
    marketAddress?.country ?? "",
  ];
  let usedMarketAddress = martaddress.filter((line) => line !== "");

  let addr = invoice.address;
  let customeraddress = [
    addr.company,
    addr.address1,
    addr.address2,
    addr.city,
    addr.province,
    addr.zip,
    addr.country,
  ].filter(Boolean) as string[];
  let usedcustomeraddressLines = customeraddress.filter((line) => line !== "");

  let flockNumberDestination =
    invoice.attributeValues?.["flockNumberDestination"] ?? "";

  const data: any = [
    [
      {
        content: "Flock No." + marketFlockNumber,
        styles: {
          fontStyle: "normal",
          cellPadding: { top: 1, bottom: 2, left: 2, right: 2 },
        },
      },
      {
        content: "Flock No." + flockNumberDestination,
        styles: {
          fontStyle: "normal",
          cellPadding: { top: 1, bottom: 2, left: 2, right: 2 },
        },
      },
    ],

    [
      {
        content: usedMarketAddress.join(", \n"),
        styles: {
          fontStyle: "normal",
          cellPadding: { top: 1, bottom: 3, left: 2, right: 2 },
        },
      },
      {
        content: usedcustomeraddressLines.join(", \n"),
        styles: {
          fontStyle: "normal",
          cellPadding: { top: 1, bottom: 3, left: 2, right: 2 },
        },
      },
    ],
  ];

  autoTable(doc, {
    body: data,
    head: [
      [
        {
          content: market.name,
        },

        {
          content: invoice.name,
        },
      ],
    ],
    startY: y,
    theme: "plain",
    margin: { top: topY, right: 10, bottom: PAGE_HEIGHT - bottomY, left: 10 },
    headStyles: {
      textColor: COLOUR_MARTEYE_500,
      fontSize: 8,
      font: "Inter",
      fontStyle: "bold",
      cellPadding: { top: 0, bottom: 0, left: 2, right: 2 },
    },
    bodyStyles: {
      fontSize: 8,
      font: "Inter",
      fontStyle: "bold",
      textColor: "#616161",
      cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
    },
    columnStyles: {
      0: { cellWidth: columnWidth },
      1: { cellWidth: columnWidth },
    },
  });

  // @ts-ignore
  y = doc.lastAutoTable.finalY + 5;

  return y;
}

function getPart2(
  doc: jsPDF,
  y: number,
  output: boolean,
  invoice: Invoice|DraftInvoice,
  topY: number,
  bottomY: number,
  market: Market
) {
  // Part 2 - Text based on super type
  const sheepDeclaration: string[] = [
    `For each lot of sheep/goats listed on this document and for eac holding from which they were consigned to the market, a declaration, signed by the keeper of the animals on each holding, has been received stating that:`,

    `Sheep and goats on the holding are not under movement restrictions for animal disease or public health reasons (excluding a 6-day standstill).`,

    `Withdrawal periods have been observed for all veterinary medicines and other treatments administered to the animals while on this holding and previous holdings.`,

    `To the best of my knowledge the animals are not showing signs of any disease or condition that may affect the safety of meat derived from them.`,
    `No analysis of samples taken from animals on the holding or other samples has shown that the animals in this consignment may have been exposed to any disease or condition that may affect the safety of meat or to substances likely to result in residues in meat.`,
  ];

  const cattleDeclaration: string[] = [
    `For all the animals listed on this document and for each holding from which they were consigned to the market, a declaration, signed by the keeper of the animals on each holding, has been received stating that:
`,
    `The holding is not under movement restriction for bovine Tuberculosis (TB)`,
    `Cattle on the holding are not under movement restrictions for other animal disease or public health reasons (excluding a 6-day standstill).`,
    `Withdrawal periods have been observed for all veterinary medicines and other treatments administered to the animals while on this holding and previous holdings.`,
    `To the best of my knowledge the animals are not showing signs of any disease or condition that may affect the safety of meat derived from them.`,
    `No analysis of samples taken from animals on the holding or other samples has shown that the animals in this consignment may have been exposed to any disease or condition that may affect the safety of meat or to substances likely to result in residues in meat.`,
    `All of the animals have originated from establishments that have received regular animal health visits from a veterinarian for the purpose of the detection and signs of disease.`,
  ];

  let declaration = cattleDeclaration;
  if (invoice.superTypes?.includes("Sheep")) {
    declaration = sheepDeclaration;
  }

  doc.setFontSize(10);
  doc.setLineHeightFactor(1.4);

  for (let i = 0; i < declaration.length; i++) {
    // Now we need to split the text into lines and add them to the document
    const lines = doc.splitTextToSize(declaration[i], PAGE_WIDTH - 20);

    // Now we need to add the lines to the document and update the y position
    let linesHeight = doc.getLineHeight() * lines.length * PTSCONVERSION;

    doc.text(lines, MARGIN, y);

    y += linesHeight + doc.getLineHeight() * 0.25;
  }

  return y;
}

function getPart3(
  doc: jsPDF,
  y: number,
  output: boolean,
  invoice: Invoice|DraftInvoice,
  topY: number,
  bottomY: number,
  market: Market,
  sales: Sale[]
) {
  // If there is more than 5 lots then we need to split the lots into two tables
  const lots = invoice.lineItems as InvoiceLineItem[];

  // simulate 200 lots for testing
  const testingLots = [];
  for (let i = 0; i < 11; i++) {
    testingLots.push(lots[0]);
  }

  y = lotsTable(doc, y, testingLots, output, topY, bottomY, market, sales);

  return y;
}

interface ItemAttributes {
  [key: string]: string;
}

function lotsTable(
  doc: jsPDF,
  y: number,
  lineItems: InvoiceLineItem[],
  output: boolean,
  topY: number,
  bottomY: number,
  market: Market,
  sales: Sale[]
) {
  const data: any = [];
  lineItems.map((item: InvoiceLineItem) => {
    let attributes: ItemAttributes[] = Object.values(
      item.metadata.itemAttributesByItemId
    );

    let earTags: string[] = [];
    attributes.forEach(function (item) {
      earTags.push(item["@eartag"]);
    });

    data.push([
      item?.metadata?.lotNumber ?? "-",
      earTags.join(", "),
      {
        content: item?.quantity ?? "-",
        styles: { halign: "right" },
      },
    ]);
  });

  // Need to split the lots into two tables if there are more than 5 lots
  if (data.length >= 5) {
    // Split the lots into two equal parts
    const split = Math.ceil(data.length / 2);
    const firstHalf = data.slice(0, split);
    const secondHalf = data.slice(split);

    // Get the page nuymber
    let pageNumber = doc.getCurrentPageInfo().pageNumber;

    let leftY = lotTable(doc, y, firstHalf, topY, bottomY, "left");

    // Need to reset the page
    if (doc.getCurrentPageInfo().pageNumber != pageNumber) {
      doc.setPage(pageNumber);
    }
    let rightY = lotTable(doc, y, secondHalf, topY, bottomY, "right");

    // We need to return the highest Y value
    y = Math.max(leftY, rightY);

    // First Half
  } else {
    y = lotTable(doc, y, data, topY, bottomY);
  }

  // We now need to add a full line in dark green
  doc.setDrawColor(COLOUR_MARTEYE_500);
  doc.setLineWidth(0.5);
  doc.line(MARGIN, y, PAGE_WIDTH - MARGIN, y);

  y += 5;

  y = lotRoundUp(doc, y, topY, bottomY, market, lineItems, sales);

  y += 5;

  return y;
}

function lotTable(
  doc: jsPDF,
  y: number,
  data: any,
  topY: number,
  bottomY: number,
  size: "full" | "left" | "right" = "full"
) {
  let margin = {
    top: topY,
    right: 10,
    bottom: PAGE_HEIGHT - bottomY,
    left: 10,
  };

  if (size === "left") {
    margin.right = PAGE_WIDTH / 2 + 2.5;
  }
  if (size === "right") {
    margin.left = PAGE_WIDTH / 2 + 2.5;
  }

  autoTable(doc, {
    body: data,
    head: [
      [
        {
          content: "Lot",
        },

        {
          content: "Tag",
        },
        {
          content: "Qty",
          styles: {
            halign: "right",
          },
        },
      ],
    ],
    startY: y,
    theme: "plain",
    margin: margin,
    headStyles: {
      fillColor: "#EFEFEF",
      textColor: "#616161",
      fontSize: 8,
      font: "Inter",
      fontStyle: "bold",
      cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
    },
    bodyStyles: {
      fontSize: 8,
      font: "Inter",
      fontStyle: "normal",
      cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
    },
    willDrawCell: function (data) {
      // Only draw the bottom border and not if it is the last row
      if (
        data.row.section === "body" &&
        data.row.index !== data.table.body.length - 1
      ) {
        doc.setDrawColor("#CBCBCB"); // set the border color
        doc.setLineWidth(0.1); // set the border with

        // draw bottom border

        let width = data.cell.x + data.cell.width;

        doc.line(
          data.cell.x,
          data.cell.y + data.cell.height,
          width,
          data.cell.y + data.cell.height
        );
      }
    },
  });

  // @ts-ignore
  y = doc.lastAutoTable.finalY + 5;

  return y;
}

function lotRoundUp(
  doc: jsPDF,
  y: number,
  topY: number,
  bottomY: number,
  market: Market,
  lineItems: InvoiceLineItem[],
  sales: Sale[]
) {
  // We need to count the lineItems qty
  let totalQty = 0;
  lineItems.forEach((item) => {
    totalQty += item.quantity;
  });

  // Assume only one sale
  let issuedAt = sales[0].startsAt as Timestamp;
  let dateText = issuedAt.toDate().toDateString();

  // We need to add a line to the top of the table
  const leftData: any = [
    [
      {
        content: "TOTAL QTY",
        styles: {
          valign: "bottom",
          fontStyle: "bold",
          fontSize: 8,
        },
      },
      {
        content: totalQty,
        styles: {
          halign: "right",
        },
      },
    ],
    [
      {
        content: "MARKET",
        styles: {
          valign: "bottom",
          fontStyle: "bold",
          fontSize: 8,
        },
      },
      {
        content: market.name,
        styles: {
          halign: "right",
        },
      },
    ],
    [
      {
        content: "MARKET DATE",
        styles: {
          valign: "bottom",
          fontStyle: "bold",
          fontSize: 8,
        },
      },
      {
        content: dateText,
        styles: {
          halign: "right",
        },
      },
    ],
  ];

  let margin = {
    top: topY,
    right: PAGE_WIDTH / 2 + 2.5,
    bottom: PAGE_HEIGHT - bottomY,
    left: 10,
  };

  autoTable(doc, {
    body: leftData,
    startY: y,
    theme: "plain",
    margin: margin,
    bodyStyles: {
      fontSize: 10,
      font: "Inter",
      textColor: "#616161",
      fontStyle: "normal",
      cellPadding: { top: 4, bottom: 4, left: 2, right: 2 },
    },
    willDrawCell: function (data) {
      if (data.row.section === "body") {
        doc.setDrawColor("#CBCBCB"); // set the border color
        doc.setLineWidth(0.1); // set the border with

        // draw bottom border

        let width = data.cell.x + data.cell.width;

        doc.line(
          data.cell.x,
          data.cell.y + data.cell.height,
          width,
          data.cell.y + data.cell.height
        );
      }
    },
  });

  // @ts-ignore
  let leftY = doc.lastAutoTable.finalY;

  // We need to add a line to the top of the table
  const rightData: any = [
    [
      {
        content: "SIGNED",
        styles: {
          valign: "bottom",
          fontStyle: "bold",
          fontSize: 8,
        },
      },
      {
        content: "",
      },
    ],
    [
      {
        content: "PRINT NAME",
        styles: {
          valign: "bottom",
          fontStyle: "bold",
          fontSize: 8,
        },
      },
      {
        content: "",
      },
    ],
    [
      {
        content: "POSITION",
        styles: {
          valign: "bottom",
          fontStyle: "bold",
          fontSize: 8,
        },
      },
      {
        content: "",
      },
    ],
  ];

  margin = {
    top: topY,
    left: PAGE_WIDTH / 2 + 2.5,
    bottom: PAGE_HEIGHT - bottomY,
    right: 10,
  };

  autoTable(doc, {
    body: rightData,
    startY: y,
    theme: "plain",
    margin: margin,
    bodyStyles: {
      fontSize: 10,
      font: "Inter",
      textColor: "#616161",
      fontStyle: "normal",
      cellPadding: { top: 4, bottom: 4, left: 2, right: 2 },
    },
    willDrawCell: function (data) {
      if (data.row.section === "body") {
        doc.setDrawColor("#CBCBCB"); // set the border color
        doc.setLineWidth(0.1); // set the border with

        // draw bottom border

        let width = data.cell.x + data.cell.width;

        doc.line(
          data.cell.x,
          data.cell.y + data.cell.height,
          width,
          data.cell.y + data.cell.height
        );
      }
    },
  });

  // @ts-ignore
  let rightY = doc.lastAutoTable.finalY;

  return Math.max(leftY, rightY) + 5;
}
