import { CompactSelection, GridSelection } from "@glideapps/glide-data-grid";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export function useDataGridSelection(
  // If true doesn't allow single cells
  onlyAllowSingleRowToBeSelected: boolean = false
) {
  let selectedRowsRef = useRef<Set<number>>(new Set());
  let [gridSelection, setGridSelection] = useState<GridSelection>({
    columns: CompactSelection.empty(),
    rows: CompactSelection.empty(),
  });

  let onGridSelectionChange = useCallback((newSelection: GridSelection) => {
    let selection = newSelection;

    if (onlyAllowSingleRowToBeSelected === true) {
      if (newSelection.current?.cell) {
        let g: GridSelection = {
          rows: CompactSelection.fromSingleSelection(
            newSelection.current.cell[1]
          ),
          columns: CompactSelection.empty(),
        };
        selection = g;
      }
    }

    setGridSelection(selection);
    selectedRowsRef.current = new Set(selection.rows.toArray());
  }, []);

  let clearGridSelection = useCallback(() => {
    setGridSelection({
      columns: CompactSelection.empty(),
      rows: CompactSelection.empty(),
    });
    selectedRowsRef.current = new Set();
  }, []);

  // if using onlyAllowSingleRowToBeSelected then setup keydown listener to move up and down
  useEffect(() => {
    if (!onlyAllowSingleRowToBeSelected) {
      return;
    }

    let onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "ArrowUp" || e.key === "ArrowDown") {
        setGridSelection((prevSelection) => {
          if (prevSelection.rows.length === 0) {
            if (e.key === "ArrowDown") {
              // select first row
              let s = {
                rows: CompactSelection.fromSingleSelection(0),
                columns: CompactSelection.empty(),
              };

              selectedRowsRef.current = new Set(s.rows.toArray());
              return s;
            }
          }

          if (prevSelection.rows.length > 0) {
            let currentRow = prevSelection.rows.toArray()[0];
            if (e.key === "ArrowUp") {
              if (currentRow > 0) {
                // select previous row
                let s = {
                  rows: CompactSelection.fromSingleSelection(currentRow - 1),
                  columns: CompactSelection.empty(),
                };

                selectedRowsRef.current = new Set(s.rows.toArray());
                return s;
              }
            }

            if (e.key === "ArrowDown") {
              // select next row
              let s = {
                rows: CompactSelection.fromSingleSelection(currentRow + 1),
                columns: CompactSelection.empty(),
              };

              selectedRowsRef.current = new Set(s.rows.toArray());
              return s;
            }
          }

          return prevSelection;
        });
      }
    };

    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [onlyAllowSingleRowToBeSelected]);

  return useMemo(() => {
    return {
      selectedRowsRef,
      gridSelection,
      onGridSelectionChange,
      clearGridSelection,
    };
  }, [
    selectedRowsRef,
    gridSelection,
    onGridSelectionChange,
    clearGridSelection,
  ]);
}
