export function KeyBoardButton({
  keyboardKey,
  type,
}: {
  type: "solid" | "outline";
  keyboardKey: string | JSX.Element;
}) {
  let classNames = "uppercase text-xs font-semibold ";

  switch (type) {
    case "solid":
      classNames +=
        "px-1 py-1 text-gray-800 bg-gray-100 border border-gray-200 rounded-lg";
      break;
    case "outline":
      classNames += "text-white";
    default:
      break;
  }

  return <kbd className={classNames}>{keyboardKey}</kbd>;
}
