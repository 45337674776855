import { formatDateTime } from "@/data/attribute-definitions/funcs/getDisplayValue";
import { DocumentIcon } from "@heroicons/react/24/solid";
import { classNames } from "../../data/classnames";
import { Invoice } from "../../types";
import {
  OpenInvoiceSheetParams,
  useInvoiceSheet,
} from "../invoice-sheet/useInvoiceSheet";

const columns = [
  {
    header: "#",
    classNames: "underline font-bold",
    getValue: (
      row: Invoice,
      openInvoiceSheet: (
        info: OpenInvoiceSheetParams,
        opts?: {
          replace: boolean;
        }
      ) => void
    ) => {
      return (
        <a
          href={`#`}
          onClick={(e) => {
            e.preventDefault();
            openInvoiceSheet({
              invoiceId: row.id,
            });
          }}
        >
          <div className="flex items-center">
            <DocumentIcon className="mr-1 h-4 w-4"></DocumentIcon>{" "}
            <span>{row.invoiceNumber}</span>
          </div>
        </a>
      );
    },
  },

  {
    header: "Date",
    getValue: (row: Invoice) => {
      if (row.issuedAt) {
        let date = row.issuedAt.toDate();
        return formatDateTime(date);
      }
    },
  },

  {
    header: "Customer",
    classNames: "",
    getValue: (row: Invoice) => row.clientType,
  },
  {
    header: "Sale type",
    getValue: (row: Invoice) => row.superTypes,
  },
  {
    header: "Sale Title",
    getValue: (row: Invoice) => row.sales.map((sale) => sale.name).join(", "),
  },
  {
    header: "Qty",
    getValue: (row: Invoice) => {
      return row.lineItems.reduce((acc, item) => acc + item.quantity, 0);
    },
  },

  {
    header: "Value",
    getValue: (row: Invoice) => {
      let currency = new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: row.currency,
      });
      return currency.format(row.finalTotalInCents / 100);
    },
  },
  {
    header: "Status",
    getValue: (row: Invoice) => {
      return row.status?.toUpperCase();
    },
  },
];

interface Props {
  invoices: Invoice[];
}

export function InvoiceTable(props: Props) {
  let { openInvoiceSheet } = useInvoiceSheet();

  let sortedInvoices = [...props.invoices].sort((a, b) => {
    let ac = a.createdAt.toDate() as Date;
    let bc = b.createdAt.toDate() as Date;

    return bc.getTime() - ac.getTime();
  });

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full py-2 align-middle">
          <div className="overflow-hidden md:rounded-lg">
            <table className="min-w-full">
              <thead className="bg-martEye-400 font-medium text-white">
                <tr className="divide-x divide-martEye-100">
                  {columns.map((column) => (
                    <th
                      key={column.header}
                      scope="col"
                      className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm "
                    >
                      {column.header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-x divide-gray-200 bg-white">
                {sortedInvoices.map((invoice) => (
                  <tr key={invoice.id} className="divide-x divide-gray-200">
                    {columns.map((column) => (
                      <td
                        key={column.header}
                        className={classNames(
                          "whitespace-nowrap py-2 pl-4 pr-3 text-sm text-martEye-700 ",
                          column.classNames
                        )}
                      >
                        {column.getValue(invoice, openInvoiceSheet)}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
