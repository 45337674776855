import { useRouter } from "next/router";
import { useCallback, useSyncExternalStore } from "react";

const hashCache = {
  hash: "",
  value: null as any,
};

function getHashSnapshot() {
  let url = window.location.href;
  let h = url.split("#")[1] || "";

  if (hashCache.hash === h) {
    return hashCache.value;
  }

  // Extract the tab query string
  let query = new URLSearchParams(h);

  let values = {} as {
    [key: string]: string | null;
  };

  query.forEach((value, key) => {
    values[key] = value;
  });

  let hasValue = Object.values(values).some((value) => value !== null);

  if (hasValue === false) {
    return null;
  } else {
    hashCache.hash = h;
    hashCache.value = values;
    return values; // careful to always return the same object
  }
}

const getHashSnapshotServer = () => null;

/***
 * Watch for changes to the url params and update the url state
 */
export function useUrlFragmentState() {
  let router = useRouter();

  let subscribeToHashChange = useCallback((callback: () => void) => {
    router.events.on("hashChangeComplete", callback);
    return () => {
      router.events.off("hashChangeComplete", callback);
    };
  }, []);

  let params = useSyncExternalStore(
    subscribeToHashChange,
    getHashSnapshot,
    getHashSnapshotServer
  );

  let setUrlParams = useCallback(
    (
      // supply null here to close the flyout
      urlParamMap:
        | {
            [key: string]: string | number | undefined | null;
          }
        | null
        | undefined,

      // any params that should be cleared when this one is set
      clearParams?: string[]
    ) => {
      let url = router.asPath;
      let h = url.split("#")[1] || "";

      let query = new URLSearchParams(h);
      if (clearParams) {
        for (let urlParam of clearParams) {
          query.delete(urlParam);
        }
      }

      if (urlParamMap) {
        let entries = Object.entries(urlParamMap);
        for (let entry of entries) {
          let [key, value] = entry;
          if (value !== null && value !== undefined) {
            query.set(key, `${value}`);
          }

          // TODO Check if this is allowed
          if (value === undefined) {
            query.delete(key);
          }
        }
      }

      router.push(url.split("#")[0] + "#" + query.toString(), undefined, {
        shallow: true,
      });
    },
    [router]
  );

  return [params, setUrlParams] as const;
}

export function useUrlFragmentParamState(urlParam: string) {
  let [urlParamValues, setParamValues] = useUrlFragmentState();
  let value = urlParamValues?.[urlParam] ?? null;

  let setUrlParamValue = useCallback(
    (value: string | null) => {
      setParamValues({
        [urlParam]: value,
      });
    },
    [setParamValues, urlParam]
  );

  return [value, setUrlParamValue] as const;
}
