import { ScissorsIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";

import { Lot, LotItem } from "../../types";

import { KeyBoardButton } from "@/data/keyboard/Keyboard.hints";
import { useKeyboardShortcut } from "@/data/keyboard/Keyboard.provider";
import { Button } from "../Buttons";
import SplitLotDialogue from "./SplitLotDialog";

interface SplitLotDialogueProps {
  showButton?: boolean;
  preSelectedLotItems?: LotItem[];
  lot: Lot;
  disabled: boolean;
  allLots: Lot[];
  allowSetToActive?: boolean;
  onClose: () => void;
  keyboardShortcutKey?: string;
  selectLot?: Lot | null;
}

function AddKeyboardShortcut(props: {
  cb: () => void;
  keyboardShortcutKey: string;
}) {
  const { showHint, modifierKey } = useKeyboardShortcut(
    [props.keyboardShortcutKey],
    (key) => {
      switch (key) {
        case props.keyboardShortcutKey:
          props.cb();
          break;
      }
    }
  );

  if (showHint) {
    return (
      <div className="absolute top-[-24px] right-[0px] flex flex-row gap-1 bg-black/20 p-1 rounded px-2 scale-[0.8] ">
        <KeyBoardButton type="solid" keyboardKey={modifierKey} />
        <KeyBoardButton type="solid" keyboardKey={"+"} />
        <KeyBoardButton type="solid" keyboardKey={props.keyboardShortcutKey} />
      </div>
    );
  }

  return null;
}
export function SplitItems(props: SplitLotDialogueProps) {
  const { showButton = true, onClose } = props;

  const [lotToSplit, setLotToSplit] = useState<Lot | null>(null);

  useEffect(() => {
    if (props.selectLot) {
      setLotToSplit(props.selectLot);
    }
  }, [props.selectLot]);

  if (!props.disabled) {
    return (
      <>
        {showButton && (
          <Button
            title="Split Items into a new Lot or move to an existing lot"
            variant="tertiary"
            onClick={() => setLotToSplit(props.lot)}
            className="hover:bg-martEye-400 hover:text-white"
          >
            Move Items
            <ScissorsIcon className="h-4 w-4" aria-hidden="true" />
          </Button>
        )}
        {props.keyboardShortcutKey && (
          <AddKeyboardShortcut
            cb={() => setLotToSplit(props.lot)}
            keyboardShortcutKey={props.keyboardShortcutKey}
          />
        )}
        <SplitLotDialogue
          preSelectedLotItems={props.preSelectedLotItems ?? []}
          lot={lotToSplit}
          allLotsToSplit={lotToSplit ? [] : lotToSplit}
          allLots={props.allLots}
          onClose={() => {
            setLotToSplit(null);
            onClose();
          }}
        />
      </>
    );
  }

  return (
    <div className="flex relative">
      {props.keyboardShortcutKey && (
        <AddKeyboardShortcut
          cb={() => {}}
          keyboardShortcutKey={props.keyboardShortcutKey}
        />
      )}
      {showButton && (
        <Button variant="tertiary" isDisabled={true} onClick={() => {}}>
          Move Items
          <ScissorsIcon className="h-4 w-4" aria-hidden="true" />
        </Button>
      )}
    </div>
  );
}
