import { Timestamp } from "firebase/firestore";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import {
  DraftInvoice,
  Invoice,
  InvoiceLineItem,
  Market,
  SuperType,
} from "types";
import { DocumentSpec, InvoicePDFProps } from "../_importDocs";
import {
  COLOUR_MARTEYE_500,
  MARGIN,
  PAGE_HEIGHT,
  PAGE_WIDTH,
  PTSCONVERSION,
} from "../variables";

const MovementNotificationCattleSpec: DocumentSpec = {
  // must be unique
  id: "movement-notification-cattle",
  objectType: "invoice",

  // displayed to the user
  name: "Movement Notification of Cattle",

  // when the PDF is downloaded, this function is called to generate the filename
  getFilename: (props) => {
    let { invoice, marketId } = props;
    let invoiceNumber =
      invoice.status === "draft" ? "Draft" : invoice.invoiceNumber;
    let filename = `movement-notification-cattle-${marketId}-${invoiceNumber}`;
    filename = filename.replace(/ /g, "-").replace(/[^a-zA-Z0-9-]/g, "");
    return `${filename}`;
  },

  // return true if this document is available for a market to generate
  isAvailableFor: (market: Market, superTypes: SuperType[]) => {
    // only available for livestock markets
    let allowedSuperTypes: SuperType[] = ["Cattle"];

    const isSuperTypeAllowed = superTypes.some((superType) =>
      allowedSuperTypes.includes(superType)
    );

    const isCountryAllowed = market.countryCode === "GB-NIR";

    return isSuperTypeAllowed && isCountryAllowed;
  },

  // the document template
  jsPDF: (props: InvoicePDFProps) => {
    const { lots, invoice, market } = props;

    const title = "Notification of Cattle Movement Consignee’s (Buyer’s)";
    const subtitle =
      "Cattle Identification (Notification of Births, Deaths and Movements) Regulations";

    const legalText =
      "The information in this document or any related inspection report may be used in support of any function of the Department of Agriculture, Environment and Rural Affairs and may be made available to other Department/Agencies for the purposes of preventing or detecting crime.";

    const doc = new jsPDF({
      putOnlyUsedFonts: true,
      compress: true,
      unit: "mm",
      format: "a4",
    });

    // Variables
    const pageWidth = PAGE_WIDTH;
    const pageHeight = PAGE_HEIGHT;
    const margin = MARGIN;

    const defaultFontSize = 12;

    // 1. Calculate the page header height and footer height so we know the safe area to work with

    // Header height (1st Page)
    let headerHeight = getPageHeaderHeight(doc, 1, title, subtitle);
    let y = headerHeight.pageOneY;

    // Footer height
    let footerHeight = getPageFooterHeight(doc, 1, legalText);

    // TODO
    // 2. Need to work out the safe areas so if the document is over this then it removes the element and moves it to the next page
    let maximumHeight = footerHeight - headerHeight.pageOneY;

    // 3. Add in the content
    y = getPart1(doc, y, true, invoice, headerHeight.pageTwoY, footerHeight);
    y = getPart2(
      doc,
      y,
      true,
      invoice,
      headerHeight.pageTwoY,
      footerHeight,
      market
    );
    y = getPart3(doc, y, true, invoice, headerHeight.pageTwoY, footerHeight);

    // 4. Need to add running headers and footers
    getPageHeader(doc, true, 1, title, subtitle);
    getPageFooter(doc, true, 1, legalText);
    // 5. Need to add a page number

    return doc;
  },
};

// Helper Functions

function getPageHeaderHeight(
  doc: jsPDF,
  page: number,
  title: string,
  subtitle: string
) {
  return getPageHeader(doc, false, page, title, subtitle);
}

function getPageHeader(
  doc: jsPDF,
  output: boolean,
  page: number,
  title: string,
  subtitle: string
) {
  // Start at the top of the page 1
  doc.setPage(1);

  let y = 0;
  let fontSize = 24;
  let fontColor = COLOUR_MARTEYE_500;

  // Add intial spacing
  y += MARGIN + 5;

  let titleLines = doc
    .setFont("Inter", "bold")
    .setTextColor(fontColor)
    .setFontSize(fontSize)
    .splitTextToSize(title, (PAGE_WIDTH - 20) * 0.75);
  let titleHeight = doc.getLineHeight() * titleLines.length * PTSCONVERSION;
  if (output) doc.text(titleLines, MARGIN, y);

  // Add the title height
  y += titleHeight;

  fontSize = 10;
  fontColor = "#616161";

  let subTitleLines = doc
    .setFont("Inter", "bold")
    .setTextColor(fontColor)
    .setFontSize(fontSize)
    .splitTextToSize(subtitle, PAGE_WIDTH - 20);

  let subTitleHeight =
    doc.getLineHeight() * subTitleLines.length * PTSCONVERSION;

  if (output) doc.text(subTitleLines, MARGIN, y);

  // Add the title height
  if (subtitle) {
    y += subTitleHeight;
  }

  let pageOneY = y;
  // Page 2 Onwards

  y = 0;
  fontSize = 10;
  fontColor = COLOUR_MARTEYE_500;

  // Add intial spacing
  y += 7.5;

  titleLines = doc
    .setFont("Inter", "bold")
    .setTextColor(fontColor)
    .setFontSize(fontSize)
    .splitTextToSize(title, (PAGE_WIDTH - 20) * 0.75);
  titleHeight = doc.getLineHeight() * titleLines.length * PTSCONVERSION;

  // Add the title height
  y += titleHeight;

  const pageCount = doc.getNumberOfPages();
  for (var i = 2; i <= pageCount; i++) {
    doc.setPage(i);
    if (output) doc.text(titleLines, MARGIN, y);
  }

  let pageTwoY = y + 5;

  return {
    pageOneY: pageOneY,
    pageTwoY: pageTwoY,
  };
}

function getPageFooterHeight(doc: jsPDF, page: number, legalText: string) {
  return getPageFooter(doc, false, page, legalText);
}

function getPageFooter(
  doc: jsPDF,
  output: boolean,
  page: number,
  legalText: string
) {
  let y = PAGE_HEIGHT - 5;
  const pageCount = doc.getNumberOfPages();
  for (var i = 1; i <= pageCount; i++) {
    y = PAGE_HEIGHT - 5;
    let fontSize = 8;
    let fontColor = "#616161";

    // Set Page
    doc.setPage(i);

    let legalTextLines = doc
      .setFont("Inter", "normal")
      .setTextColor(fontColor)
      .setFontSize(fontSize)
      .splitTextToSize(legalText, PAGE_WIDTH - 20 - 30);

    let legalTextHeight =
      doc.getLineHeight() * legalTextLines.length * PTSCONVERSION;

    if (output) doc.text(legalTextLines, MARGIN, y - legalTextHeight);

    // minimum footer height
    if (i == 1) {
      y -= legalTextHeight;
    }

    if (output) {
      doc.setFontSize(8);
      doc.text(
        "Page " + String(i) + " of " + String(pageCount),
        PAGE_WIDTH - MARGIN,
        PAGE_HEIGHT - 8,
        {
          align: "right",
        }
      );
    }
    if (i == 1) {
      y -= 5;
    }
  }
  return y;
}

interface ItemAttributes {
  [key: string]: string;
}

function getPart1(
  doc: jsPDF,
  y: number,
  output: boolean,
  invoice: Invoice | DraftInvoice,
  topY: number,
  bottomY: number
) {
  // Part 1 - Movement Notification Details
  const data: any = [];
  invoice.lineItems.map((item: InvoiceLineItem) => {
    if (item.superType !== "Cattle") {
      return;
    }

    let attributes: ItemAttributes[] = Object.values(
      item.metadata.itemAttributesByItemId
    );
    let earTags: string[] = [];
    let sexAPHIS: string[] = [];
    let colourAPHIS: string[] = [];
    let dateOfBirth: string[] = [];
    let breedCodeOfCattleAPHIS: string[] = [];

    attributes.forEach(function (item: any) {
      const dob = item["@dateOfBirth"]
        ? (item["@dateOfBirth"] as Timestamp).toDate().toLocaleDateString()
        : "-";
      earTags.push(item["@eartag"]);
      sexAPHIS.push(item["@sexAPHIS"]);
      colourAPHIS.push(item["@colourAPHIS"]);
      dateOfBirth.push(dob);
      breedCodeOfCattleAPHIS.push(item["@breedCodeOfCattleAPHIS"]);
    });
    data.push([
      item?.metadata?.lotNumber ?? "-",
      earTags.join(", "),
      colourAPHIS.join(", "),
      breedCodeOfCattleAPHIS.join(", "),
      sexAPHIS.join(", "),
      dateOfBirth.join(", "),
      "-", // TODO Receipt Confirmed
    ]);
  });
  autoTable(doc, {
    head: [
      [
        {
          content: "PART 1  Details of Cattle",
          colSpan: 7,
          styles: {
            halign: "left",
            fillColor: COLOUR_MARTEYE_500,
            textColor: "#FFFFFF",
          },
        },
      ],
      [
        {
          content: "LOT",
          colSpan: 1,
          styles: { halign: "left" },
        },
        {
          content: "IDENTIFICATION NO.",
          colSpan: 1,
          styles: { halign: "left" },
        },
        {
          content: "COLOUR",
          colSpan: 1,
          styles: { halign: "left" },
        },
        {
          content: "BREED",
          colSpan: 1,
          styles: { halign: "left" },
        },
        {
          content: "SEX",
          colSpan: 1,
          styles: { halign: "left" },
        },
        {
          content: "DOB",
          colSpan: 1,
          styles: { halign: "left" },
        },
        {
          content: "RECEIPT CONFIRMED",
          colSpan: 1,
          styles: { halign: "left" },
        },
      ],
    ],
    body: data,
    startY: y,
    theme: "plain",
    margin: { top: topY, right: 10, bottom: PAGE_HEIGHT - bottomY, left: 10 },
    headStyles: {
      fillColor: "#EFEFEF",
      textColor: "#616161",
      fontSize: 8,
      font: "Inter",
      fontStyle: "bold",
      cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
    },
    bodyStyles: {
      fontSize: 10,
      font: "Inter",
      fontStyle: "normal",
      cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
    },
  });

  // @ts-ignore
  y = doc.lastAutoTable.finalY + 5;

  return y;
}
function getPart2(
  doc: jsPDF,
  y: number,
  output: boolean,
  invoice: Invoice | DraftInvoice,
  topY: number,
  bottomY: number,
  market: Market
) {
  let address = market.address;

  // Part 2 - Movement Details
  let rowHeight = 10;
  let columnWidth = (PAGE_WIDTH - MARGIN * 2) / 2;

  let marketHerdNumber =
    market.movementLocationNumbers?.find((m) => m.type == "Herd Number")
      ?.number ?? "TBC";

  // This is a commar seperated address
  let marketAddress =
    invoice.lineItems[0].metadata.lotAttributes?.["addressMarket"] ?? "";

  // Convert to an array and remove any empty lines
  marketAddress = marketAddress.split(",");

  // Remove any empty lines
  marketAddress = marketAddress.filter((line: string) => line.trim() !== "");

  // Now lets re-add the commas to the address still in an array except for the last line
  marketAddress = marketAddress.map((line: string) => line.trim());

  let addr = invoice.address;
  let customeraddress = [
    addr.company,
    addr.address1,
    addr.address2,
    addr.city,
    addr.province,
    addr.zip,
    addr.country,
  ].filter(Boolean) as string[];
  let usedcustomeraddressLines = customeraddress.filter((line) => line !== "");

  let herdNumberDestination =
    invoice.attributeValues?.["herdNumberDestination"] ?? "";

  const data: any = [
    [
      {
        content: "HERD NUMBER",
        styles: {
          textColor: "#989898",
          cellPadding: { top: 3, bottom: 0, left: 2, right: 2 },
        },
      },
      {
        content: "HERD NUMBER",
        styles: {
          textColor: "#989898",
          cellPadding: { top: 3, bottom: 0, left: 2, right: 2 },
        },
      },
    ],
    [
      {
        content: marketHerdNumber,
        styles: {
          cellPadding: { top: 1, bottom: 2, left: 2, right: 2 },
        },
      },
      {
        content: herdNumberDestination,
        styles: {
          cellPadding: { top: 1, bottom: 2, left: 2, right: 2 },
        },
      },
    ],
    [
      {
        content: "NAME / ADDRESS",
        styles: {
          textColor: "#989898",
          cellPadding: { top: 2, bottom: 0, left: 2, right: 2 },
        },
      },
      {
        content: "NAME / ADDRESS",
        styles: {
          textColor: "#989898",
          cellPadding: { top: 2, bottom: 0, left: 2, right: 2 },
        },
      },
    ],
    [
      {
        content: marketAddress.join(", \n"),
        styles: {
          fontStyle: "normal",
          cellPadding: { top: 1, bottom: 3, left: 2, right: 2 },
        },
      },
      {
        content: usedcustomeraddressLines.join(", \n"),
        styles: {
          fontStyle: "normal",
          cellPadding: { top: 1, bottom: 3, left: 2, right: 2 },
        },
      },
    ],
    ["SIGNED", "DATE"],
  ];

  autoTable(doc, {
    head: [
      [
        {
          content: "PART 2  Movement Details",
          colSpan: 2,
          styles: {
            halign: "left",
            fillColor: COLOUR_MARTEYE_500,
            textColor: "#FFFFFF",
          },
        },
      ],
      [
        {
          content: "FROM PREMISES OF",
          colSpan: 1,
          styles: { halign: "left" },
        },
        {
          content: "TO PREMISES OF",
          colSpan: 1,
          styles: { halign: "left" },
        },
      ],
    ],
    body: data,
    startY: y,
    theme: "plain",
    margin: { top: topY, right: 10, bottom: PAGE_HEIGHT - bottomY, left: 10 },
    headStyles: {
      fillColor: "#EFEFEF",
      textColor: "#616161",
      fontSize: 8,
      font: "Inter",
      fontStyle: "bold",
      cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
    },
    bodyStyles: {
      fontSize: 8,
      font: "Inter",
      fontStyle: "bold",
      textColor: "#616161",
      cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
    },
    columnStyles: {
      0: { cellWidth: columnWidth },
      1: { cellWidth: columnWidth },
    },

    didParseCell: function (data) {
      if (
        data.row.section === "body" &&
        data.row.index === data.table.body.length - 1
      ) {
        data.cell.styles.cellPadding = {
          top: 5,
          bottom: 10,
          left: 2,
          right: 2,
        };
      }
    },
    willDrawCell: function (data) {
      if (
        data.row.section === "body" &&
        data.row.index === data.table.body.length - 1
      ) {
        doc.setDrawColor("#CBCBCB"); // set the border color
        doc.setLineWidth(0.1); // set the border with

        // draw bottom border
        let width = data.cell.x + data.cell.width - 4;

        doc.line(
          data.cell.x + 2,
          data.cell.y + data.cell.height,
          width,
          data.cell.y + data.cell.height
        );
      }
    },
  });

  // @ts-ignore
  y = doc.lastAutoTable.finalY + 5;

  return y;
}
function getPart3(
  doc: jsPDF,
  y: number,
  output: boolean,
  invoice: Invoice | DraftInvoice,
  topY: number,
  bottomY: number
) {
  // Part 3 - Movement Notification Details

  let columnWidth = (PAGE_WIDTH - MARGIN * 2) / 2;

  const data: any = [
    [
      "I/We hereby notify the Department of Agriculture, Environment and Rural Affairs that the animal(s) listed in Part 1 were moved from my/our herd.",
      "I/We being the keeper(s) of the cattle hereby notify the Department of Agriculture, Environment and Rural Affairs that the animals list in Part 1 were added to my/our herd.",
    ],
    ["SIGNED", "SIGNED"],
    ["DATE", "DATE"],
  ];

  autoTable(doc, {
    head: [
      [
        {
          content: "PART 3  Notification Details",
          colSpan: 2,
          styles: {
            halign: "left",
            fillColor: COLOUR_MARTEYE_500,
            textColor: "#FFFFFF",
          },
        },
      ],
      [
        {
          content: "NOTIFICATION OF MOVEMENT OF",
          colSpan: 1,
          styles: { halign: "left" },
        },
        {
          content: "NOTIFICATION OF MOVEMENT OF",
          colSpan: 1,
          styles: { halign: "left" },
        },
      ],
    ],
    body: data,
    startY: y,
    theme: "plain",
    margin: { top: topY, right: 10, bottom: PAGE_HEIGHT - bottomY, left: 10 },
    headStyles: {
      fillColor: "#EFEFEF",
      textColor: "#616161",
      fontSize: 8,
      font: "Inter",
      fontStyle: "bold",
      cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
    },
    bodyStyles: {
      fontSize: 8,
      font: "Inter",
      fontStyle: "normal",
      textColor: "#616161",
      cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
    },
    columnStyles: {
      0: { cellWidth: columnWidth },
      1: { cellWidth: columnWidth },
    },
    didParseCell: function (data) {
      if (data.row.section === "body" && data.row.index > 0) {
        data.cell.styles.fontStyle = "bold";
        data.cell.styles.cellPadding = {
          top: 5,
          bottom: 10,
          left: 2,
          right: 2,
        };
      }
    },

    willDrawCell: function (data) {
      if (data.row.section === "body" && data.row.index > 0) {
        doc.setDrawColor("#CBCBCB"); // set the border color
        doc.setLineWidth(0.1); // set the border with

        // draw bottom border

        let width = data.cell.x + data.cell.width - 4;

        doc.line(
          data.cell.x + 2,
          data.cell.y + data.cell.height,
          width,
          data.cell.y + data.cell.height
        );
      }
    },
  });

  // @ts-ignore
  y = doc.lastAutoTable.finalY + 5;

  return y;
}

export default MovementNotificationCattleSpec;
