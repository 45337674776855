import {
  useFirestore,
  useLoadFirestoreQuery,
} from "@/data/studio-react/firebase/useFirestore";
import { collection, limit, orderBy, query, where } from "firebase/firestore";
import { useMemo } from "react";
import { Invoice } from "types";

// TODO move
export function useCustomerInvoices(
  marketId?: string,
  customerId?: string,
  clientType?: string | null,
  queryLimit?: number,
  dateRange?: {
    start: Date;
    end: Date;
  },
  lastId?: string
) {
  let firestore = useFirestore();

  let invoicesQuery = useMemo(() => {
    if (!marketId || !customerId) {
      return null;
    }

    if (clientType === null) {
      return null;
    }

    let invoiceQuery = query(
      collection(firestore, `markets/${marketId}/invoices`),
      where(`customerId`, `==`, customerId),
      limit(queryLimit ?? 1),
      orderBy("issuedAt", "desc")
    );

    if (clientType === "Buyer" || clientType === "Seller") {
      invoiceQuery = query(invoiceQuery, where(`clientType`, `==`, clientType));
    }

    if (dateRange?.start && dateRange?.end) {
      invoiceQuery = query(
        invoiceQuery,
        where(`issuedAt`, `>=`, dateRange.start)
      );
      invoiceQuery = query(
        invoiceQuery,
        where(`issuedAt`, `<=`, dateRange.end)
      );
    }

    return invoiceQuery;
  }, [marketId, customerId, firestore, queryLimit, clientType, dateRange]);

  let invoicesLoadInfo = useLoadFirestoreQuery<Invoice>(invoicesQuery, {
    idField: "id",
  });

  return invoicesLoadInfo;
}
