import { formatAsCurrency } from "@/data/amounts";
import { Dialog, Transition } from "@headlessui/react";
import { DocumentIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { Fragment, ReactNode } from "react";
import { Invoice, Market, Payout, SimplePaymentOut } from "types";

interface PayentDialogProps {
  market: Market | null;
  invoice: Invoice | null;
  payouts: Payout[];

  customerBalances: {
    tradePayable: number; // money the customer owes
    tradeReceivable: number; // money owed to the customer
  } | null;

  isOpen: boolean;
  close: () => void;
}

export function PayoutDialog(props: PayentDialogProps) {
  let { invoice, isOpen, close } = props;

  return (
    <DialogChrome isOpen={isOpen} close={close}>
      {invoice && isOpen && (
        <DialogInner key={`${invoice.id}-${isOpen}`} {...props} />
      )}
    </DialogChrome>
  );
}

function DialogInner(props: PayentDialogProps) {
  let { invoice, payouts, isOpen, market, customerBalances, close } = props;

  // these should match the returned Payouts...
  let PayoutsOnInvoice: SimplePaymentOut[] = Object.values(
    invoice?.payoutsById || {}
  );

  console.log("invoice", invoice);
  console.log("customerBalances", customerBalances);

  let currency = invoice?.currency || null;

  return (
    <div className="bg-black rounded-lg px-5 py-4">
      {/* Header */}
      <div className="flex flex-row items-center">
        <DocumentIcon className="h-6 w-6 text-martEye-400" />
        <Dialog.Title
          as="h3"
          className="text-base font-bold leading-6 text-white flex-grow ml-2"
        >
          Payout to {invoice?.customerAccountNumber}
        </Dialog.Title>

        <button
          type="button"
          className="flex h-9 w-9 items-center justify-center rounded-full border-2 border-white/40 text-white  hover:bg-white/10"
          onClick={close}
        >
          <XMarkIcon className="h-4 w-4" />
        </button>
      </div>

      {/* Content */}

      <div className="flex flex-col gap-4 mt-4 max-h-96 overflow-auto">
        {/* Two columns */}

        <div>
          <div>
            <span>Invoice #{invoice?.invoiceNumber}</span>
            <span>
              {formatAsCurrency(currency, invoice?.finalTotalInCents)}
            </span>
          </div>

          <div>
            <span>Less Payout </span>
            <span>
              -{formatAsCurrency(currency, invoice?.finalTotalInCents)}
            </span>
          </div>

          <div>
            <span>Balance Due</span>
            <span>
              {formatAsCurrency(currency, invoice?.finalTotalInCents)}
            </span>
          </div>

          <pre className="text-white">
            {JSON.stringify(
              { PayoutsOnInvoice, payouts, customerBalances },
              null,
              2
            )}
          </pre>
        </div>
      </div>
    </div>
  );
}

function DialogChrome(props: {
  isOpen: boolean;
  close: () => void;
  children: ReactNode;
}) {
  let { isOpen, close, children } = props;

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-60"
        initialFocus={undefined}
        onClose={close}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-30 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full max-w-2xl">
                {isOpen ? children : null}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
