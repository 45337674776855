import { Timestamp } from "firebase-admin/firestore";

import { Payment, PaymentMethod } from "types";
import { cloudFunction } from "../helpers/cloudfunction";

interface CreatePaymentData {
  marketId: string;
  customerId: string;
  method: string;
  amountInCents: number;
  transactionDate?: string; // ISO 8601 date string
  reference: string | null; // use the cheque number for cheques and the reference number for BACS
  invoiceIds: string[] | null;
}

export async function createPayment(
  marketId: string,
  customerId: string,
  method: PaymentMethod,
  amountInCents: number,
  transactionDate: Timestamp | null,
  // for a cheque this is the cheque number
  // for bacs this is the reference number
  // for card this is the transaction reference
  // not always present (e.g. cash)
  reference: string | null,
  invoiceIds: string[] | null
) {
  const CreatePaymentHttpCall = cloudFunction<CreatePaymentData, Payment>(
    "CreatePaymentHttpCall"
  );

  return CreatePaymentHttpCall({
    marketId,
    customerId,
    method,
    amountInCents,
    transactionDate: transactionDate?.toDate().toISOString(),
    reference,
    invoiceIds
  });
}

interface VoidPaymentData {
  marketId: string;
  paymentId: string;
  reason: string;
}

export async function voidPayment(
  marketId: string,
  paymentId: string,
  reason: string
) {
  const VoidPaymentHttpCall = cloudFunction<VoidPaymentData, Payment>(
    "VoidPaymentHttpCall"
  );

  return VoidPaymentHttpCall({
    marketId,
    paymentId,
    reason,
  });
}
