import { CheckoutRequestData } from "@/data/studio-react/actions/createDraftInvoice";
import useStudio from "@/data/studio-react/useStudio";
import { useStudioStream } from "@/data/studio-react/useStudioStream";
import { useMarketId } from "@/data/useMartketId";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { Address, ClientType, DraftInvoice, Lot } from "types";
import { MemoComponent } from "../MemoComponent";
import { useInvoiceSheet } from "../invoice-sheet/useInvoiceSheet";
import { usePosMonitor } from "../pos-monitor/PosMonitorProvider";
import { CheckoutGroup, costructCheckoutGroups } from "./checkoutGroups";
import { useCheckoutSheet } from "./useCheckoutSheet";

interface CheckoutSheetContextType extends CheckoutSheetState {
  applyParams: (params: UpdatableCheckoutParams) => Promise<void>;
  isOpen: boolean;
  close: () => void;
  openInfoForm: () => void;
  closeInfoForm: () => void;
  issueInvoice: () => Promise<void>;
  setDiscountByLineitemInCents: (
    lineItemId: string,
    discount: number | null
  ) => void;
}

const CheckoutSheetContext = React.createContext<CheckoutSheetContextType>(
  {} as any
);

interface CheckoutSheetState {
  // request data
  // show issue dialogue (maybe not needed now)
  // draft has an error
  // loading draft
  // is complete => new invoice page
  // When the user updates the attributes / discounts / etc we need to update the draft

  baseParmeters: {
    // these come from the URL
    isOpen: boolean;
    lotIdents: { saleId: string; lotId: string }[];
    clientType: ClientType;
  } | null;

  // set by the user
  extraParams: UpdatableCheckoutParams;

  initialLoadCompleted: boolean;
  isDirty: boolean; // true if the params that affect the draft have changed
  isLoading: boolean; // true if we're waiting on a new draft

  draftInvoice: DraftInvoice | null;
  error: string | null;

  // items to show while loading
  lots: Lot[] | null;

  checkoutGroups: CheckoutGroup[];

  isInfoFormOpen: boolean;

  // Added once we get the draft invoice or the lots
  customerId: string | null;
  addresses: Address[];
  customerAccountNumber: string | null;

  isIssuingInvoice: boolean;
}

const initialState: CheckoutSheetState = {
  baseParmeters: null,
  extraParams: {},
  initialLoadCompleted: false,
  isDirty: false,
  isLoading: false,
  draftInvoice: null,
  error: null,
  lots: null,
  isInfoFormOpen: false,
  checkoutGroups: [],
  customerId: null,
  addresses: [],
  customerAccountNumber: null,
  isIssuingInvoice: false,
};

// how are we going to serialise all the params? Do we need to?
// Movement out document number. When to assign? Probably when we issue. Maybe hardcode.
// Passing in custom payment details?

function reducer(
  state: CheckoutSheetState,
  action: { type: string; payload?: any }
) {
  let nextState = state;

  switch (action.type) {
    case "sheetDidOpen":
      nextState = {
        ...initialState,
        baseParmeters: action.payload,
      };
      break;

    case "sheetDidClose":
      nextState = { ...initialState };
      break;

    case "updateParamsAndBeginLoadingDraft":
      nextState = {
        ...state,
        error: null,
        extraParams: {
          ...state.extraParams,
          ...action.payload,
        },
        isDirty: true,
        isLoading: true,
      };
      break;

    case "draftLoaded": {
      let draftInvoice = action.payload.draft as DraftInvoice;

      // important the user isn't allowed to change the params until the draft is loaded
      // or we may clobber the draft. (may optimise this later)

      nextState = {
        ...state,
        error: null,
        draftInvoice,
        isLoading: false,
        isDirty: false,
        customerId: draftInvoice.customerId,

        extraParams: {
          name: draftInvoice.name,
          addressId: draftInvoice.address.id,
          email: draftInvoice.email,
          vatNumber: draftInvoice.vatNumber,
          // Discounts can only apply to adjustment line items
          discountsByLineItemInCents: draftInvoice.adjustmentLineItems
            .filter((li) => li.adjustmentConfig)
            .filter((li) => li.adjustmentConfig?.type === "commission")
            .reduce((acc, li) => {
              return {
                ...acc,
                [li.id]: li.discountAmountInCents || 0,
              };
            }, {}),
          payout: {
            method: draftInvoice.payoutMethod!,
            settleDebtsFirst: draftInvoice.payoutParams?.settleDebtsFirst,
            accountName: draftInvoice.payoutParams?.accountName,
            accountNumber: draftInvoice.payoutParams?.accountNumber,
            sortCode: draftInvoice.payoutParams?.sortCode,
            chequeMadePayableTo: draftInvoice.payoutParams?.chequeMadePayableTo,
          },
          attributeValues: draftInvoice.attributeValues,
        },
      };

      break;
    }

    case "draftFailed":
      nextState = {
        ...state,
        isLoading: false,
        isDirty: false,
        draftInvoice: null,
        error: action.payload.error,
      };
      break;

    case "didReceiveLots": {
      nextState = {
        ...state,
        lots: action.payload.lots,
      };
      if (
        !nextState.draftInvoice &&
        nextState.lots &&
        nextState.lots.length > 0
      ) {
        // Deduce the customer ID from the lots as we don't have the draft yet
        let clientType = state.baseParmeters?.clientType || "Seller";
        let lot = nextState.lots[0];
        nextState.customerId =
          clientType === "Seller" ? lot.sellerCustomerId : lot.buyerCustomerId;
      }
      break;
    }

    case "startIssuingInvoice":
      nextState = {
        ...state,
        isIssuingInvoice: true,
        error: null,
      };
      break;
    case "endIssuingInvoice":
      nextState = {
        ...state,
        isIssuingInvoice: false,
      };
      break;

    case "update":
      nextState = {
        ...state,
        ...action.payload,
      };
      break;

    default:
      throw new Error("Invalid action " + action.type);
  }

  nextState.checkoutGroups = costructCheckoutGroups(
    nextState.baseParmeters?.clientType || "Seller",
    nextState.extraParams,
    nextState.draftInvoice?.attributes || [],
    nextState.addresses,
    nextState.customerAccountNumber
  );

  return nextState;
}

export type UpdatableCheckoutParams = Omit<
  CheckoutRequestData,
  "marketId" | "lotIdents" | "clientType"
>;

export function CheckoutSheetProvider(props: {
  children?: React.ReactNode | React.ReactNode[] | undefined | null;
}) {
  let [state, dispatch] = React.useReducer(reducer, initialState);

  let marketId = useMarketId();
  let { createDraftInvoice, issueInvoice } = useStudio();
  let { checkoutSheetParams: baseParams, closeCheckoutSheet } =
    useCheckoutSheet();

  let { getLots } = useStudio();

  // A function to allow updating the params for the draft
  let updateParamsAndFetchDraft = useCallback(
    async (
      newParams: UpdatableCheckoutParams,
      overrideBaseParams?: {
        clientType: ClientType | null;
        lotIdents: {
          saleId: string;
          lotId: string;
        }[];
        saleIds: string[];
      } | null
    ) => {
      if (!marketId) {
        console.error("Market ID not set");
        return;
      }

      if (state.isLoading) {
        // Should wait for the current draft to load
        console.error("[checkout sheet] Already loading. Ignoring update.");
        return;
      }

      let baseParams = overrideBaseParams || state.baseParmeters;

      // only provide payout details for sellers
      if (baseParams!.clientType === "Buyer") {
        delete newParams.payout;
      }

      // Mark as dirty and start loading
      dispatch({
        type: "updateParamsAndBeginLoadingDraft",
        payload: newParams,
      });

      // The lots will likely be in the cache so we can slap them in to get the first layout
      // (first run only)
      if (state.lots === null) {
        console.time("[time] lots");
        getLots(baseParams?.lotIdents, { fromCacheOnly: true }).then((lots) => {
          console.timeEnd("[time] lots");
          dispatch({
            type: "didReceiveLots",
            payload: { lots },
          });
        });
      }

      let params: CheckoutRequestData = {
        ...newParams,
        marketId,
        lotIdents: baseParams!.lotIdents,
        clientType: baseParams!.clientType!,
      };
      // Call the API
      try {
        console.time("[time] create draft invoice");
        let draft = await createDraftInvoice(params);
        // Set the draft
        dispatch({
          type: "draftLoaded",
          payload: { draft, newParams },
        });
      } catch (e) {
        console.error("Error creating draft invoice", e);
        dispatch({
          type: "draftFailed",
          payload: {
            error: (e as Error).message || "Error creating draft invoice",
          },
        });
      } finally {
        console.timeEnd("[time] create draft invoice");
      }
    },
    [createDraftInvoice, state, getLots]
  );

  let { openInvoiceSheet } = useInvoiceSheet();

  let stateAndActions = useMemo(() => {
    return {
      ...state,
      applyParams: updateParamsAndFetchDraft,
      isOpen: !!state.baseParmeters,
      close: closeCheckoutSheet,
      setDiscountByLineitemInCents: (
        lineItemId: string,
        discountInCents: number | null
      ) => {
        let discountsByLineItemInCents = {
          ...state.extraParams.discountsByLineItemInCents,
          [lineItemId]: discountInCents || 0,
        };
        if (
          discountInCents === 0 ||
          discountInCents === null ||
          discountInCents === undefined
        ) {
          delete discountsByLineItemInCents[lineItemId];
        }
        // Update with the new discount
        return updateParamsAndFetchDraft({
          ...state.extraParams,
          discountsByLineItemInCents,
        });
      },
      issueInvoice: async () => {
        if (!marketId) {
          console.error("Market ID not set");
          return;
        }
        if (!state.baseParmeters) {
          console.error("Base parameters not set");
          return;
        }

        if (state.isIssuingInvoice) {
          console.error("Already issuing invoice");
          return;
        }

        dispatch({
          type: "startIssuingInvoice",
        });

        try {
          let params: CheckoutRequestData = {
            ...state.extraParams,
            marketId: marketId,
            lotIdents: state.baseParmeters.lotIdents,
            clientType: state.baseParmeters.clientType!,
          };
          if (params.clientType === "Buyer") {
            delete params.payout;
          }

          console.log("Issuing invoice", params);

          let invoice = await issueInvoice(params);

          openInvoiceSheet({ invoiceId: invoice.id }, { replace: true });
        } catch (e) {
          console.error("Error issuing invoice", e);
          dispatch({
            type: "update",
            payload: {
              error: (e as Error).message || "Error issuing invoice",
            },
          });
        } finally {
          dispatch({
            type: "endIssuingInvoice",
          });
        }
      },
      openInfoForm: () => {
        dispatch({
          type: "update",
          payload: {
            isInfoFormOpen: true,
          },
        });
      },
      closeInfoForm: () => {
        dispatch({
          type: "update",
          payload: {
            isInfoFormOpen: false,
          },
        });
      },
    };
  }, [
    state,
    updateParamsAndFetchDraft,
    closeCheckoutSheet,
    issueInvoice,
    marketId,
    openInvoiceSheet,
  ]);

  let customer = useStudioStream("customer", marketId, state.customerId);
  useEffect(() => {
    let addresses = [
      customer?.address?.address,
      ...Object.values(customer?.otherAddresses || {}).map(
        (wrap) => wrap.address
      ),
    ].filter((a) => Boolean(a)) as Address[];

    dispatch({
      type: "update",
      payload: {
        addresses: addresses,
        customerAccountNumber: customer?.accountNumber || null,
      },
    });
  }, [customer]);

  let { showInvoiceOnPosMonitor } = usePosMonitor();
  useEffect(() => {
    if (!state.draftInvoice) {
      return;
    }
    let remove = showInvoiceOnPosMonitor(state.draftInvoice);

    return () => {
      remove();
    };
  }, [showInvoiceOnPosMonitor, state.draftInvoice]);

  if (baseParams && !state.baseParmeters) {
    console.log("[checkout sheet] opened");
    dispatch({
      type: "sheetDidOpen",
      payload: baseParams,
    });
    // Create a draft when the sheet opens
    console.log("initial load");
    updateParamsAndFetchDraft(state.extraParams, baseParams);
  }

  // sheet closed, reset the state
  if (!baseParams && state.baseParmeters) {
    dispatch({
      type: "sheetDidClose",
    });
  }

  return (
    <CheckoutSheetContext.Provider value={stateAndActions}>
      <MemoComponent>{props.children}</MemoComponent>
    </CheckoutSheetContext.Provider>
  );
}

export function useCheckoutSheetContext() {
  return useContext(CheckoutSheetContext);
}
