import { Timestamp } from "firebase/firestore";
import {
  Address,
  AttributeDefinition,
  ClientType,
  Media,
  SupportedAttributeTypes,
} from "types";
import { UpdatableCheckoutParams } from "./CheckoutSheetProvider";
import { DetailsIcon, ExportsIcon, MovementsIcon, PaymentsIcon } from "./icons";

export interface CheckoutGroupItem {
  label: string;
  type: SupportedAttributeTypes | "select";
  value: string | number | boolean | Timestamp | Media | null;
  collapsedValue?: string | null;
  path: string;
  options?: { label: string; value: string }[];
}

export interface CheckoutGroup {
  name: string;
  items: CheckoutGroupItem[];
  icon: any;
}

export function costructCheckoutGroups(
  clientType: ClientType,
  extraParams: UpdatableCheckoutParams,
  attributes: AttributeDefinition[],
  addresses: Address[],
  customerAccountNumber: string | null,
  showSettleDebtsFirst: boolean = true
): CheckoutGroup[] {
  let groups: CheckoutGroup[] = [
    {
      name: "Details",
      items: constructDetailsSection(
        extraParams,
        addresses,
        customerAccountNumber
      ),
      icon: iconForGroup("Details"),
    },
    {
      name: "Payment",
      items: constructPaymentSection(
        clientType,
        extraParams,
        showSettleDebtsFirst
      ),
      icon: iconForGroup("Payment"),
    },
    ...constructOtherSections(extraParams, attributes, clientType),
  ];

  // Remove empty groups
  return groups.filter((group) => group.items.length > 0);
}

function iconForGroup(group: string) {
  switch (group) {
    case "Details":
      return DetailsIcon;
    case "Payment":
      return PaymentsIcon;
    case "Export":
      return ExportsIcon;
    case "Movements":
      return MovementsIcon;
    default:
      return null;
  }
}

function constructOtherSections(
  extraParams: UpdatableCheckoutParams,
  attributes: AttributeDefinition[],
  clientType: ClientType
) {
  let values = extraParams?.attributeValues || {};

  let checkoutGroups = attributes
    .map((attr) => {
      let checkoutGroupExp: string;
      if (clientType === "Buyer") {
        checkoutGroupExp = attr.buyerCheckoutGroup || "Missing";
      } else {
        checkoutGroupExp = attr.sellerCheckoutGroup || "Missing";
      }
      let [checkoutGroup, index] = checkoutGroupExp.split("~");
      let idx = parseInt(index);
      return {
        def: attr,
        checkoutGroup,
        index: isNaN(idx) ? 0 : idx,
      };
    })
    .sort((a, b) => a.index - b.index)
    .reduce((acc, item) => {
      let grp: CheckoutGroup | undefined = acc.find(
        (g) => g.name === item.checkoutGroup
      );
      if (!grp) {
        grp = {
          name: item.checkoutGroup,
          items: [],
          icon: iconForGroup(item.checkoutGroup),
        };
        acc.push(grp);
      }

      let grpItem: CheckoutGroupItem = {
        label: item.def.name,
        type: item.def.type,
        value: values[item.def.id] === undefined ? null : values[item.def.id],
        path: `attributeValues.${item.def.id}`,
      };

      if (item.def.allowedValues?.length) {
        grpItem.options = item.def.allowedValues.map((value) => ({
          label: value,
          value,
        }));
        grpItem.type = "select";
      }

      grp.items.push(grpItem);
      return acc;
    }, [] as CheckoutGroup[]);

  return checkoutGroups || [];
}

function constructPaymentSection(
  clientType: ClientType,
  extraParams: UpdatableCheckoutParams,
  showSettleDebtsFirst: boolean
): CheckoutGroupItem[] {
  if (clientType === "Buyer") {
    return [];
  }

  let groups: CheckoutGroupItem[] = [
    {
      label: "Settle Debts First",
      type: "boolean",
      value:
        extraParams.payout?.settleDebtsFirst === undefined
          ? null
          : extraParams.payout?.settleDebtsFirst,
      path: "payout.settleDebtsFirst",
    },
    {
      label: "Method",
      type: "select",
      value: extraParams?.payout?.method || null,
      path: "payout.method",
      options: [
        {
          label: "BACS",
          value: "BACS",
        },
        {
          label: "Cheque",
          value: "Cheque",
        },
        {
          label: "Cash",
          value: "Cash",
        },
        {
          label: "Credit",
          value: "Credit",
        },
      ],
    },
  ];

  if (!showSettleDebtsFirst) {
    groups.shift();
  }

  if (extraParams?.payout?.method === "BACS") {
    groups = [
      ...groups,
      {
        label: "Account Name",
        type: "string",
        value: extraParams?.payout?.accountName || null,
        path: "payout.accountName",
      },
      {
        label: "Account Number",
        type: "string",
        value: extraParams?.payout?.accountNumber || null,
        path: "payout.accountNumber",
      },
      {
        label: "Sort Code",
        type: "string",
        value: extraParams?.payout?.sortCode || null,
        path: "payout.sortCode",
      },
    ];
  }
  if (extraParams?.payout?.method === "Cheque") {
    groups = [
      ...groups,
      {
        label: "Cheque Payable To",
        type: "string",
        value: extraParams?.payout?.chequeMadePayableTo || null,
        path: "payout.chequeMadePayableTo",
      },
    ];
  }

  return groups;
}

function constructDetailsSection(
  extraParams: UpdatableCheckoutParams,
  addresses: Address[],
  customerAccountNumber: string | null
): CheckoutGroupItem[] {
  return [
    {
      label: "Name",
      type: "string",
      value: extraParams?.name || null,
      collapsedValue:
        extraParams?.name && customerAccountNumber
          ? `${extraParams?.name} (${customerAccountNumber})`
          : null,
      path: "name",
    },

    {
      label: "VAT Number",
      type: "string",
      value: extraParams?.vatNumber || null,
      path: "vatNumber",
    },
    {
      label: "Address",
      type: "select",
      value: extraParams?.addressId || null,
      path: "addressId",
      options: addresses.map((address) => ({
        label: formatAddress(address),
        value: address.id,
      })),
    },
    {
      label: "Email",
      type: "string",
      value: extraParams?.email || null,
      path: "email",
    },
  ];
}

export function formatAddress(address?: Address) {
  if (!address) return "";

  let formattedAddressLines = [
    address?.company?.trim(),
    address.address1?.trim(),
    address.address2?.trim(),
    address.city?.trim(),
    address.province?.trim(),
    address.zip?.trim(),
    address.country?.trim(),
  ]
    .filter(Boolean)
    .filter((line) => line !== "")
    .filter((line) => line !== "-")
    .join(", ");

  return formattedAddressLines;
}
