import { useRouter } from "next/router";
import { useContext } from "react";
import { SessionContext } from "./studio-react/useStudioStream";

/**
 * The market ID is in the url but this is a handy hook to get it
 */
export function useMarketId() {
  let router = useRouter();
  let marketId = router.query.marketId as string | undefined;

  return marketId ? marketId : null;
}

export function useMarket() {
  let session = useContext(SessionContext);
  return session.market;
}
