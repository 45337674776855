import { classNames } from "@/data/classnames";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/24/solid";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useOpenLotFlyout } from "./LotFlyout/LotFlyout";
import { useOpenPersonFlyout } from "./PersonFlyout/PersonFlyout";
import { useCheckoutSheet } from "./checkout-sheet-draft/useCheckoutSheet";

// This is the exposed API to use
export function useShowDialog() {
  let [dialogId] = useState(() => {
    return Math.random().toString(36).substring(7);
  });
  let { openDialogId, showDialog } = useContext(ConfirmationDialogContext);

  let show = useCallback(
    (
      title: string | undefined,
      message: string | undefined,
      confirmText: string | undefined,
      onConfirm: () => void | undefined | Promise<void>,
      cancelText: string | undefined,
      onCancel: () => void | undefined,
      theme?: ThemeNames,
      type?: "confirm" | "alert"
    ) => {
      showDialog(
        dialogId,
        title,
        message,
        confirmText,
        onConfirm,
        cancelText,
        onCancel,
        theme,
        type
      );
    },
    [dialogId, showDialog]
  );

  let isOpen = openDialogId === dialogId;

  return useMemo(() => {
    return { showDialog: show, isOpen };
  }, [show, isOpen]);
}

interface ConfirmationDialogueProps {
  id: string;
  open: boolean;

  cancel: () => void;
  confirm: () => void | Promise<void>;

  isConfiming?: boolean;

  title?: string;
  message?: string;

  confirmText?: string;
  cancelText?: string;

  theme?: ThemeNames;
  type?: "confirm" | "alert";
}

type ThemeNames = "danger" | "warning" | "info" | "success";
const themeConfig = {
  danger: {
    icon: {
      component: ExclamationTriangleIcon,
      color: "text-red-600",
      bg: "bg-red-100",
    },
    confirmButton: {
      bg: "bg-red-600",
      hover: "hover:bg-red-700",
      text: "text-white",
      focusRing: "ring-red-500",
    },
  },
  warning: {
    icon: {
      component: ExclamationTriangleIcon,
      color: "text-yellow-600",
      bg: "bg-yellow-100",
    },
    confirmButton: {
      bg: "bg-yellow-600",
      hover: "hover:bg-yellow-700",
      text: "text-white",
      focusRing: "ring-yellow-500",
    },
  },
  info: {
    icon: {
      component: InformationCircleIcon,
      color: "text-blue-600",
      bg: "bg-blue-100",
    },
    confirmButton: {
      bg: "bg-blue-600",
      hover: "hover:bg-blue-700",
      text: "text-white",
      focusRing: "ring-blue-500",
    },
  },
  success: {
    icon: {
      component: CheckIcon,
      color: "text-green-600",
      bg: "bg-green-100",
    },
    confirmButton: {
      bg: "bg-green-600",
      hover: "hover:bg-green-700",
      text: "text-white",
      focusRing: "ring-green-500",
    },
  },
};

function ConfirmationDialogue(_props: ConfirmationDialogueProps) {
  const cancelButtonRef = useRef(null);

  let [props, setProps] = useState(_props as ConfirmationDialogueProps | null);

  // keeping the props in state until we animate the dialog out
  useEffect(() => {
    if (_props.id.length > 0) {
      setProps(_props);
    }
  }, [_props]);

  let theme = themeConfig[props?.theme || "danger"];
  let Icon = theme.icon.component;

  let isConfiming = _props.isConfiming;

  return (
    <Transition show={_props.open} as={Fragment}>
      <Dialog
        as="div"
        className={classNames(
          "relative z-[9999]",
          isConfiming ? "cursor-wait" : ""
        )}
        initialFocus={cancelButtonRef}
        onClose={props?.cancel || (() => {})}
        onAnimationEnd={() => {
          setProps(null);
        }}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div
                      className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${theme.icon.bg} sm:mx-0 sm:h-10 sm:w-10`}
                    >
                      <Icon
                        className={`h-6 w-6 ${theme.icon.color}`}
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <DialogTitle
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        {props?.title}
                      </DialogTitle>
                      <div className="mt-2">
                        {props?.message?.split("\n").map((line, i) => (
                          <p key={i} className="text-sm text-gray-500">
                            {line}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  {props?.type !== "alert" && (
                    <>
                      {props?.confirm !== undefined && (
                        <button
                          type="button"
                          className={classNames(
                            `inline-flex w-full justify-center rounded-md border border-transparent ${theme.confirmButton.bg} px-4 py-2 text-base font-medium text-white shadow-sm hover:${theme.confirmButton.hover} focus:outline-none focus:ring-2 focus:${theme.confirmButton.focusRing} focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`,
                            isConfiming ? "cursor-wait" : ""
                          )}
                          onClick={props?.confirm}
                          disabled={isConfiming}
                        >
                          {props?.confirmText ?? "Confirm"}
                        </button>
                      )}
                    </>
                  )}
                  <button
                    type="button"
                    className={classNames(
                      "mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm",
                      isConfiming ? "cursor-wait" : ""
                    )}
                    onClick={props?.cancel}
                    ref={cancelButtonRef}
                    disabled={isConfiming}
                  >
                    {props?.cancelText ?? "Cancel"}
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

const ConfirmationDialogContext = React.createContext({
  openDialogId: null as string | null,

  showDialog: (
    id: string,
    title: string | undefined,
    message: string | undefined,
    confirmText: string | undefined,
    onConfirm: () => void | undefined | Promise<void>,
    cancelText: string | undefined,
    onCancel: () => void | undefined,
    theme?: ThemeNames | undefined,
    type?: "alert" | "confirm"
  ) => {},
});

export function ConfirmationDialogProvider(props: { children?: any }) {
  const [dialogueProps, setDialogueProps] = useState(
    null as null | Omit<ConfirmationDialogueProps, "open">
  );

  let showDialog = useCallback(
    (
      id: string,
      title: string | undefined,
      message: string | undefined,
      confirmText: string | undefined,
      onConfirm: () => void,
      cancelText: string | undefined,
      onCancel: () => void,
      theme: ThemeNames = "danger",
      type: "alert" | "confirm" | undefined
    ) => {
      setDialogueProps({
        id,
        title,
        message,
        cancelText,
        confirmText,
        cancel: onCancel,
        confirm: onConfirm,
        theme: theme,
        type: type,
      });
    },
    [setDialogueProps]
  );

  let data = useMemo(() => {
    return {
      openDialogId:
        dialogueProps?.id && dialogueProps?.id.length > 0
          ? dialogueProps?.id
          : null,
      showDialog,
    };
  }, [showDialog, dialogueProps]);

  return (
    <ConfirmationDialogContext.Provider value={data}>
      <WiredConfirmationDialogContext.Provider
        value={{ dialogueProps, setDialogueProps }}
      >
        <MemoWrap>{props.children ?? null}</MemoWrap>
        <ConfirmationDialogueAppMount />
      </WiredConfirmationDialogContext.Provider>
    </ConfirmationDialogContext.Provider>
  );
}

const WiredConfirmationDialogContext = React.createContext({
  dialogueProps: null as null | Omit<ConfirmationDialogueProps, "open">,
  setDialogueProps: (
    props: null | Omit<ConfirmationDialogueProps, "open">
  ) => {},
});

function WiredConfirmationDialog() {
  let { dialogueProps, setDialogueProps } = useContext(
    WiredConfirmationDialogContext
  );
  let [isConfiming, setIsConfirming] = useState(false);
  return (
    <ConfirmationDialogue
      open={dialogueProps !== null}
      {...dialogueProps}
      isConfiming={isConfiming}
      id={dialogueProps?.id ?? ""}
      cancel={() => {
        dialogueProps?.cancel();
        setDialogueProps(null);
      }}
      confirm={async () => {
        setIsConfirming(true);
        await dialogueProps?.confirm();
        setIsConfirming(false);
        setDialogueProps(null);
      }}
    />
  );
}

const MemoWrap = React.memo(function MemoWrap(props: { children: any }) {
  return props.children;
});

export function ConfirmationDialogueAppMount() {
  let { personFlyoutIsOpen } = useOpenPersonFlyout();
  let { isCheckoutSheetOpen } = useCheckoutSheet();
  let { lotFlyoutIsOpen } = useOpenLotFlyout();

  let hasOpenFlyout =
    personFlyoutIsOpen || isCheckoutSheetOpen || lotFlyoutIsOpen;

  // Show if no other flyout is open
  return hasOpenFlyout ? null : <WiredConfirmationDialog />;
}

export function ConfirmationDialoguePersonFlyoutMount() {
  let { personFlyoutIsOpen } = useOpenPersonFlyout();
  return personFlyoutIsOpen ? <WiredConfirmationDialog /> : null;
}

export function ConfirmationDialogueCheckoutFlyoutMount() {
  let { isCheckoutSheetOpen } = useCheckoutSheet();
  return isCheckoutSheetOpen ? <WiredConfirmationDialog /> : null;
}

export function ConfirmationDialogueLotFlyoutMount() {
  let { lotFlyoutIsOpen } = useOpenLotFlyout();
  // Show if no other flyout is open
  return lotFlyoutIsOpen ? <WiredConfirmationDialog /> : null;
}
