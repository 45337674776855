import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
} from "@headlessui/react";
import CreatableSelect from "react-select/creatable";

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ScissorsIcon,
} from "@heroicons/react/24/solid";
import {
  doc,
  runTransaction,
  serverTimestamp,
  writeBatch,
} from "firebase/firestore";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { autoId } from "../../data/ids";
import { useFirestore } from "../../data/studio-react/firebase/useFirestore";
import { useCurrentUid } from "../../data/studio-react/firebase/useFirestoreAuth";
import { Lot, LotItem } from "../../types";
import { Button } from "../Buttons";
import { toast } from "sonner";
import { classNames } from "@/data/classnames";

interface SplitLotDialogueProps {
  lot: Lot | null | undefined;
  allLotsToSplit: Lot[] | null | undefined;
  allLots: Lot[] | null | undefined;
  onClose: () => void;
  preSelectedLotItems?: LotItem[] | [];
}

type SelectLotToMoveToProps =
  | {
      lotId: string;
      lotNumber: string;
      isNewLot: false;
    }
  | {
      lotId?: never;
      isNewLot: true;
      lotNumber: string;
    };

export default function SplitLotDialogue(props: SplitLotDialogueProps) {
  let open = props.lot !== null && props.lot !== undefined;
  let onClose = props.onClose;

  let cancelButtonRef = useRef(null);

  let [itemsStaying, setItemsStaying] = useState<LotItem[]>([]);
  let [itemsLeaving, setItemsLeaving] = useState<LotItem[]>(
    props.preSelectedLotItems ?? []
  );
  let [initialNewLot, setInitialNewLot] = useState<SelectLotToMoveToProps>({
    isNewLot: true,
    lotNumber: "",
  });

  const [selectedLotToMoveOrCreate, setSelectedLotToMoveOrCreate] =
    useState<SelectLotToMoveToProps>({
      isNewLot: true,
      lotNumber: "",
    });

  let [activeLotNumber, setActiveLotNumber] = useState<string>();
  let [newLotNumber, setNewLotNumber] = useState<string>();

  const selectedItemItems = useMemo(() => {
    if (selectedLotToMoveOrCreate.isNewLot || !props.allLots) {
      return [];
    }

    const foundItem = props.allLots.find(
      (item) => item.id === selectedLotToMoveOrCreate.lotId
    );
    if (foundItem) {
      const t = Object.values(foundItem.itemMap);
      return t;
    }
    return [];
  }, [selectedLotToMoveOrCreate]);

  useEffect(() => {
    if (props.lot) {
      let items = Object.values(props.lot.itemMap).sort(sortByIndex);
      const itemsToLeave = props.preSelectedLotItems ?? [];
      const itemsToStay = items.filter((i) => !itemsToLeave.includes(i));
      setItemsStaying(itemsToStay);
      setItemsLeaving(itemsToLeave);

      let activeNumber = props.lot.lotNumber;
      setActiveLotNumber(activeNumber ?? "");

      if (props.allLotsToSplit && activeNumber) {
        let allLotsToSplit = props.allLotsToSplit;
        let checkIfInUse = (candidate: string) => {
          // Check the next lot number to see if it's already in use
          return allLotsToSplit.some((lot) => lot.lotNumber === candidate);
        };

        let nextNumber = incrmentLotNumber(activeNumber);
        while (checkIfInUse(nextNumber)) {
          nextNumber = incrmentLotNumber(nextNumber);
        }

        setNewLotNumber(nextNumber);
        setInitialNewLot({
          isNewLot: true,
          lotNumber: nextNumber,
        });
      }
    }
  }, [props.lot, props.allLotsToSplit, props.preSelectedLotItems]);

  let currentUid = useCurrentUid();

  let canCreateSplit =
    itemsLeaving.length > 0 &&
    newLotNumber !== "" &&
    itemsStaying.length > 0 &&
    activeLotNumber !== "";

  let firestore = useFirestore();

  const createLotAndMoveItems = async () => {
    if (!canCreateSplit) {
      return;
    }
    if (!props.lot || !props.allLotsToSplit) {
      return;
    }
    try {
      const currentLotDocRef = doc(
        firestore,
        `markets/${props.lot.marketId}/sales/${props.lot.saleId}/lots/${props.lot.id}`
      );
      const movedToLotDocRef = doc(
        firestore,
        `markets/${props.lot.marketId}/sales/${props.lot.saleId}/lots/${selectedLotToMoveOrCreate.lotId}`
      );
      await runTransaction(firestore, async (transaction) => {
        const currentLotDoc = await transaction.get(currentLotDocRef);

        const movedToLotDoc = await transaction.get(movedToLotDocRef);

        if (!movedToLotDoc.exists() || !currentLotDoc.exists()) {
          throw new Error("Lots do not exist");
        }
        const currLot = currentLotDoc.data() as Lot;
        const movedToLot = movedToLotDoc.data() as Lot;

        const newCurrentLot: Partial<Lot> = {
          ...currLot,
          itemMap: itemsStaying.reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
          }, {} as { [id: string]: LotItem }),
          attributes: {
            ...currLot.attributes,
            expectedQuantity: itemsStaying.length,
          },
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          updatedBy: currentUid,
        };

        const leavingMap = itemsLeaving.reduce((acc, item) => {
          acc[item.id] = item;
          return acc;
        }, {} as { [id: string]: LotItem });

        const newMovedToLot: Partial<Lot> = {
          ...movedToLot,
          itemMap: { ...movedToLot.itemMap, ...leavingMap },
          attributes: {
            ...movedToLot.attributes,
            expectedQuantity: itemsStaying.length,
          },
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          updatedBy: currentUid,
        };

        transaction.update(currentLotDocRef, newCurrentLot);
        transaction.update(movedToLotDocRef, newMovedToLot);
      });
    } catch (error) {
      toast.error((error as any)?.message ?? "Unknown error");
      console.error(error);
    }
  };

  let split = useCallback(() => {
    if (!canCreateSplit) {
      return;
    }

    if (!props.lot || !props.allLotsToSplit) {
      return;
    }

    let currentIndex = props.lot.index;

    let lotWithNextIndex = props.allLotsToSplit.find(
      (lot) => lot.index > currentIndex
    );
    let nextIndex = lotWithNextIndex?.index ?? currentIndex + 10;

    // the new index is half way between the current lot and the next lot
    let newIndex = currentIndex + (nextIndex - currentIndex) / 2;

    let newLot: Lot = {
      ...props.lot,
      id: autoId(),
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      updatedBy: currentUid,

      itemMap: itemsLeaving.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {} as { [id: string]: LotItem }),
      index: newIndex,

      lotNumber: newLotNumber,

      attributes: {
        ...props.lot.attributes,
        expectedQuantity: itemsLeaving.length,
      },

      group: autoId(),
      startedAt: null,
      endedAt: null,
      buyerCustomerId: null,
      buyer: {
        isSet: false,
      },
      buyerCasual: null,
    };

    let updatedLot: Partial<Lot> = {
      itemMap: itemsStaying.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {} as { [id: string]: LotItem }),

      attributes: {
        ...props.lot.attributes,
        expectedQuantity: itemsStaying.length,
      },

      lotNumber: activeLotNumber,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      updatedBy: currentUid,
    };

    let batch = writeBatch(firestore);

    let newLotDoc = doc(
      firestore,
      `markets/${props.lot.marketId}/sales/${props.lot.saleId}/lots/${newLot.id}`
    );
    batch.set(newLotDoc, newLot);

    let updatedLotDoc = doc(
      firestore,
      `markets/${props.lot.marketId}/sales/${props.lot.saleId}/lots/${props.lot.id}`
    );
    batch.update(updatedLotDoc, updatedLot);

    batch.commit().catch((e) => {
      console.error("Error saving split", e);
    });

    onClose();
  }, [
    canCreateSplit,
    currentUid,
    firestore,
    itemsLeaving,
    itemsStaying,
    onClose,
    props.lot,
    props.allLotsToSplit,
    newLotNumber,
    activeLotNumber,
  ]);

  return (
    <Dialog
      as="div"
      open={open}
      data-autofocus
      className="relative z-[800]"
      initialFocus={cancelButtonRef}
      onClose={onClose}
    >
      <div className="fixed inset-0 z-[800] overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ScissorsIcon
                    className="h-6 w-6 text-red-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <DialogTitle
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Reassign Lot Items
                  </DialogTitle>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Move items from Lot <b>{activeLotNumber}</b> to{" "}
                      <b>{selectedLotToMoveOrCreate.lotNumber}</b>
                    </p>
                    {!selectedLotToMoveOrCreate.isNewLot && (
                      <p className="text-sm text-gray-500">
                        As <b>{selectedLotToMoveOrCreate.lotNumber}</b> already
                        exists the moved items will be added to it, and removed
                        from <b>{activeLotNumber}</b>
                      </p>
                    )}
                    {selectedLotToMoveOrCreate.isNewLot && (
                      <p className="text-sm text-gray-500">
                        <b>{selectedLotToMoveOrCreate.lotNumber}</b> is new, it
                        will be created, the selected items will be added to it,
                        and then removed from <b>{activeLotNumber}</b>
                      </p>
                    )}
                  </div>
                </div>
              </div>

              {/* two pannels side by side, the first contains the list of all the items. Clicking and item moves it to the second. */}

              <div className="my-8 flex items-center justify-center">
                <div className="mr-1 flex-grow w-1/2">
                  <div>
                    <label
                      htmlFor="active-lot"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Active Lot
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="active-lot"
                        id="active-lot"
                        disabled={true}
                        className="block h-10 w-full rounded-md border border-gray-300 bg-white p-2 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                        value={activeLotNumber}
                        onChange={(e) => setActiveLotNumber(e.target.value)}
                      />
                    </div>
                  </div>

                  <ul className="mt-4 h-60 overflow-auto rounded-md border border-gray-300 bg-white shadow-sm">
                    <p className="px-2 text-xs text-gray-700 py-1  font-bold  border-b border-gray-200 bg-gray-200">
                      Items to be left on {activeLotNumber}
                    </p>
                    {itemsStaying.map((item) => (
                      <li key={item.id}>
                        <button
                          type="button"
                          className="block w-full px-4  py-2 text-left text-sm text-gray-700hover:bg-gray-100 border-b border-gray-200"
                          onClick={() => {
                            setItemsStaying(
                              itemsStaying
                                .filter((i) => i.id !== item.id)
                                .sort(sortByIndex)
                            );
                            setItemsLeaving(
                              [...itemsLeaving, item].sort(sortByIndex)
                            );
                          }}
                        >
                          {item.attributes["@eartag"] ?? "Missing Eartag"}
                        </button>
                      </li>
                    ))}
                  </ul>
                  <div className="p-2">
                    <span className="text-sm text-gray-500">
                      {itemsStaying.length} item
                      {itemsStaying.length === 1 ? "" : "s"}
                    </span>
                  </div>
                </div>

                <div className="flex h-full flex-col ">
                  <button
                    type="button"
                    className="flex h-full w-10 items-center justify-center"
                    onClick={() => {
                      setItemsLeaving(
                        [...itemsLeaving, ...itemsStaying].sort(sortByIndex)
                      );
                      setItemsStaying([]);
                    }}
                  >
                    <ChevronRightIcon
                      className="h-10 w-10 text-gray-400"
                      aria-hidden="true"
                    />
                  </button>

                  <button
                    type="button"
                    className="flex h-full w-10 items-center justify-center"
                    onClick={() => {
                      setItemsStaying(
                        [...itemsStaying, ...itemsLeaving].sort(sortByIndex)
                      );
                      setItemsLeaving([]);
                    }}
                  >
                    <ChevronLeftIcon
                      className="h-10 w-10 text-gray-400"
                      aria-hidden="true"
                    />
                  </button>
                </div>

                <div className="mr-1 flex-grow w-1/2">
                  <div>
                    <label
                      htmlFor="new-lot"
                      className="block text-sm font-medium text-gray-700"
                    >
                      New Lot
                    </label>
                    <div className="mt-1">
                      <SelectLotToMoveTo
                        currentLot={props.lot}
                        // Only allow users to select lots that are of same superType and productCode
                        filterOn={{
                          superType: props?.lot?.superType,
                        }}
                        autoCreatedNextLot={initialNewLot}
                        allLots={props.allLots}
                        setSelectedLotToMoveOrCreate={
                          setSelectedLotToMoveOrCreate
                        }
                      />
                    </div>
                  </div>

                  <ul className="mt-4 h-60 overflow-auto rounded-md border border-gray-300 bg-white shadow-sm">
                    <p className="px-2 text-xs text-gray-700 py-1  font-bold  border-b border-gray-200 bg-gray-200">
                      Items to be added to {selectedLotToMoveOrCreate.lotNumber}
                      {selectedItemItems.length > 0 && (
                        <> (Already has {selectedItemItems.length} items)</>
                      )}
                    </p>
                    {itemsLeaving.map((item) => (
                      <li key={item.id}>
                        <button
                          type="button"
                          className="block w-full px-4  py-2 text-left text-sm text-gray-700 hover:bg-gray-100 border-b border-gray-200"
                          onClick={() => {
                            setItemsLeaving(
                              itemsLeaving
                                .filter((i) => i.id !== item.id)
                                .sort(sortByIndex)
                            );
                            setItemsStaying(
                              [...itemsStaying, item].sort(sortByIndex)
                            );
                          }}
                        >
                          {item.attributes["@eartag"] ?? "Missing Eartag"}
                        </button>
                      </li>
                    ))}
                  </ul>
                  <div className="p-2">
                    <span className="text-sm text-gray-500">
                      {itemsLeaving.length} item
                      {itemsLeaving.length === 1 ? "" : "s"}
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex mt-5 sm:mt-4 sm:flex sm:flex-row-reverse gap-2 justify-between">
                <div>
                  {selectedLotToMoveOrCreate.isNewLot && (
                    <Button
                      variant="primary"
                      type="button"
                      onClick={split}
                      isDisabled={!canCreateSplit}
                    >
                      Create {selectedLotToMoveOrCreate.lotNumber} and Move
                      Items
                    </Button>
                  )}
                  {!selectedLotToMoveOrCreate.isNewLot && (
                    <Button
                      variant="primary"
                      type="button"
                      onClick={createLotAndMoveItems}
                      isDisabled={!canCreateSplit}
                    >
                      Move items to {selectedLotToMoveOrCreate.lotNumber}
                    </Button>
                  )}
                </div>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={onClose}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  );
}
interface Option {
  readonly label: string;
  readonly isNewLot: boolean;
  readonly value: string;
  readonly disabled: boolean;
}
function SelectLotToMoveTo(props: {
  currentLot: Lot | null | undefined;
  filterOn: Partial<Record<keyof Lot, string | undefined>>;
  autoCreatedNextLot: SelectLotToMoveToProps;
  allLots: Lot[] | null | undefined;
  setSelectedLotToMoveOrCreate: (lot: SelectLotToMoveToProps) => void;
}) {
  const [value, setValue] = useState<Option | null>(null);
  const [selectOptions, setSelectOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (value) {
      const payload: SelectLotToMoveToProps = {
        isNewLot: value.isNewLot,
        lotNumber: value.isNewLot ? value.value : value.label,
        lotId: value.isNewLot ? value.label : value.value,
      } as SelectLotToMoveToProps;
      props.setSelectedLotToMoveOrCreate(payload);
    }
  }, [value]);

  useEffect(() => {
    const init = {
      value: props.autoCreatedNextLot.lotNumber ?? "",
      isNewLot: true,
      disabled: false,
      label: `${props.autoCreatedNextLot.lotNumber} - (New Lot)`,
    };
    setValue(init);

    const filteredL = props?.allLots?.reduce((acc, lot) => {
      const filterKeys = Object.keys(props.filterOn) as (keyof Lot)[];
      for (let key of filterKeys) {
        if (lot[key] !== props.filterOn[key]) {
          return acc;
        }
      }
      acc.push(lot);
      return acc;
    }, [] as Lot[]);

    const formatForSelect =
      filteredL?.map((lot) => ({
        disabled: lot.id === props.currentLot?.id,
        value: lot.id,
        isNewLot: false,
        label: `${lot.lotNumber}`,
      })) ?? [];

    setSelectOptions(() => {
      return [init, ...formatForSelect];
    });
  }, [props.allLots, props.autoCreatedNextLot]);

  return (
    <>
      <CreatableSelect
        classNames={{
          control: ({ isFocused }) =>
            classNames(
              "sm:text-sm",
              isFocused && "!border-martEye-400 !ring-green-500 "
            ),
          option: ({ isDisabled, isFocused, isSelected }) =>
            classNames(
              "!text-sm",
              isSelected && "!bg-martEye-400",
              !isSelected && isFocused && "!bg-martEye-400/20",
              !isDisabled && isSelected && "!active:bg-purple-800",
              !isDisabled && !isSelected && "!active:bg-purple-500"
            ),
        }}
        isClearable
        isOptionDisabled={(option) => option.disabled}
        value={value}
        options={selectOptions}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        onCreateOption={(c) => {
          if (
            !c ||
            c.trim()?.length === 0 ||
            c.trim() === props.currentLot?.lotNumber
          ) {
            toast.error("Please enter a valid lot number");
            return;
          }
          setValue({
            value: c,
            isNewLot: true,
            disabled: false,
            label: `${c} - (New Lot)`,
          });
        }}
      />
    </>
  );
}

function sortByIndex(a: LotItem, b: LotItem) {
  return a.index - b.index;
}

function incrmentLotNumber(lotNumber: string) {
  // if the last char of the lotNumber is numeric then newLotNumber should have an A appended
  // if the last char of the lotNumber is alpha then newLotNumber should have the alpha incremented

  let lastChar = lotNumber[lotNumber.length - 1];
  let isNumeric = !isNaN(parseInt(lastChar));
  let newLotNumber;

  if (isNumeric) {
    newLotNumber = lotNumber + "A";
  } else {
    // Only use characters A-Z. After that add another letter to the lot number.
    if (lastChar === "Z") {
      newLotNumber = lotNumber + "A";
    } else {
      newLotNumber =
        lotNumber.slice(0, -1) +
        String.fromCharCode(lastChar.charCodeAt(0) + 1);
    }
  }

  return newLotNumber;
}
