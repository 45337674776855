import { formatAsCurrency } from "@/data/amounts";
import { classNames } from "@/data/classnames";
import { DraftInvoice, Invoice, Lot } from "types";
import { Spinner } from "../Buttons";

const showCurrency = false;
const removeTrailingZero = false;

export function getInvoiceSummaryPanelData(
  lots: Lot[] | null | undefined,
  invoice: DraftInvoice | Invoice | null | undefined,
  isLoading: boolean
) {
  let panelData: PanelProps = {
    title: "Summary",
    aggregates: [],
    subtotals: [],
    total: "-",
    isLoading,
  };

  if (!lots && !invoice) {
  } else if (lots && !invoice) {
    // let lotsTotal = lots.reduce(
    //   (acc, lot) => acc + (lot.generated?.totalValueInCents || 0),
    //   0
    // );
    panelData = {
      title: "Summary",
      aggregates: [
        // {
        //   label: "Lots",
        //   value: formatAsCurrency(null, lotsTotal),
        // },
      ],
      subtotals: [
        {
          label: "Subtotal",
          value: "",
        },
        {
          label: "Tax on Lots",
          value: "",
        },
        {
          label: "Tax on Commission",
          value: "",
        },

        {
          label: "Tax on Additions",
          value: "",
        },
      ],
      total: "-",
      isLoading,
    };
  } else if (invoice) {
    let i = invoice;

    let currency = showCurrency ? i.currency : null;

    let isSeller = i.clientType === "Seller";
    let isBuyer = i.clientType === "Buyer";
    let isVATReg = i.customerIsVATRegistered;
    let isWithinTaxRegion = i.customerWithinTaxRegion;

    // Seller - return vat if vat registered
    // keep vat if not vat registered. Should be caught by lot gremlin
    let servicesVatMultiplier = 0;
    let goodsVatMultiplier = 0;
    if (isSeller) {
      servicesVatMultiplier = -1;
      goodsVatMultiplier = isVATReg ? 1 : -1;
    }

    // Buyer 0 vat if vta reg and not in tax region
    if (isBuyer) {
      servicesVatMultiplier = 1;
      goodsVatMultiplier = isVATReg && !isWithinTaxRegion ? 0 : 1;
    }

    let subtotal =
      i.lotTotalInCentsExVat +
      ((i.commissionTotalInCents || 0) +
        (i.nonCommissionAdjustmentsInCents || 0)) *
        servicesVatMultiplier;

    panelData = {
      title: "Summary",
      aggregates: [
        // {
        //   label: "Lots",
        //   value: formatAsCurrency(
        //     currency,
        //     i.lotTotalInCentsExVat,
        //     removeTrailingZero
        //   ),
        // },
        // This should be in the invoice subtitalGroupTotals...
        // {
        //   label: "Extras",
        //   value: formatAsCurrency(
        //     currency,
        //     (i.nonCommissionAdjustmentsInCents || 0) * multiplier,
        //     removeTrailingZero
        //   ),
        // },
        ...Object.keys(i.subtotalGroupTotals).map((key, idx) => ({
          label: key,
          value: formatAsCurrency(
            currency,
            i.subtotalGroupTotals[key],
            removeTrailingZero
          ),
        })),
      ],
      subtotals: [
        {
          label: "Subtotal",
          value: formatAsCurrency(currency, subtotal, removeTrailingZero),
        },
        {
          label: "Tax on Lots",
          value: formatAsCurrency(
            currency,
            i.vatOnLotTotalInCents * goodsVatMultiplier,
            removeTrailingZero
          ),
        },
        {
          label: "Tax on Commission",
          value: formatAsCurrency(
            currency,
            (i.vatOnCommissionInCents || 0) * servicesVatMultiplier,
            removeTrailingZero
          ),
        },

        {
          label: "Tax on Extras",
          value: formatAsCurrency(
            currency,
            (i.vatOnAdjustmentsInCents || 0) * servicesVatMultiplier,
            removeTrailingZero
          ),
        },
      ],
      total:
        formatAsCurrency(i.currency, i.finalTotalInCents, removeTrailingZero) ||
        "Unknown",
      isLoading: false,
    };
  }

  return panelData;
}

// Higher level component here that converts the data into a format that we need
export function SummaryPanel(props: {
  lots?: Lot[] | null;
  invoice?: DraftInvoice | Invoice | null;
  isLoading: boolean;
}) {
  let { lots, invoice, isLoading } = props;

  let panelData = getInvoiceSummaryPanelData(lots, invoice, isLoading);

  return <Panel {...panelData} />;
}

// The panel itself
interface PanelProps {
  title: string;

  aggregates: {
    label: string;
    value: string | null;
  }[];

  subtotals: {
    label: string;
    value: string | null;
  }[];

  total: string;

  isLoading: boolean;
}

function Panel(props: PanelProps) {
  let { title, aggregates, subtotals, total, isLoading } = props;

  return (
    <div className="flex flex-shrink-0 w-72 flex-col rounded-md bg-[#0E1513] py-4 text-sm font-light text-[#CBCBCB]">
      <h3 className="mb-6 px-5 text-lg font-medium flex flex-row items-center justify-between">
        {title}
        {isLoading ? <Spinner /> : null}
      </h3>

      <ul className="flex-grow">
        {aggregates.map((a, idx) => (
          <li
            key={`${a.label}`}
            className={classNames(
              "flex-row flex justify-between px-5 py-1.5",
              idx % 2 === 0 ? "bg-white/10" : "",
              isLoading ? "animate-pulse" : ""
            )}
          >
            <span>{a.label}</span>
            <span>{a.value}</span>
          </li>
        ))}
      </ul>

      <ul className="mb-2 px-5 mt-32">
        {subtotals.map((s) => (
          <li
            key={`${s.label}`}
            className={classNames(
              "flex flex-row justify-between border-b border-b-white/20 py-1.5",
              isLoading ? "animate-pulse" : ""
            )}
          >
            <span className="mr-3">{s.label}</span>
            <span>{s.value}</span>
          </li>
        ))}
      </ul>

      <div
        className={classNames(
          "flex flex-row items-center justify-between px-5 py-1.5",
          isLoading ? "animate-pulse" : ""
        )}
      >
        <span className="text-sm font-bold text-[#7A8582]">TOTAL</span>
        <span className="text-2xl font-bold text-white">{total}</span>
      </div>
    </div>
  );
}
