import { DraftInvoice, Invoice, Lot, Market, SuperType } from "types";
import { DocumentSpec, InvoicePDFProps } from "../_importDocs";

// PDF Building
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

import "../fonts/Inter-bold.js";
import "../fonts/Inter-extrabold.js";
import "../fonts/Inter-light.js";
import "../fonts/Inter-normal.js";

import { MARGIN } from "../variables";

// Components
import addPageOneHeader from "../components/header";
import tableRunningHeader from "../components/tableRunningHeader";

const PassoutSpec: DocumentSpec = {
  // must be unique
  id: "passout",
  objectType: "invoice",

  // displayed to the user
  name: "Pass Out",

  // when the PDF is downloaded, this function is called to generate the filename
  getFilename: (props) => {
    let { invoice, marketId } = props;
    let invoiceNumber =
      invoice.status === "draft" ? "Draft" : invoice.invoiceNumber;
    let filename = `passout-${marketId}-${invoiceNumber}`;
    filename = filename.replace(/ /g, "-").replace(/[^a-zA-Z0-9-]/g, "");
    return `${filename}`;
  },

  // return true if this document is available for a market to generate
  isAvailableFor: (market: Market, superTypes: SuperType[]) => {
    return true;
  },

  // the document template
  jsPDF: (props: InvoicePDFProps) => {
    const { lots, invoice, market } = props;

    // A passout is used to collect what was brought.
    // Show all the lot numbers with tags (if livestock) the person brought but without the price

    let lotsToShow = [] as Lot[];

    if (invoice.clientType === "Seller") {
      // The seller should only get a passout for items not sold
      for (let lot of lots) {
        if (lot.saleStatus === "Unsold") {
          lotsToShow.push(lot);
        }
      }
    } else {
      lotsToShow = lots;
    }

    let y = MARGIN;
    const doc = new jsPDF({ putOnlyUsedFonts: true, compress: true });
    y = addPageOneHeader(doc, invoice, y, "Pass", market);

    // Items
    const passOutData = (invoice: Invoice | DraftInvoice) => {
      //
      const lineItems = invoice.lineItems;

      const filteredArray = lineItems
        .filter(({ lotId }) => {
          return lotsToShow.some(({ id: id }) => id === lotId);
        })
        .map(({ lotId, ...rest }) => ({ lotId, ...rest }));

      let currency = invoice.currency || "GBP";

      const data: any = [];
      // Need to filter out the items that have been sold based on the invoice.lineItems abd the lotsToShow
      filteredArray.map((item: any) => {
        data.push([item.metadata.lotNumber, item.quantity, item.description]);
      });

      return data;
    };

    // Add running header
    tableRunningHeader(y + MARGIN, doc, invoice, "Pass");

    // Line Items Table
    const Passoutdata = passOutData(invoice);

    if (Passoutdata.length !== 0) {
      autoTable(doc, {
        head: [["LOT", "QTY", "DESCRIPTION"]],
        body: Passoutdata,
        startY: y + 12.5,
        theme: "plain",
        margin: { top: 18, right: 10, bottom: 45, left: 10 },
        headStyles: {
          fillColor: "#eaeaea",
          textColor: "#989898",
          fontSize: 8,
          font: "Inter",
          fontStyle: "bold",
          cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
        },
        bodyStyles: {
          fontSize: 10,
          font: "Inter",
          fontStyle: "normal",
          cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
        },

        willDrawCell: function (data) {
          // add borders around the head cells
          if (data.row.section === "body") {
            doc.setDrawColor("#CBCBCB"); // set the border color
            doc.setLineWidth(0.1); // set the border with

            // draw bottom border
            doc.line(
              data.cell.x,
              data.cell.y + data.cell.height,
              data.cell.x + data.cell.width,
              data.cell.y + data.cell.height
            );
          }
        },
      });
    } else if (invoice.clientType === "Seller") {
      doc.setTextColor("#FF6D6D");
      doc.setFontSize(12);
      doc.setFont("Inter", "bold");
      doc.text("The seller has no lots to pass out.", MARGIN, y + 20);
    } else {
      doc.setTextColor("#FF6D6D");
      doc.setFontSize(12);
      doc.setFont("Inter", "bold");
      doc.text("The buyer has no lots to pass out.", MARGIN, y + 20);
    }

    return doc;
  },
};

export default PassoutSpec;
