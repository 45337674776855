import { classNames } from "@/data/classnames";
import { useCurrentUid } from "@/data/studio-react/firebase/useFirestoreAuth";
import { useEffect, useState } from "react";
import { Address, AddressWrapper, Customer } from "../../../../types";

import { AddressDialog } from "@/components/addresses/AddressDialog";
import {
  EllipsisVerticalIcon,
  PlusCircleIcon,
} from "@heroicons/react/20/solid";
import AddressFormatter from "@shopify/address";
import { useAddressFunctions } from "../../../addresses/useAddressFunctions";

import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";

// TODO
// 1. Sort out the types for all of the below
interface SettingsAddressesTabProps {
  customer: Customer;
  marketId: string;
}

export function SettingsAddresses(props: SettingsAddressesTabProps) {
  let { customer, marketId } = props;
  let customerId = customer?.id;
  let currentUid = useCurrentUid();

  const [showAddressDialog, setShowAddressDialog] = useState(false);
  const [addressIsPrimary, setAddressIsPrimary] = useState<boolean>(false);
  const [currentAddress, setCurrentAddress] = useState<AddressWrapper | null>(
    null
  );

  let addresses = customer?.otherAddresses ?? [];
  let primaryAddress = customer?.address ?? null;
  const editAddress = (value: AddressWrapper | null) => {
    setAddressIsPrimary(false);
    setCurrentAddress(value);
    setShowAddressDialog(true);
  };

  const editPrimaryAddress = (value: AddressWrapper | null) => {
    setAddressIsPrimary(true);
    setCurrentAddress(value);
    setShowAddressDialog(true);
  };

  const { deleteAddress, makeAddressPrimary } = useAddressFunctions(customerId);

  return (
    <div className="flex flex-wrap gap-4">
      <ul
        role="list"
        className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3"
      >
        {primaryAddress && (
          <li className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
            <div className="flex w-full items-center px-6 py-4">
              <AddressComponent address={primaryAddress} isPrimary />

              <AddressOptions>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => editPrimaryAddress(primaryAddress)}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block w-full px-4 py-2 text-end text-sm"
                      )}
                    >
                      Edit
                    </button>
                  )}
                </Menu.Item>
              </AddressOptions>
            </div>
          </li>
        )}

        {Object.values(addresses).map((value) => {
          let addressId = value?.address?.id;
          if (!addressId) return null;

          return (
            <li
              key={value.address.id}
              className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
            >
              <div className="flex w-full items-center px-6 py-4">
                <AddressComponent address={value} />
                <AddressOptions>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => editAddress(value)}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block w-full px-4 py-2 text-end text-sm"
                        )}
                      >
                        Edit
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => makeAddressPrimary(addressId ?? "")}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "text-align block w-full px-4 py-2 text-end text-sm"
                        )}
                      >
                        Make Primary
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => deleteAddress(addressId ?? "")}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-danger-300"
                            : "text-danger-300",
                          "block w-full px-4 py-2 text-end text-sm"
                        )}
                      >
                        Delete
                      </button>
                    )}
                  </Menu.Item>
                </AddressOptions>
              </div>
            </li>
          );
        })}
      </ul>
      <div className="w-full">
        <button
          className="inline-flex items-center gap-1.5 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={() => editAddress(null)}
        >
          <PlusCircleIcon
            className="h-5 w-5 text-martEye-400"
            aria-hidden="true"
          />
          Add new
        </button>
      </div>
      <AddressDialog
        open={showAddressDialog}
        setOpen={setShowAddressDialog}
        customerId={customerId}
        address={currentAddress}
        addressIsPrimary={addressIsPrimary}
      />
    </div>
  );
}

interface AddressComponentProps {
  address: AddressWrapper;
  isPrimary?: boolean;
}

const AddressComponent = (props: AddressComponentProps) => {
  const { address, isPrimary } = props;
  const [formatedAddress, setFormatedAddress] = useState<String[]>([]);

  useEffect(() => {
    formatAddress();
    async function formatAddress() {
      let addr: Address = { ...address.address };
      let country = addr.country;
      addr.country = country.substring(0, 2);
      let addressFormatter = new AddressFormatter("en");

      // @ts-ignore
      let res = await addressFormatter.format(addr);
      setFormatedAddress(res);
    }
  }, [address]);

  let name = [address?.address?.firstName, address?.address?.lastName]
    .filter(Boolean)
    .join(" ");

  if (address.address.company) {
    name = address.address.company;
  }

  return (
    <div className="grow">
      <h4 className="inline-flex items-center gap-x-1.5 text-sm font-bold">
        {name}

        {isPrimary && (
          <span className="inline-flex items-center  rounded-full bg-martEye-100 px-2 py-1 text-xs font-medium text-martEye-400">
            Primary
          </span>
        )}
      </h4>
      <p className="text-sm">
        {Object.values(formatedAddress).map((line, index) => {
          if (!line) {
            return null;
          }

          if (
            line ===
            `${address?.address?.firstName} ${address?.address?.lastName}`
          ) {
            return null;
          }
          return (
            <span key={`${index}-${line}`} className="block">
              {line}
            </span>
          );
        })}
      </p>
    </div>
  );
};

// Address Actions

export default function AddressOptions(props: any) {
  const { children } = props;
  return (
    <Menu
      as="div"
      className="relative inline-block shrink self-start text-left"
    >
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50">
          <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">{children}</div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
