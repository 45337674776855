// provide all the info about the sale as well as methods to update it
// wrapped in ref so changes to the data don't trigger a re-render
// intended for mutation

/*
    Often when updating the sale you want other information as well.
    Watching for that information in state can trigger unnecessary re-renders.

    Here we provide one stable function that you can invoke with a callback that 
    receives all the information you may need. 

    In the callback perform the update (the callback doens't do any updates for you).
*/

import { MemoComponent } from "@/components/MemoComponent";
import { Firestore } from "firebase/firestore";
import { useRouter } from "next/router";
import React, { useCallback, useMemo, useRef } from "react";
import {
  Customer,
  Invoice,
  Lot,
  Product,
  ProductCodeConfiguration,
  Sale,
  SettingsMarketDefaults,
} from "types";
import { useFirestore } from "./firebase/useFirestore";
import { useCurrentUid } from "./firebase/useFirestoreAuth";
import { StatsAndFilters } from "./loaders/useFilteredLotData";
import { Studio, constructStudioAPI } from "./useStudio";
import { useStudioStream } from "./useStudioStream";

export interface FullSaleContext {
  firestore: Firestore;

  studio: Omit<Studio, "executeInSaleContext">;

  marketId: string | null;
  saleId: string | null;

  currentUid: string | null;

  sale: Sale | null;
  lots: Lot[];

  lotsFiltersAndStats: StatsAndFilters | null;

  invoices: Invoice[];

  marketDefaultSettings: SettingsMarketDefaults | null;
  // all the product codes relevant to this sale
  saleProductCodes: ProductCodeConfiguration[] | null;
  saleProducts: Product[];

  // All the customers that are in the sale
  customers: Customer[];
}

export type ExecuteInSaleContext = <T>(
  changeFn: (ctx: FullSaleContext) => T
) => T;
type FnToInvoke<T> = (ctx: FullSaleContext) => T;

function invokeFunctionWithContext<T>(
  ctx: FullSaleContext,
  toInvoke: FnToInvoke<T>
) {
  return toInvoke(ctx);
}

export type WithSaleContext = <T extends any[], R>(
  func: (ctx: FullSaleContext, ...args: T) => R
) => (...args: T) => R;

export const _InternalExecuteInSaleContext = React.createContext<{
  executeInSaleContext: ExecuteInSaleContext;
  withSaleContext: WithSaleContext;
}>({
  executeInSaleContext: () => {
    throw new Error(
      "ExecuteInSaleContext not initialized - please wrap in a ExecuteInSaleProvider"
    );
  },
  withSaleContext: () => {
    return null as any;
  },
});

export function ExecuteInSaleProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  let contextRef = useRef<FullSaleContext>();

  // We define runWithSaleContext using a generic type.
  let executeInSaleContext = useCallback(function runWithSaleContext<T>(
    changeFn: (ctx: FullSaleContext) => T
  ) {
    let ctx = contextRef.current!;
    return invokeFunctionWithContext(ctx, changeFn);
  },
  []);

  let value = useMemo(() => {
    // automatically injects the sale context as the first argument in the function
    function withSaleContext<T extends any[], R>(
      func: (ctx: FullSaleContext, ...args: T) => R
    ): (...args: T) => R {
      return (...args: T): R => {
        return executeInSaleContext((ctx) => func(ctx, ...args));
      };
    }

    return {
      executeInSaleContext,
      withSaleContext: withSaleContext,
    };
  }, [executeInSaleContext]);

  let sale = useStudioStream("sale:sale");
  let lots = useStudioStream("sale:lots");
  let lotsFiltersAndStats = useStudioStream("sale:lotsFiltersAndStats");
  let invoices = useStudioStream("sale:invoices");
  let marketDefaultSettings = useStudioStream("sale:marketDefaultSettings");
  let saleProducts = useStudioStream("sale:products");
  let saleProductCodes = useStudioStream("sale:productCodes");
  let customers = useStudioStream("sale:customers");

  let router = useRouter();
  let marketId = router.query.marketId as string;
  let saleId = router.query.saleId as string;

  let firestore = useFirestore();
  let currentUid = useCurrentUid();

  let { executeInSaleContext: _, ...studioApi } = useMemo(() => {
    return constructStudioAPI(
      firestore,
      currentUid,
      marketId,
      value.executeInSaleContext,
      value.withSaleContext
    );
  }, [firestore, currentUid, marketId, value]);

  let ctx: FullSaleContext = {
    firestore,
    studio: studioApi,
    currentUid,
    marketId,
    saleId,
    sale,
    lots,
    lotsFiltersAndStats,
    invoices,
    marketDefaultSettings,
    saleProducts,
    saleProductCodes,
    customers,
  };

  contextRef.current = ctx;

  return (
    <_InternalExecuteInSaleContext.Provider value={value}>
      <MemoComponent>{children}</MemoComponent>
    </_InternalExecuteInSaleContext.Provider>
  );
}
