import {
  doc,
  DocumentData,
  DocumentReference,
  getFirestore,
  onSnapshot,
  Query,
  updateDoc,
  setDoc,
  writeBatch,
} from "firebase/firestore";
import { initialiseFirebase } from "initFirebase";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useFirebaseApp } from "./useFirebaseApp";

// Can be used to sync the datagrid
export interface QueryChanges<T> {
  added: {
    newIndex: number;
    item: T;
  }[];
  modified: {
    newIndex: number;
    oldIndex: number;
    item: T;
  }[];
  removed: {
    oldIndex: number;
    item: T;
  }[];

  // The full list of items after the changes
  items: T[];

  // The full list of items before the changes
  prevItems: T[];
}

const initialQueryChanges: QueryChanges<any> = {
  added: [],
  modified: [],
  removed: [],
  items: [],
  prevItems: [],
};

export function useFirestore() {
  let app = useFirebaseApp();
  return useMemo(() => getFirestore(app), [app]);
}

export function getInitialisedFirestore() {
  let app = initialiseFirebase();
  return getFirestore(app);
}

export async function updateFirestore(docPath: string, data: object) {
  if (!data || Object.keys(data).length === 0) {
    return;
  }

  let app = initialiseFirebase();
  let db = getFirestore(app);

  let ref = doc(db, docPath);

  await updateDoc(ref, data);
}

export async function setFirestore(
  docPath: string,
  data: object,
  merge: boolean
) {
  if (!data || Object.keys(data).length === 0) {
    return;
  }

  let app = initialiseFirebase();
  let db = getFirestore(app);
  let docRef = doc(db, docPath);

  // Try to set the document with merge option true, which will create the document if it doesn't exist
  await setDoc(docRef, data, { merge });
}

export type QueryLoadState<T> = ReturnType<typeof useLoadFirestoreQuery<T>>;

export function useLoadFirestoreQuery<T>(
  q: Query<DocumentData> | null,
  options?: {
    idField?: keyof T;
  }
  //   onChange?: (changes: QueryChanges<T>) => void
) {
  let [loading, setLoading] = useState(true);
  let [hasLoaded, setHasLoaded] = useState(false);
  let [error, setError] = useState<Error | null>(null);
  let retryTimeout = useRef<number | null>(null);
  let errorCount = useRef(0);
  let [nextChanges, setNextChanges] =
    useState<QueryChanges<T>>(initialQueryChanges);

  // onChange listeners
  let nextChangesRef = useRef(nextChanges);
  nextChangesRef.current = nextChanges;
  let listeners = useRef<((changes: QueryChanges<T>) => void)[]>([]);
  let addListener = useCallback(
    (listener: (changes: QueryChanges<T>) => void) => {
      listeners.current.push(listener);
      // Send the current state to the listener
      listener(nextChangesRef.current);
      return () => {
        listeners.current = listeners.current.filter((l) => l !== listener);
      };
    },
    []
  );
  // Call the listeners when the changes change
  let onChange = useCallback((changes: QueryChanges<T>) => {
    for (let listener of listeners.current) {
      listener(changes);
    }
  }, []);

  let idField = options?.idField;

  let loadQuery = useCallback(() => {
    if (!q) {
      return;
    }

    // // @ts-ignore
    // let queryStr = JSON.stringify(q._query);

    setLoading(true);
    let unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        setHasLoaded(true);

        let prevChanges = nextChangesRef.current;

        let nextItems = prevChanges?.items.slice() || [];
        let changes: QueryChanges<T> = {
          added: [],
          modified: [],
          removed: [],
          items: nextItems,
          prevItems: prevChanges?.items.slice() || [],
        };

        for (let change of snapshot.docChanges()) {
          let { type, newIndex, oldIndex, doc } = change;

          if (type === "added") {
            nextItems.splice(newIndex, 0, {
              ...(idField ? { [idField]: doc.id } : {}),
              ...doc.data(),
            } as T);

            changes.added.push({
              newIndex,
              item: nextItems[newIndex],
            });
          }

          if (type === "modified") {
            nextItems.splice(oldIndex, 1);

            let newItem = {
              ...(idField ? { [idField]: doc.id } : {}),
              ...doc.data(),
            } as T;

            nextItems.splice(newIndex, 0, newItem);

            // Register a move for the item at the old and the new index
            changes.modified.push({
              newIndex,
              oldIndex,
              item: newItem,
            });
          }

          if (type === "removed") {
            let oldItem = nextItems.splice(oldIndex, 1);
            changes.removed.push({
              oldIndex,
              item: oldItem[0],
            });
          }
        }

        setNextChanges(changes);
        onChange(changes);
        setLoading(false);
        setError(null);
      },
      (err) => {
        console.error("error loading query", q.type, err);
        setError(err);
        setLoading(false);

        // exponential backoff. Min 1s, Max 10s
        errorCount.current++;
        let timeout = Math.min(10000, Math.pow(2, errorCount.current) * 1000);
        retryTimeout.current = window.setTimeout(() => {
          retryTimeout.current = null;

          // Reset the state
          setNextChanges((prevChanges) => {
            return {
              ...prevChanges,
              added: [],
              modified: [],
              removed: prevChanges.items.map((item, index) => ({
                oldIndex: index,
                item,
              })),
              prevItems: prevChanges.items,
              items: [],
            };
          });

          loadQuery();
        }, timeout);
      }
    );
    return unsubscribe;
  }, [q, idField, onChange]);

  useEffect(() => {
    if (retryTimeout.current) {
      window.clearTimeout(retryTimeout.current);
      retryTimeout.current = null;
    }
    let unsub = loadQuery();

    return () => {
      if (unsub) {
        unsub();
      }

      if (retryTimeout.current) {
        window.clearTimeout(retryTimeout.current);
        retryTimeout.current = null;
      }

      setLoading(true);
      setError(null);
      errorCount.current = 0;
      setNextChanges((prev) => {
        // The query has changed reset the state
        return {
          ...prev,
          added: [],
          modified: [],
          removed: prev.items.map((item, index) => ({
            oldIndex: index,
            item,
          })),
          // this is a bit smelly
          prevItems: prev.items,
          items: [],
        };
      });
    };
  }, [loadQuery]);

  return useMemo(
    () => ({
      data: nextChanges.items,
      addListener,
      loading,
      error,
      changes: nextChanges,
      hasLoaded,
    }),
    [nextChanges, loading, error, hasLoaded, addListener]
  );
}

export type DocLoadState<T> = ReturnType<typeof useLoadFirestoreDoc<T>>;

export function useLoadFirestoreDoc<T>(
  docRefOrString: DocumentReference<DocumentData> | string | null,
  options?: {
    idField?: keyof T;
  }
) {
  let [exists, setExists] = useState(null as null | boolean);
  let [data, setData] = useState<T | null>(null);
  let [loading, setLoading] = useState(true);
  let [error, setError] = useState<Error | null>(null);
  let retryTimeout = useRef<number | null>(null);
  let errorCount = useRef(0);

  let idField = options?.idField;

  let docRef = useMemo(() => {
    if (typeof docRefOrString === "string") {
      let app = initialiseFirebase();
      return doc(getFirestore(app), docRefOrString);
    }
    return docRefOrString;
  }, [docRefOrString]);

  let loadDoc = useCallback(() => {
    if (!docRef) {
      return;
    }

    setLoading(true);
    let unsubscribe = onSnapshot(
      docRef,
      (doc) => {
        if (doc.exists() === false) {
          console.log("Doc does not exist");
          setData(null);
          setLoading(false);
          setError(null);
          setExists(false);
          return;
        }

        setData({
          ...(idField ? { [idField]: doc.id } : {}),
          ...doc.data(),
        } as T);
        setLoading(false);
        setError(null);
        setExists(true);
      },
      (err) => {
        setError(err);
        setLoading(false);

        // exponential backoff. Min 1s, Max 10s
        errorCount.current++;
        let timeout = Math.min(10000, Math.pow(2, errorCount.current) * 1000);
        retryTimeout.current = window.setTimeout(loadDoc, timeout);
      }
    );
    return unsubscribe;
  }, [docRef, idField]);

  useEffect(() => {
    if (retryTimeout.current) {
      window.clearTimeout(retryTimeout.current);
      retryTimeout.current = null;
    }

    let unsub = loadDoc();

    return () => {
      if (unsub) {
        unsub();
      }

      if (retryTimeout.current) {
        window.clearTimeout(retryTimeout.current);
        retryTimeout.current = null;
      }

      setLoading(true);
      setError(null);
      errorCount.current = 0;
      setData(null);
      setExists(null);
    };
  }, [loadDoc, docRef, idField]);

  return useMemo(
    () => ({ data, loading, error, exists }),
    [data, loading, error, exists]
  );
}

export async function batchUpdate(
  updates: {
    docPath: string;
    update: any;
  }[]
) {
  let firestore = getInitialisedFirestore();

  let batch = writeBatch(firestore);
  let itemsInBatch = 0;

  async function checkBatch() {
    if (itemsInBatch >= 500) {
      await batch.commit();
      batch = writeBatch(firestore);
      itemsInBatch = 0;
    }
  }

  for (let { docPath, update } of updates) {
    let docRef = doc(firestore, docPath);
    batch.update(docRef, update);
    itemsInBatch += 1;

    await checkBatch();
  }

  if (itemsInBatch > 0) {
    await batch.commit();
  }
}
