import {
  Timestamp,
  deleteField,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { useCallback } from "react";

import { useFirestore } from "@/data/studio-react/firebase/useFirestore";
import { useCurrentUid } from "@/data/studio-react/firebase/useFirestoreAuth";
import { useMarketId } from "@/data/useMartketId";

export function useDeleteCartItem() {
  let firestore = useFirestore();
  let currentUid = useCurrentUid();

  let marketId = useMarketId();

  let deleteCartItem = useCallback(
    async (customerId: string, itemId: string) => {
      let docRef = doc(firestore, `markets/${marketId}/carts/${customerId}`);
      let document = await getDoc(docRef);
      if (document.exists()) {
        await updateDoc(docRef, {
          [`itemsById.${itemId}`]: deleteField(),
          updatedAt: Timestamp.now(),
        });
      }
    },
    [currentUid, marketId, firestore]
  );

  return deleteCartItem;
}
