import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
// Reports
import { ReportData } from "pages/api/[marketId]/documents/reports/[reportId]";
import { format } from "date-fns";

export default function generateHeaders(doc: jsPDF, reportData: ReportData) {
  const { title, sales, marketName } = reportData;

  const salesText = sales
    ?.map(
      (sale) => sale.name + ": " + format(new Date(sale.startsAt), "MM/dd/yyyy")
    )
    .join("\n");

  autoTable(doc, {
    theme: "plain",
    body: [
      [
        {
          content: marketName,
        },
        {
          content: title,
          styles: { halign: "right", valign: "bottom", fontSize: 30 },
          rowSpan: 2,
        },
      ],

      [
        {
          content: salesText,
          styles: { fontSize: 10, fontStyle: "normal", textColor: "#616161" },
        },
      ],
    ],
    bodyStyles: {
      fontStyle: "bold",
      fontSize: 12,
      textColor: "#294F43",
      valign: "bottom",
      cellPadding: 0,
    },
    margin: {
      top: 10,
      left: 10,
      right: 10,
      bottom: 10,
    },
  });

  let y = (doc as any).lastAutoTable.finalY + 5;

  // Add Separating Line
  doc.setLineWidth(2);
  doc.setDrawColor("#CBCBCB");
  doc.line(10, y, 210 - 10, y, "S");

  y += 5;

  return y;
}
