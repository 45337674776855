import { DocumentSpec, ReportPDFProps } from "../../_importDocs";
import createPDF, { getTable } from "../components/defaults";
import getFooter from "../components/footer";

const AccountAuditTrail: DocumentSpec = {
  // must be unique
  id: "account-audit-trail",

  objectType: "report",

  // displayed to the user
  name: "Account Audit Trail",

  // when the PDF is downloaded, this function is called to generate the filename
  getFilename: (props) => {
    return `Account Audit Trail`;
  },

  // unused for sales...
  isAvailableFor: () => {
    return true;
  },

  jsPDF: (props: ReportPDFProps) => {
    const { reportData } = props;
    const { tables, title } = reportData;

    let { doc, bottomY, startY } = createPDF(props);

    for (const table of tables) {
      startY = getTable(doc, table, bottomY, startY);
    }

    getFooter(doc);

    return doc;
  },
};

export default AccountAuditTrail;
