import autoTable, {
  RowInput,
  ColumnInput,
  Styles,
  UserOptions,
} from "jspdf-autotable";
import { Line } from "@/pages/api/[marketId]/documents/reports/[reportId]";
import jsPDF from "jspdf";

interface Total {
  key: string;
  label: string;
  value: number;
}

export interface Column {
  dataKey?: string;
  content: string;
  colSpan?: number;
  rowSpan?: number;
  styles?: Partial<Styles>;
}

export type Table = {
  title: string; // the title of the table
  columns: {
    key: string;
    label: string;
    groupLabel?: string; // the group label
    totalLabel?: string; // override the column title if we want something different
  }[]; // the columns to display in the report
  lines: Line[]; //
  totals?: Total[];
  columnTotals?: {
    [key: string]: number;
  };
};

type Columns = Column[];

interface Totals {
  [key: string]: number;
}

function generateColumns(table: Table) {
  const data = table.columns;
  let hasGroup = false;
  let currentGroup = undefined;
  let startGroupIndex = 0;
  let currentGroupIndex = 0;
  const mainColumns: Column[] = [];
  const headerColumns: Column[] = [];
  const columns: Columns[] = [];
  const columnsKeys: ColumnInput[] = [];
  const linesIndex: number[] = [];

  for (let i = 0; i < data.length; i++) {
    const isLastColumn = i === data.length - 1;
    const column = data[i];
    currentGroupIndex = i;

    // if we have a group label, we need to add a new row
    if (column.groupLabel && !hasGroup) {
      hasGroup = true;
      currentGroup = column.groupLabel;
      startGroupIndex = i;

      if (i > 0) {
        headerColumns.push({
          content: "",
          colSpan: currentGroupIndex,
        });
        linesIndex.push(i);
      }
    }

    if (column.groupLabel !== currentGroup && hasGroup) {
      headerColumns.push({
        content: currentGroup ?? "",
        colSpan: currentGroupIndex - startGroupIndex,
      });

      currentGroup = column.groupLabel ?? undefined;
      startGroupIndex = i;
      linesIndex.push(i);
    }

    if (isLastColumn && hasGroup) {
      headerColumns.push({
        content: column.groupLabel ?? "",
        colSpan: currentGroupIndex - startGroupIndex + 1,
      });
    }

    mainColumns.push({
      dataKey: column.key,
      content: column.label,
      colSpan: 1,
    });
  }

  // Style Header Columns
  headerColumns.forEach((column, index) => {
    column.styles = {
      fillColor: "#294F43",
      textColor: "#ffffff",
      fontSize: 7,
      cellPadding: {
        top: 3,
        left: 2,
        right: 2,
        bottom: 3,
      },
    };

    // Content Uppercase
    column.content = column.content.toUpperCase();
  });

  mainColumns.forEach((column, index) => {
    column.styles = {
      fillColor: "#EFEFEF",
      textColor: "#616161",
      fontSize: 6,
      cellPadding: {
        top: 3,
        left: 2,
        right: 2,
        bottom: 3,
      },
    };

    // Remove the Group Label from the Column i.e Services:VAT (1) becomes VAT (1)
    if (column.content.includes(":")) {
      column.content = column.content.split(":")[1];
    }

    // Content Uppercase
    column.content = column.content.toUpperCase();

    // Add the column key to the columnsKeys array
    columnsKeys.push({ header: column.content, dataKey: column.dataKey ?? "" });
  });

  // If table add this to the columns
  if (table.title) {
    columns.push([
      {
        content: table.title,
        colSpan: mainColumns.length,
        styles: {
          textColor: "#294F43",
          cellPadding: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 2,
          },
        },
      },
    ]);
  }

  if (headerColumns.length > 0) {
    columns.push(headerColumns);
  }
  columns.push(mainColumns);

  return {
    columns,
    columnsKeys,
    linesIndex,
  };
}

function generateBody(table: Table) {
  const data = table.lines;
  const canceledRows: number[] = [];
  const body: RowInput[] = [];

  if (!data) {
    return {
      body,
      canceledRows,
    };
  }
  for (let i = 0; i < data.length; i++) {
    const row: RowInput = {};
    const rowValues = data[i].values;

    for (const key in rowValues) {
      const value = rowValues[key];
      row[key] = value;
    }

    if (data[i].isCancelled) {
      canceledRows.push(i);
    }

    // Skip empty rows
    if (Object.values(row).length === 0) continue;

    body.push(row);
  }

  return {
    body,
    canceledRows,
  };
}

function generateFooter(data: Totals) {
  const footer: RowInput = {};

  for (const key in data) {
    const value = data[key];
    footer[key] = value;
  }

  return footer;
}

function generateTotals(data: Total[]) {
  const totals: RowInput[] = [];

  for (let i = 0; i < data.length; i++) {
    const row: RowInput = {};

    const value = data[i].value;
    const label = data[i].label;

    totals.push([
      { content: label, styles: { textColor: "0E1513" } },
      { content: value, styles: { halign: "right" } },
    ]);
  }

  return totals;
}

export default function generateTable(
  doc: jsPDF,
  table: Table,
  bottomY: number = 0,
  y?: number
) {
  const head = generateColumns(table);
  const { body, canceledRows } = generateBody(table);
  const footer = generateFooter(table?.columnTotals ?? {});
  const totals = generateTotals(table.totals ?? []);
  const startY = y ?? undefined;

  let args: UserOptions = {
    theme: "plain",
    body: body,
    head: head.columns,
    columns: head.columnsKeys,
    startY: startY,
    margin: {
      top: 10,
      left: 10,
      right: 10,
      bottom: bottomY,
    },
    bodyStyles: {
      fillColor: "#ffffff",
      fontSize: 7,
      cellPadding: {
        top: 3,
        left: 2,
        right: 2,
        bottom: 3,
      },
    },
    footStyles: {
      fillColor: "#ffffff",
      textColor: "#616161",
      fontStyle: "bold",
      fontSize: 7,
      cellPadding: {
        top: 3,
        left: 2,
        right: 2,
        bottom: 3,
      },
    },
    didParseCell: function (data) {
      if (data.section === "foot" && data.column.index === 0) {
        // Add the total label
        data.cell.text = ["TOTALS"];
      }
    },
    willDrawCell: function (data) {
      const verticalLines = head.linesIndex;
      if (verticalLines.includes(data.column.index)) {
        doc.setDrawColor("#CBCBCB");
        doc.setLineWidth(1);
        doc.line(
          data.cell.x,
          data.cell.y,
          data.cell.x,
          data.cell.y + data.cell.height
        );
      }

      if (data.section === "body") {
        doc.setFillColor("#ffffff");
      }

      if (data.section === "foot") {
        doc.setTextColor("#0e1513");
        doc.setFillColor("#ffffff");
        doc.setDrawColor("#294F43");
        doc.setLineWidth(1);
        doc.line(
          data.cell.x,
          data.cell.y,
          data.cell.x + data.cell.width,
          data.cell.y
        );
        doc.line(
          data.cell.x,
          data.cell.y + data.cell.height,
          data.cell.x + data.cell.width,
          data.cell.y + data.cell.height
        );
      }
    },
    didDrawCell: function (data) {
      // Need to draw the lines manually

      if (data.section === "body") {
        // Add the horizontal lines
        doc.setDrawColor("#CBCBCB");
        doc.setLineWidth(1);
        doc.line(
          data.cell.x,
          data.cell.y + data.cell.height,
          data.cell.x + data.cell.width,
          data.cell.y + data.cell.height
        );

        // Add Srikethrough if needed

        if (canceledRows.includes(data.row.index)) {
          doc.setDrawColor("#FF0000");
          doc.setLineWidth(0.25);
          doc.line(
            data.cell.x,
            data.cell.y + data.cell.height / 2 - 0.5,
            data.cell.x + data.cell.width,
            data.cell.y + data.cell.height / 2 - 0.5
          );
        }
      }
    },
  };

  if (footer && Object.keys(footer).length > 0) {
    args = {
      ...args,
      foot: [footer],
    };
  }

  autoTable(doc, args);

  let currentY = (doc as any).lastAutoTable.finalY;

  if (totals) {
    totalsTable(doc, totals, bottomY);

    currentY = (doc as any).lastAutoTable.finalY;

    // Add spacer
    currentY += 5;
  }

  return currentY;
}

function totalsTable(doc: jsPDF, totals: RowInput[], bottomY: number = 10) {
  autoTable(doc, {
    theme: "plain",
    tableWidth: 70,
    body: totals,
    margin: {
      top: 10,
      left: 10,
      right: 10,
      bottom: bottomY,
    },
    bodyStyles: {
      fillColor: "#ffffff",
      fontStyle: "bold",
      fontSize: 7,
      cellPadding: {
        top: 3,
        left: 2,
        right: 2,
        bottom: 3,
      },
      lineColor: "#CBCBCB",
      lineWidth: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0.5,
      },
    },
  });
}

export { generateColumns, generateBody, generateFooter, generateTotals };
