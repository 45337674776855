import { Currency, Invoice, Lot } from "../types";
import { formatAsCurrency } from "./amounts";

export function calculateAverages(currency: Currency, lots: Lot[]) {
  let averages: Invoice["averages"] = [];

  let groups = {
    avgWeightKg: [],
    avgPpk: [],
    avgItemPrice: [],
  } as { [key: string]: Lot[] };

  for (let lot of lots) {
    if (lot.generated?.averageWeightKg) {
      groups.avgWeightKg.push(lot);
    }
    if (lot.generated?.pricePerKiloInCents) {
      groups.avgPpk.push(lot);
    }
    if (lot.generated?.pricePerItemInCents) {
      groups.avgItemPrice.push(lot);
    }
  }

  if (groups.avgWeightKg.length > 0) {
    let ls = groups.avgWeightKg;
    let totalItems = ls
      .map((l) => l.generated!.countOfItems!)
      .reduce((acc, cur) => acc + cur, 0);
    let totalWeight = ls.reduce(
      (acc, l) =>
        acc + l.generated!.averageWeightKg! * l.generated!.countOfItems!,
      0
    );

    let avgWeightKg = totalWeight / totalItems;
    avgWeightKg = Math.round(avgWeightKg * 100) / 100;
    if (!isNaN(avgWeightKg)) {
      averages.push({
        label: "Avg Weight (kg)",
        value: avgWeightKg,
        formattedValue: `${avgWeightKg} kg`,
      });
    }
  }

  if (groups.avgPpk.length > 0) {
    let ls = groups.avgPpk;
    let totalItems = ls
      .map((l) => l.generated!.countOfItems!)
      .reduce((acc, cur) => acc + cur, 0);
    let totalPpk = ls.reduce(
      (acc, l) =>
        acc + l.generated!.pricePerKiloInCents! * l.generated!.countOfItems!,
      0
    );

    let avgPpk = totalPpk / totalItems;
    avgPpk = Math.round(avgPpk * 100) / 100;
    if (!isNaN(avgPpk)) {
      averages.push({
        label: "Avg PPK",
        value: avgPpk,
        formattedValue: formatAsCurrency(currency, avgPpk, false) || "Unknown",
      });
    }
  }

  if (groups.avgItemPrice.length > 0) {
    let ls = groups.avgItemPrice;
    let totalItems = ls
      .map((l) => l.generated!.countOfItems!)
      .reduce((acc, cur) => acc + cur, 0);
    let totalItemPrice = ls.reduce(
      (acc, l) =>
        acc + l.generated!.pricePerItemInCents! * l.generated!.countOfItems!,
      0
    );
    let avgItemPrice = totalItemPrice / totalItems;
    avgItemPrice = Math.round(avgItemPrice * 100) / 100;
    if (!isNaN(avgItemPrice)) {
      averages.push({
        label: "Avg Item Price",
        value: avgItemPrice,
        formattedValue:
          formatAsCurrency(currency, avgItemPrice, false) || "Unknown",
      });
    }
  }

  return averages;
}
