import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import {
  FaceFrownIcon,
  GlobeAmericasIcon,
  RectangleStackIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { MapPinIcon } from "@heroicons/react/24/solid";
import { format, isToday } from "date-fns";
import { useRouter } from "next/router";
import { Fragment, useEffect, useRef, useState } from "react";
import { classNames } from "../data/classnames";
import { CustomerFromSearch, Lot, Sale, Address } from "../types";
import { CPHIcon, GavelIcon, HerdIcon, SheepIcon } from "./Icons";
import { useSearchDocuments } from "./SearchProvider";

interface QuickSearchHit {
  id: string;
  title: string | any;
  subtitle?: string | any;
  tooltip?: string;
  url: string;
  type: string;
  icon: any;
}

function createCustomerToolTipString(person: CustomerFromSearch): string {
  const address = person.address?.address as Address;
  const herdNumber = person.attributeDefaultsSeller?.herdNumberOrigin?.[0];
  const flockNumber = person.attributeDefaultsSeller?.flockNumberOrigin?.[0];
  const cphNumber = person.attributeDefaultsSeller?.cphNumberOrigin?.[0];

  let tooltip = "";
  if (address) {
    tooltip += Object.entries(address)
      .map(([key, value]) => value)
      .filter(Boolean)
      .join(", ");
  }

  if (cphNumber) {
    tooltip += `\nCPH: ${cphNumber}`;
  }

  if (herdNumber) {
    tooltip += `\nHerd Number: ${herdNumber}`;
  }

  if (flockNumber) {
    tooltip += `\nFlock Number: ${flockNumber}`;
  }

  return tooltip;
}

export function CommandPallet() {
  let router = useRouter();
  let [open, setOpen] = useState(false);
  let [documents, setDocuments] = useState([] as QuickSearchHit[]);
  let [query, setQuery] = useState("");

  let queryRef = useRef("");
  let search = useSearchDocuments();

  let inputRef = useRef<HTMLInputElement>(null); // Add this line to create a ref for the input

  useEffect(() => {
    if (open) {
      // Adding a timeout to delay focus until the transition ends
      const timer = setTimeout(() => {
        inputRef.current?.focus();
      }, 100); // Adjust the timeout duration if necessary

      return () => clearTimeout(timer); // Cleanup the timeout
    }
  }, [open]); // This effect depends on the 'open' state
  useEffect(() => {
    let q = query;

    if (q.length === 0) {
      setDocuments([]);
      return;
    }

    search<CustomerFromSearch | Lot | Sale>(q, {
      sort: ["updatedAt:desc"],
    }).then((results) => {
      if (queryRef.current === q) {
        setDocuments(
          results.hits.map((hit) => {
            let id = "";
            let title = "" as any;
            let subtitle = undefined;
            let tooltip = "";
            let url = "";
            let type = "";
            let icon = <UserIcon className="w-5 h-5  " />;

            // @ts-ignore
            switch (hit._type) {
              case "customer":
                let c = hit as CustomerFromSearch;
                id = c.id;

                const herdNumber =
                  c.attributeDefaultsSeller?.herdNumberOrigin?.[0];
                const flockNumber =
                  c.attributeDefaultsSeller?.flockNumberOrigin?.[0];
                const cphNumber =
                  c.attributeDefaultsSeller?.cphNumberOrigin?.[0];

                title = (
                  <div className="flex w-full items-center">
                    <div className="flex flex-col w-full ">
                      <div className="flex w-full gap-2 flex-row">
                        <div>
                          <span className="font-bold underline mr-2 capitalize">
                            {c.accountNumber?.padEnd(6, "")}
                          </span>
                          <span className="text-xs capitalize">
                            {c.firstName || ""} {c.lastName || ""}
                          </span>
                        </div>
                      </div>

                      <div className="flex gap-2 h-6 items-center">
                        {cphNumber && (
                          <div
                            title={`CPH Number: ${cphNumber}`}
                            className="flex justify-center items-center bg-martEye-100 text-martEye-400 text-xs font-medium px-1 rounded-full border border-martEye-400 border-opacity-50 shadow-sm h-5"
                          >
                            <CPHIcon className="mr-1 w-5 h-5" />
                            {cphNumber}
                          </div>
                        )}
                        {herdNumber && (
                          <div
                            title={`Herd Number: ${herdNumber}`}
                            className="flex justify-center items-center bg-martEye-100 text-martEye-400 text-xs font-medium px-1 rounded-full border border-martEye-400 border-opacity-50 shadow-sm h-5"
                          >
                            <HerdIcon height={20} className="mr-1" />
                            {herdNumber}
                          </div>
                        )}
                        {flockNumber && (
                          <div
                            title={`Flock Number: ${flockNumber}`}
                            className="flex justify-center items-center bg-martEye-100 text-martEye-400 text-xs font-medium px-1 rounded-full border border-martEye-400 border-opacity-50 shadow-sm h-5"
                          >
                            <SheepIcon height={20} className="mr-1" />
                            {flockNumber}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
                tooltip = createCustomerToolTipString(c);
                url = `#u=${c.id}`;
                type = "Customer";
                icon = <UserIcon className="w-5 h-5 " />;

                let address = c.address?.address;
                let movementNumber = cphNumber || "";

                let addr = address
                  ? [
                      address.company,
                      address.address2,
                      address.city,
                      address.city === address.province
                        ? undefined
                        : address.province,
                    ]
                      .filter(Boolean)
                      .join(", ")
                      .trim()
                  : "";
                subtitle = (
                  <div className="">
                    {addr.length ? (
                      <>
                        <div className="flex ">
                          <div className="w-6">
                            <MapPinIcon className="w-4 h-4 opacity-50" />
                          </div>
                          <div>
                            <span className="line-clamp-2 text-xs capitalize">
                              {addr}
                            </span>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                );
                break;
              case "lot":
                let l = hit as Lot;
                id = l.id;
                title = `Lot ${l.lotNumber || ""} - ${l.productCode || ""}`;
                url = `/${l.marketId}/sales/${l.saleId}#l=${l.id}`;
                type = "Lot";
                icon = <RectangleStackIcon className="w-5 h-5 " />;
                break;
              case "sale":
                let s = hit as Sale;
                id = s.id;
                title = `${s.name || "No Name"}`;

                let startsSeconds = s.startsAt;
                let date = new Date(startsSeconds * 1000);
                if (isToday(date)) {
                  subtitle = "Today";
                } else {
                  subtitle = format(date, "d MMM yyyy");
                }

                url = `/${s.marketId}/sales/${s.id}`;
                type = "Sale";
                icon = <GavelIcon className="w-5 h-5 " />;
                break;
            }

            return {
              id,
              title,
              subtitle,
              tooltip,
              url,
              type,
              icon,
            };
          })
        );
      }
    });
  }, [query, search]);

  // Open on ctrl + k or cmd + k
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        (event.metaKey || event.ctrlKey) &&
        (event.key === "k" || event.key === "K")
      ) {
        event.preventDefault();
        event.stopPropagation();

        setOpen(true);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const groups = documents.reduce((groups, item) => {
    let type = item.type as string;

    return {
      ...groups,
      [type]: [...(groups[type] || []), item],
    };
  }, {} as { [type: string]: QuickSearchHit[] });

  return (
    <Transition
      show={open}
      as={Fragment}
      afterLeave={() => {
        setQuery("");
        queryRef.current = "";
      }}
      appear
    >
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <DialogPanel className="mx-auto max-w-xl transform overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox
                onChange={(item: QuickSearchHit) => {
                  if (!item?.url) {
                    return null;
                  }
                  router.push(item.url);
                  setOpen(false);
                }}
              >
                <div className="relative">
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <ComboboxInput
                    ref={inputRef}
                    autoComplete="off"
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
                    placeholder="Search..."
                    onChange={(event) => {
                      setQuery(event.target.value);
                      queryRef.current = event.target.value;
                    }}
                  />
                </div>

                {query === "" && (
                  <div className="border-t border-gray-100 py-14 px-6 text-center text-sm sm:px-14">
                    <GlobeAmericasIcon
                      className="mx-auto h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                    <p className="mt-4 font-semibold text-gray-900">
                      Search for people, sales and invoices
                    </p>

                    <p className="mt-2 text-gray-500">
                      <kbd className="bg-gray-100 border border-gray-300 rounded px-1 py-px text-xs font-mono">
                        ctrl+k
                      </kbd>{" "}
                      opens this search bar
                    </p>
                  </div>
                )}

                {documents.length > 0 && (
                  <ComboboxOptions
                    static
                    className="max-h-80 scroll-pt-11 scroll-pb-2 space-y-2 overflow-y-auto pb-2"
                  >
                    {Object.entries(groups).map(([category, items]) => (
                      <li key={category} className="flex flex-col gap-2">
                        <h2 className="bg-gray-100 py-2.5 px-4 text-sm font-semibold text-gray-900">
                          {category}
                        </h2>
                        <ul className="mt-2 text-sm text-gray-800">
                          {/* @ts-ignore */}
                          {items.map((item) => (
                            <ComboboxOption
                              key={item.url}
                              value={item}
                              className={({ active }) =>
                                classNames(
                                  " border-t border-gray-200 cursor-default select-none px-4 py-2 flex flow- justify-between items-center",
                                  active &&
                                    "bg-martEye-400 text-white cursor-pointer"
                                )
                              }
                              title={item.tooltip}
                            >
                              {({ active }) => (
                                <>
                                  <div className="mr-2">{item.icon}</div>
                                  <div className="flex flex-row w-full">
                                    <div className="flex items-center justify-between w-full">
                                      {item.title}
                                    </div>
                                    {/* <div className="flex-grow"></div> */}
                                    <div
                                      className={classNames(
                                        "w-2/3",
                                        active
                                          ? " text-white/70"
                                          : "text-gray-800/70"
                                      )}
                                    >
                                      {item.subtitle}
                                    </div>
                                  </div>
                                </>
                              )}
                            </ComboboxOption>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ComboboxOptions>
                )}

                {query !== "" && documents.length === 0 && (
                  <div className="border-t border-gray-100 py-14 px-6 text-center text-sm sm:px-14">
                    <FaceFrownIcon
                      className="mx-auto h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                    <p className="mt-4 font-semibold text-gray-900">
                      No results found
                    </p>
                    <p className="mt-2 text-gray-500">
                      We couldn’t find anything with that term. Please try
                      again.
                    </p>
                  </div>
                )}
              </Combobox>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
}
