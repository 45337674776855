import { CurrentUserContext } from "@/components/CurrentUserProvider";
import { useFirebaseApp } from "@/data/studio-react/firebase/useFirebaseApp";
import { getAuth } from "firebase/auth";
import { useContext, useMemo } from "react";

export function useFirebaseAuth() {
  let app = useFirebaseApp();
  return useMemo(() => getAuth(app), [app]);
}

export function useCurrentUser() {
  return useContext(CurrentUserContext);
}

export function useCurrentUid() {
  let user = useCurrentUser();
  return user?.uid || null;
}
