import { HerdIcon, SheepIcon } from "@/components/Icons";
import { useOpenPersonFlyout } from "@/components/PersonFlyout/PersonFlyout";
import { classNames } from "@/data/classnames";
import { getDisplayName } from "@/data/customerUtils";
import { useStudioStream } from "@/data/studio-react/useStudioStream";
import { ShoppingCartIcon } from "@heroicons/react/24/solid";
export interface SellersCardProps {
  sellerCustomerId: string | null;
  showEdit?: boolean;
  classnames?: string;
  doNotOpenProfileOnClick?: boolean;
  hideExtraInfo?: boolean;
}

export const SellersCard = ({
  classnames,
  sellerCustomerId,
  doNotOpenProfileOnClick,
  hideExtraInfo,
}: SellersCardProps) => {
  let marketId = useStudioStream("session:marketId");

  let { openPersonFlyout } = useOpenPersonFlyout();

  let customer = useStudioStream("customer", marketId, sellerCustomerId);

  if (!sellerCustomerId || !customer) {
    // render a placeholder to prevent layout shift
    return (
      <div
        className={classNames(
          !doNotOpenProfileOnClick && "cursor-pointer",
          classnames
        )}
      >
        <div
          className={classNames(
            "w-full rounded-t-md p-4 text-xs text-white",
            classnames
          )}
        ></div>
      </div>
    );
  }

  let address = customer?.address?.address;
  let phoneNumbers = customer?.phoneNumber;

  let displayName = getDisplayName(customer);

  return (
    <div
      className={classNames(
        !doNotOpenProfileOnClick && "cursor-pointer",
        classnames
      )}
      onClick={() => {
        if (!doNotOpenProfileOnClick) {
          openPersonFlyout(sellerCustomerId, undefined, true);
        }
      }}
    >
      <div
        className={classNames(
          "w-full rounded-t-md bg-martEye-600  p-4 text-xs text-white",
          classnames
        )}
      >
        <h3 className="text-base font-bold text-martEye-400">{displayName}</h3>

        <address className="font-normal not-italic leading-tight ">
          {address?.address1 && (
            <>
              {address.address1}
              <br />
            </>
          )}
          {address?.address2 && (
            <>
              {address.address2}
              <br />
            </>
          )}
          {address?.city && (
            <>
              {address.city}
              <br />
            </>
          )}
          {address?.province && address?.city !== address?.province && (
            <>
              {address.province}
              <br />
            </>
          )}
          {address?.zip && (
            <>
              {address.zip}
              <br />
            </>
          )}
          {address?.country && (
            <>
              {address.country}
              <br />
            </>
          )}
        </address>
        {phoneNumbers && (
          <span className="mt-2 block font-bold">
            Tel. {phoneNumbers.phoneNumber}
          </span>
        )}
      </div>
      {!hideExtraInfo &&
        ["GB-ENG", "GB-SCT", "GB-WLS"].includes(address?.country ?? "") && (
          <div className="bg-martEye-500 px-4 py-2 text-white ">
            <div className="inline-flex gap-x-1.5">
              <span className="inline-flex items-center rounded-full bg-martEye-600 px-2 py-1 text-xs font-medium text-martEye-100">
                CPH
              </span>
              {customer.attributeDefaultsSeller?.cphNumberOrigin?.[0] ?? ""}
            </div>
          </div>
        )}
      <div className="flex justify-between rounded-b-md bg-martEye-400 text-white p-2">
        {!hideExtraInfo ? (
          <>
            {customer?.attributeDefaultsSeller?.flockNumberOrigin?.[0] ? (
              <div className="inline-flex items-center gap-x-1.5 text-sm">
                <SheepIcon height={20} />{" "}
                {customer?.attributeDefaultsSeller?.flockNumberOrigin?.[0] ??
                  "" ??
                  ""}
              </div>
            ) : (
              <div></div>
            )}
            {customer?.attributeDefaultsSeller?.herdNumberOrigin?.[0] ? (
              <div className="inline-flex items-center gap-x-1.5 text-sm">
                <HerdIcon height={20} />{" "}
                {customer?.attributeDefaultsSeller?.herdNumberOrigin?.[0] ??
                  "" ??
                  ""}
              </div>
            ) : (
              <div></div>
            )}

            <div className="inline-flex items-center gap-x-1.5 text-sm"></div>
          </>
        ) : (
          <div />
        )}
        {/* Does show when not clickable. e.g. overview */}
        {!doNotOpenProfileOnClick && (
          <div className="inline-flex items-center gap-x-1.5 text-sm border px-2 py-1 rounded-md border-white/30">
            <ShoppingCartIcon height={20}></ShoppingCartIcon>Cart
          </div>
        )}
      </div>
    </div>
  );
};

export default SellersCard;
