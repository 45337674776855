// Types
import { format } from "date-fns";
import { Timestamp } from "firebase/firestore";
import { Address, DraftInvoice, Invoice, Market } from "types";

// JS PDF
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { getTextDimensionsWithCharSpace } from "../../utils";

interface HeaderConfig {
  output: boolean;
  dateFormat: string;
  title: string;
  margin: number;
  textFillColor: string;
  logo?: string | null;
  market: Market;
}

export default function header(
  doc: jsPDF,
  invoice: Invoice | DraftInvoice,
  config: HeaderConfig
) {
  // We want to return the height of the page 1 header and the height of the page 2 header
  const mainHeaderHeight = mainHeader(doc, invoice, config);
  const runningHeaderHeight = runningHeader(doc, invoice, config);

  // Lets add this to the doc object
  doc.mainHeaderHeight = mainHeaderHeight;
  doc.runningHeaderHeight = runningHeaderHeight;

  // Run the running header function
  if (config.output) {
    // Run this for every page
    const pageCount = doc.getNumberOfPages();
    for (var i = 2; i <= pageCount; i++) {
      // Set the page
      doc.setPage(i);

      // Run the running header function
      runningHeader(doc, invoice, config);
    }
  }
}

function runningHeader(
  doc: jsPDF,
  invoice: Invoice | DraftInvoice,
  config: HeaderConfig
) {
  let runningHeaderHeight = 0;

  if (config.output && doc.getCurrentPageInfo().pageNumber == 1) {
    return runningHeaderHeight;
  }

  const issuedAt = invoice.issuedAt as Timestamp;
  const date = issuedAt.toDate();

  // Format the date
  const formattedDate = format(date, config.dateFormat).toString();

  // Split the title into multiple lines if needed
  const subTitle = `Invoice #${(invoice as Invoice).invoiceNumber}`;

  // Lets Start adding this to the page
  doc.setCharSpace(-0.5);

  doc.setFontSize(37);
  doc.setFont("Inter", "extrabold");
  doc.setCharSpace(-0.5);
  doc.setTextColor(config.textFillColor);
  doc.text(config.title, config.margin, config.margin, {
    align: "left",
    maxWidth: 120,
    baseline: "top",
  });

  // Need to get the height of the subtitle
  const titleHeight = doc.getTextDimensions(config.title);

  // Reset the char space
  doc.setCharSpace(0);

  // Add the subtitle and the date to the page
  doc.setFontSize(14);
  doc.setFont("Inter", "bold");
  doc.text(
    subTitle.trim(),
    doc.internal.pageSize.getWidth() - config.margin,
    config.margin,
    {
      align: "right",
      maxWidth: 60,
      baseline: "top",
    }
  );

  // Need to get the height of the subtitle
  const subTitleHeight = doc.getTextDimensions(subTitle);

  // Now add the date after the subtitle
  doc.text(
    formattedDate.trim(),
    doc.internal.pageSize.getWidth() - config.margin,
    config.margin + subTitleHeight.h + 1,
    {
      align: "right",
      maxWidth: 60,
      baseline: "top",
    }
  );

  const dateHeight = doc.getTextDimensions(formattedDate);

  // Reset the char space
  doc.setCharSpace(0);

  // Now we need to return the height of the header
  if (titleHeight.h > subTitleHeight.h + dateHeight.h) {
    runningHeaderHeight = titleHeight.h;
  } else {
    runningHeaderHeight = subTitleHeight.h + dateHeight.h;
  }

  if (!config.output) {
    // If we are not outputting the header, we need to delete the page we just created
    let pageNumber = doc.getCurrentPageInfo().pageNumber;
    doc.deletePage(pageNumber);
    doc.addPage();
  }

  // Lets now add 1.5 x the margin to the runningHeaderHeight
  runningHeaderHeight += config.margin * 1.5;

  return runningHeaderHeight;
}

function mainHeader(
  doc: jsPDF,
  invoice: Invoice | DraftInvoice,
  config: HeaderConfig
) {
  doc.setPage(1);

  // Market Header
  marketHeader(doc, invoice, config);

  // Customer Header
  customerHeader(doc, invoice, config);

  if (!config.output) {
    // If we are not outputting the header, we need to delete the page we just created
    let pageNumber = doc.getCurrentPageInfo().pageNumber;
    doc.deletePage(pageNumber);
    doc.addPage();
  }

  return doc.lastAutoTable.finalY;
}

function marketHeader(
  doc: jsPDF,
  invoice: Invoice | DraftInvoice,
  config: HeaderConfig
) {
  let size = 0;

  if (config.logo) {
    size = 30;
    doc.addImage(config.logo, "PNG", config.margin, config.margin, size, size);
  }
  // Market Title
  const title = config.title;
  const subTitle = `Invoice #${(invoice as Invoice).invoiceNumber}`;
  const marketTitle = config.market.name;

  const issuedAt = invoice.issuedAt as Timestamp;
  const date = issuedAt.toDate();

  // Format the date
  const formattedDate = format(date, config.dateFormat).toString();

  // Config
  const left = config.margin + size + 5;
  const right = config.margin;
  const cellWidth = (doc.internal.pageSize.getWidth() - left - right) / 10;

  const marketAddress = config.market.address
    ? (config.market.address as Address)
    : null;

  const address = [
    marketAddress?.address2 ?? "",
    marketAddress?.city ?? "",
    marketAddress?.province ?? "",
    marketAddress?.zip ?? "",
    marketAddress?.country ?? "",
  ];

  const misc = [
    config.market?.telephone ? "Tel: " + config.market.telephone : "",
    config.market?.email ? "Email: " + config.market.email : "",
    config.market?.vatNumber ? "VAT No: " + config.market.vatNumber : "",
  ];

  const usedMarketAddress = address.filter((line) => line !== "");
  const usedmiscLines = misc.filter((line) => line !== "");

  autoTable(doc, {
    startY: config.margin,
    head: [
      [
        {
          content: marketTitle,
          colSpan: 4,
        },
        {
          content: `${subTitle}`,
          styles: { halign: "right" },
          colSpan: 6,
        },
      ],
    ],
    body: [
      [
        {
          content: usedMarketAddress.join("\n"),
          colSpan: 2,
        },
        {
          content: usedmiscLines.join("\n"),
          colSpan: 2,
        },
        //@ts-expect-error
        {
          content: title,
          colSpan: 6,
          styles: {
            halign: "right",
            fontSize: 50,
            fontStyle: "extrabold",
            font: "Inter",
            textColor: config.textFillColor,
          },
        },
      ],
    ],
    headStyles: {
      fontSize: 16,
      //@ts-expect-error
      fontStyle: "extrabold",
      font: "Inter",
      cellPadding: { top: 2, bottom: 2, left: 0, right: 0 },
    },
    bodyStyles: {
      cellPadding: { top: 0, bottom: 0, left: 0, right: 0 },
    },
    theme: "plain",
    margin: { left: left, top: config.margin, right: right },
    columnStyles: {
      0: { cellWidth: cellWidth },
      1: { cellWidth: cellWidth },
      2: { cellWidth: cellWidth },
      3: { cellWidth: cellWidth },
      4: { cellWidth: cellWidth },
      5: { cellWidth: cellWidth },
      6: { cellWidth: cellWidth },
      7: { cellWidth: cellWidth },
      8: { cellWidth: cellWidth },
      9: { cellWidth: cellWidth },
    },
    willDrawCell: (data) => {
      if (data.section === "body" && data.column.index === 4) {
        doc.setCharSpace(-0.75);

        const pageWidth = doc.internal.pageSize.getWidth();
        // need to get the width of the text
        const textWidth = getTextDimensionsWithCharSpace(
          doc,
          data.cell.text[0],
          -0.75
        ).width;

        doc.text(
          data.cell.text[0],
          pageWidth - config.margin - textWidth,
          data.cell.y - 2,
          {
            align: "left",
            baseline: "top",
          }
        );

        // remove the text
        data.cell.text = [""];
      }
    },
  });

  // Reset the char space
  doc.setCharSpace(0);
}

function customerHeader(
  doc: jsPDF,
  invoice: Invoice | DraftInvoice,
  config: HeaderConfig
) {
  const cellWidth = (doc.internal.pageSize.getWidth() - 2 * config.margin) / 12;

  const issuedAt = invoice.issuedAt as Timestamp;
  const date = issuedAt.toDate();

  // Format the date
  const formattedDate = format(date, config.dateFormat).toString();

  const misc = [invoice.customerAccountNumber];

  const flockNumberDestination =
    invoice.attributeValues?.["flockNumberDestination"] ?? "";

  const cphNumberDestination =
    invoice.attributeValues?.["cphNumberDestination"] ?? "";

  const destinationMovementNumber = cphNumberDestination
    ? `CPH: ${cphNumberDestination}`
    : `Flock No. ${flockNumberDestination}`;

  misc.push(destinationMovementNumber);

  const customerAddress = [
    invoice.address.company ? invoice.address.company : "",
    invoice.address.address1 ? invoice.address.address1 : "",
    invoice.address.address2 ? invoice.address.address2 : "",
    invoice.address.city ? invoice.address.city : "",
    invoice.address.province ? invoice.address.province : "",
    invoice.address.zip ? invoice.address.zip : "",
    invoice.address.country ? invoice.address.country : "",
  ];
  const usedCustomerAddress = customerAddress.filter(
    (line) => line !== "" && line !== "-"
  );
  const customerAddressLines = usedCustomerAddress.join(", ");
  misc.push(customerAddressLines);

  const usedMiscLines = misc.filter((line) => line !== "");
  const miscLines = usedMiscLines.join("\n");

  autoTable(doc, {
    head: [
      [
        // @ts-expect-error
        {
          content: invoice.name,
          colSpan: 8,
          styles: {
            fontSize: 24,
            fontStyle: "extrabold",
          },
        },
        {
          content: "",
          colSpan: 4,
        },
      ],
    ],
    body: [
      [
        {
          content: miscLines,
          colSpan: 8,
          styles: {
            textColor: "#616161",
          },
        },
        {
          content: formattedDate,
          colSpan: 4,
          styles: {
            halign: "right",
            valign: "bottom",
            fontSize: 14,
            fontStyle: "bold",
          },
        },
      ],
    ],
    columnStyles: {
      0: { cellWidth: cellWidth },
      1: { cellWidth: cellWidth },
      2: { cellWidth: cellWidth },
      3: { cellWidth: cellWidth },
      4: { cellWidth: cellWidth },
      5: { cellWidth: cellWidth },
      6: { cellWidth: cellWidth },
      7: { cellWidth: cellWidth },
      8: { cellWidth: cellWidth },
      9: { cellWidth: cellWidth },
      10: { cellWidth: cellWidth },
      11: { cellWidth: cellWidth },
    },
    headStyles: {
      font: "Inter",
      cellPadding: { top: 0, bottom: 2, left: 0, right: 0 },
    },
    bodyStyles: {
      cellPadding: { top: 0, bottom: 4, left: 0, right: 0 },
    },
    theme: "plain",
    margin: { left: config.margin, right: config.margin },
  });
}
