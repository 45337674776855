import { classNames } from "@/data/classnames";
import { AnimatePresence, motion } from "framer-motion";
import * as React from "react";
import { Arrow, useHover, useLayer } from "react-laag";

interface ToolTipProps {
  children: React.ReactNode | any;
  text: string;
  disabled?: boolean;
  keyboardShortcut?: string;
  backgroundColor?: string;
}

export function Tooltip(props: ToolTipProps) {
  let { children, text, backgroundColor, keyboardShortcut } = props;
  // We use `useHover()` to determine whether we should show the tooltip.
  // Notice how we're configuring a small delay on enter / leave.
  let [isOver, hoverProps] = useHover({ delayEnter: 100, delayLeave: 300 });

  // Tell `useLayer()` how we would like to position our tooltip
  let { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen: isOver,
    placement: "top-center",
    triggerOffset: 8, // small gap between wrapped content and the tooltip
    auto: true,
  });

  // when children equals text (string | number), we need to wrap it in an
  // extra span-element in order to attach props
  let trigger;
  if (isReactText(children)) {
    trigger = (
      // tooltip-text-wrapper
      <span className="" {...triggerProps} {...hoverProps}>
        {children}
      </span>
    );
  } else {
    // In case of an react-element, we need to clone it in order to attach our own props
    trigger = React.cloneElement(children, {
      ...triggerProps,
      ...hoverProps,
    });
  }

  if (props.disabled) {
    return children;
  }

  // We're using framer-motion for our enter / exit animations.
  // This is why we need to wrap our actual tooltip inside `<AnimatePresence />`.
  // The only thing left is to describe which styles we would like to animate.
  return (
    <>
      {trigger}
      {renderLayer(
        <AnimatePresence>
          {isOver && (
            <motion.div
              className={classNames(
                "text-white text-sm rounded p-2 text-right z-50",
                backgroundColor ? backgroundColor : "bg-black/80",
                keyboardShortcut && "inline-flex items-center gap-x-2 px-4"
              )}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.1 }}
              {...layerProps}
            >
              {/* replace \n with <br /> in text; */}
              {text.split("\n").map((str, i) => (
                <React.Fragment key={i}>
                  {str}
                  <br />
                </React.Fragment>
              ))}

              {keyboardShortcut && (
                <span className="border border-white text-xs rounded px-2 py-0.5 text-center">
                  {keyboardShortcut}
                </span>
              )}

              <Arrow
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                {...arrowProps}
                backgroundColor={
                  backgroundColor ? backgroundColor : "rgba(0, 0, 0, 0.8)"
                }
                borderColor={
                  backgroundColor ? backgroundColor : "rgba(0, 0, 0, 0.8)"
                }
                borderWidth={1}
                size={6}
              />
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </>
  );
}

function isReactText(children: any) {
  return ["string", "number"].includes(typeof children);
}
