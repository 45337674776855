import {
  useFirestore,
  useLoadFirestoreDoc,
} from "@/data/studio-react/firebase/useFirestore";
import { useMarketId } from "@/data/useMartketId";
import { doc } from "firebase/firestore";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Customer } from "types";
import { useStudioStream } from "./useStudioStream";

export const SaleCustomersContext = React.createContext<Customer[]>([]);

// Brings all the customers that are in the sale into the context
export function SaleDataCustomersProvider(props: {
  children: React.ReactNode;
}) {
  let lotsInfo = useStudioStream("sale:lots-info");

  // the customerId is the key, the value is an array of lotIds
  let [customerIdsMap, setCustomerIdsMap] = useState<{
    [customerId: string]: Set<string>;
  }>({});

  let [customerToId, setCustomerToId] = useState<Map<string, Customer>>(
    new Map()
  );

  // efficiently keeps track of customer IDs present in the sale
  useEffect(() => {
    if (!lotsInfo.changes) {
      return;
    }

    setCustomerIdsMap((prev) => {
      let newIds = { ...prev };

      let customerIdsDeleted: string[] = [];

      lotsInfo.changes!.added.forEach((change) => {
        if (change.item.sellerCustomerId) {
          let lotIds =
            newIds[change.item.sellerCustomerId] || new Set<string>();
          lotIds.add(change.item.id);
          newIds[change.item.sellerCustomerId] = lotIds;
        }
        if (change.item.buyerCustomerId) {
          let lotIds = newIds[change.item.buyerCustomerId] || new Set<string>();
          lotIds.add(change.item.id);
          newIds[change.item.buyerCustomerId] = lotIds;
        }
      });

      lotsInfo.changes?.modified.forEach((change) => {
        let oldItem = lotsInfo.changes.prevItems[change.oldIndex] || {};
        let item = change.item;
        if (oldItem?.sellerCustomerId !== item?.sellerCustomerId) {
          if (oldItem.sellerCustomerId) {
            let lotIds = newIds[oldItem.sellerCustomerId];
            lotIds.delete(item.id);
            customerIdsDeleted.push(oldItem.sellerCustomerId);
          }
          if (item.sellerCustomerId) {
            let lotIds = newIds[item.sellerCustomerId] || new Set<string>();
            lotIds.add(item.id);
            newIds[item.sellerCustomerId] = lotIds;
          }
        }

        if (oldItem.buyerCustomerId !== item.buyerCustomerId) {
          if (oldItem.buyerCustomerId) {
            let lotIds = newIds[oldItem.buyerCustomerId];
            lotIds.delete(item.id);
            customerIdsDeleted.push(oldItem.buyerCustomerId);
          }
          if (item.buyerCustomerId) {
            let lotIds = newIds[item.buyerCustomerId] || new Set<string>();
            lotIds.add(item.id);
            newIds[item.buyerCustomerId] = lotIds;
          }
        }
      });

      lotsInfo.changes?.removed.forEach((change) => {
        let item = change.item;
        if (item.sellerCustomerId) {
          let lotIds = newIds[item.sellerCustomerId];
          lotIds.delete(item.id);
          customerIdsDeleted.push(item.sellerCustomerId);
        }
        if (item.buyerCustomerId) {
          let lotIds = newIds[item.buyerCustomerId];
          lotIds.delete(item.id);
          customerIdsDeleted.push(item.buyerCustomerId);
        }
      });

      customerIdsDeleted.forEach((customerId) => {
        if (newIds[customerId]?.size === 0) {
          delete newIds[customerId];
        }
      });

      return newIds;
    });
  }, [lotsInfo.changes]);

  let uniqCustomerIds = useMemo(() => {
    return Object.keys(customerIdsMap).sort();
  }, [customerIdsMap]);

  let customers = useMemo(() => {
    return uniqCustomerIds
      .map((customerId) => {
        return customerToId.get(customerId);
      })
      .filter((customer): customer is Customer => {
        return customer !== undefined;
      });
  }, [uniqCustomerIds, customerToId]);

  let setCustomer = useCallback((customer: Customer) => {
    setCustomerToId((prev) => {
      let newMap = new Map(prev);
      newMap.set(customer.id, customer);
      return newMap;
    });
  }, []);

  let removeCustomer = useCallback((customerId: string) => {
    setCustomerToId((prev) => {
      let newMap = new Map(prev);
      newMap.delete(customerId);
      return newMap;
    });
  }, []);

  return (
    <>
      <SaleCustomersContext.Provider value={customers}>
        {props.children}
      </SaleCustomersContext.Provider>

      {uniqCustomerIds.map((customerId) => (
        <CustomerLoaderMemo
          key={customerId}
          customerId={customerId}
          setCustomer={setCustomer}
          removeCustomer={removeCustomer}
        />
      ))}
    </>
  );
}

function CustomerLoader(props: {
  customerId: string;
  setCustomer: (customer: Customer) => void;
  removeCustomer: (customerId: string) => void;
}) {
  let firestore = useFirestore();
  let marketId = useMarketId();
  let customerId = props.customerId;

  let docQuery = useMemo(() => {
    return doc(firestore, `markets/${marketId}/customers/${customerId}`);
  }, [firestore, marketId, customerId]);

  let customerLoad = useLoadFirestoreDoc<Customer>(docQuery);

  useEffect(() => {
    if (customerLoad.data) {
      props.setCustomer(customerLoad.data);
    }
  }, [customerLoad.data, props.setCustomer]);

  useEffect(() => {
    return () => {
      if (props.removeCustomer) {
        props.removeCustomer(props.customerId);
      }
    };
  }, [props.removeCustomer, props.customerId]);

  return null;
}

const CustomerLoaderMemo = React.memo(CustomerLoader);
