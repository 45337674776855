import { Button } from "@/components/Buttons";
import { classNames } from "@/data/classnames";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { PlusCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { Field, Form, Formik, useFormikContext } from "formik";
import { Fragment, useEffect, useMemo, useRef } from "react";
import * as Yup from "yup";
import { ClientType, CurrenciesWithGuinea } from "../../types";
import SelectField from "./fields/SelectField";
import { TextField } from "./fields/TextField";
import CustomerLookupCombobox from "@/components/form/CustomerLook";

import { formatAsCurrency } from "@/data/amounts";
import { useStudioStream } from "@/data/studio-react/useStudioStream";
import { useCreateCartItem } from "./useCreateCartItem";
import { CustomerItem } from "../sale-page/useCustomerSearch";

interface AddCartProductDialogProps {
  currency: CurrenciesWithGuinea;
  open: boolean;
  setOpen: (open: boolean) => void;
  cartType: ClientType;
  marketId: string | null | undefined;
  customerId: string | null;
  currentUid: string | null | undefined;
}

const CreateCartItemSchema = Yup.object().shape({
  unitPriceInPounds: Yup.number().min(1, "Value must be at least 1"),
  quantity: Yup.number().min(1, "Quantity must be at least 1"),
  productId: Yup.string().required("Please select a product"),
});

interface FormValues {
  unitPriceInPounds: number;
  quantity: number;
  productId: string;
  passthroughFundsToCustomer: CustomerItem | null;
}

const SubmitButton = (props: {
  isValid: boolean;
  isSubmitting: boolean;
  formattedTotal: string | null;
}) => {
  const { isValid, isSubmitting } = props;
  const { submitForm } = useFormikContext();

  const handleSubmit = () => {
    submitForm();
  };

  let isDisabled = !isValid || props.isSubmitting;

  return (
    <Button
      type="button"
      onClick={isValid ? handleSubmit : null}
      isDisabled={isDisabled}
      isLoading={isSubmitting}
      className={classNames(
        "bg-martEye-400 text-martEye-100 disabled:opacity-50"
      )}
    >
      Add to Cart {props.formattedTotal ? `(${props.formattedTotal})` : null}
    </Button>
  );
};

export function AddCartItemDialog(props: AddCartProductDialogProps) {
  const { open, setOpen, cartType, customerId, currency } = props;
  const cancelButtonRef = useRef(null);

  const createCartItem = useCreateCartItem();

  let products = useStudioStream("sale:products");

  const onSubmit = async (values: FormValues) => {
    if (!customerId) {
      return;
    }

    try {
      let passthroughFundsToCustomerId = null;
      if (values.passthroughFundsToCustomer) {
        passthroughFundsToCustomerId = values.passthroughFundsToCustomer.id;
      }

      await createCartItem(
        customerId,
        cartType,
        values.productId,
        values.quantity,
        Math.round(values.unitPriceInPounds * 100),
        passthroughFundsToCustomerId
      );

      setOpen(false);
    } catch (e) {
      console.error("Error adding document: ", e);
      return false;
    }
  };

  const productOptions = useMemo(() => {
    // Filter out products that are not enabled
    products = products.filter((product) => product.isEnabled === true);

    return products.map((product) => {
      return {
        label: product.name,
        value: product.id,
      };
    });
  }, [products]);

  const defaultValues: FormValues = {
    unitPriceInPounds: 1,
    quantity: 1,
    productId: "",
    passthroughFundsToCustomer: null,
  };

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-70"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-martEye-700 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left text-martEye-700 shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="flex items-start justify-between">
                    <DialogTitle
                      as="h3"
                      className="inline-flex items-center gap-x-1.5 text-xl font-bold"
                    >
                      <PlusCircleIcon className="h-6 w-6 text-martEye-400" />
                      Extras
                    </DialogTitle>

                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        className="rounded-full bg-martEye-500 p-1 text-martEye-100  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-martEye-400 hover:bg-martEye-400"
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>

                  <div className="mt-4">
                    <Formik
                      initialValues={defaultValues}
                      onSubmit={onSubmit}
                      validationSchema={CreateCartItemSchema}
                      validateOnMount={true}
                    >
                      {({
                        values,
                        setFieldValue,
                        getFieldMeta,
                        isValid,
                        isSubmitting,
                      }) => {
                        const productId = values.productId;

                        useEffect(() => {
                          // if the product changes we need to change the default price -
                          if (values.productId) {
                            let product = products.find(
                              (product) => product.id === values.productId
                            );
                            if (product) {
                              setFieldValue(
                                "unitPriceInPounds",
                                Math.round(
                                  product.defaultUnitPriceInCents / 100
                                )
                              );
                            }
                          }
                        }, [productId]);

                        useEffect(() => {
                          // If the product changes we need to change the pass through funds
                          let product = products.find(
                            (product) => product.id === values.productId
                          );
                          if (!product?.passThroughFundsToAnotherCustomer) {
                            setFieldValue("passthroughFundsToCustomer", null);
                          }
                        }, [productId, products, setFieldValue, getFieldMeta]);

                        let totalCents =
                          values.unitPriceInPounds * 100 * values.quantity;

                        let formattedTotal = formatAsCurrency(
                          currency,
                          totalCents
                        );
                        if (totalCents === 0) {
                          formattedTotal = null;
                        }

                        const selectedProduct = products.find(
                          (product) => product.id === values.productId
                        );

                        return (
                          <Form className="flex flex-wrap gap-2">
                            <Field
                              name="productId"
                              component={SelectField}
                              label={
                                cartType === "Buyer"
                                  ? "Select Addition"
                                  : "Select Deduction"
                              }
                              options={productOptions}
                            />

                            {selectedProduct?.passThroughFundsToAnotherCustomer && (
                              <>
                                <CustomerLookupCombobox
                                  label={`Passthrough Funds To ${
                                    cartType === "Buyer" ? "Customer" : "Seller"
                                  }`}
                                  customerType={cartType}
                                  value={values.passthroughFundsToCustomer}
                                  onChange={async (value) => {
                                    setFieldValue(
                                      "passthroughFundsToCustomer",
                                      value ?? null
                                    );
                                  }}
                                  disallowCasual={true}
                                />
                              </>
                            )}

                            <div className="flex w-full gap-4">
                              <Field
                                name="unitPriceInPounds"
                                component={TextField}
                                label={`Value (${currency})`}
                                type="number"
                              />

                              <Field
                                name="quantity"
                                component={TextField}
                                label="Quantity"
                                type="number"
                                step="1"
                              />
                            </div>

                            <div className="mt-2">
                              <SubmitButton
                                isValid={isValid}
                                isSubmitting={isSubmitting}
                                formattedTotal={formattedTotal}
                              />
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default AddCartItemDialog;
