import { useCallback, useMemo } from "react";
import { ClientType, Lot } from "types";
import { useFlyout } from "../Flyout/useFlyout";

const urlParam = "co";
const urlParamLotIdents = "colot";

export type OpenCheckoutSheetParams = {
  clientType: ClientType;
  lots: Lot[];
};

export function useCheckoutSheet() {
  let [isFlyoutOpen, openFlyout, closeFlyout, flyoutParameters] = useFlyout([
    urlParam,
    urlParamLotIdents,
  ]);

  let openCheckoutSheet = useCallback(
    (info: OpenCheckoutSheetParams, clearOthers = false) => {
      let params = null as any;

      if (info.clientType) {
        params = {
          [urlParam]: info.clientType,
        };
      }

      if ("lots" in info) {
        let { lots } = info;
        if (lots) {
          params[urlParamLotIdents] = lots
            .map((l) => `${l.saleId}-${l.id}`)
            .join("_");
        }
      }

      openFlyout(params, clearOthers);
    },
    [openFlyout, flyoutParameters]
  );

  return useMemo(() => {
    let checkoutSheetParams = null as null | {
      clientType: ClientType | null;
      lotIdents: { saleId: string; lotId: string }[];
      saleIds: string[];
    };

    if (isFlyoutOpen) {
      let possibleClientType = flyoutParameters?.[urlParam] ?? null;
      let lotIdentsStr = flyoutParameters?.[urlParamLotIdents] ?? null;

      let lotIdents = [] as { saleId: string; lotId: string }[];
      let saleIds = new Set<string>();

      if (lotIdentsStr) {
        lotIdents = lotIdentsStr.split("_").map((encoded) => {
          let [saleId, lotId] = encoded.split("-");
          saleIds.add(saleId);
          return { saleId, lotId };
        });
      }

      let clientType = "Buyer" as ClientType;
      if (possibleClientType === "Seller" || possibleClientType === "Buyer") {
        clientType = possibleClientType;
      }

      checkoutSheetParams = {
        clientType,
        lotIdents,
        saleIds: Array.from(saleIds),
      };
    }

    return {
      isCheckoutSheetOpen: isFlyoutOpen,
      openCheckoutSheet,
      closeCheckoutSheet: () => closeFlyout(false),
      checkoutSheetParams,
    };
  }, [closeFlyout, flyoutParameters, isFlyoutOpen, openCheckoutSheet]);
}
