import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import type { Active } from "@dnd-kit/core";

import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { AttributeValueType, AttributeDefinition } from "types";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

// Updates
import { useCallback } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Button } from "@/components/Buttons";
import { useShowDialog } from "@/components/ConfirmationDialogProvider";
import { useState, useMemo } from "react";

// Toast

const DefaultsTextField = ({
  attribute,
  values,
  setValues,
}: {
  attribute: AttributeDefinition;
  values: string[];
  setValues: (values: AttributeValueType[]) => void;
}) => {
  // Handle input change
  const handleChange = (index: number, value: string) => {
    const newValues: string[] = [...values];
    newValues[index] = value;

    if (value === "") {
      newValues.splice(index, 1);
    }

    setValues(newValues);
  };

  const handleBlur = useCallback(
    (value: string) => {
      if (value === "") {
        return;
      }
      let newValues = [...values, value];
      setValues(newValues);
    },
    [values, setValues]
  );

  // Sortable
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  let sortable = values.length > 1;

  const [active, setActive] = useState<Active | null>(null);
  const activeItem = useMemo(
    () => values.find((value) => value === active?.id),
    [active, values]
  );

  return (
    <div>
      <DndContext
        sensors={sensors}
        onDragStart={({ active }) => {
          setActive(active);
        }}
        onDragEnd={({ active, over }) => {
          if (over && active.id !== over?.id) {
            const activeIndex = values.indexOf(active.id as string);
            const overIndex = values.indexOf(over.id as string);
            setValues(arrayMove(values, activeIndex, overIndex));
          }
          setActive(null);
        }}
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
        onDragCancel={() => {
          setActive(null);
        }}
      >
        <SortableContext items={values} strategy={verticalListSortingStrategy}>
          {values.map((value, index) => (
            <SortableItem
              key={index}
              index={index}
              value={value}
              attribute={attribute}
              handleChange={handleChange}
              sortable={sortable}
            />
          ))}
        </SortableContext>
      </DndContext>

      <div className="mt-2 flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-martEye-400">
        <input
          type="text"
          className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 min-h-[40px] pl-3"
          placeholder="Add new value"
          onBlur={(e) => {
            let value = e.target.value;
            handleBlur(value);
            // Clear the input
            e.target.value = "";
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              (event.target as HTMLInputElement).blur(); // This will remove focus from the input field
            }
          }}
        />
      </div>
    </div>
  );
};

const SortableItem = ({
  index,
  value,
  attribute,
  sortable,
  handleChange,
}: {
  index: number;
  attribute: AttributeDefinition;
  sortable: boolean;
  value: string;
  handleChange: (index: number, value: string) => void;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: value });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  let { showDialog } = useShowDialog();

  return (
    <div ref={setNodeRef} style={style} className="flex gap-x-2 mt-2">
      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-martEye-400 bg-white grow">
        <input
          key={index}
          type="text"
          className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 min-h-[40px] pl-3"
          value={value}
          onChange={(event) => handleChange(index, event.target.value)}
        />

        {sortable && (
          <button
            {...listeners}
            {...attributes}
            className="flex select-none items-center pr-3 text-gray-500 sm:text-sm"
          >
            <svg
              viewBox="0 0 20 20"
              className="h-4 w-4 overflow-visible fill-current"
            >
              <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
            </svg>
          </button>
        )}
      </div>

      <Button
        variant="danger"
        className="z-10"
        onClick={() => {
          // Add a are you sure dialog
          showDialog(
            `Remove ${attribute.name} default value?`,
            "This will be permanently removed. Are you sure?",
            "Delete",
            () => {
              handleChange(index, "");
            },
            "Cancel",
            () => {},
            "danger"
          );
        }}
      >
        <XMarkIcon className="h-4 w-4" />
      </Button>
    </div>
  );
};

export default DefaultsTextField;
