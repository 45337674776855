import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";

import { classNames } from "@/data/classnames";
import { ClientType } from "types";

interface CheckoutSheetHeaderProps {
  clientType?: ClientType;
  close: () => void;
}

export function CheckoutSheetHeader(props: CheckoutSheetHeaderProps) {
  let { clientType, close } = props;

  let title = `Create ${clientType === "Buyer" ? "Invoice" : "Statement"}`;

  return (
    <div className="relative px-6 py-5 flex flex-col justify-start bg-martEye-700">
      <div className="flex flex-row items-start">
        <div className="inline-flex items-start gap-x-2 flex-col">
          <Dialog.Title
            as="h3"
            className={classNames(
              "inline-flex items-center gap-x-1.5 text-2xl font-bold text-white"
            )}
          >
            {title}
          </Dialog.Title>
          <div className="inline-flex items-center justify-center px-2 py-1 text-xs font-bold bg-[#FFCBCB] text-[#FF0000] rounded-full">
            DRAFT
          </div>
        </div>

        <div className="flex flex-grow flex-row items-center justify-end space-x-4">
          <button
            type="button"
            className="flex h-9 w-9 items-center justify-center rounded-full border border-white/40 text-white  hover:bg-white/10"
            onClick={close}
          >
            <XMarkIcon className="h-4 w-4" />
          </button>
        </div>
      </div>
    </div>
  );
}
