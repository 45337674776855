import { formatAsCurrency } from "@/data/amounts";
import { classNames } from "@/data/classnames";
import { useStudioStream } from "@/data/studio-react/useStudioStream";
import { useMarketId } from "@/data/useMartketId";
import { BanknotesIcon } from "@heroicons/react/20/solid";
import { PrinterIcon } from "@heroicons/react/24/solid";
import { ReactNode, useEffect, useState } from "react";
import { Address, Customer, Invoice, Payment, Payout } from "types";
import { useOpenPersonFlyout } from "../PersonFlyout/PersonFlyout";
import { CheckoutInfo } from "../checkout-sheet-draft/CheckoutInfo";
import { LineItemTable } from "../checkout-sheet-draft/LineItemTable";
import SlideUpSheet from "../checkout-sheet-draft/SlideUpSheet";
import { SummaryPanel } from "../checkout-sheet-draft/SummaryPanel";
import { costructCheckoutGroups } from "../checkout-sheet-draft/checkoutGroups";
import { DocumentDialog } from "../document-print-dialog/DocumentDialog";
import { usePosMonitor } from "../pos-monitor/PosMonitorProvider";
import { InvoiceActionButton } from "./InvoiceActionButton";
import { InvoiceSheetHeader } from "./InvoiceSheetHeader";
import { PaymentDialog } from "./PaymentDialog";
import { PayoutDialog } from "./PayoutDialog";
import { useInvoiceSheet } from "./useInvoiceSheet";

function InvoiceSheet() {
  let {
    closeInvoiceSheet,
    invoiceId,
    showDocumentDialog: showDocumentDialogOnOpen,
  } = useInvoiceSheet();

  let marketId = useMarketId();
  let market = useStudioStream("market", marketId);
  let invoice = useStudioStream("invoice", marketId, invoiceId);
  let customer = useStudioStream("customer", marketId, invoice?.customerId);
  let payouts = useStudioStream("payouts", marketId, {
    invoiceIds: invoiceId ? [invoiceId] : null,
  });
  let payments = useStudioStream("payments", marketId, {
    invoiceIds: invoiceId ? [invoiceId] : null,
  });
  let printers = useStudioStream("printers", marketId);
  let [printDialogOpen, setPrintDialogOpen] = useState(
    showDocumentDialogOnOpen
  );
  let [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  let [payoutDialogOpen, setPayoutDialogOpen] = useState(false);

  let cxTrdReceivable = useStudioStream("balance", marketId, {
    account:
      invoice?.customerId && `${invoice?.customerId}:asset:trade receivable`,
  });
  let cxTrdPayable = useStudioStream("balance", marketId, {
    account:
      invoice?.customerId && `${invoice?.customerId}:liability:trade payable`,
  });

  let { showInvoiceOnPosMonitor } = usePosMonitor();
  useEffect(() => {
    if (!invoice) return;

    let remove = showInvoiceOnPosMonitor(invoice);

    return () => {
      remove();
    };
  }, [showInvoiceOnPosMonitor, invoice]);

  let customerBalances =
    cxTrdReceivable &&
    cxTrdPayable &&
    !cxTrdReceivable.loading &&
    !cxTrdPayable.loading
      ? {
          tradeReceivable: cxTrdReceivable.data!,
          tradePayable: cxTrdPayable.data!,
        }
      : null;

  return (
    <InvoiceSheetChrome
      submountDialog={
        <>
          <DocumentDialog
            market={market}
            invoice={invoice}
            payouts={payouts}
            printers={printers}
            isOpen={printDialogOpen}
            close={() => setPrintDialogOpen(false)}
          />
          <PaymentDialog
            market={market}
            invoice={invoice}
            payments={payments}
            customerBalances={customerBalances}
            isOpen={paymentDialogOpen}
            close={() => setPaymentDialogOpen(false)}
          />
          <PayoutDialog
            market={market}
            invoice={invoice}
            payouts={payouts}
            customerBalances={customerBalances}
            isOpen={payoutDialogOpen}
            close={() => setPayoutDialogOpen(false)}
          />
        </>
      }
    >
      <InvoiceSheetInner
        invoice={invoice}
        customer={customer}
        payouts={payouts}
        payments={payments}
        closeInvoiceSheet={closeInvoiceSheet}
        openDocumentDialog={() => setPrintDialogOpen(true)}
        openPaymentDialog={() => setPaymentDialogOpen(true)}
        openPayoutDialog={() => setPayoutDialogOpen(true)}
      />
    </InvoiceSheetChrome>
  );
}

function InvoiceSheetChrome(props: {
  children: ReactNode;
  submountDialog: ReactNode;
}) {
  let { closeInvoiceSheet, isInvoiceSheetOpen } = useInvoiceSheet();
  return (
    <SlideUpSheet
      isOpen={isInvoiceSheetOpen}
      close={closeInvoiceSheet}
      classname="inset-4 bg-martEye-100"
      submountDialog={props.submountDialog}
    >
      {isInvoiceSheetOpen ? props.children : null}
    </SlideUpSheet>
  );
}

function InvoiceSheetInner(props: {
  invoice: Invoice | null;
  customer: Customer | null;
  payouts: Payout[] | null;
  payments: Payment[] | null;
  closeInvoiceSheet: () => void;
  openDocumentDialog: () => void;
  openPaymentDialog: () => void;
  openPayoutDialog: () => void;
}) {
  let { invoice, customer, payouts, payments, closeInvoiceSheet } = props;

  // An invoice can trigger multiple payouts (e.g. to pay off another invoice with credit)
  let mainPayouts = payouts?.filter((p) => p.method === invoice?.payoutMethod);
  let mainPayout = mainPayouts?.length ? mainPayouts[0] : null;

  let addresses = customer
    ? ([
        customer?.address.address,
        ...Object.values(customer?.otherAddresses || {}).map(
          (wrap) => wrap.address
        ),
      ].filter((a) => Boolean(a)) as Address[])
    : [];

  let extraParams = {
    name: invoice?.name,
    addressId: invoice?.address.id,
    email: invoice?.email,
    vatNumber: invoice?.vatNumber,
    // Discounts can only apply to adjustment line items
    discountsByLineItemInCents: invoice?.adjustmentLineItems
      .filter((li) => li.adjustmentConfig)
      .reduce((acc, li) => {
        return {
          ...acc,
          [li.id]: li.discountAmountInCents || 0,
        };
      }, {}),
    payout: {
      method: invoice?.payoutMethod!,
      // todo../ get linked payouts,
      accountName: mainPayout?.accountName,
      accountNumber: mainPayout?.accountNumber,
      sortCode: mainPayout?.sortCode,
      chequeMadePayableTo: mainPayout?.chequeMadePayableTo,
    },
    attributeValues: invoice?.attributeValues,
  };

  let showSettleDebtsFirst = false;
  let checkoutGroups = costructCheckoutGroups(
    invoice?.clientType || "Seller",
    extraParams,
    invoice?.attributes || [],
    addresses,
    invoice?.customerAccountNumber || null,
    showSettleDebtsFirst
  );

  return (
    <>
      <InvoiceSheetHeader
        clientType={invoice?.clientType}
        status={invoice?.status}
        invoiceNumber={invoice?.invoiceNumber}
        close={closeInvoiceSheet}
        actionsButton={<InvoiceActionButton invoice={invoice} />}
      />
      <div className="overflow-auto ">
        <div className="flex flex-grow flex-row p-4">
          <div className="mr-4 flex flex-shrink flex-grow flex-col">
            <div className="mb-4">
              <CheckoutInfo checkoutGroups={checkoutGroups} allowEdit={false} />
            </div>

            <div className="mb-4">
              <LineItemTable
                isLoadingDocument={!!invoice}
                lotCountHint={1}
                lotLineItems={invoice?.lineItems}
                hasError={false}
                commissionLineItems={invoice?.adjustmentLineItems.filter(
                  (i) => i.subtotalGroup === "Commission"
                )}
                adjustmentLineItems={invoice?.adjustmentLineItems.filter(
                  (i) => i.subtotalGroup !== "Commission"
                )}
                discountByLineItem={
                  extraParams.discountsByLineItemInCents || {}
                }
                customerIsVATRegistered={invoice?.customerIsVATRegistered}
                customerWithinTaxRegion={invoice?.customerWithinTaxRegion}
              />
            </div>

            <div className="mb-4">
              {payments && payments.length > 0 && (
                <div>
                  <h3>Payments</h3>
                  <ul>
                    {payments.map((p) => (
                      <li key={p.id}>
                        {formatAsCurrency(
                          invoice?.currency ?? null,
                          p.amountInCents
                        )}{" "}
                        {p.method} ({p.status})
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {payouts && payouts.length > 0 && (
                <div>
                  <h3 className="text-lg font-bold text-slate-500 inline-flex items-center gap-x-1.5">
                    Payouts
                  </h3>
                  <ul>
                    {payouts.map((p) => (
                      <li key={p.id}>
                        {formatAsCurrency(
                          invoice?.currency ?? null,
                          p.amountInCents
                        )}{" "}
                        {p.method} ({p.status})
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-shrink-0 flex-col gap-y-2">
            <SummaryPanel invoice={invoice} isLoading={!!invoice} />

            {invoice?.clientType === "Buyer" && (
              <button
                type="button"
                disabled={!invoice}
                className={classNames(
                  "focus:ring-green-500 inline-flex items-center justify-center gap-x-1.5 rounded-md bg-[#4BA36A] px-4 py-4 text-center text-base font-normal text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:brightness-125 focus:brightness-125",
                  "disabled:cursor-not-allowed disabled:opacity-50"
                )}
                onClick={props.openPaymentDialog}
              >
                <BanknotesIcon className="h-5 w-5 mr-1" aria-hidden={true} />
                Make Payment
              </button>
            )}

            {invoice?.clientType === "Seller" && (
              <button
                type="button"
                disabled={!invoice}
                className={classNames(
                  "focus:ring-green-500 inline-flex items-center justify-center gap-x-1.5 rounded-md bg-[#4BA36A] px-4 py-4 text-center text-base font-normal text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:brightness-125 focus:brightness-125",
                  "disabled:cursor-not-allowed disabled:opacity-50"
                )}
                onClick={props.openPayoutDialog}
              >
                <BanknotesIcon className="h-5 w-5 mr-1" aria-hidden={true} />
                Make Payment Out
              </button>
            )}

            <button
              type="button"
              disabled={!invoice}
              className={classNames(
                "focus:brightness-125 focus:ring-yellow-500 inline-flex items-center justify-center gap-x-1.5 rounded-md bg-[#D29F3B] px-4 py-4 text-center text-base font-normal text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:brightness-125",
                "disabled:cursor-not-allowed disabled:opacity-50"
              )}
              onClick={props.openDocumentDialog}
            >
              <PrinterIcon className="h-5 w-5 mr-1" aria-hidden={true} />
              Documents
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

// If the person flyout is open we need to mount the invoice sheet
// inside it to capture the keyboard events. Otherwise mount it on the sale page
export function InvoiceSheetSalePageMount() {
  let { personFlyoutIsOpen } = useOpenPersonFlyout();
  let { invoiceId, isInvoiceSheetOpen } = useInvoiceSheet();
  return personFlyoutIsOpen ? null : (
    <InvoiceSheet key={`${invoiceId}-${isInvoiceSheetOpen}`} />
  );
}

export function InvoiceSheetPersonFlyoutMount() {
  let { personFlyoutIsOpen } = useOpenPersonFlyout();
  let { invoiceId, isInvoiceSheetOpen } = useInvoiceSheet();
  return personFlyoutIsOpen ? (
    <InvoiceSheet key={`${invoiceId}-${isInvoiceSheetOpen}`} />
  ) : null;
}
