import { Timestamp } from "firebase/firestore";
import { FarmAssurances, Media } from "types";
import { cloudFunction } from "../helpers/cloudfunction";

export async function importCustomers({
  customersToImport,
  marketId,
}: {
  customersToImport: ImportCustomerInfo[];
  marketId: string;
}): Promise<ImportCustomerBatchResults> {
  let func = cloudFunction<
    {
      customersToImport: ImportCustomerInfo[];
      marketId: string;
    },
    ImportCustomerBatchResults
  >("createCustomerCall");
  return func({
    customersToImport,
    marketId,
  });
}

interface ImportCustomerBatchResults {
  results: ImportCustomerResult[];
}

interface ImportCustomerResult {
  success: boolean;
  error?: string;
  customerId?: string;
}

export interface ImportCustomerInfo {
  // The user's "account" with the market. Useful for quick look up + showing on invoices
  accountNumber: string;
  firstName?: string;
  lastName?: string;

  tradingName?: string;

  email?: string;
  email2?: string;

  phoneNumber?: string;
  phoneNumber2?: string;

  address: {
    company?: string;
    firstName?: string;
    lastName?: string;
    address1: string;
    address2?: string;
    city: string;
    province?: string;
    zip: string;
    // ISO Country Code. Must include the sub-region if applicable (e.g. GB-ENG, GB-WLS, GB-NIR, GB-SCT)
    country: string;

    // For services that require tracing of movements we need to have holding numbers.
    // Not everyone will need these (e.g. if you are just buying machinery)
  };
  // County Parish Holding number for farmers in the UK
  cphNumber?: string;

  photoURL?: string | null;

  // For services that require farm assurance we need to have farm assurance numbers.
  // Not everyone will need these (e.g. if you are just buying machinery)

  // For Red Tractor in the UK. (Not sure if this is the same for other countries)
  farmAssuranceNumberCattle?: string;

  /**
   * @description this should be an ISO Timestamp string if provided. Otherwise uses the current date if farm assurance is provided
   */
  farmAssuranceExpiryCattle?: string;

  farmAssuranceSchemeCattle?: string;

  farmAssuranceNumberSheep?: string;
  farmAssuranceExpirySheep?: string;
  farmAssuranceSchemeSheep?: string;

  farmAssuranceNumberPigs?: string;
  farmAssuranceExpiryPigs?: string;
  farmAssuranceSchemePigs?: string;

  bankAccount?: string;
  bankSortCode?: string;
  bankIBAN?: string;

  // For customers holding cattle
  herdNumber?: string;
  // for customers holding sheep
  flockNumber?: string;

  // People may or may not have VAT numbers. This will affect the tax calculation on their bill.
  // the VAT number is used to determine the country and has prefix GB, IE, DE etc. for specific countries
  vatNumber?: string;

  metadata?: {
    [key: string]: string | number | boolean;
  };

  // example; default zero. If included creates the transactions
  openingBalance?: number;
}
