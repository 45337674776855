import {
  deleteField,
  doc,
  runTransaction,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { Lot } from "types";
import { FullSaleContext } from "../ExecuteInSaleContext";

/***
Transaction for removing Items from a Lot  - 
 */
export async function deleteLotItems(
  ctx: FullSaleContext,
  lot: Lot,
  itemIds: string[]
): Promise<void> {

  let { currentUid } = ctx;

  let lotDocRef = doc(
    ctx.firestore,
    `markets/${ctx.marketId}/sales/${ctx.saleId}/lots/${lot.id}`
  );

  let itemsToDelete = itemIds.reduce((acc, itemId) => {
    acc[`itemMap.${itemId}`] = deleteField();
    return acc;
  }, {} as any);

  let update: Partial<Lot> = {
    updatedAt: serverTimestamp(),
    updatedBy: currentUid,
    ...itemsToDelete,
  };

  // the "update" here will throw if the object doesn't exist so we don't need to do a existence check
  await updateDoc(lotDocRef, update);
}
