import { useCallback, useEffect, useRef, useState } from "react";
import { useUrlFragmentState } from "./useUrlFragmentState";

// We need a way to close all other flyouts when one is opened
// This passively tracks all the flyout params that are in use
const flyoutUrlParamRegistry = [] as string[];

/***
 * Functions to get and set fragment params that we use for flyouts and overlays
 */
export function useFlyout(urlParams: string[]) {
  let stableUrlParams = useRef(urlParams);

  // add to the registry
  useEffect(() => {
    for (let urlParam of stableUrlParams.current) {
      flyoutUrlParamRegistry.push(urlParam);
    }
    return () => {
      for (let urlParam of stableUrlParams.current) {
        // Remove only one instance from the registry. This may be mounted a few times.
        let index = flyoutUrlParamRegistry.indexOf(urlParam);
        if (index > -1) {
          flyoutUrlParamRegistry.splice(index, 1);
        }
      }
    };
  }, []);

  // return the params as they update
  // allow them to be set
  // clear out others
  let [urlParamValues, setUrlParams] = useUrlFragmentState();

  let openFlyout = useCallback(
    (
      // supply null here to close the flyout
      urlParamMap: {
        [key: string]: string | number | undefined | null;
      } | null,

      // if true closes any other open flyouts
      clearOthers: boolean = true
    ) => {
      setUrlParams(
        urlParamMap,
        clearOthers ? flyoutUrlParamRegistry : undefined
      );
    },
    [setUrlParams]
  );

  let closeFlyout = useCallback(
    (clearOthers: boolean = false) => {
      setUrlParams(
        null,
        clearOthers ? flyoutUrlParamRegistry : stableUrlParams.current
      );
    },
    [setUrlParams]
  );

  let [flyoutParameters, setFlyoutParameters] = useState(
    null as null | {
      [key: string]: string | null;
    }
  );

  useEffect(() => {
    setFlyoutParameters((existing) => {
      if (urlParamValues === null) {
        return null;
      }
      let params = {} as any;
      let hasParams = false;
      let matchesExisting = true;

      for (let urlParam of stableUrlParams.current) {
        let existingValue = existing?.[urlParam] ?? null;
        let newValue = urlParamValues[urlParam] ?? null;

        if (newValue !== null) {
          hasParams = true;
        }

        if (existingValue !== newValue) {
          matchesExisting = false;
        }

        params[urlParam] = newValue;
      }
      if (!hasParams) {
        return null;
      }

      if (matchesExisting) {
        return existing;
      }

      return params;
    });
  }, [urlParamValues]);

  let isFlyoutOpen = flyoutParameters !== null;

  return [isFlyoutOpen, openFlyout, closeFlyout, flyoutParameters] as const;
}
