import { Invoice } from "types";
import { cloudFunction } from "../helpers/cloudfunction";

interface VoidInvoiceBody {
  marketId: string;
  invoiceId: string;
  reason: string;
}

export async function voidInvoice(
  marketId: string,
  invoiceId: string,
  reason: string
) {
  let voidInvoiceCall = cloudFunction<VoidInvoiceBody, Invoice>(
    "voidInvoiceCall"
  );

  if (!marketId || !invoiceId) {
    throw new Error(`No market id or sale id`);
  }

  if (!reason) {
    throw new Error(`A reason is required to void an invoice`);
  }

  let result = await voidInvoiceCall({ marketId, invoiceId, reason });
  console.log("voidInvoice result", result);

  return result;
}
