import { currencyToSymbol, formatAsCurrency } from "@/data/amounts";
import { classNames } from "@/data/classnames";
import useStudio from "@/data/studio-react/useStudio";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Radio,
  RadioGroup,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { BanknotesIcon } from "@heroicons/react/20/solid";
import { DocumentIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { Fragment, ReactNode, useEffect, useState } from "react";
import {
  Invoice,
  Market,
  Payment,
  PaymentMethod,
  SimplePaymentIn,
} from "types";
import { Spinner } from "../Buttons";

interface PayentDialogProps {
  market: Market | null;
  invoice: Invoice | null;
  payments: Payment[];

  customerBalances: {
    tradePayable: number; // money the customer owes
    tradeReceivable: number; // money owed to the customer
  } | null;

  isOpen: boolean;
  close: () => void;
}

const paymentOptions: {
  id: PaymentMethod;
  label: string;
  referenceRequired?: boolean;
  referenceLabel?: string;
}[] = [
  {
    id: "Cash",
    label: "Cash",
    referenceRequired: false,
  },
  {
    id: "Cheque",
    label: "Cheque",
    referenceRequired: true,
    referenceLabel: "Cheque Number",
  },
  {
    id: "Card",
    label: "Card",
    referenceRequired: true, // optional
  },
  {
    id: "BankTransfer",
    label: "Bank Transfer",
    referenceRequired: true,
    referenceLabel: "Reference",
  },
  {
    id: "Credit",
    label: "Credit",
  },
];

export function PaymentDialog(props: PayentDialogProps) {
  let { invoice, payments, isOpen, market, customerBalances, close } = props;

  // these should match the returned payments...
  let paymentsOnInvoice: SimplePaymentIn[] = Object.values(
    invoice?.paymentsInById || {}
  );

  let currency = invoice?.currency || null;
  let currencySymbol = currencyToSymbol(currency);

  let maxCreditAvailable = customerBalances?.tradeReceivable || 0;

  let totalPaid = paymentsOnInvoice.reduce(
    (acc, p) => acc + p.amountInCents,
    0
  );
  let remainignToPay = (invoice?.finalTotalInCents || 0) - (totalPaid || 0);

  let [selectedMethod, setSelectedMethod] = useState<PaymentMethod>("Cash");
  let [amount, setAmount] = useState<string>(
    `${(remainignToPay / 100).toFixed(2)}`
  );

  useEffect(() => {
    setAmount(`${(remainignToPay / 100).toFixed(2)}`);
  }, [remainignToPay, isOpen]);

  let [reference, setReference] = useState<string>("");

  let isSettled = remainignToPay <= 0;

  let { createPayment } = useStudio();

  let [isLoading, setIsLoading] = useState(false);
  let makePayment = async () => {
    if (!invoice) return;

    setIsLoading(true);

    try {
      await createPayment(
        invoice.marketId,
        invoice.customerId,
        selectedMethod,
        Math.round(parseFloat(amount) * 100),
        null,
        reference === "" ? null : reference,
        [invoice.id]
      );
      close();
    } catch (e) {
      console.error(e);
      alert("Failed to record payment");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <DialogChrome isOpen={isOpen} close={close}>
      <div className="bg-black rounded-lg px-5 py-4 flex flex-col">
        {/* Header */}
        <div className="flex flex-row items-center">
          <DocumentIcon className="h-6 w-6 text-martEye-400" />
          <DialogTitle
            as="h3"
            className="text-base font-bold leading-6 text-white flex-grow ml-2"
          >
            Record Payment - {invoice?.customerAccountNumber}
          </DialogTitle>

          <button
            type="button"
            className="flex h-9 w-9 items-center justify-center rounded-full border-2 border-white/40 text-white  hover:bg-white/10"
            onClick={close}
          >
            <XMarkIcon className="h-4 w-4" />
          </button>
        </div>

        {/* Content */}

        <div className="flex flex-col gap-4 mt-4 items-center flex-grow">
          {/* Top section shows the balances */}

          <ul className="flex flex-col w-full">
            {/* <li className="flex flex-row justify-between border-b border-gray-100/10 py-1 text-sm">
              <span>Ledger Balance: </span>
              <span>
                {formatAsCurrency(
                  currency,
                  (customerBalances?.tradeReceivable || 0) +
                    (customerBalances?.tradePayable || 0)
                )}
              </span>
            </li> */}
            <li className="flex flex-row justify-between border-b border-gray-100/10 py-1 text-sm">
              <span className="text-gray-100">
                Invoice #{invoice?.invoiceNumber}
              </span>
              <span className="text-gray-400/60">
                {formatAsCurrency(currency, invoice?.finalTotalInCents, false)}
              </span>
            </li>
            <li className="flex flex-row justify-between border-b border-gray-100/10 py-1 text-sm">
              <span className="text-gray-100">Amount Paid</span>
              <span className="text-gray-400/60">
                {formatAsCurrency(currency, totalPaid * -1, false)}
              </span>
            </li>
            <li className="flex flex-row justify-between py-1 text-sm">
              <span className="text-gray-100">Remaining to Pay</span>
              <span className="text-lg text-white">
                {formatAsCurrency(currency, remainignToPay, false)}
              </span>
            </li>
          </ul>

          <PaymentMethodSelector
            disabled={isSettled}
            options={paymentOptions}
            selected={selectedMethod}
            setSelected={setSelectedMethod}
            currencySymbol={currencySymbol}
            amount={amount}
            setAmount={setAmount}
            reference={reference}
            setReference={setReference}
            canPayWithCredit={maxCreditAvailable >= 0}
          />
        </div>

        <div className="w-full flex flex-col items-stretch mt-20 mb-2">
          <button
            type="button"
            className="relative inline-flex items-center justify-center gap-x-3 rounded-lg bg-green-600 px-5 py-3 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 disabled:cursor-not-allowed disabled:opacity-60 disabled:hover:bg-green-600"
            // onClick={props.printDocuments}
            disabled={isSettled || isLoading}
            onClick={makePayment}
          >
            {isLoading && (
              <div className="absolute right-3 h-5 w-5 flex items-center justify-center">
                <Spinner />
              </div>
            )}
            <BanknotesIcon className="h-5 w-5" aria-hidden="true" />
            Record Payment
          </button>
        </div>
      </div>
    </DialogChrome>
  );
}

const PaymentMethodSelector = function (props: {
  options: {
    id: PaymentMethod;
    label: string;
    referenceRequired?: boolean;
    referenceLabel?: string;
  }[];
  selected: PaymentMethod;
  setSelected: (method: PaymentMethod) => void;
  currencySymbol: string | null | undefined;

  disabled: boolean;

  amount: string;
  setAmount: (amount: string) => void;

  reference: string;
  setReference: (reference: string) => void;

  canPayWithCredit: boolean;
}) {
  let { options, selected, setSelected, canPayWithCredit } = props;

  let selectedOption = options.find((o) => o.id === selected);
  let selectedRequiresReference = selectedOption?.referenceRequired || false;

  return (
    <div className="flex flex-col items-stretch">
      <fieldset aria-label="Choose a payment method">
        <div className="flex items-center justify-between">
          <div className="text-sm font-medium leading-6 text-gray-100 ">
            Method
          </div>
        </div>

        <RadioGroup
          value={selected}
          onChange={(m) => setSelected(m)}
          className="mt-0.5 grid grid-cols-5 gap-3"
        >
          {options.map((option) => (
            <Radio
              as={Fragment}
              key={option.id}
              value={option.id}
              autoFocus={option.id === selected}
              disabled={
                (canPayWithCredit === false && option.id === "Credit") ||
                props.disabled
              }
            >
              {({ checked, focus, disabled }) => (
                <span
                  className={classNames(
                    //   option.inStock ? 'cursor-pointer focus:outline-none' : 'cursor-not-allowed opacity-25',
                    focus ? "ring-2 ring-green-600 ring-offset-2" : "",
                    checked
                      ? "bg-green-600 text-white hover:bg-green-500"
                      : "bg-black text-gray-100 ring-1 ring-inset ring-gray-200/30 hover:bg-white/20",
                    disabled ? "cursor-not-allowed opacity-40" : "",
                    "flex items-center justify-center rounded-md px-3 py-2 text-sm font-semibold sm:flex-1 text-center cursor-pointer"
                  )}
                >
                  {option.label}
                </span>
              )}
            </Radio>
          ))}
        </RadioGroup>
      </fieldset>
      <div className="mt-4">
        <label
          htmlFor="Amount"
          className="block text-sm font-medium leading-6 text-gray-100"
        >
          Amount
        </label>

        <div className="mt-0.5 flex rounded-md shadow-sm">
          <div className="relative flex flex-grow items-stretch focus-within:z-10">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span
                className={classNames(
                  "text-gray-400 sm:text-sm",
                  props.disabled ? "opacity-40" : ""
                )}
              >
                {props.currencySymbol}
              </span>
            </div>
            <input
              type="number"
              name="amount"
              id="amount"
              className="block w-full rounded-md border-0 py-1.5 pl-7 text-white ring-1 ring-inset ring-gray-200/30 placeholder:text-gray-100 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6 bg-transparent disabled:opacity-40  disabled:cursor-not-allowed"
              placeholder="0.00"
              value={props.amount}
              disabled={props.disabled}
              onChange={(e) => props.setAmount(e.target.value)}
              onBlur={(e) => {
                let val = parseFloat(e.target.value);
                if (isNaN(val)) {
                  props.setAmount("");
                  return;
                }
                // Ensure 2 decimal places
                props.setAmount(val.toFixed(2));
              }}
            />
          </div>
        </div>
      </div>

      <div className="mt-4">
        <div className={"block h-9"}>
          <label
            htmlFor="reference"
            className={classNames(
              "block text-sm font-medium leading-6",
              selectedRequiresReference ? "text-gray-100" : "text-gray-400"
            )}
          >
            {selectedOption?.referenceLabel || "Reference"}
          </label>
          <div className="mt-0.5">
            <input
              type="text"
              name="reference"
              id="reference"
              className="relative block w-full rounded-md border-0 bg-transparent py-1.5 text-gray-100 ring-1 ring-inset ring-gray-200/30 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6 disabled:opacity-40  disabled:cursor-not-allowed"
              placeholder={
                selectedRequiresReference
                  ? selectedOption?.referenceLabel || "Reference"
                  : "Not required"
              }
              disabled={!selectedRequiresReference}
              value={selectedRequiresReference ? props.reference : ""}
              onChange={(e) => props.setReference(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

function DialogChrome(props: {
  isOpen: boolean;
  close: () => void;
  children: ReactNode;
}) {
  let { isOpen, close, children } = props;

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-60" onClose={close}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-30 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full max-w-2xl">
                {isOpen ? children : null}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
