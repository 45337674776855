import { Sale } from "types";
import { cloudFunction } from "../helpers/cloudfunction";

interface CreateSaleRequest {
  name: string;
  description?: string | null;
  recurring?: null | "Weekly" | "Bi-weekly" | "Monthly";
  startsAt: string; // ISO string
  location?: string | null;
  availableProductCodes?: string[];
  image?: string | null;

  attributeDefaults?: {
    [attributekey: string]: string | number | boolean;
  };
}

/**
 * The details required to duplicate a sale
 *  */
interface DuplicateSaleRequest {
  marketId: string;
  /**
   * the sale Id which is being duplicated
   */
  sourceSaleId: string;
  /**
   * The name of the new sale
   */
  name: string;
  /**
   * The description of the sale
   */
  description?: string | null;
  /**
   * The date and time the sale starts. ISO string
   */

  /**
   *Recurring of the sale
   */
  recurring?: null | "Weekly" | "Bi-weekly" | "Monthly";
  startsAt: string;
}

interface DuplicateSaleResponse {
  saleId: string;
}

export async function createSale(marketId: string, info: CreateSaleRequest) {
  let createSaleCall = cloudFunction<
    CreateSaleRequest & { marketId: string },
    Sale
  >("createSale");

  if (!info) {
    throw new Error(`No sale info to create a sale`);
  }

  let result = await createSaleCall({ ...info, marketId });

  return result;
}

export async function duplicateSale(
  marketId: string,
  info: DuplicateSaleRequest
) {
  let duplicateSaleCall = cloudFunction<
    DuplicateSaleRequest & { marketId: string },
    DuplicateSaleResponse
  >("duplicateSale");

  if (!info) {
    throw new Error(`No sale info to create a sale`);
  }

  let result = await duplicateSaleCall({ ...info, marketId });

  return result;
}
