import { genColour } from "@/data/colours";
import { getDisplayName } from "@/data/customerUtils";
import { contrastColor } from "contrast-color";
import rgbHex from "rgb-hex";
import { Customer } from "../../types";

const groupColours = genColour;

interface PersonIconProps {
  customer: Customer | null;
}

export const PersonIcon = (props: PersonIconProps) => {
  let { customer } = props;

  if (!customer) {
    return null;
  }

  if (customer?.photoURL) {
    return (
      <span className="inline-flex h-14 w-14 items-center justify-center rounded-full bg-gray-500">
        <img
          src={customer.photoURL}
          alt="Customer photo"
          className="h-14 w-14 rounded-full"
        />
      </span>
    );
  }

  let text = "";
  let colour = "";
  let textColor = "";

  if (customer.bidderNumber) {
    text = `${customer.bidderNumber!}`;
    colour = rgbHex(groupColours(text as string));
    textColor = contrastColor({ bgColor: `#${colour}` });
  } else {
    text = getDisplayName(customer)
      .split(" ")
      .slice(0, 3)
      .map((name) => name[0])
      .join("");

    colour = rgbHex(groupColours(customer.id as string));
    textColor = contrastColor({ bgColor: `#${colour}` });
  }

  return (
    <span
      className={`inline-flex h-14 w-14 items-center justify-center rounded-full bg-grey-500`}
      style={{ backgroundColor: `#${colour}` }}
    >
      <span
        className="text-xl font-medium leading-none text-white"
        style={{ color: textColor }}
      >
        {text}
      </span>
    </span>
  );
};

export default PersonIcon;
