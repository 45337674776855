import { Spinner } from "@/components/Buttons";
import { classNames } from "@/data/classnames";
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Label,
} from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { useCallback, useRef, useState } from "react";
import { usePopper } from "react-popper";
import { LotIssue } from "../../types";
import { ValidationIssue, inputBorder } from "../LotFlyout/LotValidation";

// Tooltip
import { Tooltip } from "@/components/Tootip";
interface ComboBoxProps {
  id?: string;
  className?: string;
  value: string;
  name: string;
  label: string;
  columns: number;
  options: Array<string> | Array<{ label: string; value: string }>;
  onChange: (value: string | null) => void;
  error?: LotIssue | false;
  required?: boolean;
  isLoadingIn?: boolean;
  isPreSale?: boolean;
  allowRemove?: boolean;
}

export const ComboBox = ({
  id,
  options,
  value,
  name,
  label,
  columns,
  required,
  error = false,
  isLoadingIn,
  isPreSale = true,
  allowRemove = true,

  onChange,
}: ComboBoxProps) => {
  const selected = value;
  const ButtonContent = ({ selected, options, onChange, allowRemove }: any) => {
    if (selected) {
      let selectedLabel = options.find(
        (option: any) => option.value === selected
      )?.label;

      return (
        <Tooltip text={selectedLabel}>
          <span className="min-w-0 inline-flex items-center flex-nowrap gap-x-0.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium uppercase text-martEye-700 ring-1 ring-inset ring-gray-100">
            <span className="truncate nowrap min-w-0">{selectedLabel}</span>
            {allowRemove && (
              <span
                className="cursor-pointer w-[20px] shrink-0"
                onClick={(e) => {
                  onChange(null);
                  e.stopPropagation();
                }}
              >
                <XMarkIcon className="ml-0.5 h-5 w-5 text-gray-500" />
              </span>
            )}
          </span>
        </Tooltip>
      );
    } else {
      return <span>Please select</span>;
    }
  };

  // Only show error if the field has been interacted with
  if (!value && isPreSale) {
    error = false;
  }

  let fieldOptions = options.map((option) => {
    if (typeof option === "string") {
      return { label: option, value: option };
    }
    return option;
  });

  let [query, setQuery] = useState("");

  // Popper

  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState<HTMLDivElement | null>(
    null
  );
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 5],
        },
      },
    ],
  });

  const parentOnChange = onChange;

  let comboBoxOnChange = useCallback(
    (value: string | null) => {
      setQuery("");
      if (parentOnChange) {
        parentOnChange(value);
      }
    },
    [parentOnChange]
  );

  const inputClasses = inputBorder({ error });

  return (
    <>
      <div className={classNames(columns ? `col-span-${columns}` : "")}>
        <Combobox as="div" value={selected} onChange={comboBoxOnChange}>
          <Label
            htmlFor={id ?? name}
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {label}

            {required && (
              <span className="text-red-500 ml-0.5" aria-hidden="true">
                * <span className="sr-only">Required Field</span>
              </span>
            )}
          </Label>
          <div className="relative mt-1">
            <div ref={setTargetElement} className={inputClasses}>
              <ul className="flex items-center h-full gap-x-[2px] p-[2px] w-full">
                {selected ? (
                  <li className="pl-[3px] min-w-[120px] flex">
                    <ButtonContent
                      selected={selected}
                      options={fieldOptions}
                      onChange={onChange}
                      allowRemove={allowRemove}
                    />
                  </li>
                ) : null}

                <li className="relative w-full min-w-0">
                  <ComboboxInput
                    id={id ?? name}
                    className="w-full rounded border-0 focus-within:outline-none focus-within:ring-0 focus:outline-none focus:ring-0 focus:ring-transparent text-sm"
                    placeholder={selected ? "" : "Please Select"}
                    onChange={(event) => {
                      if (selected) {
                        onChange(null);
                        setQuery(event.target.value);
                      } else {
                        setQuery(event.target.value);
                      }
                    }}
                    displayValue={(option) => ""}
                    value={query === selected ? "" : query}
                    onKeyDown={(event: any) => {
                      if (event.key === "Enter") {
                        setQuery("");
                      }

                      if (
                        event.key === "Backspace" &&
                        query === "" &&
                        selected
                      ) {
                        onChange(null);
                      }
                    }}
                  />

                  <ComboboxButton className="absolute inset-y-0 right-0 flex items-center pr-[5px]">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </ComboboxButton>
                </li>
                {isLoadingIn && (
                  <li className="w-[30px] shrink-0 h-full rounded overflow-hidden relative">
                    <div className="flex h-full w-full items-center justify-center">
                      <Spinner className="h-4 w-4 animate-spin" />
                    </div>
                  </li>
                )}
                {error && value && (
                  <li className="w-[30px] shrink-0 h-full rounded overflow-hidden relative">
                    <ValidationIssue error={error} />
                  </li>
                )}
              </ul>
            </div>

            {fieldOptions.length > 0 && (
              <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {fieldOptions.map((option) => (
                  <ComboboxOption
                    key={option.value}
                    value={option.value}
                    className={({ active }) =>
                      classNames(
                        "relative cursor-default select-none py-2 px-4",
                        active ? "bg-martEye-400 text-white" : "text-gray-900"
                      )
                    }
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate"
                            )}
                          >
                            {option.label}
                          </span>
                        </div>
                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-martEye-400",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </ComboboxOption>
                ))}
              </ComboboxOptions>
            )}
          </div>
        </Combobox>
      </div>
    </>
  );
};

export default ComboBox;
