import { classNames } from "@/data/classnames";
import { motion } from "framer-motion";
import { Router } from "next/router";
import { useEffect, useState } from "react";

/***
 * A thin loading bar that travels across the top of the screen when loading
 */
export default function LoadingBar() {
  const [progress, setProgress] = useState(null as null | number);

  useEffect(() => {
    function setLoadingTrue() {
      setProgress(95);
    }
    function setLoadingFalse() {
      setProgress(100);
    }

    Router.events.on("routeChangeStart", setLoadingTrue);
    Router.events.on("routeChangeComplete", setLoadingFalse);
    return () => {
      Router.events.off("routeChangeStart", setLoadingTrue);
      Router.events.off("routeChangeComplete", setLoadingFalse);
    };
  });

  useEffect(() => {
    // Remove it after 1 second
    if (progress === 100) {
      let t = setTimeout(() => {
        setProgress(null);
      }, 1000);
      return () => {
        clearTimeout(t);
      };
    }
  }, [progress]);

  if (progress === null) {
    return null;
  }

  return (
    <motion.div
      style={{ height: 2, zIndex: 1000 }}
      className={classNames(
        "pointer-events-none",
        "absolute top-0 left-0 bg-martEye-400"
      )}
      initial={{ width: "50%", opacity: 1 }}
      animate={{ width: progress + "%", opacity: progress === 100 ? 0 : 1 }}
      transition={{
        width: {
          duration: progress === 100 ? 0.5 : 10,
          ease: progress === 100 ? "backOut" : "linear",
        },
        opacity: { delay: 0.5 },
      }}
    />
  );
}
