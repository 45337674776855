import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { useState } from "react";

// TODO - rework with popper? Could be nicer.

export default function QSHelpOnHover(props: {
  helpText?: string | React.ReactNode;
}) {
  if (!props.helpText) {
    return null;
  }

  let [isShowing, setShowing] = useState(false);

  let helpNode = (
    <div className="text-sm px-2 py-1 bg-slate-600 text-gray-50 absolute bottom-0 w-96 -left-36">
      {props.helpText}
    </div>
  );

  return (
    <div
      className="relative inline-block"
      onMouseOver={() => {
        setShowing(true);
      }}
      onMouseOut={() => {
        setShowing(false);
      }}
    >
      {isShowing ? helpNode : null}
      <InformationCircleIcon className="ml-1 h-4 w-4 text-gray-500" />
    </div>
  );
}
