import { DocumentSpec, InvoicePDFProps } from "../../_importDocs";

// JS PDF
import { jsPDF } from "jspdf";
import { Market, SuperType } from "types";

import { getMarketBranding } from "../utils";

// Parts
import footer from "../sheep-tag-list/parts/footer";
import header from "../sheep-tag-list/parts/header";
import table from "./parts/table"; // For now we won't use the table from the sheep-tag-list

const TagListSpec: DocumentSpec = {
  // must be unique
  id: "taglist",
  objectType: "invoice",
  name: "Tag List",

  // when the PDF is downloaded, this function is called to generate the filename
  getFilename: (props) => {
    let { invoice, marketId } = props;
    let invoiceNumber =
      invoice.status === "draft" ? "Draft" : invoice.invoiceNumber;
    let filename = `tag-list-${marketId}-${invoiceNumber}`;
    filename = filename.replace(/ /g, "-").replace(/[^a-zA-Z0-9-]/g, "");
    return `${filename}`;
  },

  // return true if this document is available for a market to generate
  isAvailableFor: (market: Market, superTypes: SuperType[]) => {
    // only for livestock
    let allowedSuperTypes: SuperType[] = ["Cattle", "Pigs"];
    return superTypes.some((superType) =>
      allowedSuperTypes.includes(superType)
    );
  },
  // the document template
  jsPDF: (props: InvoicePDFProps) => {
    const { market, invoice } = props;

    const margin = 10;
    const doc = new jsPDF({
      putOnlyUsedFonts: true,
      compress: true,
      unit: "mm",
      format: "a4",
    });

    const {
      dateFormat,
      textFillColor,
      tableHeaderFillColor,
      tableHeaderTextColor,
      tableStripeFillColor,
      tableStripeTextColor,
    } = getMarketBranding(market);

    header(doc, invoice, {
      output: false,
      dateFormat: dateFormat,
      title: "Tag List",
      margin: margin,
      textFillColor: textFillColor,
      logo: props.marketLogo,
      market: market,
    });

    table(doc, invoice, {
      tagsPerRow: 4,
      margin: margin,
      headerFillColor: tableHeaderFillColor,
      headerTextColor: tableHeaderTextColor,
      stripeFillColor: tableStripeFillColor,
      stripeTextColor: tableStripeTextColor,
    });

    header(doc, invoice, {
      output: true,
      dateFormat: dateFormat,
      title: "Tag List",
      margin: margin,
      textFillColor: textFillColor,
      logo: props.marketLogo,
      market: market,
    });

    footer(doc, invoice, {
      margin,
      textFillColor: textFillColor,
    });

    return doc;
  },
};

export default TagListSpec;
