import { useMemo } from "react";
import { Customer } from "../../../types";

import { VerticalTabs } from "../../VerticalTabs";

// Sub Tabs
import {
  SettingsAddresses,
  SettingsBanking,
  SettingsGeneral,
  SettingsNotes,
  SettingsDefaults,
} from "./Settings";

interface SettingsTabProps {
  customer: Customer | null;
  marketId: string;
}

export function SettingsTab(props: SettingsTabProps) {
  let { customer, marketId } = props;

  const tabs = useMemo(() => {
    if (!customer) {
      return [];
    }
    return [
      {
        title: "General",
        content: <SettingsGeneral customer={customer} marketId={marketId} />,
      },
      {
        title: "Addresses",
        content: <SettingsAddresses customer={customer} marketId={marketId} />,
      },
      {
        title: "Banking",
        content: <SettingsBanking customer={customer} marketId={marketId} />,
      },
      {
        title: "Notes",
        content: <SettingsNotes customer={customer} marketId={marketId} />,
      },
      {
        title: "Defaults",
        content: <SettingsDefaults customer={customer} marketId={marketId} />,
      },
    ];
  }, [customer, marketId]);

  return (
    <>
      <div className="flex flex-wrap gap-4 p-3">
        <VerticalTabs urlParam="settings" tabs={tabs} />
      </div>
    </>
  );
}
