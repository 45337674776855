// Get the 
import countries from 'i18n-iso-countries';
import english from "i18n-iso-countries/langs/en.json";
countries.registerLocale(english);

export function getCountriesList() {

  let object = countries.getNames("en", {select: "official"});

  // Create a new Map object
  const map = new Map();

  // Move the key "IE" to the top of the map

  // Add Ireland to the map
  map.set("GB-ENG", "England");
  map.set("GB-SCT", "Scotland");
  map.set("GB-WLS", "Wales");
  map.set("GB-NIR", "Northern Ireland");
  map.set("IE", object.IE);


  // Iterate through the object and add the remaining key-value pairs to the map
  for (const [key, value] of Object.entries(object)) {
    if (key !== "IE" && key !== "GB") {
      map.set(key, value);
    }
  }

  // Convert the map back to an object
  object = Object.fromEntries(map);

  return object;

}

