import { AttributeDefinition, Lot } from "types";
import { TemplateContext } from "../attributeControls";

export function isReadonly(
  ctx: TemplateContext,
  lot: Lot,
  attribute: AttributeDefinition,
  itemId: string | null
) {
  if (attribute.readonly === true) {
    return true;
  }

  // we may make some fields read-only as the sale progresses...

  return false;
}

export function isReadOnlyOnSaleSheet(
  ctx: TemplateContext,
  lot: Lot,
  attribute: AttributeDefinition
) {
  if (attribute.readonly === true) {
    return true;
  }

  // on the sale sheet we can't edit if a lot had multiple items and it's an item attribute
  if (attribute.level === "item") {
    if (ctx.items.length > 1) {
      return true;
    }
  }

  return false;
}
