import {
  ChequeField,
  DraftInvoice,
  FieldPositions,
  Invoice,
  InvoiceLineItem,
  Market,
  SimplePaymentOut,
  SuperType,
} from "types";
import { DocumentSpec, InvoicePDFProps } from "../_importDocs";
var converter = require("number-to-words");

// PDF Building
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import colours from "../../tailwind.config";

import "../fonts/Inter-bold.js";
import "../fonts/Inter-light.js";
import "../fonts/Inter-normal.js";

interface numberUnits {
  hunderdsOfThousands: number;
  tensOfThousands: number;
  thousands: number;
  hundreds: number;
  tens: number;
  units: number;
  afterDecimal: number;
}

/**
 * Sets the colour for debugging the cheque fields on screen
 */
const CONTRAST_CHEQUE = false;

import {
  ASTERISK_FILLER,
  CANCELLED_FILLER,
  COLOUR_MARTEYE_500,
  MARGIN,
  PAGE_HEIGHT,
  PAGE_WIDTH,
  PTSPERMM,
} from "../variables";
const colors = colours?.theme?.extend?.colors as any;

// Components
import addPageOneHeader from "../components/header";
import tableRunningHeader from "../components/tableRunningHeader";

// TODO Move these functions
function averagePricePerUnitBySuperType(data: InvoiceLineItem[]) {
  const superTypes: any = {};

  data.forEach((item) => {
    const superType: string = item.superType || "";

    if (superTypes[superType] === undefined) {
      superTypes[superType] = {
        totalQuantity: 0,
        totalPrice: 0,
        averagePrice: 0,
      };
    }

    superTypes[superType].totalQuantity += item.quantity;
    superTypes[superType].totalPrice += item.totalAmountInCentsExVat;
    superTypes[superType].averagePrice =
      superTypes[superType].totalPrice / superTypes[superType].totalQuantity;
  });

  return superTypes;
}

function combineDataBySubtotalAndTaxRate(invoice: Invoice | DraftInvoice) {
  const data = invoice.adjustmentLineItems || [];
  const currency = invoice.currency || "GBP";
  const combinedData = new Map();

  data.forEach((item) => {
    const key = `${item.subtotalGroup}-${item.taxRate}`;

    if (!combinedData.has(key)) {
      combinedData.set(key, {
        subtotalGroup: item.subtotalGroup,
        taxRate: item.taxRate,
        totalAmountInCentsExVat: 0,
      });
    }
    combinedData.get(key).totalAmountInCentsExVat +=
      item.totalAmountInCentsExVat;
  });

  const array = Array.from(combinedData.values());

  return array.map(({ subtotalGroup, taxRate, totalAmountInCentsExVat }) => [
    subtotalGroup,
    taxRate.percentage * 100 + "%",
    new Intl.NumberFormat("en-gb", {
      style: "currency",
      currency: currency,
    }).format(totalAmountInCentsExVat / 100),
  ]);
}
/**
 * The positions of the fields on the cheque. in mm from the top left corner of A4 paper
 */
const marketChequeFieldPositions: { [key: string]: FieldPositions[] } = {
  ballymena: [
    { id: "chequeDate", x: 168.5, y: 238 },
    { id: "chequeNumber", x: 26.3, y: 253 },
    { id: "payee", x: 51, y: 253 },
    { id: "amount", x: 168.5, y: 253 },
    { id: "hunderdsOfThousands", x: 26.3, y: 270 },
    { id: "tensOfThousands", x: 43.8, y: 270 },
    { id: "thousands", x: 62.4, y: 270 },
    { id: "hundreds", x: 81, y: 270 },
    { id: "tens", x: 100, y: 270 },
    { id: "units", x: 116, y: 270 },
  ],
  default: [
    { id: "chequeDate", x: 168.5, y: 238 },
    { id: "chequeNumber", x: 26.3, y: 253 },
    { id: "payee", x: 51, y: 253 },
    { id: "amount", x: 168.5, y: 253 },
    { id: "hunderdsOfThousands", x: 26.3, y: 270 },
    { id: "tensOfThousands", x: 43.8, y: 270 },
    { id: "thousands", x: 62.4, y: 270 },
    { id: "hundreds", x: 81, y: 270 },
    { id: "tens", x: 100, y: 270 },
    { id: "units", x: 116, y: 270 },
    // { id: "amountInWords", x: 51, y: 247 },
  ],
};

const InvoiceSpec: DocumentSpec = {
  // must be unique
  id: "cheque",
  objectType: "invoice",

  // displayed to the user
  name: "Cheque",

  requiresChequePrinter: true,

  // when the PDF is downloaded, this function is called to generate the filename
  getFilename: (props) => {
    let { invoice, marketId } = props;
    let filename = `cheque-${marketId}-${(invoice as Invoice).invoiceNumber}`;
    filename = filename.replace(/ /g, "-").replace(/[^a-zA-Z0-9-]/g, "");

    return `${filename}`;
  },

  // return true if this document is available for a market to generate
  isAvailableFor: (market: Market, superTypes: SuperType[]) => {
    return true;
  },

  // the document template
  jsPDF: (props: InvoicePDFProps) => {
    const { invoice, market, chequeNumber, payoutId } = props;
    let y = MARGIN;
    let checkSafeArea = 70;

    const tableData = (invoice: Invoice | DraftInvoice) => {
      //
      let currency = invoice.currency || "GBP";

      const data: any = [];

      invoice.lineItems.map((item: InvoiceLineItem) => {
        data.push([
          item.description,
          item.metadata.lotNumber,
          item.quantity + (item.unitOfSale == "Per KG" ? " Kg" : ""),
          ,
          new Intl.NumberFormat("en-gb", {
            style: "currency",
            currency: currency,
          }).format(item.impreciseUnitPriceInCents / 100),
          item.taxRate.percentage * 100 + "%",
          new Intl.NumberFormat("en-gb", {
            style: "currency",
            currency: currency,
          }).format(item.totalAmountInCentsExVat / 100),
        ]);
      });

      return data;
    };

    const adjustmentTableData = (invoice: Invoice | DraftInvoice) => {
      let currency = invoice.currency || "GBP";

      return combineDataBySubtotalAndTaxRate(invoice);
    };

    const addHeaders = (doc: any, invoice: Invoice | DraftInvoice) => {
      const pageCount = doc.internal.getNumberOfPages();

      for (var i = 2; i <= pageCount; i++) {
        doc.setPage(i);
        tableRunningHeader(15, doc, invoice);
      }
    };

    const addFooters = (doc: any) => {
      const pageCount = doc.internal.getNumberOfPages();

      doc.setFont("Inter", "light");
      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i);

        let fontSize = 7.5;
        let legalTextLines = doc
          .setFontSize(fontSize)
          .splitTextToSize(
            "For each lot of sheep/goats listed on this document and for each holding from which they were consigned to the market, a declaration, signed by the keeper of the animals on each holding, has been received stating that. Sheep and goats on the holding are not under movement restrictions for animal disease or public health reasons (excluding a 6-day standstill). Withdrawal periods have been observed for all veterinary medicines and other treatments administered to the animals while on this holding and previous holdings. To the best of my knowledge the animals are not showing signs of any disease or condition that may affect the safety of meat derived from them. No analysis of samples taken from animals on the holding or other samples has shown that the animals in this consignment may have been exposed to any disease or condition that may affect the safety of meat or to substances likely to result in residues in meat.",
            190
          );

        doc.text(10, 260, legalTextLines);

        doc.setFontSize(8);
        doc.text(
          "Page " + String(i) + " of " + String(pageCount),
          PAGE_WIDTH - MARGIN,
          287,
          {
            align: "right",
          }
        );
      }
    };

    const addInvoiceTotal = (
      doc: any,
      invoice: Invoice | DraftInvoice,
      y: number
    ) => {
      const clientType = invoice.clientType;
      const currency = invoice.currency || "GBP";
      const total = new Intl.NumberFormat("en-gb", {
        style: "currency",
        currency: currency,
      }).format(invoice.lotTotalInCentsExVat / 100);

      let additions = "";
      let additionalTotal =
        invoice.commissionTotalInCents! +
        invoice.nonCommissionAdjustmentsInCents!;
      if (additionalTotal > 0) {
        additions = new Intl.NumberFormat("en-gb", {
          style: "currency",
          currency: currency,
        }).format(additionalTotal / 100);
        additions = clientType == "Seller" ? "- " + additions : additions;
      }

      let vat = new Intl.NumberFormat("en-gb", {
        style: "currency",
        currency: currency,
      }).format(
        (invoice.vatOnCommissionInCents! +
          invoice.vatOnLotTotalInCents +
          invoice.vatOnAdjustmentsInCents!) /
          100
      );
      vat = clientType == "Seller" ? "- " + vat : vat;

      const net = new Intl.NumberFormat("en-gb", {
        style: "currency",
        currency: currency,
      }).format(invoice.finalTotalInCents / 100);

      // Defaults
      let minimumGap = 25;
      let startPageNumber = doc.internal.getCurrentPageInfo().pageNumber;
      // Labels
      let netValueText = "NET VALUE";
      let vatText = "VAT";
      let additionsText =
        invoice.clientType == "Seller" ? "DEDUCTIONS" : "ADDITIONS";
      let totalText = "TOTAL";

      // Work out the minimum height and width required.
      doc.setFont("Inter", "bold");
      doc.setFontSize(14);
      let netValueTotalWidth = doc.getTextWidth(net);
      doc.setFontSize(10);
      let netValueTextWidth = doc.getTextWidth(netValueText);

      // Table Width
      let tableWidth = netValueTotalWidth + netValueTextWidth + minimumGap;
      let startX = PAGE_WIDTH - MARGIN - tableWidth;

      // Table Color
      doc.setTextColor("#989898");

      let tableData = [
        [totalText, total],
        [vatText, vat],
      ];

      if (additions) {
        //Add the additions row after the total row
        tableData.splice(1, 0, [additionsText, additions]);
      }

      autoTable(doc, {
        body: tableData,
        pageBreak: "avoid",
        foot: [[netValueText, net]],
        startY: doc.lastAutoTable.finalY + MARGIN,
        theme: "plain",
        margin: { top: 30, right: MARGIN, bottom: 45, left: startX },
        showHead: "never",
        tableWidth: tableWidth,
        bodyStyles: {
          fontSize: 10,
          font: "Inter",
          fontStyle: "normal",
          cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
          valign: "middle",
        },
        footStyles: {
          fontSize: 10,
          font: "Inter",
          fontStyle: "bold",
          cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
          valign: "bottom",
        },
        didDrawPage: function (data) {
          if (
            startPageNumber !== doc.internal.getCurrentPageInfo().pageNumber
          ) {
            doc.setPage(startPageNumber);
            doc.setFont("Inter", "bold");
            doc.setFontSize(8);
            doc.text(
              "Totals continue on the next page",
              PAGE_WIDTH - MARGIN,
              doc.lastAutoTable.finalY + 8,
              { align: "right" }
            );
          }
        },
        willDrawCell: function (data) {
          if (data.row.section === "foot") {
            doc.setTextColor(colors.martEye[500]);
          }

          if (data.section === "body" && data.column.index === 0) {
            doc.setFont("Inter", "bold");
          }

          if (data.section === "foot" && data.column.index === 1) {
            doc.setFontSize(14);
          }

          if (data.column.index === 1) {
            data.cell.styles.halign = "right";
          }

          if (data.row.section === "body") {
            doc.setTextColor("#989898");
            doc.setDrawColor("#989898"); // set the border color
            doc.setLineWidth(0.1); // set the border with

            if (data.column.index === 1) {
              doc.setTextColor(colors.martEye[500]);
            }

            // draw bottom border
            doc.line(
              data.cell.x,
              data.cell.y + data.cell.height,
              data.cell.x + data.cell.width,
              data.cell.y + data.cell.height
            );
          }
        },
      });
    };

    const addPricePerHead = (
      doc: any,
      invoice: Invoice | DraftInvoice,
      y: number
    ) => {
      const currency = invoice.currency || "GBP";
      const data = averagePricePerUnitBySuperType(invoice.lineItems);
      const rows: any = [];

      // Defaults
      const maxWidth = PAGE_WIDTH / 2 - MARGIN;
      let lineHeight = 1.4;
      let fontSize = 11;
      let oneLineHeight = (fontSize * lineHeight) / PTSPERMM;

      for (const key in data) {
        const animal = key;
        const pricePerHead = new Intl.NumberFormat("en-gb", {
          style: "currency",
          currency: currency,
        }).format(data[key].averagePrice / 100);
        rows.push(`${animal}: ${pricePerHead} P/H`);
      }

      doc.setTextColor(COLOUR_MARTEYE_500);
      doc.setFont("Inter", "bold");
      doc.setFontSize(fontSize);
      doc.text(MARGIN, y, "Price/head average");
      y += oneLineHeight;

      doc.setTextColor("");
      doc.setFont("Inter", "normal");
      doc.setFontSize(fontSize);
      doc.text(MARGIN, y, rows, {
        lineHeightFactor: lineHeight,
      });
      y += oneLineHeight * (rows.length - 1);

      return y;
    };

    const doc = new jsPDF({ putOnlyUsedFonts: true, compress: true });

    doc.setTextColor(colors.martEye[500]);

    y = addPageOneHeader(doc, invoice, y, "Cheque", market);

    tableRunningHeader(y + MARGIN, doc, invoice);

    let valueCellWidth = 0;
    let vatCellWidth = 0;

    // Line Items Table
    autoTable(doc, {
      head: [["ITEMS", "LOT", "QTY", "PRICE", "VAT", "VALUE"]],
      body: tableData(invoice),
      startY: y + 12.5,
      theme: "plain",
      margin: { top: 18, right: 10, bottom: checkSafeArea, left: 10 },
      headStyles: {
        fillColor: "#eaeaea",
        textColor: "#989898",
        fontSize: 8,
        font: "Inter",
        fontStyle: "bold",
        cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
      },
      bodyStyles: {
        fontSize: 10,
        font: "Inter",
        fontStyle: "normal",
        cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
      },

      willDrawCell: function (data) {
        if (data.column.index === 5) {
          data.cell.styles.halign = "right";
          vatCellWidth = data.cell.width;
        }

        if (data.column.index === 6) {
          data.cell.styles.halign = "right";
          valueCellWidth = data.cell.width;
        }

        // add borders around the head cells
        if (data.row.section === "body") {
          doc.setDrawColor("#CBCBCB"); // set the border color
          doc.setLineWidth(0.1); // set the border with

          // draw bottom border
          doc.line(
            data.cell.x,
            data.cell.y + data.cell.height,
            data.cell.x + data.cell.width,
            data.cell.y + data.cell.height
          );
        }
      },
    });

    let adjustmentData = adjustmentTableData(invoice);

    if (adjustmentData.length) {
      // Adjustments Table
      autoTable(doc, {
        head: [
          [
            invoice.clientType == "Seller" ? "DEDUCTIONS" : "ADDITIONS",
            "VAT",
            "VALUE",
          ],
        ],
        body: adjustmentData,

        columnStyles: {
          1: { cellWidth: vatCellWidth },
          2: { cellWidth: valueCellWidth },
        },

        // @ts-ignore
        startY: doc.lastAutoTable.finalY + 10,
        theme: "plain",
        margin: { top: 18, right: 10, bottom: checkSafeArea, left: 10 },
        headStyles: {
          fillColor: "#eaeaea",
          textColor: "#989898",
          fontSize: 8,
          font: "Inter",
          fontStyle: "bold",
          cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
        },
        bodyStyles: {
          fontSize: 10,
          font: "Inter",
          fontStyle: "normal",
          cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
        },

        willDrawCell: function (data) {
          if (data.column.index === 1) {
            data.cell.styles.halign = "right";
          }

          if (data.column.index === 2) {
            data.cell.styles.halign = "right";
          }

          // add borders around the head cells
          if (data.row.section === "body") {
            doc.setDrawColor("#CBCBCB"); // set the border color
            doc.setLineWidth(0.1); // set the border with

            // draw bottom border
            doc.line(
              data.cell.x,
              data.cell.y + data.cell.height,
              data.cell.x + data.cell.width,
              data.cell.y + data.cell.height
            );
          }
        },
      });
    }

    // Invoice Total
    // @ts-ignore
    //y = addPricePerHead(doc, invoice, doc.lastAutoTable.finalY + MARGIN);

    // @ts-ignore
    //addInvoiceTotal(doc, invoice, doc.lastAutoTable.finalY);

    // Add running headers and footers must be done last
    // addHeaders(doc, invoice);
    //  addFooters(doc);

    if (invoice.clientType === "Seller") {
      // TODO REMOVE THIS
      const pageCount = doc.getNumberOfPages();
      for (var i = 0; i <= pageCount; i++) {
        doc.setPage(i);

        if (CONTRAST_CHEQUE) {
          doc.setFillColor("#ff0000");
          doc.setTextColor("#ffffff");
        } else {
          doc.setFillColor("#ffffff");
          doc.setTextColor("#000000");
        }

        doc.rect(
          0,
          PAGE_HEIGHT - checkSafeArea,
          PAGE_WIDTH,
          checkSafeArea,
          "F"
        );
      }

      const chequeFieldPositions =
        marketChequeFieldPositions[market.id] ||
        marketChequeFieldPositions.default;

      addCheque(doc, invoice, chequeNumber, payoutId, chequeFieldPositions);
      //addHeaders(doc, invoice);
    }

    return doc;

    // const url = doc.output("bloburi");
    // //@ts-ignore
    // return <iframe src={url} height="100%" width="100%"></iframe>;
  },
};

function toNumberUnits(num: number) {
  /* Break up the the number into units for converting to a string or number */
  // divisors in words
  const oneHundredThousand = 100000;
  const tenThousand = 10000;
  const oneThousand = 1000;
  const oneHunderd = 100;
  const ten = 10;
  const oneUnit = 1;

  let hunderdsOfThousands = Math.floor(num / oneHundredThousand);
  num = num - hunderdsOfThousands * oneHundredThousand;

  let tensOfThousands = Math.floor(num / tenThousand);
  num = num - tensOfThousands * tenThousand;

  let thousands = Math.floor(num / oneThousand);
  num = num - thousands * oneThousand;

  let hundreds = Math.floor(num / oneHunderd);
  num = num - hundreds * oneHunderd;

  let tens = Math.floor(num / ten);
  num = num - tens * ten;

  let units = Math.floor(num / oneUnit);
  num = num - units * oneUnit;

  let afterDecimalUnit = Math.round(num * 100);

  let numberOfUnits: numberUnits = {
    hunderdsOfThousands: hunderdsOfThousands,
    tensOfThousands: tensOfThousands,
    thousands: thousands,
    hundreds: hundreds,
    tens: tens,
    units: units,
    afterDecimal: afterDecimalUnit,
  };

  return numberOfUnits;
}

export function toWords(num: number, currency?: string) {
  let currencyUnit = "";
  let afterDecimalUnit = "";

  switch (currency) {
    case "EUR":
      currencyUnit = "EURO";
      afterDecimalUnit = "CENT";
      break;
    case "USD":
      currencyUnit = "DOLLAR";
      afterDecimalUnit = "CENT";
      break;
    case "GBP":
      currencyUnit = "POUND";
      afterDecimalUnit = "PENCE";

    default:
      break;
  }

  var split = num.toString().split(".");

  if (split.length == 1) {
    // return this if no decimal place
    return `${converter.toWords(split[0])} ${currencyUnit}`.toLocaleUpperCase();
  } else {
    // accounts for words
    return `${converter
      .toWords(split[0])
      .toLocaleUpperCase()} ${currencyUnit} AND ${converter
      .toWords(split[1].padEnd(2, "0"))
      .toLocaleUpperCase()} ${afterDecimalUnit}`;
  }
}

function add(
  doc: jsPDF,
  content: string,
  fieldPositions: FieldPositions[],
  fieldName: ChequeField,
  xPadding: number = 5,
  yPadding: number = 5
) {
  let position = fieldPositions.find((field) => field.id === fieldName);

  if (position) {
    doc.text(content, position.x + xPadding, position.y + yPadding);
  } else {
    doc.text(content, 0, 0);
  }
}

async function addCheque(
  doc: jsPDF,
  invoice: Invoice | DraftInvoice,
  chequeNumber: string | null | undefined,
  payoutId: string | null | undefined,
  positions: FieldPositions[]
): Promise<void> {
  const payouts = (invoice as Invoice).payoutsById || undefined;
  let simplePendingPayout: SimplePaymentOut | null = null;

  //we update the cheque number in the payout object and invoice in the `CreatePrintJobsCall` function
  //Aaron. maybe better to pass the cheque number a query param to the invoice object?
  // this wont work if multiple payouts of cheques

  const isValid = chequeNumber && chequeNumber.length > 0 && payoutId;

  if (isValid) {
    simplePendingPayout =
      Object.values(payouts).find(
        (payout) =>
          payout.payoutId === payoutId && payout.paymentMethod === "Cheque"
      ) || null;
  } else if (!isValid) {
    // we dont have a cheque number until printing so we just use the first one. This is only when downloading the pdf
    // we dont consider multiple payouts of cheques

    simplePendingPayout =
      Object.values(payouts).find(
        (payout) => payout.paymentMethod === "Cheque"
      ) || null;
  }

  if (!simplePendingPayout) {
    console.error("No payout found for cheque");
    return;
  }

  // Ideally I want to ensure the payout object hasnt already been iseued. I think passing it as a query parm is a nice way to do it

  // get the payout object from the payout collection

  // we assume cheque will not be posted dated so printing will always be the same day
  const today = new Date().toLocaleDateString("en-GB");
  add(doc, today, positions, "chequeDate");

  // find the first pending cheque payout.
  // Should we include the name here in the payout object?
  // make it easier to change name cheque will keeping al invoice etc in place
  //Aaron

  // need to get the name of the person the cheque is issued to

  // if the cheque is valid we can use the trading name as preferred
  add(doc, invoice.name ?? "", positions, "payee");

  if (isValid) {
    add(doc, chequeNumber, positions, "chequeNumber");
  } else {
    add(doc, CANCELLED_FILLER, positions, "chequeNumber");
  }

  if (simplePendingPayout.amountInCents) {
    let totalInCurrency = (simplePendingPayout.amountInCents / 100).toFixed(2);
    if (chequeNumber) {
      add(doc, `**${totalInCurrency}**`, positions, "amount");
    } else {
      add(doc, ASTERISK_FILLER, positions, "amount");
    }
  }

  let amountToWrite = 0;
  if (chequeNumber && simplePendingPayout.amountInCents) {
    amountToWrite = simplePendingPayout.amountInCents / 100;
  } else {
    amountToWrite = 0;
  }

  // // write the amount in words with optional currency unit
  if (positions.filter((p) => p.id == "amountInWords")) {
    add(
      doc,
      toWords(amountToWrite, invoice.currency),
      positions,
      "amountInWords"
    );
  } else {
    add(doc, CANCELLED_FILLER, positions, "amountInWords");
  }

  const numbersInWords = toNumberUnits(amountToWrite);

  if (positions.filter((p) => p.id == "hunderdsOfThousands")) {
    if (numbersInWords.hunderdsOfThousands > 0) {
      add(
        doc,
        toWords(numbersInWords.hunderdsOfThousands),
        positions,
        "hunderdsOfThousands"
      );
    } else {
      add(doc, ASTERISK_FILLER, positions, "hunderdsOfThousands");
    }
  }

  if (positions.filter((p) => p.id == "tensOfThousands")) {
    if (numbersInWords.tensOfThousands > 0) {
      add(
        doc,
        toWords(numbersInWords.tensOfThousands),
        positions,
        "tensOfThousands"
      );
    } else {
      add(doc, ASTERISK_FILLER, positions, "tensOfThousands");
    }
  }

  if (positions.filter((p) => p.id == "thousands")) {
    if (numbersInWords.thousands > 0) {
      add(doc, toWords(numbersInWords.thousands), positions, "thousands");
    } else {
      add(doc, ASTERISK_FILLER, positions, "thousands");
    }
  }

  if (positions.filter((p) => p.id == "hundreds")) {
    if (numbersInWords.hundreds > 0) {
      add(doc, toWords(numbersInWords.hundreds), positions, "hundreds");
    } else {
      add(doc, ASTERISK_FILLER, positions, "hundreds");
    }
  }

  if (positions.filter((p) => p.id == "tens")) {
    if (numbersInWords.tens > 0) {
      add(doc, toWords(numbersInWords.tens), positions, "tens");
    } else {
      add(doc, ASTERISK_FILLER, positions, "tens");
    }
  }

  if (positions.filter((p) => p.id == "units")) {
    if (numbersInWords.units > 0) {
      add(doc, toWords(numbersInWords.units), positions, "units");
    } else {
      add(doc, ASTERISK_FILLER, positions, "units");
    }
  }
}

export default InvoiceSpec;
