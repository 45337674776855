import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { Fragment, memo, useRef } from "react";

interface SlideUpSheetProps {
  isOpen: boolean;
  close: () => void;
  children?: React.ReactNode;
  classname?: string;
  // If there's another dialog that needs to open over the slideup, supply it here
  submountDialog?: any;
}

function SlideUpSheet(props: SlideUpSheetProps) {
  let open = props.isOpen;
  let onClose = props.close;
  let cancelButtonRef = useRef(null);
  let classname = props.classname || `inset-10`;

  return (
    <Transition show={open === true} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-60"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 "
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </div>
        </TransitionChild>

        <div className="fixed inset-0 z-10">
          <div className="overflow-hidden flex flex-col h-full min-h-full max-h-full justify-center text-center items-center ">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-80 translate-y-full"
              enterTo="opacity-100 translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 scale-100"
              leaveTo="opacity-60 translate-y-full scale-40"
            >
              <DialogPanel
                className={`transform rounded-lg overflow-hidden flex flex-col text-left shadow-xl transition-all absolute ${
                  classname ? classname : "bg-white"
                }`}
              >
                {props.children}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
        {props.submountDialog && props.submountDialog}
      </Dialog>
    </Transition>
  );
}

export default memo(SlideUpSheet);
