import { logout } from "@/data/logout";
import { onAuthStateChanged, signInWithCustomToken, User } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useFirebaseAuth } from "../data/studio-react/firebase/useFirestoreAuth";

export const CurrentUserContext = React.createContext(null as null | User);

export function CurrentUserProvider(props: { children: any }) {
  let auth = useFirebaseAuth();

  let [user, setUser] = useState<User | null>(auth.currentUser);
  useEffect(() => {
    let unsub = onAuthStateChanged(auth, (u) => {
      if (u) {
        setUser(u);
      } else {
        setUser(null);
      }
    });

    return () => {
      unsub();
    };
  }, [setUser, auth]);

  useEffect(() => {
    async function knockknock(token: string) {
      await logout();

      try {
        let result = await signInWithCustomToken(auth, token);
        if (result.user) {
          console.log(
            `Hello ${
              result.user.displayName || result.user.email || result.user.uid
            }`
          );
          return "ok";
        } else {
          console.warn(`Ah ah ah, you didn't say the magic word`);
          return;
        }
      } catch (e) {
        console.warn(`Ah ah ah, you didn't say the magic word`);
        return;
      }
    }

    //@ts-ignore
    window.marteye = Object.assign({}, window.marteye, { knockknock });
  }, [auth]);

  return (
    <CurrentUserContext.Provider value={user}>
      <MemoComp>{props.children}</MemoComp>
    </CurrentUserContext.Provider>
  );
}

const MemoComp = React.memo(function Comp(props: { children: any }) {
  return props.children;
});
