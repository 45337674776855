import { useCurrentUid } from "@/data/studio-react/firebase/useFirestoreAuth";
import { useRouter } from "next/router";
import { useCallback } from "react";

// Dialog

// Tabs
import CartTab from "./Tabs/CartTab";

// Icons
import Flyout from "../Flyout/Flyout";
import { useFlyout } from "../Flyout/useFlyout";
import { TemporaryCustomerIcon } from "../Icons";

const urlParam = "tempAcc";

export function useOpenTemporaryAccountFlyout() {
  let [isFlyoutOpen, openFlyout] = useFlyout([urlParam]);

  let openTemporaryAccountFlyout = useCallback(
    (uid: string | null, clearOthers: boolean = true) => {
      openFlyout(
        uid
          ? {
              [urlParam]: uid,
            }
          : null,
        clearOthers
      );
    },
    [openFlyout]
  );

  return {
    openTemporaryAccountFlyout,
    temporaryAccountFlyoutIsOpen: isFlyoutOpen,
  };
}

export default function TemporaryAccountFlyout() {
  let currentUid = useCurrentUid();
  let router = useRouter();
  let marketId = router.query.marketId as string;
  let saleId = router.query.saleId as string;

  return (
    <Flyout urlParams={[urlParam]}>
      {(urlParamValues) => {
        let uid = urlParamValues ? urlParamValues[urlParam] : null;

        return (
          <Flyout.Body>
            <Flyout.Title>
              <div className="flex">
                <div className="mr-2 flex-shrink-0 self-top">
                  <span
                    className={`inline-flex h-14 w-14 items-center justify-center rounded-full bg-white`}
                  >
                    <TemporaryCustomerIcon width={28} height={28} />
                  </span>
                </div>
                <div>
                  {uid}
                  <span className="block text-sm font-light text-gray-400">
                    Temporary Account
                  </span>
                </div>
              </div>
            </Flyout.Title>
            {/* Main */}
            <div className="relative flex-grow divide-y divide-gray-200 bg-martEye-100">
              <CartTab
                temporaryAccountId={uid}
                currentUid={currentUid}
                saleId={saleId}
                marketId={marketId}
              />
            </div>
          </Flyout.Body>
        );
      }}
    </Flyout>
  );
}
