import {
  deleteField,
  doc,
  getDoc,
  serverTimestamp,
  Transaction,
} from "firebase/firestore";
import { Lot, LotSaleStatus } from "types";
import { FullSaleContext } from "../ExecuteInSaleContext";
import { batchUpdate } from "../firebase/useFirestore";
import { cloudFunction } from "../helpers/cloudfunction";
import { createShortCustomerDetails } from "../helpers/createShortCustomerDetails";

type UpdateObject = {
  [keyPath: string]: any;
};

export async function updateSaleLot(
  ctx: FullSaleContext,
  lotId: string,
  newValues: Partial<Lot> | UpdateObject,
  transaction?: Transaction
): Promise<Lot> {
  await updateSaleLots(ctx, [lotId], newValues, transaction);

  let docRef = doc(
    ctx.firestore,
    `markets/${ctx.marketId}/sales/${ctx.saleId}/lots/${lotId}`
  );
  let updatedLot = await getDoc(docRef);
  return updatedLot.data() as Lot;
}

export function updateSaleLots(
  ctx: FullSaleContext,
  lotIds: string[],
  newValues: Partial<Lot> | UpdateObject,
  transaction?: Transaction
): Promise<void> {
  return updateLots(ctx, lotIds, newValues, transaction);
}

export async function updateLots(
  ctx: FullSaleContext,
  lotIds: string[],
  newValues: Partial<Lot> | UpdateObject,
  transaction?: Transaction
) {
  let { currentUid, marketId, saleId } = ctx;

  if (!newValues || Object.keys(newValues).length === 0) {
    return;
  }

  if (!marketId) throw new Error(`No market id`);
  if (!currentUid) throw new Error(`No current user`);
  if (!saleId) throw new Error(`No sale id`);
  if (!lotIds.length) throw new Error(`No lot ids`);
  if (lotIds.some((id) => !id)) throw new Error(`Missing lot id`);

  if (newValues.id)
    throw new Error(`Cannot change lot id. Please exclude it from update.`);
  if (newValues.saleId && newValues.saleId !== saleId)
    throw new Error(`Cannot change sale id`);
  if (newValues.marketId && newValues.marketId !== marketId)
    throw new Error(`Cannot change market id`);

  // We can change the sale Status but only that and nothing else
  // This forces the update to be atomic.
  if (newValues.saleStatus) {
    if (transaction) {
      throw new Error(`Cannot update the sale status in a transaction.`);
    }
    let keys = Object.keys(newValues);
    if (keys.length > 1 || keys[0] !== "saleStatus") {
      throw new Error(
        `If updating the lot sale status you can only update that parameter and nothing else`
      );
    }

    await updateLotsSaleStatus(ctx, lotIds, newValues.saleStatus);
    return;
  }

  // If the user is setting the buyer or the seller then we need to create the ShortCustomerDetails too
  if ("sellerCustomerId" in newValues) {
    if (
      !newValues.sellerCustomerId ||
      deleteField().isEqual(newValues.sellerCustomerId)
    ) {
      if (transaction) {
        throw new Error(
          `Cannot alter the seller customer id in a transaction.`
        );
      }
      // we wish to delete
      newValues.sellerCustomerId = deleteField();
    }

    newValues.seller = await createShortCustomerDetails(
      ctx,
      newValues.sellerCustomerId
    );
  }

  if ("buyerCustomerId" in newValues) {
    if (
      !newValues.buyerCustomerId ||
      deleteField().isEqual(newValues.buyerCustomerId)
    ) {
      if (transaction) {
        throw new Error(`Cannot alter the buyer customer id in a transaction.`);
      }
      // we wish to delete
      newValues.buyerCustomerId = deleteField();
    }
    newValues.buyer = await createShortCustomerDetails(
      ctx,
      newValues.buyerCustomerId
    );
  }

  if (
    "totalGroupWeightKg" in newValues ||
    "attributes.totalGroupWeightKg" in newValues
  ) {
    throw new Error(`Please use updateLotGroupWeight to update the weight`);
  }

  // If we're setting the unitPrice and the lot start and end times are not set then we need to set them
  let isSettingUnitPrice = "unitPriceInCents" in newValues;

  // All the sold lots move to the top as the sale goes on
  // If we're setting the price here, work out the bounds to move the lot/group between
  let minIndex = 0;
  let maxIndex = Infinity;
  let step = 1;
  if (isSettingUnitPrice) {
    let findLastIndex = (lots: Lot[], predicate: (l: Lot) => boolean) => {
      for (let i = lots.length - 1; i >= 0; i--) {
        if (predicate(lots[i])) {
          return i;
        }
      }
      return -1;
    };

    let lastSoldLotIndex = findLastIndex(ctx.lots, (l) => {
      return l.unitPriceInCents !== undefined && l.saleStatus !== undefined;
    });
    if (lastSoldLotIndex > -1) {
      let lastSoldLot = ctx.lots[lastSoldLotIndex];
      minIndex = lastSoldLot.index;
    }

    // let nextLot = ctx.lots[lastSoldLotIndex + 1];
    // if (nextLot) {
    //   maxIndex = nextLot.index;
    // } else {
    //   if (lastSoldLotIndex) {
    //     let lastSoldLot = ctx.lots[lastSoldLotIndex];
    //     maxIndex = lastSoldLot.index + 100; //arbitrary number
    //   } else {
    //     maxIndex = 100;
    //   }
    // }
    // step = (maxIndex - minIndex) / (lotIds.length + 1);
  }

  const payload = lotIds.map((lotId, idx) => {
    if (isSettingUnitPrice) {
      let previousLot = ctx.lots.find((l) => l.id === lotId);
      if (previousLot && !previousLot?.startedAt && !newValues.startedAt) {
        newValues.startedAt = serverTimestamp();
      }
      if (previousLot && !previousLot?.endedAt && !newValues.endedAt) {
        newValues.endedAt = serverTimestamp();
      }

      // newValues.index = minIndex + step * (idx + 1);
    }
    return {
      docPath: `markets/${marketId}/sales/${saleId}/lots/${lotId}`,
      update: {
        ...newValues,
        updatedAt: serverTimestamp(),
        updatedBy: currentUid,
      },
    };
  });

  // Change here for transaction
  if (transaction) {
    for (let i = 0; i < payload.length; i++) {
      const items = payload[i];
      const docRef = doc(ctx.firestore, items.docPath);
      transaction.update(docRef, items.update);
    }
    return;
  }

  await batchUpdate(payload);
}

interface UpdateLotStatusBody {
  marketId: string;
  saleId: string;
  lotIds: string[];
  saleStatus: LotSaleStatus;
}

async function updateLotsSaleStatus(
  ctx: FullSaleContext,
  lotIds: string[],
  status: LotSaleStatus
) {
  let updateLotSaleStatus = cloudFunction<
    UpdateLotStatusBody,
    {
      success: boolean;
      created: string[];
      deleted: string[];
      updated: string[];
    }
  >("updateLotSaleStatus");

  if (!ctx.marketId || !ctx.saleId) {
    throw new Error(`No market id or sale id`);
  }

  let result = await updateLotSaleStatus({
    marketId: ctx.marketId,
    saleId: ctx.saleId,
    lotIds,
    saleStatus: status,
  });

  console.log(
    `Sale status updated: ${status} for lots: ${lotIds.join(", ")}`,
    result
  );

  return result;
}
