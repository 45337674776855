import { Timestamp } from "firebase-admin/firestore";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { Lot, Sale, Market, Address } from "types";
import { DocumentSpec, SalePDFProps } from "../_importDocs";
import {
  COLOUR_GREY_200,
  COLOUR_MARTEYE_500,
  MARGIN,
  PAGE_HEIGHT,
  PAGE_WIDTH,
  PTSCONVERSION,
} from "../variables";
const SaleSummaryNewSpec: DocumentSpec = {
  // must be unique
  id: "sale-summary",

  objectType: "sale",

  // displayed to the user
  name: "Sale Summary",

  // when the PDF is downloaded, this function is called to generate the filename
  getFilename: (props) => {
    let { sale, lots } = props;

    let sanitisedSaleName = sale.name.replace(/[^a-z0-9]/gi, "_");
    // replace more than one underscore with a single underscore
    sanitisedSaleName = sanitisedSaleName.replace(/_+/g, "_");

    let filesafeDate = (sale.startsAt as Timestamp)
      .toDate()
      .toISOString()
      .split("T")[0]
      .replace(/[^a-z0-9]/gi, "-");

    return `Summary ${filesafeDate} ${sanitisedSaleName}`;
  },

  // unused for sales...
  isAvailableFor: () => {
    return true;
  },

  // reactPDF: (props: SalePDFProps) => {
  //   let { lots, sale } = props;

  //   let attributesWeCareAbout = [
  //     "@eartag",
  //     "@breedCodeOfCattleAPHIS",
  //     "@sexAPHIS",
  //     "@dateOfBirth",
  //   ];

  //   let attributeControllers = sale.attributeSet
  //     .filter((attr) => attributesWeCareAbout.includes(attr.id))
  //     .map((attr) => createAttributeController(attr, null));

  //   let saleDate = (sale.startsAt as Timestamp).toDate();

  //   let friendlyDate = saleDate.toLocaleDateString("en-GB", {
  //     weekday: "long",
  //     year: "numeric",
  //     month: "long",
  //     day: "numeric",
  //   });

  //   lots.sort((a, b) => {
  //     return a.index - b.index;
  //   });

  //   return (
  //     <Document>
  //       <Page size="A4" style={styles.page}>
  //         <View style={styles.section}>
  //           <Text style={{ marginBottom: 16 }}>
  //             {sale.name} - {friendlyDate}
  //           </Text>

  //           {lots.map((lot) => {
  //             let items = Object.values(lot.itemMap);
  //             return (
  //               <View
  //                 key={lot.id}
  //                 wrap={false}
  //                 style={[styles.lotRow, styles.row]}
  //               >
  //                 <View style={[styles.cell, { width: 60 }]}>
  //                   <Text style={styles.text}>Lot {lot.lotNumber}</Text>

  //                   <Text style={styles.text}>x{items.length}</Text>
  //                 </View>

  //                 <View style={styles.cell}>
  //                   <Text style={[styles.text, { marginBottom: 4 }]}>
  //                     {lot.seller?.accountNumber}
  //                   </Text>
  //                   <Text style={styles.text}>{lot.seller?.displayName}</Text>
  //                 </View>

  //                 <View style={[styles.cell, { flexGrow: 1 }]}>
  //                   {items.map((item) => {
  //                     return (
  //                       <View
  //                         key={item.id}
  //                         style={{ display: "flex", flexDirection: "row" }}
  //                       >
  //                         {attributeControllers.map((controller) => (
  //                           <Text style={[styles.attribute]}>
  //                             {controller.getDisplayValue(lot, item.id)}
  //                           </Text>
  //                         ))}
  //                       </View>
  //                     );
  //                   })}
  //                 </View>
  //               </View>
  //             );
  //           })}
  //         </View>
  //       </Page>
  //     </Document>
  //   );
  // },

  // the document template
  jsPDF: (props: SalePDFProps) => {
    const { sale, lots, market } = props;

    let title = "Sale Summary";
    const legalText = `Title for the goods stated on this invoice shall remain with the ${
      market.name ?? "auctioneers"
    } and shall not pass to the purchaser until the amount due under this invoice has been paid in full`;

    const doc = new jsPDF({
      putOnlyUsedFonts: true,
      compress: true,
      unit: "mm",
      format: "a4",
    });

    // Variables
    const pageWidth = PAGE_WIDTH;
    const pageHeight = PAGE_HEIGHT;
    const margin = MARGIN;
    const defaultFontSize = 12;

    // 1. Calculate the page header height and footer height so we know the safe area to work with

    // Header height (1st Page)
    let headerHeight = getPageOneHeader(doc, title, market);
    let y = headerHeight.currentY;

    // Header height (2nd Page+)
    let topY = headerHeight.topY;

    // Footer height
    let bottomY = getPageFooterHeight(doc, 1, legalText);

    // 2. Need to work out the safe areas so if the document is over this then it removes the element and moves it to the next page
    y = getPart1(doc, y, topY, bottomY, sale, lots);
    // 3. Add in the content

    // 4. Need to add running headers and footers
    getPageHeader(doc, "running", title, market);
    getPageFooter(doc, true, 1, legalText);

    return doc;
  },
};

function getPageOneHeader(doc: jsPDF, title: string, market: Market) {
  return getPageHeader(doc, "first", title, market);
}

function getPageHeader(
  doc: jsPDF,
  page: "first" | "running",
  title: string,
  market: Market
) {
  // Start at the top of the page 1

  let y = 0;
  let fontSize = 24;
  let fontColor = COLOUR_MARTEYE_500;

  let titleText = title;
  doc.setDrawColor(COLOUR_GREY_200);

  if (page === "first") {
    doc.setPage(1);
    // Add intial spacing
    y += MARGIN;

    const marketAddress = market.address ? (market.address as Address) : null;

    let martaddress = [
      marketAddress?.address2 ?? "",
      marketAddress?.city ?? "",
      marketAddress?.province ?? "",
      marketAddress?.zip ?? "",
      marketAddress?.country ?? "",
    ];
    let name = market.name ?? "";

    let misc = [
      market.telephone ? "Tel: " + market.telephone : "",
      market.email ? "Email: " + market.email : "",
      market.vatNumber ? "VAT No: " + market.vatNumber : "",
    ];

    let usedMarketAddress = martaddress.filter((line) => line !== "");
    let usedmiscLines = misc.filter((line) => line !== "");

    let data = [
      [
        { content: usedMarketAddress.join(", \n") },
        { content: usedmiscLines.join(", \n") },
        {
          content: titleText,
        },
      ],
    ];

    autoTable(doc, {
      body: data,
      head: [
        [
          {
            content: name,
            colSpan: 3,
          },
        ],
      ],
      startY: y,
      theme: "plain",
      margin: { top: y, right: MARGIN, bottom: 0, left: MARGIN },
      bodyStyles: {
        fontSize: 8,
        font: "Inter",
        fontStyle: "normal",
        textColor: COLOUR_MARTEYE_500,
        cellPadding: { top: 0, bottom: 0, left: 2, right: 2 },
      },
      headStyles: {
        fontSize: 10,
        font: "Inter",
        fontStyle: "bold",
        textColor: COLOUR_MARTEYE_500,
        cellPadding: { top: 0, bottom: 0, left: 2, right: 2 },
      },
      columnStyles: {
        0: {
          cellWidth: 60,
        },
        1: {
          cellWidth: 60,
        },
        2: {
          cellWidth: "auto",
          halign: "right",
          fontSize: 24,
          fontStyle: "bold",
          valign: "top",
        },
      },

      willDrawCell: function (data) {
        if (
          data.row.section === "body" &&
          (data.column.index == 0 || data.column.index == 1)
        ) {
          doc.setLineHeightFactor(1.4); // Use setLineHeightMultiplier instead of setLineHeightFactor
        }
      },
    });

    // @ts-ignore
    y = doc.previousAutoTable.finalY + 10;

    // Add Separating Line
    doc.setLineWidth(2);
    doc.line(MARGIN, y, PAGE_WIDTH - MARGIN, y, "S");

    y += 7;
  }

  // Page 2 Onwards
  let pageTwoY = 0;
  fontSize = 10;
  fontColor = COLOUR_MARTEYE_500;

  // Add intial spacing
  y += MARGIN;

  let titleTextLines = doc
    .setFont("Inter", "bold")
    .setTextColor(fontColor)
    .setFontSize(fontSize)
    .splitTextToSize(titleText, (PAGE_WIDTH - 20) * 0.75);
  let titleHeight = doc.getLineHeight() * titleTextLines.length * PTSCONVERSION;

  // Add the title height
  pageTwoY += titleHeight;

  const pageCount = doc.getNumberOfPages();
  for (var i = 2; i <= pageCount; i++) {
    doc.setPage(i);
    if (page == "running") doc.text(titleTextLines, MARGIN, y);
  }

  pageTwoY += MARGIN;

  return {
    currentY: y,
    topY: pageTwoY,
  };
}

function getPageFooterHeight(doc: jsPDF, page: number, legalText: string) {
  return getPageFooter(doc, false, page, legalText);
}

function getPageFooter(
  doc: jsPDF,
  output: boolean,
  page: number,
  legalText: string
) {
  let y = PAGE_HEIGHT - 5;
  const pageCount = doc.getNumberOfPages();
  for (var i = 1; i <= pageCount; i++) {
    y = PAGE_HEIGHT - 5;
    let fontSize = 8;
    let fontColor = "#616161";

    // Set Page
    doc.setPage(i);

    let legalTextLines = doc
      .setFont("Inter", "normal")
      .setTextColor(fontColor)
      .setFontSize(fontSize)
      .splitTextToSize(legalText, PAGE_WIDTH - 20 - 30);

    let legalTextHeight =
      doc.getLineHeight() * legalTextLines.length * PTSCONVERSION;

    if (output) doc.text(legalTextLines, MARGIN, y - legalTextHeight);

    // minimum footer height
    if (i == 1) {
      y -= legalTextHeight;
    }

    if (output) {
      doc.setFontSize(8);
      doc.text(
        "Page " + String(i) + " of " + String(pageCount),
        PAGE_WIDTH - MARGIN,
        PAGE_HEIGHT - 8,
        {
          align: "right",
        }
      );
    }
    if (i == 1) {
      y -= 10;
    }
  }
  return PAGE_HEIGHT - y;
}

function getPart1(
  doc: jsPDF,
  y: number,
  topY: number,
  bottomY: number,
  sale: Sale,
  lots: Lot[]
) {
  // Loop through the sales
  let issuedAt = sale.startsAt as Timestamp;
  let saleDate = issuedAt.toDate().toDateString();

  var data: any = [];

  // Iterate over lots array
  lots.forEach(function (lot) {
    var items = Object.values(lot.itemMap);
    items.forEach(function (item) {
      data.push([
        lot.lotNumber,
        item.attributes["@eartag"],
        item.attributes["@breedOfCattle"] ??
          item.attributes["@breedOfSheep"] ??
          "",
        item.attributes["@sex"],
      ]);
    });
  });

  autoTable(doc, {
    head: [
      [
        {
          content: `${sale.name}`,
          colSpan: 1,
          styles: {
            fontSize: 10,
            halign: "left",
            textColor: COLOUR_MARTEYE_500,
            fillColor: "#FFFFFF",
            cellPadding: { top: 3, bottom: 2, left: 1, right: 2 },
          },
        },
        {
          content: `${saleDate}`,
          colSpan: 3, // needs to changed when more columns are added
          styles: {
            fontSize: 10,
            halign: "right",
            textColor: COLOUR_MARTEYE_500,
            fillColor: "#FFFFFF",
            cellPadding: { top: 3, bottom: 2, left: 1, right: 2 },
          },
        },
      ],
      [
        {
          content: "Lot",
          styles: { halign: "left" },
        },
        {
          content: "Tag",
          styles: { halign: "left" },
        },
        {
          content: "Breed",
          styles: { halign: "left" },
        },
        {
          content: "Sex",
          styles: { halign: "left" },
        },
      ],
    ],
    body: data,
    startY: y,
    theme: "plain",
    margin: {
      top: topY,
      right: 10,
      bottom: bottomY,
      left: 10,
    },
    headStyles: {
      fillColor: "#EFEFEF",
      textColor: "#616161",
      fontSize: 8,
      font: "Inter",
      fontStyle: "bold",
      cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
    },
    bodyStyles: {
      fontSize: 10,
      font: "Inter",
      fontStyle: "normal",
      cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
    },
  });

  return y;
}

export default SaleSummaryNewSpec;
