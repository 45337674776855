// Loading Animation
import { classNames } from "@/data/classnames";
import { useStudioStream } from "@/data/studio-react/useStudioStream";
import { useMarketId } from "@/data/useMartketId";
import {
  DocumentIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { PrinterIcon } from "@heroicons/react/24/solid";
import { ReactNode, useState } from "react";
import { Spinner } from "../Buttons";
import { ConfirmationDialogueCheckoutFlyoutMount } from "../ConfirmationDialogProvider";
import { DocumentDialog } from "../document-print-dialog/DocumentDialog";
import { CheckoutInfo } from "./CheckoutInfo";
import { CheckoutInfoForm } from "./CheckoutInfoForm";
import { CheckoutSheetHeader } from "./CheckoutSheetHeader";
import {
  CheckoutSheetProvider,
  useCheckoutSheetContext,
} from "./CheckoutSheetProvider";
import { LineItemTable } from "./LineItemTable";
import SlideUpSheet from "./SlideUpSheet";
import { SummaryPanel } from "./SummaryPanel";

export function CheckoutSheet() {
  let [printDialogOpen, setPrintDialogOpen] = useState(false);

  return (
    <CheckoutSheetProvider>
      <CheckoutSheetChrome
        printDialogOpen={printDialogOpen}
        setPrintDialogOpen={setPrintDialogOpen}
      >
        <CheckoutSheetInner setPrintDialogOpen={setPrintDialogOpen} />
      </CheckoutSheetChrome>
    </CheckoutSheetProvider>
  );
}

function CheckoutSheetChrome(props: {
  children: ReactNode;
  printDialogOpen: boolean;
  setPrintDialogOpen: (open: boolean) => void;
}) {
  let { printDialogOpen, setPrintDialogOpen } = props;

  let ctx = useCheckoutSheetContext();
  let { draftInvoice } = ctx;

  let marketId = useMarketId();
  let market = useStudioStream("market", marketId);
  let printers = useStudioStream("printers", marketId);

  return (
    <SlideUpSheet
      isOpen={ctx.isOpen}
      close={ctx.close}
      classname="inset-4 bg-martEye-100"
      submountDialog={
        <>
          <DocumentDialog
            market={market}
            invoice={draftInvoice}
            payouts={[]}
            printers={printers}
            isOpen={printDialogOpen}
            close={() => setPrintDialogOpen(false)}
          />
          <CheckoutInfoForm
            extraParams={ctx.extraParams}
            checkoutGroups={ctx.checkoutGroups || []}
            isOpen={ctx.isInfoFormOpen}
            close={ctx.closeInfoForm}
            applyParams={ctx.applyParams}
          />
          <ConfirmationDialogueCheckoutFlyoutMount />
        </>
      }
    >
      {ctx.isOpen ? props.children : null}
    </SlideUpSheet>
  );
}

function CheckoutSheetInner(props: {
  setPrintDialogOpen: (open: boolean) => void;
}) {
  let ctx = useCheckoutSheetContext();

  let { lots, draftInvoice, isLoading, error } = ctx;

  return (
    <>
      <CheckoutSheetHeader
        clientType={ctx.baseParmeters?.clientType}
        close={ctx.close}
      />
      <div className="flex flex-grow flex-row p-4 overflow-auto">
        <div className="mr-4 flex flex-shrink flex-grow flex-col">
          {error && (
            <div className="border-l-4 border-red-400 bg-red-50 p-4 mb-2 rounded-md">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon
                    className="h-5 w-5 text-red-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-red-700">{error}</p>
                </div>
              </div>
            </div>
          )}
          <div className="mb-4">
            <CheckoutInfo
              checkoutGroups={ctx.checkoutGroups}
              openForm={ctx.openInfoForm}
              allowEdit={!ctx.isIssuingInvoice}
            />
          </div>

          <div className="mb-4">
            <LineItemTable
              isLoadingDocument={ctx.isLoading}
              lotCountHint={ctx.baseParmeters?.lotIdents.length || 0}
              lots={lots}
              lotLineItems={draftInvoice?.lineItems}
              hasError={!!error}
              commissionLineItems={draftInvoice?.adjustmentLineItems.filter(
                (i) => i.subtotalGroup === "Commission"
              )}
              adjustmentLineItems={draftInvoice?.adjustmentLineItems.filter(
                (i) => i.subtotalGroup !== "Commission"
              )}
              discountByLineItem={
                ctx.extraParams.discountsByLineItemInCents || {}
              }
              setDiscountByLineitemInCents={(
                lineItemId: string,
                discount: number | null
              ) => {
                ctx.setDiscountByLineitemInCents(lineItemId, discount);
              }}
              customerIsVATRegistered={draftInvoice?.customerIsVATRegistered}
              customerWithinTaxRegion={draftInvoice?.customerWithinTaxRegion}
            />
          </div>
        </div>
        <div className="flex flex-shrink-1 flex-col gap-y-2">
          <SummaryPanel
            invoice={draftInvoice}
            lots={lots}
            isLoading={isLoading}
          />

          <button
            type="button"
            disabled={isLoading || !draftInvoice || ctx.isIssuingInvoice}
            className={classNames(
              "focus:bg-yellow-500focus:ring-yellow-500 inline-flex items-center justify-center gap-x-1.5 rounded-md bg-yellow-200 px-4 py-4 text-center text-base font-normal text-black shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:bg-yellow-600",
              "disabled:cursor-not-allowed disabled:opacity-50"
            )}
            onClick={() => {
              props.setPrintDialogOpen(true);
            }}
          >
            <PrinterIcon className="h-5 w-5 mr-1" aria-hidden={true} />
            Draft Documents
          </button>
          <button
            type="button"
            disabled={isLoading || !draftInvoice || ctx.isIssuingInvoice}
            className={classNames(
              "relative inline-flex items-center justify-center gap-x-1.5 rounded-md bg-martEye-400 px-4 py-4 text-center text-base font-normal text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-martEye-400 hover:bg-green-700",
              "disabled:cursor-not-allowed disabled:opacity-50"
            )}
            onClick={ctx.issueInvoice}
          >
            <DocumentIcon className="h-5 w-5" aria-hidden={true} />
            Generate{" "}
            {draftInvoice?.clientType === "Buyer" ? "Invoice" : "Statement"}
            <div className="absolute right-8">
              <Spinner animating={ctx.isIssuingInvoice} />
            </div>
          </button>
        </div>
      </div>
    </>
  );
}
