import { Lot, LotIssue } from "types";
import classNames from "classnames";

// Data
import { useSaleAttributeControllers } from "@/data/attribute-definitions/attributeControls";
import { useUrlFragmentParamState } from "../Flyout/useUrlFragmentState";

// UI
import {
  ShoppingCartIcon,
  QuestionMarkCircleIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";

import { GavelDownIcon } from "../Icons";
import { motion } from "framer-motion";
import { Tooltip } from "@/components/Tootip";
import { useCallback, useEffect, useState } from "react";

export const lotValidation = (lot: Lot | null) => {
  let allLotIssues = Object.values(lot?.issues ?? {}).sort((a, b) => {
    return a.createdAt.toMillis() - b.createdAt.toMillis();
  });

  let lotIssues = allLotIssues.filter(
    (issue) => issue.code !== "validation-error"
  );

  let validationIssuesByPath = allLotIssues
    .filter((issue) => issue.code === "validation-error")
    .reduce((acc, issue) => {
      let path = issue.path ?? "";
      if (!acc[path]) {
        acc[path] = [];
      }
      acc[path].push(issue);
      return acc;
    }, {} as { [key: string]: LotIssue[] });

  return {
    allLotIssues,
    lotIssues,
    validationIssuesByPath,
  };
};

export const lotValidationByPath = (lot: Lot, path: string) => {
  const { validationIssuesByPath } = lotValidation(lot);

  let validationIssues = validationIssuesByPath[path] || [];
  let error: boolean | LotIssue = false;

  if (validationIssues.length > 0) {
    error = validationIssues[0];
  }

  return error;
};

export const ValidationIssue = ({ error }: { error: LotIssue }) => {
  const text = error.description;
  const severity = error.severity;

  const icon =
    severity === "error" ? (
      <ExclamationTriangleIcon className="h-5 w-5 " aria-hidden="true" />
    ) : (
      <QuestionMarkCircleIcon className="h-5 w-5 " aria-hidden="true" />
    );

  return (
    <Tooltip text={text}>
      <div
        className={classNames(
          " h-full w-full inline-flex items-center justify-center cursor-help",
          severity === "error" && "text-red-500",
          severity === "warning" && "text-warning",
          severity === "info" && "text-blue-500"
        )}
      >
        {icon}
      </div>
    </Tooltip>
  );
};

export const inputBorder = ({
  error,
  disabled,
}: {
  error: LotIssue | false;
  disabled?: boolean;
}): string => {
  const hasError = error ? true : false;
  const errorSeverity = error ? error.severity : null;

  const classes = classNames(
    "h-[44px] block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 placeholder:text-gray-400 focus-within:ring-2 focus-within:ring-inset focus:outline-none focus:ring-2  sm:text-sm sm:leading-6",
    !hasError &&
      "ring-gray-300 focus-within:ring-martEye-400 focus:ring-martEye-400",
    errorSeverity === "error" &&
      "ring-red-500 focus-within:ring-red-500 focus:ring-red-500",
    errorSeverity === "warning" &&
      "ring-warning focus-within:ring-warning focus:ring-warning",
    errorSeverity === "info" &&
      "ring-blue-500 focus-within:ring-blue-500 focus:ring-blue-500",
    disabled && "bg-gray-100 cursor-not-allowed"
  );

  return classes;
};

export const ValidationIssuesPanel = ({ issues }: { issues: LotIssue[][] }) => {
  if (issues.length === 0) return null;

  const issueArray: LotIssue[] = Object.values(issues).flat(1);
  const issueCount = issueArray.length;

  const [currentIssue, setCurrentIssue] = useState<number>(0);
  const [element, setElement] = useState<HTMLElement | null>(null);
  const [nextIssue, setNextIssue] = useState<LotIssue | null>(
    issueArray[currentIssue] || null
  );

  useEffect(() => {
    if (issueArray.length > 0) {
      setNextIssue(issueArray[currentIssue]);
    }
  }, [currentIssue, issueArray]);

  useEffect(() => {
    if (nextIssue?.path) {
      const el = document.getElementById(nextIssue.path);
      setElement(el);
    }
  }, [nextIssue]);

  const scrollIntoView = async (id: string | null | undefined) => {
    if (!id) return;

    const element = document.getElementById(id);
    if (!element) {
      alert("No element found with id: " + id);
      return;
    }

    const focusElement: IntersectionObserverCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.target === element && entry.intersectionRatio >= 0.9) {
          if (element instanceof HTMLElement) {
            element.focus();
          }
          let nextIssueIndex = currentIssue + 1;
          setCurrentIssue(nextIssueIndex < issueCount ? nextIssueIndex : 0);
          observer.disconnect();
        }
      });
    };

    const observer = new IntersectionObserver(focusElement, {
      root: null,
      rootMargin: "0px",
      threshold: 0.9,
    });

    observer.observe(element);

    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };

  if (!nextIssue) return null;
  return (
    <div className="p-3 sticky top-0 z-10">
      <div className="ring-1 ring-danger-300 text-danger-300 rounded-lg bg-danger-100 p-4 flex items-center justify-between gap-x-4">
        <div className="flex gap-x-2 items-center">
          <div className="bg-white rounded-lg w-[35px] h-[35px] inline-flex items-center justify-center">
            {/* <span className="inline-flex items-center rounded-full bg-danger-300 px-2 py-1 text-xs font-semi-bold text-white">
              {issueCount}
            </span> */}
            <ExclamationTriangleIcon className="h-4 w-4" />
          </div>
          <dl className="font-medium leading-tight text-sm">
            <dt className="text-danger-300 font-bold">
              {issueCount} Fields with Errors
            </dt>
            {!element && (
              <dd className="text-grey-500">{nextIssue.description}</dd>
            )}
          </dl>
        </div>

        <div className="flex gap-x-4 items-center">
          {element && (
            <div>
              <button
                onClick={() => {
                  if (nextIssue?.path) {
                    scrollIntoView(nextIssue?.path);
                  }
                }}
                className="inline-flex gap-x-0.5 w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-100"
              >
                Go to Next Error
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const flyoutErrors = (lot: Lot | null | undefined) => {
  if (!lot) {
    return {
      lotIssues: [],
      validationIssuesByPath: {},
    };
  }

  let allLotIssues = Object.values(lot?.issues ?? {}).sort((a, b) => {
    return a.createdAt.toMillis() - b.createdAt.toMillis();
  });

  let lotIssues = allLotIssues.filter(
    (issue) => issue.code !== "validation-error"
  );
  let validationIssuesByPath = allLotIssues
    .filter((issue) => issue.code === "validation-error")
    .reduce((acc, issue) => {
      let path = issue.path ?? "";
      if (!acc[path]) {
        acc[path] = [];
      }
      acc[path].push(issue);
      return acc;
    }, {} as { [key: string]: LotIssue[] });

  const itemIssuesKeys = Object.keys(validationIssuesByPath).filter((key) =>
    key.startsWith("itemMap")
  );

  // Need to get the itemkeys from the itemIssues and then get the item issues from the lot issues
  const itemIssues = itemIssuesKeys.map((key) => validationIssuesByPath[key]);

  const overviewIssuesKeys = Object.keys(validationIssuesByPath).filter(
    (key) => !key.startsWith("itemMap")
  );
  const overviewIssues = overviewIssuesKeys.map(
    (key) => validationIssuesByPath[key]
  );

  // Lets group the issues by the path
  const allErrors = [...overviewIssues, ...itemIssues];

  return {
    allErrors,
    itemIssues,
    overviewIssues,
  };
};

const lotProgress = (lot: Lot) => {
  if (!lot) {
    return {};
  }

  const hasPrice = lot.unitPriceInCents ?? false;

  let attributes = useSaleAttributeControllers();

  // 1. Get the keys of the attributes that are required for the presale
  const requiredAttributes = attributes.filter(
    (attr) =>
      attr.definition?.requiredPreSale === true ||
      attr.definition?.requiredToCheckout === true
  );

  const preSaleOnly = requiredAttributes.filter(
    (attr) => attr.definition?.requiredPreSale === true
  );

  const preSaleOnlyCount = preSaleOnly.length ?? 0;
  const postSaleCount = requiredAttributes.length ?? 0;

  const requiredCount = hasPrice ? postSaleCount : preSaleOnlyCount;

  // 2. Need to check to see if we have values for each of the required attributes

  // Can assume that validation will work for the requiredPreSale attributes
  const { allErrors = [] } = flyoutErrors(lot);
  const overviewIssuesCount = allErrors.length;

  // Next Error to display – Flatten the array of arrays
  const issueArray: LotIssue[] = Object.values(allErrors).flat(1);
  const nextError = issueArray[0];

  const completed = requiredCount - overviewIssuesCount;
  const percentage = completed / requiredCount;

  const isComplete = completed === requiredCount;

  // Message to display
  const message = hasPrice
    ? isComplete
      ? "Ready to Checkout"
      : "Checkout Blocked"
    : isComplete
    ? "Ready for Sale"
    : "Lot Completion";

  // Icons to display
  const icon = hasPrice ? (
    isComplete ? (
      <ShoppingCartIcon className="h-7 w-7" aria-hidden="true" />
    ) : (
      <XCircleIcon className="h-7 w-7" aria-hidden="true" />
    )
  ) : isComplete ? (
    <GavelDownIcon className="h-5 w-5" aria-hidden="true" />
  ) : (
    <XCircleIcon className="h-7 w-7" aria-hidden="true" />
  );

  return {
    hasPrice: hasPrice,
    progress: percentage,
    completed: completed,
    isComplete: isComplete,
    isPreSale: !hasPrice,
    total: hasPrice ? requiredCount : preSaleOnlyCount,
    nextError,
    message,
    icon,
  };
};

const scrollIntoView = async (id: string | null | undefined) => {
  if (!id) return;

  const element = document.getElementById(id);
  if (!element) {
    alert("No element found with id: " + id);
    return;
  }

  const focusElement: IntersectionObserverCallback = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.target === element && entry.intersectionRatio >= 0.9) {
        if (element instanceof HTMLElement) {
          element.focus();
        }

        observer.disconnect();
      }
    });
  };

  const observer = new IntersectionObserver(focusElement, {
    root: null,
    rootMargin: "0px",
    threshold: 0.9,
  });

  observer.observe(element);

  element.scrollIntoView({
    behavior: "smooth",
    block: "center",
    inline: "center",
  });
};

const NextErrorButton = ({
  nextError,
  lot,
}: {
  nextError: LotIssue;
  lot: Lot;
}) => {
  let [tabIndex, selectTabIndex] = useUrlFragmentParamState("lt");

  const onClick = useCallback(() => {
    if (!nextError.path) return;
    const currentTab = tabIndex ?? "0";
    const errorTab = nextError.path?.startsWith("itemMap") ? 1 : 0;

    // 1. Need to work out if the next error is in the overview or in the items and then what we are currently in
    if (Number(currentTab) === errorTab) {
      if (errorTab === 0) {
        // Scroll into view and focus on the element
        scrollIntoView(nextError.path);
      }
    } else {
      selectTabIndex(String(errorTab));

      if (errorTab === 0) {
        setTimeout(() => {
          // Scroll into view and focus on the element
          scrollIntoView(nextError.path);
        }, 100);
      }
    }
  }, [nextError, tabIndex, selectTabIndex, lot]);

  return (
    <button
      onClick={onClick}
      className="inline-flex gap-x-0.5 w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-100"
    >
      Enter Attribute
    </button>
  );
};

export const LotCompletion = ({ lot }: { lot: Lot }) => {
  const { progress, isComplete, message, icon, nextError, isPreSale } =
    lotProgress(lot);

  return (
    <div className="bg-white w-full p-3 flex flex-col gap-3 overflow-hidden rounded drop-shadow-lg">
      <div className="flex justify-between">
        <div className="flex items-center gap-x-2">
          <div
            className={classNames(
              " w-10 h-10 shrink-0 rounded-lg inline-flex items-center justify-center",
              isComplete
                ? "bg-martEye-100 text-martEye-400"
                : isPreSale
                ? "bg-warning-200 text-warning-500"
                : "bg-danger-200 text-danger-300"
            )}
          >
            {icon}
          </div>
          <dl className="text-sm leading-snug">
            <dt
              className={classNames(
                "font-bold",
                isComplete
                  ? "text-martEye-400"
                  : isPreSale
                  ? "text-martEye-700"
                  : "text-danger-300"
              )}
            >
              {message}
            </dt>

            {!isComplete && (
              <dd className="font-medium text-martEye-700">
                {nextError?.description}
              </dd>
            )}
          </dl>
        </div>{" "}
        {!isComplete && nextError && (
          <div>
            <NextErrorButton nextError={nextError} lot={lot} />
          </div>
        )}
      </div>

      <div className="bg-grey-100 h-2 -mb-3 -mx-3 pt-3 relative">
        <motion.div
          className={classNames(
            "absolute top-0 bottom-0 left-0 w-full origin-left",
            isComplete
              ? "bg-martEye-400"
              : isPreSale
              ? "bg-warning-500"
              : "bg-danger-300"
          )}
          animate={{ scaleX: progress }}
          transition={{ duration: 0.2 }}
        />
      </div>
    </div>
  );
};

export default ValidationIssue;
