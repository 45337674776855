import { Timestamp, deleteField } from "firebase/firestore";
//@ts-ignore
import Handlebars from "handlebars/dist/cjs/handlebars";
import { useMemo } from "react";
import { AttributeDefinition, Lot, LotItem } from "types";
import { useCurrentUid } from "../studio-react/firebase/useFirestoreAuth";
import useStudio from "../studio-react/useStudio";
import { useStudioStream } from "../studio-react/useStudioStream";
import { getCartDetailValue } from "./funcs/getCartDetailValue";
import { getCopyValue } from "./funcs/getCopyValue";
import { getDisplayValue } from "./funcs/getDisplayValue";
import { isReadOnlyOnSaleSheet, isReadonly } from "./funcs/isReadOnly";

type HandlebarsTemplateDelegate<T> = (context: T, options?: any) => string;

export type LotAttributeValueType = string | number | boolean | Timestamp;

export interface LotAttributeController {
  // A value to show in labels and tables
  getDisplayValue: (lot: Lot, itemId: string | null) => string;

  // The value to copy to the clipboard if the user copies from the grid
  // not valid for string values, only numbers
  getCopyValue: (lot: Lot, itemId: string | null) => any;

  // Add this to show the attribue at checkout
  getCartDetailValue: (lot: Lot) => string | null | undefined;

  getValue: (
    lot: Lot,
    itemId: string | null
  ) => LotAttributeValueType | null | undefined;

  // setting to null / undefined will remove the attribute from the lot
  // setting a value will also apply any validation
  setValue(
    lot: Lot,
    value: LotAttributeValueType | null | undefined
  ): Promise<void>;

  // passing null for the item ID will create a new item
  setItemValue(
    lot: Lot,
    itemId: string | null,
    value: LotAttributeValueType | null | undefined
  ): Promise<void>;

  /***
   * true if this attribute for this lot is readonly
   */
  isReadonly: (lot: Lot, itemId: string | null) => boolean;

  // True if you can't edit this cell on the sale sheet
  isReadOnlyOnSaleSheet: (lot: Lot) => boolean;

  isLoading: (lot: Lot, itemId: string | null) => boolean;

  definition: AttributeDefinition;
  _templates: AttributeRenderTemplates;
}

export function useSaleAttributeControllers() {
  let sale = useStudioStream("sale:sale");
  let currentUid = useCurrentUid();
  let studio = useStudio();

  return useMemo(() => {
    if (!sale?.attributeSet) {
      return [];
    }

    return sale.attributeSet.map((attr) =>
      createAttributeController(attr, currentUid, studio)
    );
  }, [sale?.attributeSet, currentUid, studio]);
}

export function createAttributeController(
  attribute: AttributeDefinition,
  currentUid: string | null | undefined,
  studio?: ReturnType<typeof useStudio>
): LotAttributeController {
  let templates = precompileTemplates(attribute);
  return {
    _templates: templates,
    definition: attribute,

    getDisplayValue: (lot: Lot, itemId: string | null) => {
      return getDisplayValue(
        templates,
        getTemplateContext(lot, attribute, itemId),
        attribute,
        itemId
      );
    },

    getCopyValue: (lot: Lot, itemId: string | null) => {
      return getCopyValue(
        templates,
        getTemplateContext(lot, attribute, itemId)
      );
    },

    getCartDetailValue: (lot: Lot) => {
      return getCartDetailValue(
        templates,
        getTemplateContext(lot, attribute, null)
      );
    },

    getValue: (lot: Lot, itemId: string | null) => {
      return getTemplateContext(lot, attribute, itemId).value;
    },

    setValue: async (
      lot: Lot,
      newValue: LotAttributeValueType | null | undefined
    ) => {
      if (!studio) {
        throw new Error(
          `Studio not set - initialise with useSaleAttributeControllers()`
        );
      }

      if (attribute.level !== "lot") {
        throw new Error(
          `Can only set value for lot attributes. ${attribute.id} is a ${attribute.level} attribute`
        );
      }

      if (attribute.id === "totalGroupWeightKg") {
        // special case for weight
        if (!lot.group) {
          throw new Error(
            `Unable to update ${attribute.id}. Lot ${lot.id} has no group`
          );
        }
        if (newValue && typeof newValue !== "number") {
          throw new Error(
            `Unable to update ${attribute.id}. Value must be a number`
          );
        }

        let weightValue = newValue === undefined ? null : (newValue as number);
        await studio.updateLotGroupWeight(lot.group, weightValue);

        return;
      }

      let updateValue;
      if (
        newValue &&
        typeof newValue === "object" &&
        "_seconds" in newValue &&
        "_nanoseconds" in newValue
      ) {
        updateValue = new Timestamp(
          (newValue as { _seconds: number; _nanoseconds: number })._seconds,
          (newValue as { _seconds: number; _nanoseconds: number })._nanoseconds
        );
      } else {
        updateValue = newValue === undefined ? deleteField() : newValue;
      }

      await studio.updateLot(lot.id, {
        [`attributes.${attribute.id}`]: updateValue,
      });
    },

    setItemValue: async (
      lot: Lot,
      itemId: string | null,
      newValue: LotAttributeValueType | null | undefined
    ) => {
      if (!studio) {
        throw new Error(
          `Studio not set - initialise with useSaleAttributeControllers()`
        );
      }
      if (attribute.level !== "item") {
        throw new Error(
          `Can only set value for lot items. ${attribute.id} is a ${attribute.level} attribute`
        );
      }

      // Check if newValue is an object and has the properties of a timestamp map
      let updateValue;
      if (
        newValue &&
        typeof newValue === "object" &&
        "_seconds" in newValue &&
        "_nanoseconds" in newValue
      ) {
        updateValue = new Timestamp(
          (newValue as { _seconds: number; _nanoseconds: number })._seconds,
          (newValue as { _seconds: number; _nanoseconds: number })._nanoseconds
        );
      } else {
        updateValue = newValue === undefined ? deleteField() : newValue;
      }

      // let updateValue = newValue === undefined ? deleteField() : newValue;

      if (!itemId) {
        let lotItem = await studio.createLotItem(lot);
        itemId = lotItem.id;
      }

      await studio.updateLot(lot.id, {
        [`itemMap.${itemId}.attributes.${attribute.id}`]: updateValue,
      });
    },

    isReadonly: (lot: Lot, itemId: string | null) => {
      return isReadonly(
        getTemplateContext(lot, attribute, itemId ? itemId : null),
        lot,
        attribute,
        itemId ? itemId : null
      );
    },

    isReadOnlyOnSaleSheet: (lot: Lot) => {
      return isReadOnlyOnSaleSheet(
        getTemplateContext(lot, attribute, null),
        lot,
        attribute
      );
    },

    isLoading: (lot: Lot, itemId: string | null) => {
      // if the value is <!--loading--> then we're loading
      // also needs to be a read only attribute
      if (!attribute.readonly) {
        return false;
      }

      if (attribute.level === "lot") {
        let path = `attributes.${attribute.id}`;
        let pathReplacingDots = path.replace(/\./g, "_");

        return !!lot.loadingAttributes?.[pathReplacingDots];
      }

      // If there's only one item and the itemId is null then we can use the item ID

      let itemCount = lot.itemMap ? Object.keys(lot.itemMap).length : 0;
      let pathItemId = itemId;
      if (itemCount === 1 && itemId === null) {
        pathItemId = Object.keys(lot.itemMap)[0];
      }

      let path = `itemMap.${pathItemId}.attributes.${attribute.id}`;
      let pathReplacingDots = path.replace(/\./g, "_");

      return !!lot.loadingAttributes?.[pathReplacingDots];
    },
  };
}

export interface TemplateContext {
  value: LotAttributeValueType | null;
  values: LotAttributeValueType[];
  lot: Lot;

  items: LotItem[];

  // if true then all items have the same value
  allItemsHaveSameValue: boolean;

  // if true then nothing has been set for this attribute yet
  noValuesSet: boolean;

  // the number of unique values
  uniqueCount: number;

  // the unique values
  uniqueValues: LotAttributeValueType[];
}

function getTemplateContext(
  lot: Lot,
  attribute: AttributeDefinition,
  itemId: string | null
): TemplateContext {
  let attributeId = attribute.id;
  let items = lot.itemMap ? Object.values(lot.itemMap) : [];

  if (attribute.level === "item") {
    // if it's item level we can:
    //    get the value using the item ID
    //  if the ID is null (e.g. on the sale sheet) then we can get the value from the only item
    //  or if there are multiple items we get all the values

    let values: LotAttributeValueType[];
    let value: LotAttributeValueType | null;
    let uniqueValues: Set<LotAttributeValueType>;

    if (itemId) {
      if (
        lot.itemMap[itemId] &&
        attributeId in lot.itemMap[itemId].attributes
      ) {
        value = lot.itemMap[itemId].attributes[attributeId];
        // important this isn't all the values for the lot, just the values for this item
        // it simplifies the logic in getDisplayValue etc functions
        values = [value!];
      } else {
        values = [];
        value = null;
      }

      uniqueValues = new Set(values);
    } else {
      values = items
        .map((item) => item.attributes[attributeId])
        .map((value) => {
          if (value === null || value === undefined) {
            return null;
          }

          return value;
        })
        .filter((v) => v !== undefined);
      value =
        values.length === 0 ? null : values.length === 1 ? values[0] : null;

      uniqueValues = new Set(values);
      if (uniqueValues.size === 1) {
        value = values[0];
      }
    }

    if (value === undefined) {
      value = null;
    }

    return {
      value,
      values,
      lot,
      items,
      allItemsHaveSameValue: uniqueValues.size < 2,
      noValuesSet: values.length === 0,
      uniqueCount: uniqueValues.size,
      uniqueValues: Array.from(uniqueValues).sort(),
    };
  } else {
    // lot level

    if (itemId !== null && itemId !== undefined) {
      throw new Error(
        `itemId must be null for lot level attributes, it was ${itemId}`
      );
    }

    let attributes = lot.attributes || {};
    let value = attributes[attributeId];
    let values = value !== null && value !== undefined ? [value] : [];

    return {
      value,
      values,
      lot,
      items,
      allItemsHaveSameValue: true,
      noValuesSet: values.length === 0,
      uniqueCount: values.length,
      uniqueValues: values,
    };
  }
}

export interface AttributeRenderTemplates {
  defaultValueCompiledTemplate: null | HandlebarsTemplateDelegate<any>;
  displayCompiledTemplate: null | HandlebarsTemplateDelegate<any>;
  displayCompiledTemplateMultiValue: null | HandlebarsTemplateDelegate<any>;
  // copyCompiledTemplate: null | HandlebarsTemplateDelegate<any>;
  // preSaveCompiledTemplate: null | HandlebarsTemplateDelegate<any>;
  // cartDetailCompiledTemplate: null | HandlebarsTemplateDelegate<any>;
}
function precompileTemplates(attrDef: AttributeDefinition) {
  return {
    // -precompile the templates
    defaultValueCompiledTemplate: null,
    displayCompiledTemplate: attrDef.displayTemplate
      ? Handlebars.compile(attrDef.displayTemplate)
      : null,
    displayCompiledTemplateMultiValue: attrDef.displayTemplateMultiValue
      ? Handlebars.compile(attrDef.displayTemplateMultiValue)
      : null,
    // copyCompiledTemplate: attrDef.copyTemplate
    //   ? Handlebars.compile(attrDef.copyTemplate)
    //   : null,
    // preSaveCompiledTemplate: attrDef.preSaveTemplate
    //   ? Handlebars.compile(attrDef.preSaveTemplate)
    //   : null,
    // cartDetailCompiledTemplate: attrDef.cartDetailTemplate
    //   ? Handlebars.compile(attrDef.cartDetailTemplate)
    //   : null,
    // -/ precompile the templates
  };
}
