import { DocumentSpec, SalePDFProps } from "../../_importDocs";
import { Timestamp } from "firebase-admin/firestore";

// JS PDF
import { Market, SuperType } from "types";
import { jsPDF } from "jspdf";

import { getMarketBranding } from "../../invoice-docs/utils";

// Parts
import table from "./parts/table"; // For now we won't use the table from the sheep-tag-list
import header from "./parts/header";
import footer from "./parts/footer";

const TagListSpec: DocumentSpec = {
  // must be unique
  id: "sale-tag-list",
  objectType: "sale",
  name: "Sale Tag List",

  // when the PDF is downloaded, this function is called to generate the filename
  getFilename: (props) => {
    let { sale } = props;

    let sanitisedSaleName = sale.name.replace(/[^a-z0-9]/gi, "_");
    // replace more than one underscore with a single underscore
    sanitisedSaleName = sanitisedSaleName.replace(/_+/g, "_");

    let filesafeDate = (sale.startsAt as Timestamp)
      .toDate()
      .toISOString()
      .split("T")[0]
      .replace(/[^a-z0-9]/gi, "-");

    return `Tag List ${filesafeDate} ${sanitisedSaleName}`;
  },

  // return true if this document is available for a market to generate
  isAvailableFor: (market: Market, superTypes: SuperType[]) => {
    return true;
  },
  // the document template
  jsPDF: (props: SalePDFProps) => {
    console.time("pdfGeneration");

    const { market, sale, lots } = props;

    const margin = 10;
    const doc = new jsPDF({
      putOnlyUsedFonts: true,
      compress: true,
      unit: "mm",
      format: "a4",
    });

    const {
      dateFormat,
      textFillColor,
      tableHeaderFillColor,
      tableHeaderTextColor,
      tableStripeFillColor,
      tableStripeTextColor,
    } = getMarketBranding(market);

    header(doc, sale, {
      output: false,
      dateFormat: dateFormat,
      title: "Tag List",
      margin: margin,
      textFillColor: textFillColor,
      logo: props.marketLogo,
      market: market,
    });

    table(doc, lots, {
      tagsPerRow: 4,
      margin: margin,
      headerFillColor: tableHeaderFillColor,
      headerTextColor: tableHeaderTextColor,
      stripeFillColor: tableStripeFillColor,
      stripeTextColor: tableStripeTextColor,
    });

    header(doc, sale, {
      output: true,
      dateFormat: dateFormat,
      title: "Tag List",
      margin: margin,
      textFillColor: textFillColor,
      logo: props.marketLogo,
      market: market,
    });

    footer(doc, sale, {
      margin,
      textFillColor: textFillColor,
    });

    console.timeEnd("pdfGeneration");

    return doc;
  },
};

export default TagListSpec;
