// Types
import { Address, DraftInvoice, Invoice, InvoiceLineItem, Market } from "types";

// JS PDF
import { formatAsCurrency } from "@/data/amounts";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { PTSCONVERSION } from "../../utils";

export interface TableConfig {
  margin: number;
  market: Market;
  paymentQrCode?: string | null;
  paymentLink?: string | null;
  headerFillColor: string;
  headerTextColor: string;
  stripeFillColor: string;
  stripeTextColor: string;
  body?: any;
  foot?: any;
  columns?: any;
  startY?: number;
}

export default function Table(
  doc: jsPDF,
  invoice: Invoice | DraftInvoice | DraftInvoice,
  config: TableConfig
) {
  // Table Data
  const main = mainData(invoice);
  tableOutput(doc, {
    ...config,
    ...main,
  });

  const additions = additionsData(invoice);
  tableOutput(doc, {
    ...config,
    ...additions,
  });

  // Totals
  let startY = subTotalData(doc, invoice, config);
  startY = addAverages(doc, invoice, {
    ...config,
    startY,
  });

  addPaymentDetails(doc, invoice, config);
}

const tableOutput = (doc: jsPDF, config: TableConfig) => {
  const margin = config.margin;

  let startY = doc.lastAutoTable.finalY;
  let top = doc.runningHeaderHeight;
  let bottom = doc.mainFooterPosition;

  autoTable(doc, {
    body: config.body,
    foot: config.foot,
    showFoot: "lastPage",
    startY: startY,
    theme: "plain",
    pageBreak: "avoid",
    margin: { left: margin, right: margin, top: top, bottom: bottom },
    headStyles: {
      fillColor: config.headerFillColor,
      textColor: config.headerTextColor,
      fontSize: 8,
      font: "Inter",
      fontStyle: "bold",
      cellPadding: { top: 3, bottom: 3, left: 4, right: 4 },
    },
    bodyStyles: {
      textColor: "#000000",
      fontSize: 9,
      font: "Inter",
      fontStyle: "normal",
      cellPadding: { top: 3, bottom: 3, left: 4, right: 4 },
    },
    footStyles: {
      textColor: "#000000",
      fontSize: 9,
      font: "Inter",
      fontStyle: "bold",
      cellPadding: { top: 3, bottom: 3, left: 4, right: 4 },
    },

    columns: config.columns,
    columnStyles: {
      lotNumber: { cellWidth: "auto" },
      description: { cellWidth: "auto" },
      unitPrice: { cellWidth: 35 },
      taxRate: { cellWidth: 20 },
      quantity: { cellWidth: 20 },
      totalAmount: { cellWidth: 35 },
    },

    willDrawCell: function (data) {
      if (data.column.dataKey === "taxRate") {
        data.cell.styles.halign = "right";
      }

      if (data.column.dataKey === "totalAmount") {
        data.cell.styles.halign = "right";
      }

      // add borders around the head cells
      if (data.row.section === "body") {
        doc.setDrawColor(config.stripeFillColor); // set the border color
        doc.setLineWidth(0.1); // set the border with

        // draw bottom border
        doc.line(
          data.cell.x,
          data.cell.y + data.cell.height,
          data.cell.x + data.cell.width,
          data.cell.y + data.cell.height
        );
      }
    },
  });

  // Lets push the lastY + 5
  doc.lastAutoTable.finalY += 5;
};

// TableData function
const mainData = (invoice: Invoice | DraftInvoice) => {
  let hideLotTaxColumn = invoice.lineItems.every(
    (li) => li.taxAmountInCents === 0
  );
  let currency = invoice.currency || "GBP";

  const data: any = [];

  invoice.lineItems.map((item: InvoiceLineItem) => {
    let row = {
      lotNumber: item.metadata.lotNumber,
      description: item.description,
      // unitPrice: new Intl.NumberFormat("en-gb", {
      //   style: "currency",
      //   currency: currency,
      // }).format(item.impreciseUnitPriceInCents / 100),
      //We need to get guinea on invoice if want to use this currency as it can be given in guineas
      unitPrice: (item.impreciseUnitPriceInCents / 100).toFixed(2),
      taxRate: item.taxRate.percentage * 100 + "%",

      quantity: item.quantity + (item.unitOfSale == "Per KG" ? " Kg" : ""),

      totalAmount: new Intl.NumberFormat("en-gb", {
        style: "currency",
        currency: currency,
      }).format(item.totalAmountInCentsExVat / 100),
    };

    data.push(row);
  });

  let lotSubtotal = invoice.lotTotalInCentsExVat;

  let totalItems = invoice.lineItems
    .map((li) => li.quantity)
    .reduce((a, b) => a + b, 0);

  // Line Items Table
  let columns = [
    {
      header: "LOT",
      dataKey: "lotNumber",
    },
    {
      header: "QTY",
      dataKey: "quantity",
    },

    {
      header: "DESCRIPTION",
      dataKey: "description",
    },
    {
      header: "PRICE",
      dataKey: "unitPrice",
    },
    {
      header: "VAT",
      dataKey: "taxRate",
    },
    {
      header: "VALUE",
      dataKey: "totalAmount",
    },
  ];

  // If we are hiding the tax column, remove it from the columns
  if (hideLotTaxColumn) {
    // Remove the VAT column
    // Find the index of the VAT column in the columns array
    let vatIndex = columns.findIndex((column) => column.dataKey === "taxRate");

    if (vatIndex !== -1) {
      columns.splice(vatIndex, 1);
    }
  }

  let lineItemFoot = [
    {
      content: "",
      dataKey: "lotNumber",
    },
    {
      content: totalItems,
      dataKey: "quantity",
    },
    {
      content: "",
      dataKey: "description",
    },
    {
      content: "",
      dataKey: "unitPrice",
    },
    {
      content: "",
      dataKey: "taxRate",
    },
    {
      content: formatAsCurrency(invoice.currency, lotSubtotal, false),
      dataKey: "totalAmount",
    },
  ];

  // If we are hiding the tax column, remove it from the columns
  if (hideLotTaxColumn) {
    // Remove the VAT column
    // Find the index of the VAT column in the columns array
    let vatIndex = lineItemFoot.findIndex(
      (column) => column.dataKey === "taxRate"
    );

    if (vatIndex !== -1) {
      lineItemFoot.splice(vatIndex, 1);
    }
  }

  return {
    body: data as any,
    columns: columns as any,
    foot: [lineItemFoot] as any,
  };
};

const additionsData = (invoice: Invoice | DraftInvoice) => {
  const data = invoice.adjustmentLineItems || [];
  const currency = invoice.currency || "GBP";
  let hideLotTaxColumn = invoice.lineItems.every(
    (li) => li.taxAmountInCents === 0
  );
  let groupedData = data
    .map((item) => {
      return {
        item,
        collapseKey: `${item.adjustmentConfig?.id || item.productId}-${
          item.taxRate.percentage
        }`,
      };
    })
    .reduce((acc, item) => {
      if (!acc[item.collapseKey]) {
        acc[item.collapseKey] = [];
      }
      acc[item.collapseKey].push(item.item);
      return acc;
    }, {} as { [key: string]: InvoiceLineItem[] });

  let collapsed = Object.values(groupedData).map((items) => {
    let firstItem = items[0];
    let totalAmountInCentsExVat = items.reduce(
      (acc, item) => acc + item.totalAmountInCentsExVat,
      0
    );
    let totalCount = items
      .map((item) => item.quantity)
      .reduce((acc, item) => acc + item, 0);
    return {
      description: firstItem.description,
      price: firstItem.impreciseUnitPriceInCents,
      taxRate: firstItem.taxRate,
      quantity: totalCount,
      totalAmountInCentsExVat,
    };
  });

  // Line Items Table
  let columns = [
    {
      header: "ADDITIONS",
      dataKey: "description",
    },
    {
      header: "QTY",
      dataKey: "quantity",
    },

    {
      header: "PRICE",
      dataKey: "unitPrice",
    },
    {
      header: "VAT",
      dataKey: "taxRate",
    },
    {
      header: "VALUE",
      dataKey: "totalAmount",
    },
  ];

  let body = collapsed.map((item) => {
    return {
      description: item.description,
      unitPrice: new Intl.NumberFormat("en-gb", {
        style: "currency",
        currency: currency,
      }).format(item.price / 100),
      taxRate: item.taxRate.percentage * 100 + "%",
      quantity: item.quantity,
      totalAmount: new Intl.NumberFormat("en-gb", {
        style: "currency",
        currency: currency,
      }).format(item.totalAmountInCentsExVat / 100),
    };
  });

  let total =
    invoice.adjustmentLineItems
      .map((li) => li.totalAmountInCentsExVat)
      .reduce((a, b) => a + b, 0) / 100;

  let totalCurrency = new Intl.NumberFormat("en-gb", {
    style: "currency",
    currency: invoice.currency || "GBP",
  }).format(total);

  let totalQty = invoice.adjustmentLineItems
    .map((li) => li.quantity)
    .reduce((a, b) => a + b, 0);

  let foot = [
    {
      content: "",
      dataKey: "description",
    },
    {
      content: totalQty,
      dataKey: "quantity",
    },

    {
      content: "",
      dataKey: "unitPrice",
    },
    {
      content: "",
      dataKey: "taxRate",
    },
    {
      content: totalCurrency,
      dataKey: "totalAmount",
    },
  ];

  // If we are hiding the tax column, remove it from the columns
  if (hideLotTaxColumn) {
    // Remove the VAT column
    // Find the index of the VAT column in the columns array
    let vatIndex = columns.findIndex((column) => column.dataKey === "taxRate");

    if (vatIndex !== -1) {
      columns.splice(vatIndex, 1);
    }

    // Find the index of the VAT column in the columns array
    let vatIndexFoot = foot.findIndex((column) => column.dataKey === "taxRate");

    if (vatIndexFoot !== -1) {
      foot.splice(vatIndexFoot, 1);
    }
  }

  return {
    body,
    columns,
    foot: [foot],
  };
};

const subTotalData = (
  doc: jsPDF,
  invoice: Invoice | DraftInvoice,
  config: TableConfig
) => {
  const clientType = invoice.clientType;
  const currency = invoice.currency || "GBP";
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  // Data
  const total = new Intl.NumberFormat("en-gb", {
    style: "currency",
    currency: currency,
  }).format(invoice.lotTotalInCentsExVat / 100);

  let vat = new Intl.NumberFormat("en-gb", {
    style: "currency",
    currency: currency,
  }).format(
    (invoice.vatOnCommissionInCents! +
      invoice.vatOnLotTotalInCents +
      invoice.vatOnAdjustmentsInCents!) /
      100
  );
  vat = clientType == "Seller" ? "- " + vat : vat;

  const net = new Intl.NumberFormat("en-gb", {
    style: "currency",
    currency: currency,
  }).format(invoice.finalTotalInCents / 100);

  // Defaults
  let minimumGap = 25;
  let startPageNumber = doc.getCurrentPageInfo().pageNumber;
  // Labels
  let netValueText = "NET VALUE";
  let vatText = "VAT";
  // let totalText = "SUBTOTAL";

  // Work out the minimum height and width required.
  doc.setFont("Inter", "bold");
  doc.setFontSize(14);
  let netValueTotalWidth = doc.getTextWidth(net);
  doc.setFontSize(10);
  let netValueTextWidth = doc.getTextWidth(netValueText);

  // Table Width
  let tableWidth = netValueTotalWidth + netValueTextWidth + minimumGap;
  let startX = pageWidth - config.margin - tableWidth;

  // Start The Table
  let subtotals = Object.keys(invoice.subtotalGroupTotals).map((key) => {
    let amount = invoice.subtotalGroupTotals[key];
    let amountFormatted = new Intl.NumberFormat("en-gb", {
      style: "currency",
      currency: currency,
    }).format(amount / 100);
    return [key.toUpperCase(), amountFormatted];
  });

  let body = [...subtotals, [vatText, vat]];
  // let head = [[totalText, total]];

  let startY = doc.lastAutoTable.finalY + config.margin ?? 0;
  let top = doc.runningHeaderHeight;
  let bottom = doc.mainFooterPosition;

  doc.setTextColor(config.stripeTextColor);

  autoTable(doc, {
    body: body,
    // head: head,
    pageBreak: "avoid",
    foot: [[netValueText, net]],
    startY: startY,
    theme: "plain",
    margin: { top: top, right: config.margin, bottom: bottom, left: startX },
    showHead: "firstPage",
    tableWidth: tableWidth,
    bodyStyles: {
      fontSize: 10,
      font: "Inter",
      fontStyle: "normal",
      cellPadding: { top: 3.5, bottom: 3.5, left: 4, right: 4 },
      valign: "middle",
    },
    footStyles: {
      fontSize: 14,
      font: "Inter",
      fontStyle: "bold",
      cellPadding: { top: 3.5, bottom: 3.5, left: 4, right: 4 },
      valign: "bottom",
    },
    headStyles: {
      fontSize: 10,
      font: "Inter",
      fontStyle: "bold",
      fillColor: config.stripeFillColor,
      textColor: config.stripeTextColor,
      cellPadding: { top: 3.5, bottom: 3.5, left: 4, right: 4 },
      valign: "middle",
    },

    didDrawPage: function (data) {
      if (startPageNumber !== doc.getCurrentPageInfo().pageNumber) {
        // If we are on a new page, we need to reset the startY
        startY = top;

        let newPage = doc.getCurrentPageInfo().pageNumber;

        // Add a note to the bottom of the page
        doc.setTextColor(config.stripeTextColor);
        doc.setPage(startPageNumber);
        doc.setFont("Inter", "bold");
        doc.setFontSize(10);
        doc.text(
          "Totals continue on the next page",
          pageWidth - config.margin,
          doc.lastAutoTable.finalY + config.margin / 2,
          { align: "right" }
        );

        // Set the page number
        doc.setPage(newPage);
      }
    },
    willDrawCell: function (data) {
      if (data.section === "body" && data.column.index === 0) {
        doc.setFont("Inter", "bold");
      }

      if (data.section === "foot" && data.column.index === 0) {
        doc.setFontSize(10);
      }

      if (data.column.index === 1) {
        data.cell.styles.halign = "right";
      }

      if (data.row.section === "body") {
        doc.setDrawColor("#989898"); // set the border color
        doc.setLineWidth(0.1); // set the border with

        if (data.column.index === 1) {
          doc.setTextColor(config.stripeTextColor);
        }

        // draw bottom border
        doc.line(
          data.cell.x,
          data.cell.y + data.cell.height - 0.5,
          data.cell.x + data.cell.width,
          data.cell.y + data.cell.height - 0.5
        );
      }
    },
  });

  return startY;
};

const addPaymentDetails = (
  doc: jsPDF,
  invoice: Invoice | DraftInvoice,
  config: TableConfig
) => {
  const marketAddress = config.market?.address as Address | undefined;
  const paymentDetails =
    invoice?.paymentInstructions ?? config?.market?.paymentInstructions ?? "";

  // Need to split the payment details into lines based on \n and remove any empty lines
  const paymentDetailsLines = paymentDetails
    .split("\\n")
    .map((line) => line.trim());

  // Defaults
  const pageHeight = doc.internal.pageSize.getHeight();
  const pageWidth = doc.internal.pageSize.getWidth();
  const maxWidth = pageWidth / 2 - config.margin;
  const bottom = doc.mainFooterPosition;

  // Remove non needed lines
  let lines = [marketAddress?.company ?? "", ...paymentDetailsLines];

  // Remove any empty lines
  lines = lines.filter((line) => line !== "");

  if (!lines.length) {
    // No payment details
    lines = ["Please get in touch with us for payment details"];
  }

  // Work out if we need to further split the lines
  let splitLines: string[] = [];
  lines.forEach((line) => {
    // If the line is too long, we need to split it
    splitLines = [...splitLines, ...doc.splitTextToSize(line, maxWidth / 2)];
  });

  lines = splitLines;

  // Space Needed
  let spaceNeeded = 0;

  // Add the text height to the space needed
  doc.setLineHeightFactor(1.4);
  let textHeight = doc.getLineHeight() * (lines.length + 1) * PTSCONVERSION;

  spaceNeeded += textHeight;

  // If we have a QR code, add the space needed for the QR code if this is less than the text height
  let qrCodeHeight = maxWidth / 2 - config.margin;
  let qrCodeX = config.margin + 5;
  let startX = config.margin + 5;

  if (config.paymentQrCode) {
    spaceNeeded += 5;
    startX = config.margin + maxWidth / 2;
    if (qrCodeHeight > spaceNeeded) {
      spaceNeeded = qrCodeHeight;
    }
  }

  // Add padding to the space needed
  spaceNeeded += 10;

  // Need to see if we need to add a page break
  let startY =
    pageHeight - doc.mainFooterPosition - spaceNeeded - config.margin / 2;
  let spaceLeft = pageHeight - startY - bottom;

  if (spaceNeeded > spaceLeft) {
    doc.addPage();
    startY = doc.runningHeaderHeight;
  }

  // Start Output
  let qrCodeY = startY + 5;

  // Add a rectangle
  if (invoice.clientType === "Buyer") {
    doc.setFillColor(config.stripeFillColor);
    doc.roundedRect(config.margin, startY, maxWidth, spaceNeeded, 2, 2, "F");

    // Add the text to the rectangle with padding
    doc.setTextColor(config.stripeTextColor);
    doc.setFontSize(14);
    doc.setFont("Inter", "bold");

    let startText = startY + 5;
    if (config.paymentQrCode) {
      startText += 5;
    }

    doc.text("Payment Details", startX, startText, {
      align: "left",
      baseline: "top",
    });

    let lineHeight = doc.getLineHeight() * PTSCONVERSION;
    if (lineHeight) {
      startText += lineHeight;
    }
    doc.setFont("Inter", "normal");
    doc.setFontSize(10);

    doc.text(lines, startX, startText, {
      align: "left",
      baseline: "top",
    });
  }

  if (config.paymentLink && config.paymentQrCode) {
    // Make the image a link
    doc.link(qrCodeX, qrCodeY, qrCodeHeight, qrCodeHeight, {
      url: config.paymentLink,
    });
    doc.addImage(
      config.paymentQrCode,
      "PNG",
      qrCodeX,
      qrCodeY,
      qrCodeHeight,
      qrCodeHeight
    );
  }

  // Set the line height back to the original
  doc.setLineHeightFactor(1.15);

  return startY + spaceNeeded + config.margin / 2;
};

const addAverages = (
  doc: jsPDF,
  invoice: Invoice | DraftInvoice,
  config: TableConfig
) => {
  const averages = (invoice.averages ?? []).map(
    ({ label, value }) => `${label}: ${value}`
  );

  // Defaults
  const pageHeight = doc.internal.pageSize.getHeight();
  const pageWidth = doc.internal.pageSize.getWidth();
  const maxWidth = pageWidth / 2 - config.margin;
  const bottom = doc.mainFooterPosition;

  let startY = config.startY ?? doc.lastAutoTable.finalY + config.margin ?? 0;

  // Bail out if we have no averages
  if (averages.length === 0) {
    return startY;
  }

  // Remove non needed lines
  let lines = [...averages];

  // Remove any empty lines
  lines = lines.filter((line) => line !== "");

  // Work out if we need to further split the lines
  let splitLines: string[] = [];
  lines.forEach((line) => {
    // If the line is too long, we need to split it
    splitLines = [...splitLines, ...doc.splitTextToSize(line, maxWidth)];
  });

  lines = splitLines;

  // Space Needed
  let spaceNeeded = 0;

  // Add the text height to the space needed
  doc.setLineHeightFactor(1.4);
  let textHeight = doc.getLineHeight() * (lines.length + 1) * PTSCONVERSION;

  spaceNeeded += textHeight;

  // Add padding to the space needed
  spaceNeeded += 10;

  // Need to see if we need to add a page break
  let spaceLeft = pageHeight - startY - bottom;

  if (spaceNeeded > spaceLeft) {
    doc.addPage();
    startY = doc.runningHeaderHeight;
  }

  // Add a rectangle
  doc.setFillColor(config.stripeFillColor);
  doc.roundedRect(config.margin, startY, maxWidth, spaceNeeded, 2, 2, "F");

  // Add the text to the rectangle with padding
  doc.setTextColor(config.stripeTextColor);

  // Add the text to the rectangle with padding
  doc.setTextColor(config.stripeTextColor);
  doc.setFontSize(14);
  doc.setFont("Inter", "bold");

  let startText = startY + 5;

  doc.text("Averages", config.margin + 5, startText, {
    align: "left",
    baseline: "top",
  });

  let lineHeight = doc.getLineHeight() * PTSCONVERSION;
  if (lineHeight) {
    startText += lineHeight;
  }
  doc.setFont("Inter", "normal");
  doc.setFontSize(10);

  doc.text(lines, config.margin + 5, startText, {
    align: "left",
    baseline: "top",
  });
  doc.setLineHeightFactor(1.15);

  return config.startY ?? 0;
};
