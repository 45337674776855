import jsPDF from "jspdf";
import { PAGE_HEIGHT, PAGE_WIDTH, PTSCONVERSION, MARGIN } from "./defaults";

export function getPageFooterHeight(doc: jsPDF, legalText?: string) {
  return getPageFooter(doc, legalText, false);
}

export default function getPageFooter(
  doc: jsPDF,
  legalText: string | null = null,
  output: boolean = true
) {
  let previousTextColor = doc.getTextColor();
  let previousFontSize = doc.getFontSize();

  doc.setFontSize(8);
  doc.setTextColor("#616161");

  let y = PAGE_HEIGHT - 5;
  const pageCount = doc.getNumberOfPages();
  for (var i = 1; i <= pageCount; i++) {
    y = PAGE_HEIGHT - 5;

    // Set Page
    doc.setPage(i);

    if (legalText) {
      let legalTextLines = doc.splitTextToSize(legalText, PAGE_WIDTH - 20 - 30);

      let legalTextHeight =
        doc.getLineHeight() * legalTextLines.length * PTSCONVERSION;

      if (output) doc.text(legalTextLines, MARGIN, y - legalTextHeight);

      // minimum footer height
      if (i == 1) {
        y -= legalTextHeight;
      }
    }

    if (output) {
      doc.setFontSize(8);
      doc.text(
        "Page " + String(i) + " of " + String(pageCount),
        PAGE_WIDTH - MARGIN,
        PAGE_HEIGHT - 8,
        {
          align: "right",
        }
      );
    }
    if (i == 1) {
      y -= 10;
    }

    // Reset
    doc.setTextColor(previousTextColor);
    doc.setFontSize(previousFontSize);
  }
  return PAGE_HEIGHT - y;
}
