import { FirebaseApp, getApps, initializeApp } from "firebase/app";
import {
  clearIndexedDbPersistence,
  enableIndexedDbPersistence,
  getFirestore,
} from "firebase/firestore";

export function initialiseFirebase() {
  let apps = getApps();

  if (apps.length) {
    // console.log("app already initialised");
    return apps[0];
  }

  const app = initializeApp({
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_MEASUREMENT_ID,
    storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
  });

  // Enable persistence & check to see if the cache should be cleared
  enablePersistence(app);

  return app;
}

async function enablePersistence(app: FirebaseApp) {
  let db = getFirestore(app);

  if (typeof window === "undefined") {
    return;
  }

  // Sometimes persisted bad data that causes the code to throw never gets cleared

  // Check to see if the cache should be cleared first
  if (
    window.localStorage &&
    window.localStorage.getItem("clearFirestoreCache")
  ) {
    window.localStorage.removeItem("clearFirestoreCache");
    console.log("Clearing Firestore cache");
    await clearIndexedDbPersistence(db);
  }

  try {
    await enableIndexedDbPersistence(db);
    // Mount handler here to clear the cache if there's an unhandled error IN THE APP (not as a result of the persistence)
    window.addEventListener("unhandledrejection", function (event) {
      console.error(
        "Unhandled rejection (promise: ",
        event.promise,
        ", reason: ",
        event.reason,
        "). Will clear cache on reload."
      );

      // Write a param to clear the cache on next load
      window.localStorage.setItem("clearFirestoreCache", "true");
    });

    window.addEventListener("error", function (event) {
      console.error("Error: ", event.error, "). Will clear cache on reload.");

      // Write a param to clear the cache on next load
      window.localStorage.setItem("clearFirestoreCache", "true");
    });
  } catch (e) {
    let err = e as { code: string };

    if (err.code == "failed-precondition") {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
      console.log(
        "Multiple tabs open, persistence can only be enabled in one tab at a a time."
      );
    } else if (err.code == "unimplemented") {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
      console.log(
        "The current browser does not support all of the features required to enable persistence"
      );
    }
  }
}
