import colours from "../tailwind.config";
// @ts-ignore
const martEye = colours?.theme?.extend?.colors?.martEye || "";

export const MARGIN = 10;
export const PAGE_WIDTH = 210;
export const PAGE_HEIGHT = 297;
export const PTSPERMM = 2.835;
export const PTSCONVERSION = 0.352778;

//TODO link up the colours with tailwind.
export const COLOUR_MARTEYE_700 = martEye[700];
export const COLOUR_MARTEYE_500 = martEye[500];
export const COLOUR_MARTEYE_400 = martEye[400];
export const COLOUR_MARTEYE_200 = martEye[200];
export const COLOUR_MARTEYE_100 = martEye[100];
export const COLOUR_GREY_200 = "#CBCBCB";

export const ASTERISK_FILLER = "*****";
export const CANCELLED_FILLER = "CANCELLED";
