import { useCurrentUid } from "@/data/studio-react/firebase/useFirestoreAuth";
import { updateCustomer } from "@/data/customer";
import {
  AttributeDefinition,
  Customer,
  AttributeValueType,
} from "../../../../types";
import { useStudioStream } from "@/data/studio-react/useStudioStream";

import DefaultsTextField from "./Defaults/InputField";
import DefaultsCheckboxField from "./Defaults/CheckboxField";

import { useCallback } from "react";

import { toast } from "sonner";

interface SettingsProps {
  customer: Customer;
  marketId: string;
}

function SettingsDefaults(props: SettingsProps) {
  let { customer, marketId } = props;

  let currentUid = useCurrentUid();

  const attributeDefaultsBuyer = customer?.attributeDefaultsBuyer || {};
  const attributeDefaultsSeller = customer?.attributeDefaultsSeller || {};

  let allAttributes = useStudioStream("globalAttributes");

  let sellerAttributes = allAttributes?.filter(
    (a) => a.sellerCustomerAttribute
  );

  let buyerAttributes = allAttributes?.filter((a) => a.buyerCustomerAttribute);
  // console.log("seller", sellerAttributes);
  // console.log("buyer", buyerAttributes);

  if (!buyerAttributes || !sellerAttributes || !customer || !currentUid) {
    return null;
  }

  return (
    <div className="grid gap-4">
      <div className="bg-white rounded-md w-full p-4">
        <h2 className="text-martEye-500 font-bold mb-2">Buyer Defaults</h2>

        <div className="grid grid-cols-2 gap-4">
          {buyerAttributes?.map((attribute) => {
            let currentValue = attributeDefaultsBuyer[attribute.id] || [];
            console.log("buyerCurrentValue", currentValue);

            return (
              <DefaultSettingEditor
                key={attribute.id}
                attribute={attribute}
                currentValues={currentValue}
                customer={customer}
                currentUid={currentUid}
                marketId={marketId}
                allValues={attributeDefaultsBuyer}
                attributeType="Buyer"
              />
            );
          })}
        </div>
      </div>
      <div className="bg-white rounded-md w-full p-4">
        <h2 className="text-martEye-500 font-bold">Seller Defaults</h2>
        <div className="grid grid-cols-2 gap-4">
          {sellerAttributes?.map((attribute) => {
            let currentValue = attributeDefaultsSeller[attribute.id] || [];
            console.log("sellerCurrentValue", currentValue);
            return (
              <DefaultSettingEditor
                key={attribute.id}
                attribute={attribute}
                currentValues={currentValue}
                customer={customer}
                currentUid={currentUid}
                marketId={marketId}
                allValues={attributeDefaultsSeller}
                attributeType="Seller"
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default SettingsDefaults;

const DefaultSettingEditor = ({
  attribute,
  currentValues,
  allValues,
  customer,
  marketId,
  currentUid,
  attributeType,
}: {
  attribute: AttributeDefinition;
  currentValues: AttributeValueType[];
  allValues: {
    [attributekey: string]: AttributeValueType[];
  };
  customer: Customer;
  marketId: string;
  currentUid: string | null;
  attributeType: "Buyer" | "Seller";
}) => {
  const attributeId = attribute.id;
  const customerId = customer.id;

  const setValues = useCallback(
    (value: string[] | boolean | number) => {
      if (!currentUid) {
        toast.error("You must be logged in to update customer attributes");
        return;
      }

      let update: Partial<Customer> = {
        [`attributeDefaults${attributeType}`]: {
          ...allValues,
          [attributeId]: value,
        },
      };
      updateCustomer(marketId, currentUid, customerId, update);
    },
    [
      attributeId,
      marketId,
      customerId,
      updateCustomer,
      currentUid,
      allValues,
      attributeType,
    ]
  );

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.target.blur(); // This will remove focus from the input field
    }
  };

  return (
    <div className="border rounded-lg p-3">
      <h2 className="font-bold text-martEye-800 text-sm">{attribute.name}</h2>

      {attribute.type === "string" && (
        <DefaultsTextField
          attribute={attribute}
          values={currentValues as string[]}
          setValues={setValues}
        />
      )}

      {attribute.type === "boolean" && (
        <DefaultsCheckboxField
          attribute={attribute}
          values={currentValues as boolean[]}
          setValues={setValues}
        />
      )}
    </div>
  );
};
