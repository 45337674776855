import React from "react";
import { CurrenciesWithGuinea, LotIssue } from "types";

// UI
import { Spinner } from "@/components/Buttons";
import classNames from "classnames";
import { ValidationIssue, inputBorder } from "../LotFlyout/LotValidation";

// Data
import { currencyToSymbol } from "@/data/amounts";

interface InputProps {
  id?: string;
  label: string;
  name: string;
  value: string;
  type?: "text";
  disabled?: boolean;
  className?: string;
  required?: boolean;
  error?: LotIssue | false;
  prefix?: string;
  suffix?: string;
  isLoadingIn?: boolean;
  currency?: CurrenciesWithGuinea;

  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;

  selectOnFocus?: boolean;
  blurOnEnter?: boolean;
}

const SimpleTextInput = React.forwardRef<HTMLInputElement, InputProps>(
  function TextInputComponent(
    props: InputProps,
    ref: React.Ref<HTMLInputElement>
  ) {
    // Initial props
    const {
      id,
      type,
      name,
      value,
      disabled,
      required,
      prefix,
      suffix,
      className,
      isLoadingIn,
      currency,
    } = props;

    let error = props.error ?? false;

    // Only show error if the field has been interacted with
    if (!value) {
      error = false;
    }

    // Validation
    const inputBorderClasses = inputBorder({ error, disabled });
    const inputClasses = classNames(
      "w-full rounded border-0 focus-within:outline-none focus-within:ring-0 focus:outline-none focus:ring-0 focus:ring-transparent text-sm py-2 px-3 bg-transparent",
      disabled ? "cursor-not-allowed" : ""
    );

    return (
      <>
        <div className={className}>
          <label
            htmlFor={id ?? name}
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {props.label}

            {required && (
              <span className="text-red-500 ml-0.5" aria-hidden="true">
                * <span className="sr-only">Required Field</span>
              </span>
            )}
          </label>
          <div className="relative mt-1">
            <div className={inputBorderClasses}>
              <ul className="flex items-center gap-x-[2px] p-[2px] h-full w-full">
                {currency && (
                  <li className="shrink-0">
                    <span className="text-sm py-2 pl-3 -mr-3">
                      {currencyToSymbol(currency)}
                    </span>
                  </li>
                )}
                <li className="relative w-full min-w-0">
                  <input
                    ref={ref}
                    type={type ?? "text"}
                    name={name}
                    id={id ?? name}
                    className={inputClasses}
                    value={value}
                    disabled={disabled}
                    onFocus={(e) => {
                      if (props.selectOnFocus) {
                        e.target.select();
                      }
                      props.onFocus && props.onFocus(e);
                    }}
                    onChange={(e) => {
                      let value = e.target.value;
                      props.onChange && props.onChange(value);
                    }}
                    onBlur={(e) => props.onBlur && props.onBlur(e.target.value)}
                    onKeyDown={(e) => {
                      if (props.blurOnEnter) {
                        if (e.key === "Enter") {
                          (e.target as HTMLInputElement).blur();
                        }
                      }
                    }}
                  />
                </li>{" "}
                {isLoadingIn && (
                  <li className="w-[30px] shrink-0 h-full rounded overflow-hidden relative">
                    <div className="flex h-full w-full items-center justify-center">
                      <Spinner className="h-4 w-4 animate-spin" />
                    </div>
                  </li>
                )}
                {error && value && (
                  <li className="w-[30px] shrink-0 h-full rounded overflow-hidden relative">
                    <ValidationIssue error={error} />
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
);
export default SimpleTextInput;
