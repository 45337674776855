import {
  DocumentData,
  DocumentReference,
  Query,
  QueryConstraint,
  collection,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";
import {
  Invoice,
  Lot,
  Product,
  ProductCodeConfiguration,
  ProductConfiguration,
  Sale,
  SettingsMarketDefaults,
  SettingsProductCodes,
} from "types";
import { MemoComponent } from "../../components/MemoComponent";
import { ExecuteInSaleProvider } from "./ExecuteInSaleContext";
import {
  DocLoadState,
  QueryLoadState,
  useFirestore,
  useLoadFirestoreDoc,
  useLoadFirestoreQuery,
} from "./firebase/useFirestore";
import { useCurrentUid } from "./firebase/useFirestoreAuth";
import {
  StatsAndFilters,
  useLoadFilteredLotData,
} from "./loaders/useFilteredLotData";
import { SaleDataCustomersProvider } from "./SaleDataCustomersProvider";

interface StudioSaleProviderProps {
  marketId: string;
  saleId: string;
  children?: any;
}

const defaultDocLoadValue = {
  data: null,
  error: null,
  loading: false,
  exists: null,
};

const defaultQueryLoadValue: QueryLoadState<any> = {
  data: [],
  loading: false,
  hasLoaded: false,
  error: null,
  changes: {
    added: [],
    modified: [],
    removed: [],
    items: [],
    prevItems: [],
  },
  addListener: () => {
    throw new Error("woah there cowboy");
  },
};

const createInitialState = (
  instanceId: string,
  marketId: string,
  saleId: string
) => ({
  instanceId, // just for debugging
  marketId,
  saleId,

  saleInfo: defaultDocLoadValue as DocLoadState<Sale>,
  lotsInfo: defaultQueryLoadValue as QueryLoadState<Lot>,
  lotsFiltersAndStats: null as StatsAndFilters | null,

  invoicesInfo: defaultQueryLoadValue as QueryLoadState<Invoice>,

  marketDefaultSettingsInfo:
    defaultDocLoadValue as DocLoadState<SettingsMarketDefaults>,
  productCodesSettingsInfo:
    defaultDocLoadValue as DocLoadState<SettingsProductCodes>,
  productsInfo: defaultDocLoadValue as DocLoadState<ProductConfiguration>,

  // derrived
  saleHasLoaded: false as boolean,
  lotsHaveLoaded: false as boolean,
  invoicesHaveLoaded: false as boolean,

  saleProductCodes: [] as ProductCodeConfiguration[],
  products: [] as Product[],
});

type State = ReturnType<typeof createInitialState>;
type StateKey = keyof State;
type StateValue = State[StateKey];

function reducer(
  state: State,
  action:
    | { type: "merge"; payload: { key: StateKey; value: StateValue } }
    | {
        type: "reset";
        payload: { instanceId: string; marketId: string; saleId: string };
      }
) {
  let nextState = state;

  if (action.type === "reset") {
    let fromKey = `${state.instanceId}-${state.marketId}-${state.saleId}`;
    let toKey = `${action.payload.instanceId}-${action.payload.marketId}-${action.payload.saleId}`;

    if (fromKey === toKey) {
      // no change
      console.log(`Reset called but there was no change to the IDs. Ignoring.`);
      return state;
    }

    return createInitialState(
      action.payload.instanceId,
      action.payload.marketId,
      action.payload.saleId
    );
  }

  if (action.type === "merge") {
    if (state[action.payload.key] === action.payload.value) {
      // no change
      return state;
    }

    nextState = { ...state, [action.payload.key]: action.payload.value };

    let saleHasLoaded = !!nextState.saleInfo.data;
    let lotsHaveLoaded = nextState.lotsInfo.hasLoaded;
    let invoicesHaveLoaded = nextState.invoicesInfo.hasLoaded;

    if (
      action.payload.key === "saleInfo" ||
      action.payload.key === "productCodesSettingsInfo"
    ) {
      let saleProductCodes = [] as ProductCodeConfiguration[];
      if (nextState.productCodesSettingsInfo.data && nextState.saleInfo.data) {
        let productCodesSettings = nextState.productCodesSettingsInfo.data;

        let sale = nextState.saleInfo.data;
        if (sale.availableProductCodes) {
          // filter to only show the product codes in the sale
          // order by the code
          let codes = Object.values(productCodesSettings)
            .filter((p) => sale.availableProductCodes!.includes(p.code))
            .sort((a, b) => a.code.localeCompare(b.code));
          saleProductCodes = codes;
        }
      }

      nextState = {
        ...nextState,
        saleProductCodes,
      };
    }

    if (action.payload.key === "productsInfo") {
      let products = [] as Product[];
      if (nextState.productsInfo.data) {
        products = Object.values(nextState.productsInfo.data).sort((a, b) => {
          if (a.name && b.name) {
            return a.name.localeCompare(b.name);
          }
          return a.createdAt.toMillis() - b.createdAt.toMillis();
        });
      }
      nextState = {
        ...nextState,
        products,
      };
    }

    nextState = {
      ...nextState,
      saleHasLoaded,
      lotsHaveLoaded,
      invoicesHaveLoaded,
    };
  }

  return nextState;
}

function useCreateStudioSaleState(
  instanceId: string,
  marketId: string,
  saleId: string
) {
  let [state, dispatch] = useReducer(
    reducer,
    createInitialState(instanceId, marketId, saleId)
  );

  let merge = useCallback(
    (k: StateKey, v: StateValue) => {
      dispatch({
        type: "merge",
        payload: {
          key: k,
          value: v,
        },
      });
    },
    [dispatch]
  );

  return {
    state,
    merge,
  };
}

// Access these throught the useStudioStream hook
export const _InternalSaleCtxs = {
  saleInfo: React.createContext<DocLoadState<Sale>>(defaultDocLoadValue),
  lotsInfo: React.createContext<QueryLoadState<Lot>>(defaultQueryLoadValue),
  lotsFiltersAndStats: React.createContext<StatsAndFilters | null>(null),
  invoicesInfo: React.createContext<QueryLoadState<Invoice>>(
    defaultQueryLoadValue
  ),
  marketDefaultSettingsInfo:
    React.createContext<DocLoadState<SettingsMarketDefaults>>(
      defaultDocLoadValue
    ),

  products: React.createContext<Product[]>([]),
  saleProductCodes: React.createContext<ProductCodeConfiguration[]>([]),
};

export function StudioSaleProvider(props: StudioSaleProviderProps) {
  let id = useMemo(() => {
    // Random 8 char string
    return Math.random().toString(36).substring(2, 10);
  }, []);

  let { marketId, saleId } = props;

  let { state, merge } = useCreateStudioSaleState(id, marketId, saleId);

  let currentUid = useCurrentUid();

  // Sale
  useWatchDoc(merge, "saleInfo", `markets/${marketId}/sales/${saleId}`, {
    idField: "id",
    delayUntilTruthy: currentUid && marketId && saleId,
  });

  // // Market Default Settings
  useWatchDoc(
    merge,
    "marketDefaultSettingsInfo",
    `markets/${marketId}/settings/defaults`
  );

  // // Product Codes
  useWatchDoc(
    merge,
    "productCodesSettingsInfo",
    `markets/${marketId}/settings/productCodes`
  );

  // // Products
  useWatchDoc(merge, "productsInfo", `markets/${marketId}/settings/products`, {
    delayUntilTruthy: state.invoicesHaveLoaded,
  });

  // // Lots
  useWatchQuery(merge, "lotsInfo", `markets/${marketId}/sales/${saleId}/lots`, {
    idField: "id",
    constraints: [orderBy("index", "asc")],
    delayUntilTruthy: state.saleHasLoaded,
  });

  // // lots filters and stats
  let lotsFiltersAndStats = useLoadFilteredLotData(
    state.saleHasLoaded ? state.lotsInfo : null,
    state.saleInfo.data?.attributeSet
  );
  useEffect(() => {
    merge("lotsFiltersAndStats", lotsFiltersAndStats);
  }, [lotsFiltersAndStats, merge]);

  // invoices
  useWatchQuery(merge, "invoicesInfo", `markets/${marketId}/invoices`, {
    idField: "id",
    constraints: [where(`saleIds`, `array-contains`, saleId)],
    delayUntilTruthy: state.lotsHaveLoaded && saleId,
  });

  return (
    // we use the contexts here so individual slices of data can be watched without triggering a re-render
    // when anything else changes
    <_InternalSaleCtxs.saleInfo.Provider value={state.saleInfo}>
      <_InternalSaleCtxs.lotsInfo.Provider value={state.lotsInfo}>
        <_InternalSaleCtxs.lotsFiltersAndStats.Provider
          value={state.lotsFiltersAndStats}
        >
          <_InternalSaleCtxs.invoicesInfo.Provider value={state.invoicesInfo}>
            <_InternalSaleCtxs.marketDefaultSettingsInfo.Provider
              value={state.marketDefaultSettingsInfo}
            >
              <_InternalSaleCtxs.products.Provider value={state.products}>
                <_InternalSaleCtxs.saleProductCodes.Provider
                  value={state.saleProductCodes}
                >
                  <SaleDataCustomersProvider>
                    <ExecuteInSaleProvider>
                      <MemoComponent>{props.children}</MemoComponent>
                    </ExecuteInSaleProvider>
                  </SaleDataCustomersProvider>
                </_InternalSaleCtxs.saleProductCodes.Provider>
              </_InternalSaleCtxs.products.Provider>
            </_InternalSaleCtxs.marketDefaultSettingsInfo.Provider>
          </_InternalSaleCtxs.invoicesInfo.Provider>
        </_InternalSaleCtxs.lotsFiltersAndStats.Provider>
      </_InternalSaleCtxs.lotsInfo.Provider>
    </_InternalSaleCtxs.saleInfo.Provider>
  );
}

/***
 * Load the doc and update the reducer state when it changes
 */
function useWatchDoc<T>(
  merge: (k: StateKey, v: StateValue) => void,
  key: StateKey,
  docRefOrString: DocumentReference<DocumentData> | string | null,
  options?: {
    idField?: keyof T;
    // if not undefined, will pause loading until true
    delayUntilTruthy?: any;
  }
) {
  let delayLoading =
    options?.delayUntilTruthy === undefined ? false : !options.delayUntilTruthy;

  let loadInfo = useLoadFirestoreDoc<T>(
    delayLoading ? null : docRefOrString,
    options
  );

  useEffect(() => {
    merge(key, loadInfo as StateValue);
  }, [loadInfo, key, merge]);
}

function useWatchQuery<T>(
  merge: (k: StateKey, v: StateValue) => void,
  key: StateKey,
  qOrString: Query<DocumentData> | null | string,
  options?: {
    idField?: keyof T;
    // note changing the constraints doens't re-run the query
    // if we want to do this we should use an "extraData" param like FlatList
    constraints?: QueryConstraint[];
    // if not undefined, will pause loading until true
    delayUntilTruthy?: any;
  }
) {
  let db = useFirestore();

  let constraintsRef = useRef(options?.constraints);
  constraintsRef.current = options?.constraints;

  let delayLoading =
    options?.delayUntilTruthy === undefined ? false : !options.delayUntilTruthy;

  let q = useMemo(() => {
    if (delayLoading) {
      // undefined in the constraints can throw an error
      return null;
    }
    if (!qOrString) {
      return null;
    }
    let q = qOrString as Query<DocumentData>;
    if (typeof qOrString === "string") {
      let extraConstraints = constraintsRef.current || [];
      q = query(collection(db, qOrString), ...extraConstraints);
    }
    return q;
  }, [qOrString, db, delayLoading]);

  let loadInfo = useLoadFirestoreQuery<T>(delayLoading ? null : q, {
    idField: options?.idField,
  });

  useEffect(() => {
    merge(key, loadInfo as StateValue);
  }, [key, loadInfo, merge]);
}
