import { Address, DraftInvoice, Invoice, Market } from "types";
import {
  COLOUR_MARTEYE_100,
  COLOUR_MARTEYE_400,
  COLOUR_MARTEYE_500,
  MARGIN,
  PAGE_WIDTH,
  PTSPERMM,
} from "../variables";

export const addPageOneHeader = (
  doc: any,
  invoice: Invoice | DraftInvoice,
  y: number,
  type = "Invoice",
  market: Market
) => {
  const company = market.name;
  const addressMaxWidth = 70;
  const miscMaxWidth = 70;

  let currentY = MARGIN;
  let lineHeight = 1.4;
  let fontSize = 11;
  let oneLineHeight = (fontSize * lineHeight) / PTSPERMM;

  const marketAddress = market.address ? (market.address as Address) : null;

  let martaddress = [
    marketAddress?.address2 ?? "",
    marketAddress?.city ?? "",
    marketAddress?.province ?? "",
    marketAddress?.zip ?? "",
    marketAddress?.country ?? "",
  ];
  // let misc = [
  //   address.tel ? "Tel: " + address.tel : "",
  //   address.fax ? "Fax: " + address.fax : "",
  //   address.vat ? "VAT No: " + address.vat : "",
  // ];

  let customeraddress = [
    invoice.address.company ? invoice.address.company : "",
    invoice.address.address1 ? invoice.address.address1 : "",
    invoice.address.address2 ? invoice.address.address2 : "",
    invoice.address.city ? invoice.address.city : "",
    invoice.address.province ? invoice.address.province : "",
    invoice.address.zip ? invoice.address.zip : "",
    invoice.address.country ? invoice.address.country : "",
  ];

  // Remove non needed lines
  let usedAddressLines = martaddress.filter((line) => line !== "");
  //let usedmiscLines = misc.filter((line) => line !== "");
  let usedmiscLines: string[] = [];
  let usedcustomeraddressLines = customeraddress.filter((line) => line !== "");

  let addressLines = doc
    .setFontSize(fontSize)
    .splitTextToSize(usedAddressLines, addressMaxWidth);

  let miscLines = doc
    .setFontSize(fontSize)
    .splitTextToSize(usedmiscLines, miscMaxWidth);

  let customerAddressLines = doc
    .setFontSize(fontSize)
    .splitTextToSize(usedcustomeraddressLines, miscMaxWidth);

  // Work out the height needed!

  let heightNeeded = 2 * MARGIN;

  // Add the lines
  if (addressLines.length >= miscLines.length) {
    heightNeeded +=
      ((addressLines.length - 1) * lineHeight * fontSize) / PTSPERMM;
  } else {
    heightNeeded += ((miscLines.length - 1) * lineHeight * fontSize) / PTSPERMM;
  }

  // If there is a trading name
  if (company) {
    heightNeeded += (lineHeight * 12) / PTSPERMM;
  }

  // if there is a VAT number
  doc.setPage(1);

  // TODO Change the location of the colors
  if (invoice.clientType === "Seller") {
    doc.setFillColor(COLOUR_MARTEYE_100);
    doc.setDrawColor(COLOUR_MARTEYE_100);
    doc.setTextColor(COLOUR_MARTEYE_500);
  } else {
    doc.setFillColor(COLOUR_MARTEYE_100);
    doc.setDrawColor(COLOUR_MARTEYE_400);
    doc.setTextColor(COLOUR_MARTEYE_500);
  }

  // doc.rect(MARGIN, MARGIN, PAGE_WIDTH - 2 * MARGIN, heightNeeded, "F");
  doc.setLineWidth(2);
  doc.line(MARGIN, heightNeeded, PAGE_WIDTH - MARGIN, heightNeeded, "S");

  addInvoiceDetails(doc, invoice, type);

  if (company) {
    doc.setFont("Inter", "bold");
    doc.setFontSize(12);
    doc.text(MARGIN, currentY, company);

    // Set the y for the next line
    currentY += oneLineHeight * 1.25;
  }

  doc.setFont("Inter", "normal");
  doc.setFontSize(fontSize);
  doc.text(MARGIN, currentY, addressLines, {
    lineHeightFactor: lineHeight,
  });

  doc.setFont("Inter", "normal");
  doc.setFontSize(fontSize);
  doc.text(MARGIN + addressMaxWidth + MARGIN, currentY, miscLines, {
    lineHeightFactor: lineHeight,
  });

  // Set the Y
  currentY = heightNeeded + MARGIN;

  // Add in the address
  if (invoice.customerAccountNumber) {
    doc.setTextColor(COLOUR_MARTEYE_500);
    doc.setFontSize(fontSize);
    doc.setFont("Inter", "bold");
    doc.text(MARGIN, currentY, invoice.customerAccountNumber);
    currentY += oneLineHeight;
  }
  if (invoice.name) {
    doc.setTextColor(COLOUR_MARTEYE_500);
    doc.setFontSize(fontSize);
    doc.setFont("Inter", "bold");
    doc.text(MARGIN, currentY, invoice.name);
    currentY += oneLineHeight;
  }

  // Change text varibles
  fontSize = 11;
  lineHeight = 1.25;

  doc.setTextColor("#616161");
  doc.setFont("Inter", "normal");
  doc.setFontSize(fontSize);
  doc.text(MARGIN, currentY, customerAddressLines, {
    lineHeightFactor: lineHeight,
  });

  // Set the Y
  currentY +=
    ((customerAddressLines.length - 1) * lineHeight * fontSize) / PTSPERMM;

  return currentY;
};

export const addInvoiceDetails = (
  doc: any,
  invoice: any,
  type: string = "Invoice"
) => {
  let p_textSize = doc.getFontSize();

  doc.setFont("Inter", "normal");
  doc.setFontSize(16);
  doc.text("#" + invoice.invoiceNumber, PAGE_WIDTH - MARGIN, MARGIN, {
    align: "right",
  });

  if (invoice.clientType === "Seller" && type == "Invoice") {
    type = "Statement";
  }
  doc.setFont("Inter", "bold");
  doc.setFontSize(30);
  doc.text(type, PAGE_WIDTH - MARGIN, MARGIN * 2, { align: "right" });
  doc.setFont("Inter", "light");
  doc.setFontSize(p_textSize);
};

export default addPageOneHeader;
