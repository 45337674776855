import { format } from "date-fns";
import { Timestamp } from "firebase-admin/firestore";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { Invoice } from "../../types";
import { DocumentSpec, SalePDFProps } from "../_importDocs";

const BuyersSummary: DocumentSpec = {
  // must be unique
  id: "buyers-summary",

  objectType: "sale",

  // displayed to the user
  name: "Buyers Summary",

  // when the PDF is downloaded, this function is called to generate the filename
  getFilename: (props) => {
    let { sale } = props;

    let sanitisedSaleName = sale.name.replace(/[^a-z0-9]/gi, "_");
    // replace more than one underscore with a single underscore
    sanitisedSaleName = sanitisedSaleName.replace(/_+/g, "_");

    let filesafeDate = (sale.startsAt as Timestamp)
      .toDate()
      .toISOString()
      .split("T")[0]
      .replace(/[^a-z0-9]/gi, "-");

    return `Buyers Summary  ${filesafeDate} ${sanitisedSaleName}`;
  },

  // unused for sales...
  isAvailableFor: () => {
    return true;
  },

  jsPDF: (props: SalePDFProps) => {
    const { invoices, lots } = props;

    const addHeaders = (doc: any) => {
      const pageCount = doc.internal.getNumberOfPages();

      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i);

        doc.setFontSize(8);
        doc.text(
          `Buyers Summary – Generated ${format(
            new Date(),
            "dd/MM/yyyy HH:mm:ss"
          )}`,
          10,
          8
        );
        doc.text(`Page ${i} of ${pageCount}`, 210 - 10, 8, {
          align: "right",
        });
      }
    };

    const tableData = (invoices: Invoice[]) => {
      const data: any = [];
      invoices.map((invoice: Invoice) => {
        if (invoice.clientType === "Seller") {
          return;
        }

        const currency = invoice.currency;

        let additionalTotal =
          invoice.commissionTotalInCents! +
          invoice.nonCommissionAdjustmentsInCents!;

        let additionalVAT =
          invoice.vatOnCommissionInCents! + invoice.vatOnAdjustmentsInCents!;

        data.push([
          invoice.invoiceNumber,
          invoice.customerAccountNumber,
          invoice.name,
          new Intl.NumberFormat("en-gb", {
            style: "currency",
            currency: currency,
          }).format(invoice.lotTotalInCentsExVat / 100),
          new Intl.NumberFormat("en-gb", {
            style: "currency",
            currency: currency,
          }).format(invoice.vatOnLotTotalInCents / 100),
          new Intl.NumberFormat("en-gb", {
            style: "currency",
            currency: currency,
          }).format(additionalTotal / 100),
          new Intl.NumberFormat("en-gb", {
            style: "currency",
            currency: currency,
          }).format(additionalVAT / 100),
          new Intl.NumberFormat("en-gb", {
            style: "currency",
            currency: currency,
          }).format(invoice.finalTotalInCents / 100),
        ]);
      });

      return data;
    };

    const doc = new jsPDF({ putOnlyUsedFonts: true, compress: true });

    autoTable(doc, {
      head: [
        [
          {
            content: "",
            colSpan: 3,
            styles: { halign: "left" },
          },
          {
            content: "--- Goods ---",
            colSpan: 2,
            styles: { halign: "center" },
          },
          {
            content: "--- Services ---",
            colSpan: 2,
            styles: { halign: "center" },
          },
          {
            content: "",
            colSpan: 1,
            styles: { halign: "left" },
          },
        ],
        ["NO.", "REF", "Name", "Value", "VAT", "Value", "VAT", "Due"],
      ],
      body: [...tableData(invoices)],
      theme: "plain",
      margin: { top: 10, right: 10, bottom: 10, left: 10 },
      headStyles: {
        textColor: "#294F43",
        fontSize: 8,
        font: "Inter",
        fontStyle: "bold",
        cellPadding: { top: 1.5, bottom: 1.5, left: 1, right: 1 },
      },
      bodyStyles: {
        fontSize: 9,
        font: "Inter",
        fontStyle: "normal",
        cellPadding: { top: 1.5, bottom: 1.5, left: 1, right: 1 },
      },
    });
    addHeaders(doc);

    return doc;
  },
};

export default BuyersSummary;
