import crypto, { randomBytes } from "crypto";

/**
 * Generate a unique client-side identifier.
 *
 * Used for the creation of new documents borrowed from Firebase
 *
 * @returns {string} If no seed is supplied a unique 20-character wide identifier. Otherwise a deterministic 20-character identifer based on the seed.
 */
export function autoId(seed?: string): string {
  if (seed) {
    return idFromSeed(seed);
  }

  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let autoId = "";
  while (autoId.length < 20) {
    const bytes = randomBytes(40);
    bytes.forEach((b) => {
      // Length of `chars` is 62. We only take bytes between 0 and 62*4-1
      // (both inclusive). The value is then evenly mapped to indices of `char`
      // via a modulo operation.
      const maxValue = 62 * 4 - 1;
      if (autoId.length < 20 && b <= maxValue) {
        autoId += chars.charAt(b % 62);
      }
    });
  }
  return autoId;
}

/***
 * Generate a unique identifier from a seed string.
 *
 * Works by hashing the seed string and taking the first 20 characters of the hash.
 */
export function idFromSeed(idseed: string): string {
  let id = crypto
    .createHash("sha256")
    .update(idseed)
    .digest("hex")
    .substring(0, 20);
  return id;
}
