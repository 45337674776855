import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { useFirebaseApp } from "@/data/studio-react/firebase/useFirebaseApp";
import { getFunctions, httpsCallable } from "firebase/functions";
import meilisearch, {
  MeiliSearch,
  SearchParams,
  SearchResponse,
} from "meilisearch";
import { useRouter } from "next/router";
import { useFirebaseAuth } from "../data/studio-react/firebase/useFirestoreAuth";

const defaultSearch = async function <T = Record<string, any>>(
  query?: string | null,
  options?: SearchParams,
  config?: Partial<Request>
): Promise<SearchResponse<T>> {
  console.error(`Unable to search, no clients available`);
  return {
    hits: [],
    offset: 0,
    limit: 0,
    processingTimeMs: 0,
    query: "",
  };
};

const SearchContext = createContext(defaultSearch);

const availableServerUrls = [
  "https://search-alpha.marteyestudio.com",
  "https://search-beta.marteyestudio.com",
];

interface SearchProviderProps {
  children: any;
}

// A user needs a token (apikey) to access the search engine.
function useSearchToken() {
  let [token, setToken] = useState(null as null | string);
  let auth = useFirebaseAuth();
  let app = useFirebaseApp();

  useEffect(() => {
    let unsub = auth.onIdTokenChanged(async (user) => {
      if (user) {
        let functions = getFunctions(app, "europe-west2");
        let getSearchToken = httpsCallable(functions, "getSearchToken");

        try {
          let token = await getSearchToken();
          setToken(token.data as string);
        } catch (e) {
          console.error("Unable to get access to search", e);
        }
      }
    });

    return () => {
      unsub();
      setToken(null);
    };
  }, [app, auth]);

  return token;
}

export function SearchProvider(props: SearchProviderProps) {
  let [clients, setClients] = useState(null as null | MeiliSearch[]);
  let [activeClient, setActiveClient] = useState(null as null | MeiliSearch);
  let token = useSearchToken();

  useEffect(() => {
    if (!token) {
      return;
    }

    let apikKey = token;
    let newClients = availableServerUrls.map(
      (url) =>
        new meilisearch({
          host: url,
          apiKey: apikKey,
        })
    );

    setClients(newClients);
    setActiveClient(newClients[Math.floor(Math.random() * newClients.length)]);

    return () => {
      setClients(null);
    };
  }, [token]);

  let router = useRouter();
  let marketId = router.query.marketId as string;

  let search = useCallback(
    async function <T = Record<string, any>>(
      query?: string | null,
      options?: SearchParams,
      config?: Partial<Request>,
      debug: boolean = false
    ): Promise<SearchResponse<T>> {
      if (!activeClient) {
        return {
          hits: [],
          offset: 0,
          limit: 0,
          processingTimeMs: 0,
          query: "",
          noActiveClient: true,
        } as any;
      }

      let client = activeClient;
      let index = client.index("documents");

      let opts = options || {};
      // ensure the marketId filter is set
      if (opts.filter) {
        opts.filter = `marketId = '${marketId}' AND ${opts.filter}`;
      } else {
        opts.filter = `marketId = '${marketId}'`;
      }
      if (debug) {
        console.log(
          "Searching with options",
          opts,
          "and config",
          config,
          "and query",
          query,
          "and index",
          "documents"
        );
      }

      try {
        return await index.search(query, opts, config);
      } catch (e) {
        console.error("Unable to search", e);
        throw e;
      }
    },
    [activeClient, marketId]
  );

  useEffect(() => {
    // @ts-ignore
    window.marteye = window.marteye || {};
    // @ts-ignore
    window.marteye.search = (...args) => {
      let a: any = [...args];
      a[3] = true;
      let startTime = performance.now();

      search.apply(null, a).then((result) => {
        let endTime = performance.now();
        console.log(
          `${result.hits.length} found in`,
          endTime - startTime,
          "ms"
        );

        if (result.hits.length === 0) {
          console.log("No results found");
          return;
        }
        console.log(...result.hits);
      });
      return;
    };
    return () => {
      // @ts-ignore
      delete window.marteye.search;
    };
  }, [search]);

  // We can only search in the market context
  if (!marketId) {
    return props.children;
  }

  return (
    <SearchContext.Provider value={search}>
      {props.children}
    </SearchContext.Provider>
  );
}

export function useSearchDocuments() {
  return useContext(SearchContext);
}
