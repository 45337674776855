import { Timestamp } from "firebase/firestore";
import colours from "../../tailwind.config";
import { MARGIN, PAGE_WIDTH } from "../variables";
const colors = colours?.theme?.extend?.colors as any;

export const tableRunningHeader = (
  y: number,
  doc: any,
  invoice: any,
  type: string = "Invoice"
) => {
  // Add running header to the top of the page
  let spacer = 3;
  let startY = y || 15;
  let startX = MARGIN;

  // Invoice #2486/7 cont.
  let text = type + " #" + invoice.invoiceNumber;
  if (startY === 15) {
    text += " cont.";
  }

  doc.setTextColor(colors?.martEye[500]);
  doc.setFont("Inter", "bold");
  doc.text(startX, startY, text);

  // Get text width
  let invoiceTextWidth = doc.getTextWidth(text);

  // Invoice Date ?
  let issuedAt = invoice.issuedAt as Timestamp;
  let dateText = issuedAt.toDate().toDateString();

  doc.setFont("Inter", "bold");
  doc.text(dateText, PAGE_WIDTH - startX, startY, { align: "right" });
  let dateTextWidth = doc.getTextWidth(dateText);

  // Evening Breeding Sheep

  // Work out maximum space available
  let dotsWidth = doc.getTextWidth("...");
  let maximumSpace =
    PAGE_WIDTH -
    2 * MARGIN -
    invoiceTextWidth -
    spacer -
    dateTextWidth -
    spacer -
    dotsWidth;

  // ! check with aaron that there can't be more than one type
  let rightText = doc.splitTextToSize(invoice?.sales[0]?.name, maximumSpace);

  // Truncate if needed
  if (rightText.length > 1) {
    rightText = rightText[0] + "...";
  }

  doc.setFont("Inter", "normal");
  doc.text(rightText, 200 - dateTextWidth - spacer, startY, {
    align: "right",
    maxWidth: 100,
  });
};
export default tableRunningHeader;
