/***
  Needs to
    - create and maintain the crossfilter
    - apply filters
    - update/callback showing what cells have changed (to update the grid)
    - fuzzy search?
    - sort?


    - returns a ref to the full list
    - returns a count of the filtered list
    - returns a ref to the filtered list
    - returns functions we can use to apply / remove filters


    possible filters:
      - sold (has gone through ring)
      - unsold (has not gone through ring)
      - uncleared casual accounts 
      - movement docs missing (aphis / sheep)
      - invoice issued / not issued
      - filter by product category
      - 

 */
import {
  generateLotNumberSortKey,
  getLotStatus,
  sortByLotNumber,
} from "@/data/lots";
import crossfilter2, { Crossfilter } from "crossfilter2";
import Fuse from "fuse.js";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ItemWithId } from "../../../components/datagrid/gridConfig";
import {
  AttributeDefinition,
  CurrenciesWithGuinea,
  Lot,
  LotWithItemsAsArray,
} from "../../../types";
import { formatAsCurrency } from "../../amounts";
import { QueryChanges, QueryLoadState } from "../firebase/useFirestore";

export enum BuyerType {
  Casual = "Casual",
  Regular = "Regular",
}

export enum InvoiceStatus {
  Invoiced = "Invoiced",
  NotInvoiced = "Not Invoiced",
}
export enum StatementStatus {
  Complete = "Complete",
  Incomplete = "Incomplete",
}

export interface StatsAndFilters {
  filters: {
    filterByBuyerCurrentValue: () => string | undefined;
    filterByBuyer: (buyerId: string | null) => void;
    filterBySellerCurrentValue: () => string | undefined;
    filterBySeller: (sellerId: string | null) => void;
    filterByProductCodeCurrentValue: () => string | undefined;
    filterByProductCode: (productCodes: string | null) => void;
    filterByBuyerType: (buyerType: BuyerType | null) => void;
    filterBySellerStatementStatusCurrentValue: () =>
      | StatementStatus
      | undefined;
    filterBySellerStatementStatus: (
      statementStatus: StatementStatus | null
    ) => void;
    filterByBuyerInvoiceStatus: (invoiceStatus: InvoiceStatus | null) => void;
    filterByBuyerTypeCurrentValue: () => BuyerType | undefined;
    filterByBuyerInvoiceStatusCurrentValue: () => InvoiceStatus | undefined;

    filterByLotStatusCurrentValue: () =>
      | undefined
      | "Incomplete"
      | "Complete"
      | "Errors";
    filterByLotStatus: (
      lotStatus: "Incomplete" | "Complete" | "Errors"
    ) => void;
    clearFilters: () => void;
  };
  values: {
    numberOfLots: () => number;
    numberOfBuyers: () => number | null;
    numberOfSellers: () => number | null;
    numberExpected: () => number;
    numberEntered: () => number;
    valueInCents: () => number;
    valueInCurrency: (currency: CurrenciesWithGuinea) => string | null;
  };
  groups: {
    lotStatusGroup: () => readonly crossfilter2.Grouping<
      crossfilter2.NaturallyOrderedValue,
      unknown
    >[];
    buyerGroup: () => readonly crossfilter2.Grouping<
      crossfilter2.NaturallyOrderedValue,
      unknown
    >[];
    sellerGroup: () => readonly crossfilter2.Grouping<
      crossfilter2.NaturallyOrderedValue,
      unknown
    >[];
    productCodeGroup: () => readonly crossfilter2.Grouping<
      crossfilter2.NaturallyOrderedValue,
      unknown
    >[];
    buyerGroupWithLots: () => {
      accountNumber: string;
      lots: Lot[];
    }[];
    sellerGroupWithLots: () => {
      accountNumber: string;
      lots: Lot[];
    }[];
  };
  filteredItems: Lot[];
  search: (query: string) => void;
  toggleSort: () => void;
  currentSortKey: "lotNumber" | "index";
}

export function useLoadFilteredLotData(
  lotLoadInfo: QueryLoadState<Lot> | null | undefined,
  attributeSet: AttributeDefinition[] | undefined
): StatsAndFilters {
  let [filteredItems, setFilteredItems] = useState<Lot[]>([]);

  let [sortKey, setSortKey] = useState<"lotNumber" | "index">("lotNumber");

  let crossfilterData = useCreateAndSyncCrossfilter(
    lotLoadInfo?.changes,
    setFilteredItems,
    sortKey
  );

  let statsAndFilters = useCreateStatsAndFilters(crossfilterData);

  let { searchResults, search } = useLotSearch(filteredItems, attributeSet);

  return useMemo(() => {
    let result = {
      filteredItems: searchResults,
      ...statsAndFilters,
      search,
      currentSortKey: sortKey,
      toggleSort: () => {
        setSortKey((prev) => {
          return prev === "lotNumber" ? "index" : "lotNumber";
        });
      },
    };

    if (typeof window !== "undefined") {
      // @ts-ignore
      window.filteredItems = searchResults;
      // @ts-ignore
      window.statsAndFilters = statsAndFilters;
    }

    return result;
  }, [searchResults, statsAndFilters, search, sortKey]);
}

function useLotSearch(
  filteredItems: Lot[],
  attributeSet: AttributeDefinition[] | undefined
) {
  let [query, setQuery] = useState("");

  let fuse = useMemo(() => {
    let items = filteredItems.map((item, index) => {
      return { ...item, items: Object.values(item.itemMap) };
    });

    let attributeKeys = [] as string[];
    let itemAttributeKeys = [] as string[];

    if (attributeSet !== undefined) {
      attributeSet.map((attribute) => {
        if (attribute.level === "item") {
          itemAttributeKeys.push(`items.attributes.${attribute.id}`);
        } else if (attribute.level === "lot") {
          attributeKeys.push(`attributes.${attribute.id}`);
        }
      });
    }

    return new Fuse<LotWithItemsAsArray>(items, {
      keys: [
        "productCode",
        "lotNumber",
        "sellerCustomerId",
        "seller.accountNumber",
        "seller.displayName",
        "unitOfSale",
        "buyerCasual",
        "buyerCustomerId",
        "buyer.accountNumber",
        "buyer.displayName",
        "sellerInvoiceId",
        "buyerInvoiceId",
        "saleStatus",
        ...attributeKeys,
        ...itemAttributeKeys,
        "items.notes.text",
      ],
      shouldSort: false,
      threshold: 0.3,
    });
  }, [filteredItems, attributeSet]);

  let searchResults = useMemo(() => {
    if (query.length === 0) {
      return filteredItems;
    }
    return fuse
      .search(query)
      .map((i) => i.item)
      .sort((a, b) => {
        return a.index - b.index;
      })
      .map((l) => {
        let lot: any = Object.assign({}, l);
        delete lot.items;
        let x: Lot = lot;
        return x;
      });
  }, [query, filteredItems, fuse]);

  let search = useCallback((query: string) => {
    setQuery(query);
  }, []);

  return { searchResults, search };
}

function useCreateStatsAndFilters<T>(
  crossfilterData: crossfilter2.Crossfilter<Lot>
) {
  let [dimensionsAndGroups, setDimensionsAndGroups] = useState(
    null as null | {
      buyerDimension: crossfilter2.Dimension<Lot, string>;
      buyerGroup: crossfilter2.Group<
        Lot,
        crossfilter2.NaturallyOrderedValue,
        unknown
      >;
      buyerGroupAll: crossfilter2.GroupAll<Lot, { [key: string]: number }>;
      buyerCountUnique: crossfilter2.GroupAll<Lot, { [key: string]: number }>;
      sellerCountUnique: crossfilter2.GroupAll<Lot, { [key: string]: number }>;
      enteredGroup: crossfilter2.GroupAll<Lot, unknown>;
      enteredSum: crossfilter2.GroupAll<Lot, unknown>;
      expectedGroup: crossfilter2.GroupAll<Lot, unknown>;
      expectedSum: crossfilter2.GroupAll<Lot, unknown>;
      productCodeDimension: crossfilter2.Dimension<Lot, string>;
      sellerStatementStatusDimension: crossfilter2.Dimension<
        Lot,
        StatementStatus
      >;
      productCodeGroup: crossfilter2.Group<
        Lot,
        crossfilter2.NaturallyOrderedValue,
        unknown
      >;
      sellerDimension: crossfilter2.Dimension<Lot, string>;
      sellerGroupAll: crossfilter2.GroupAll<Lot, { [key: string]: number }>;
      sellerGroup: crossfilter2.Group<
        Lot,
        crossfilter2.NaturallyOrderedValue,
        unknown
      >;

      buyerInvoiceStatusDimension: crossfilter2.Dimension<Lot, InvoiceStatus>;

      buyerTypeDimension: crossfilter2.Dimension<Lot, BuyerType>;

      lotStatusDimension: crossfilter2.Dimension<
        Lot,
        "Incomplete" | "Complete" | "Errors"
      >;
      lotStatusGroup: crossfilter2.Group<
        Lot,
        crossfilter2.NaturallyOrderedValue,
        unknown
      >;

      totalValueGroup: crossfilter2.GroupAll<Lot, unknown>;
      totalValueInCents: crossfilter2.GroupAll<Lot, unknown>;
    }
  );

  useEffect(() => {
    let buyerDimension = crossfilterData.dimension(
      (d: Lot) => d.buyer?.accountNumber || "blank"
    );

    let sellerDimension = crossfilterData.dimension(
      (d: Lot) => d.seller?.accountNumber || "blank"
    );

    sellerDimension = crossfilterData.dimension((d: Lot) => {
      return d.seller?.accountNumber || "blank";
    });

    let lotStatusDimension = crossfilterData.dimension((d: Lot) => {
      let { status } = getLotStatus(d);

      // We condense the states
      switch (status) {
        case "error":
          return "Errors";
        case "completed":
          return "Complete";
        default:
          return "Incomplete";
      }
    });
    let sellerStatementStatusDimension = crossfilterData.dimension((d: Lot) => {
      if (d.sellerInvoiceId) {
        return StatementStatus.Complete;
      }
      return StatementStatus.Incomplete;
    });

    let buyerInvoiceStatusDimension = crossfilterData.dimension((d: Lot) => {
      if (d.buyerInvoiceId) {
        return InvoiceStatus.Invoiced;
      }
      return InvoiceStatus.NotInvoiced;
    });
    let buyerTypeDimension = crossfilterData.dimension((d: Lot) => {
      let buyerTypeCasual = d.buyerCasual;
      if (buyerTypeCasual) {
        return BuyerType.Casual;
      }

      return BuyerType.Regular;
    });

    let productCodeDimension = crossfilterData.dimension((d: Lot) =>
      d.productCode ? d.productCode : "_null"
    );

    let buyerGroup = buyerDimension.group();
    let buyerGroupAll = crossfilterData.groupAll<{ [key: string]: number }>();

    let bReduceArgs = countUniqueReducer(
      (i) => i.buyer?.accountNumber || "_null"
    );
    let buyerCountUnique = buyerGroupAll.reduce(
      bReduceArgs.add,
      bReduceArgs.remove,
      bReduceArgs.initial
    );

    let sellerGroup = sellerDimension.group();
    let sellerGroupAll = crossfilterData.groupAll<{ [key: string]: number }>();
    let sReduceArgs = countUniqueReducer(
      (i) => i.seller?.accountNumber || "_null"
    );
    let sellerCountUnique = sellerGroupAll.reduce(
      sReduceArgs.add,
      sReduceArgs.remove,
      sReduceArgs.initial
    );

    let lotStatusGroup = lotStatusDimension.group();
    let productCodeGroup = productCodeDimension.group();

    let expectedGroup = crossfilterData.groupAll();
    let expectedSum = expectedGroup.reduceSum((i) => {
      return i.generated?.countOfItems ?? 0;
    });

    let enteredGroup = crossfilterData.groupAll();
    let enteredSum = enteredGroup.reduceSum((i) => {
      if ("countOfEartagsScanned" in i.attributes) {
        return i.attributes.countOfEartagsScanned ?? 0;
      }
      return i.generated?.countOfItems ?? 0;
    });

    let totalValueGroup = crossfilterData.groupAll();
    let totalValueInCents = totalValueGroup.reduceSum((i) => {
      try {
        return i.generated?.totalValueInCents ?? 0;
      } catch (e) {
        return 0;
      }
    });

    setDimensionsAndGroups({
      buyerTypeDimension,
      buyerInvoiceStatusDimension,
      sellerStatementStatusDimension,
      buyerDimension,
      buyerGroup,
      buyerGroupAll,
      buyerCountUnique,
      sellerCountUnique,
      enteredGroup,
      enteredSum,
      expectedGroup,
      expectedSum,
      productCodeDimension,
      productCodeGroup,
      sellerDimension,
      sellerGroupAll,
      sellerGroup,
      lotStatusDimension,
      lotStatusGroup,
      totalValueGroup,
      totalValueInCents,
    });

    return () => {
      buyerInvoiceStatusDimension.dispose();
      sellerStatementStatusDimension.dispose();
      buyerDimension.dispose();
      buyerTypeDimension.dispose();
      buyerGroup.dispose();
      enteredGroup.dispose();
      enteredSum.dispose();
      expectedGroup.dispose();
      expectedSum.dispose();
      productCodeDimension.dispose();
      productCodeGroup.dispose();
      sellerDimension.dispose();
      sellerGroup.dispose();
      lotStatusDimension.dispose();
      lotStatusGroup.dispose();
      totalValueGroup.dispose();
      totalValueInCents.dispose();
      buyerGroupAll.dispose();
      sellerGroupAll.dispose();
      sellerCountUnique.dispose();
      buyerCountUnique.dispose();
    };
  }, [crossfilterData]);

  let actions = useMemo(() => {
    let valueInCents = () =>
      (dimensionsAndGroups?.totalValueInCents.value() as number) ?? null;

    return {
      filters: {
        filterByBuyerCurrentValue: () => {
          return dimensionsAndGroups?.buyerDimension.currentFilter() as
            | string
            | undefined;
        },

        filterByBuyer: (buyerId: string | null) => {
          if (dimensionsAndGroups) {
            if (buyerId === null) {
              dimensionsAndGroups.buyerDimension.filterAll();
            } else {
              dimensionsAndGroups.buyerDimension.filterExact(buyerId);
            }
          }
        },

        filterBySellerCurrentValue: () => {
          return dimensionsAndGroups?.sellerDimension.currentFilter() as
            | string
            | undefined;
        },
        filterBySeller: (sellerId: string | null) => {
          if (dimensionsAndGroups) {
            if (sellerId === null) {
              dimensionsAndGroups.sellerDimension.filterAll();
            } else {
              dimensionsAndGroups.sellerDimension.filterExact(sellerId);
            }
          }
        },

        filterByProductCodeCurrentValue: () => {
          return dimensionsAndGroups?.productCodeDimension.currentFilter() as
            | string
            | undefined;
        },
        filterByProductCode: (productCode: string | null) => {
          if (dimensionsAndGroups) {
            if (productCode === null) {
              dimensionsAndGroups.productCodeDimension.filterAll();
            } else {
              dimensionsAndGroups.productCodeDimension.filterExact(productCode);
            }
          }
        },

        filterByBuyerType: (buyerType: BuyerType | null) => {
          if (dimensionsAndGroups) {
            if (buyerType === null) {
              dimensionsAndGroups.buyerTypeDimension.filterAll();
            } else {
              dimensionsAndGroups.buyerTypeDimension.filterExact(buyerType);
            }
          }
        },
        filterBySellerStatementStatus: (
          invoiceStatus: StatementStatus | null
        ) => {
          if (dimensionsAndGroups) {
            if (invoiceStatus === null) {
              dimensionsAndGroups.sellerStatementStatusDimension.filterAll();
            } else {
              dimensionsAndGroups.sellerStatementStatusDimension.filterExact(
                invoiceStatus
              );
            }
          }
        },
        filterBySellerStatementStatusCurrentValue: () => {
          return dimensionsAndGroups?.sellerStatementStatusDimension.currentFilter() as
            | StatementStatus
            | undefined;
        },
        filterByBuyerInvoiceStatus: (invoiceStatus: InvoiceStatus | null) => {
          if (dimensionsAndGroups) {
            if (invoiceStatus === null) {
              dimensionsAndGroups.buyerInvoiceStatusDimension.filterAll();
            } else {
              dimensionsAndGroups.buyerInvoiceStatusDimension.filterExact(
                invoiceStatus
              );
            }
          }
        },
        filterByBuyerInvoiceStatusCurrentValue: () => {
          return dimensionsAndGroups?.buyerInvoiceStatusDimension.currentFilter() as
            | InvoiceStatus
            | undefined;
        },

        filterByBuyerTypeCurrentValue: () => {
          return dimensionsAndGroups?.buyerTypeDimension.currentFilter() as
            | BuyerType
            | undefined;
        },

        filterByLotStatusCurrentValue: () => {
          return dimensionsAndGroups?.lotStatusDimension.currentFilter() as
            | "Incomplete"
            | "Complete"
            | "Errors"
            | undefined;
        },

        filterByLotStatus: (
          lotStatus: "Incomplete" | "Complete" | "Errors" | null
        ) => {
          if (dimensionsAndGroups) {
            if (lotStatus === null) {
              dimensionsAndGroups.lotStatusDimension.filterAll();
            } else {
              dimensionsAndGroups.lotStatusDimension.filterExact(lotStatus);
            }
          }
        },

        clearFilters: () => {
          if (dimensionsAndGroups) {
            dimensionsAndGroups.sellerStatementStatusDimension.filterAll();
            dimensionsAndGroups.buyerInvoiceStatusDimension.filterAll();
            dimensionsAndGroups.buyerTypeDimension.filterAll();
            dimensionsAndGroups.buyerDimension.filterAll();
            dimensionsAndGroups.sellerDimension.filterAll();
            dimensionsAndGroups.productCodeDimension.filterAll();
            dimensionsAndGroups.lotStatusDimension.filterAll();
          }
        },
      },

      values: {
        numberOfLots: () => crossfilterData.allFiltered().length ?? null,
        numberOfBuyers: () => {
          return Object.keys(
            dimensionsAndGroups?.buyerCountUnique.value() ?? {}
          ).length;
        },
        numberOfSellers: () => {
          return Object.keys(
            dimensionsAndGroups?.sellerCountUnique.value() ?? {}
          ).length;
        },

        numberExpected: () => {
          return (dimensionsAndGroups?.expectedSum.value() as number) ?? null;
        },
        numberEntered: () => {
          return (dimensionsAndGroups?.enteredSum.value() as number) ?? null;
        },
        valueInCents: valueInCents,
        valueInCurrency: (currency: CurrenciesWithGuinea) => {
          let value = valueInCents();
          if (value === null) {
            return null;
          }

          return formatAsCurrency(currency, value);
        },
      },

      groups: {
        lotStatusGroup: () => {
          return dimensionsAndGroups?.lotStatusGroup.all() ?? [];
        },
        buyerGroup: () => {
          return dimensionsAndGroups?.buyerGroup.all() ?? [];
        },
        sellerGroup: () => {
          return dimensionsAndGroups?.sellerGroup.all() ?? [];
        },
        productCodeGroup: () => {
          return dimensionsAndGroups?.productCodeGroup.all() ?? [];
        },

        buyerGroupWithLots: () => {
          let group = dimensionsAndGroups?.buyerGroup.all() ?? [];
          return group
            .filter((b) => b.key !== "blank")
            .map((buyer) => {
              let lots = crossfilterData.all().filter((lot) => {
                return lot.buyer?.accountNumber === buyer.key;
              });
              return {
                accountNumber: buyer.key as string,
                lots,
              };
            });
        },
        sellerGroupWithLots: () => {
          let group = dimensionsAndGroups?.sellerGroup.all() ?? [];

          return group
            .filter((b) => b.key !== "blank")
            .map((seller) => {
              let lots = crossfilterData.all().filter((lot) => {
                return lot.seller?.accountNumber === seller.key;
              });
              return {
                accountNumber: seller.key as string,
                lots,
              };
            });
        },
      },
    };
  }, [dimensionsAndGroups, crossfilterData]);

  return actions;
}

function useCreateAndSyncCrossfilter<T extends ItemWithId>(
  changes: QueryChanges<T> | null | undefined,
  setFilteredItems: Dispatch<SetStateAction<T[]>>,
  sortKey: "lotNumber" | "index" = "lotNumber"
) {
  let [crossfilterData] = useState<Crossfilter<T>>(() => {
    return crossfilter2<T>([]);
  });

  let dimsForSorting = useRef<{
    lotNumberSortDimension: crossfilter2.Dimension<T, string>;
    indexDimension: crossfilter2.Dimension<T, number>;
  } | null>();

  useEffect(() => {
    let indexDimension = crossfilterData.dimension((d) => d.index);
    let lotNumberSortDimension = crossfilterData.dimension((d) => {
      if ("lotNumber" in d) {
        let l = d as unknown as Lot;
        return generateLotNumberSortKey(l);
      }
      return "";
    });

    dimsForSorting.current = {
      lotNumberSortDimension,
      indexDimension,
    };
    return () => {
      dimsForSorting.current = null;
      indexDimension.dispose();
      lotNumberSortDimension.dispose();
    };
  }, [crossfilterData]);

  let sortKeyRef = useRef(sortKey);
  sortKeyRef.current = sortKey;
  let updateFilteredItems = useCallback(() => {
    let dims = dimsForSorting.current;
    if (!dims) {
      return;
    }

    let nextItems = [] as T[];
    if (sortKeyRef.current === "lotNumber") {
      // The dimension gets us most of the way there but we're not able to
      // do both lot number + group sorting together. So we delegate that here
      nextItems = dims.lotNumberSortDimension.bottom(Infinity);
      nextItems = sortByLotNumber(
        nextItems as unknown as Lot[]
      ) as unknown as T[];
    } else {
      nextItems = dims.indexDimension.bottom(Infinity);
    }

    setFilteredItems(nextItems);
  }, [crossfilterData]);

  // Watch the crossfilter for changes
  useEffect(() => {
    let dispose = crossfilterData.onChange((type) => {
      updateFilteredItems();
    });
    return () => {
      dispose();
    };
  }, [updateFilteredItems]);

  // Change when the sort key changes
  useEffect(() => {
    updateFilteredItems();
  }, [updateFilteredItems, sortKey]);

  // Sync the crossfilter with the changes
  useEffect(() => {
    if (!changes) {
      return;
    }

    let { added, modified, removed } = changes;

    let allAdded = added.map((change) => change.item);
    crossfilterData.add(allAdded);

    let allModified = modified.map((change) => change.item);
    crossfilterData.remove((item) => {
      return allModified.some((change) => item.id === change.id);
    });
    crossfilterData.add(allModified);

    crossfilterData.remove((item) => {
      return removed.some((change) => change.item.id === item.id);
    });
  }, [changes, crossfilterData]);

  return crossfilterData;
}

function countUniqueReducer(keyGetter: (i: Lot) => string) {
  return {
    add: (p: { [key: string]: number }, v: Lot, nf: boolean) => {
      let k = keyGetter(v);
      if (!(k in p)) {
        p[k] = 1;
      } else {
        p[k] = p[k] + 1;
      }

      return p;
    },
    remove: (p: { [key: string]: number }, v: Lot, nf: boolean) => {
      let k = keyGetter(v);
      if (p[k] === 1) {
        delete p[k];
      } else {
        p[k] = p[k] - 1;
      }

      return p;
    },
    initial: () => {
      return {} as { [key: string]: number };
    },
  };
}
