import { interpolateRainbow } from "d3-scale-chromatic";
import seedrandom from "seedrandom";

/**
 * Deterministic way to generate a random colour for a user
 * @param uid
 */
export function genColour(seed?: string | null) {
  let rng = seedrandom(`${seed?.split("").reverse().join("")}`);
  let number = Math.round(rng() * 999);
  let color = (seed && interpolateRainbow(number / 999)) || "transparent";
  return color;
}
