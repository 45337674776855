import Image from "next/image";
import {
  ReactNode,
  cloneElement,
  isValidElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import EmptyCartIcon from "../../../public/images/empty-cart.svg";

// Data
import { useAllUninvoicedLotsBoughtAndSold } from "@/components/PersonFlyout/useAllLotsBroughtAndSold";
import { ClientType, Currency, Lot } from "../../../types";

// Components
import { Button } from "@/components/Buttons";
import TransferLotDialog from "@/components/PersonFlyout/Tabs/Cart/TransferLot";
import {
  ActionButton,
  SaleSection,
} from "@/components/PersonFlyout/Tabs/CartTab";
import { formatAsCurrency } from "@/data/amounts";
import { calculateAverages } from "@/data/averages";
import { useStudioStream } from "@/data/studio-react/useStudioStream";

interface CartTabProps {
  temporaryAccountId: string | null;
  currentUid: string | null;
  saleId: string;
  marketId: string;
}

export const CartTab = (props: CartTabProps) => {
  const { temporaryAccountId, saleId, marketId, currentUid } = props;

  let { boughtLots, soldLots } = useAllUninvoicedLotsBoughtAndSold(
    marketId,
    temporaryAccountId,
    saleId,
    true // Is casual
  );

  let lotInfo = useStudioStream("sale:lots-info");
  let marketDefaults = useStudioStream("marketDefaultSettings", props.marketId);

  let { lotsBroughtThisSale, lotsSoldThisSale } = useMemo(() => {
    let lotsBroughtThisSale = [] as Lot[];
    let lotsSoldThisSale = [] as Lot[];

    if (!temporaryAccountId) {
      return { lotsBroughtThisSale, lotsSoldThisSale };
    }

    for (let l of lotInfo.data as Lot[]) {
      if (l.saleStatus === "Rerun" || l.saleStatus === "Resold") continue;

      if (l.buyerCasual === temporaryAccountId) {
        if (!l.buyerInvoiceId) {
          lotsBroughtThisSale.push(l);
        }
      }
      if (l.sellerCasual === temporaryAccountId) {
        if (!l.sellerInvoiceId) {
          lotsSoldThisSale.push(l);
        }
      }
    }

    return { lotsBroughtThisSale, lotsSoldThisSale };
  }, [lotInfo.data, temporaryAccountId]);

  let { lotsBroughtBySale, lotsSoldBySale } = useMemo(() => {
    // group the lots by sale. Show purchases and sales for each sale

    let key = "";

    let lotsBroughtBySale = [] as {
      saleId: string;
      marketId: string;
      lots: Lot[];
    }[];

    let lotsSoldBySale = [] as {
      saleId: string;
      marketId: string;
      lots: Lot[];
    }[];

    if (!temporaryAccountId) {
      return {
        lotsBroughtBySale,
        lotsSoldBySale,
        key,
      };
    }

    for (let l of lotsBroughtThisSale) {
      key += l.id;

      let sale = lotsBroughtBySale.find(
        (s) => s.saleId === l.saleId && s.marketId === l.marketId
      );
      if (!sale) {
        lotsBroughtBySale.push({
          saleId: l.saleId,
          marketId: l.marketId,
          lots: [l],
        });
      } else {
        sale.lots.push(l);
      }
    }

    for (let l of lotsSoldThisSale) {
      key += l.id;

      let sale = lotsSoldBySale.find(
        (s) => s.saleId === l.saleId && s.marketId === l.marketId
      );
      if (!sale) {
        lotsSoldBySale.push({
          saleId: l.saleId,
          marketId: l.marketId,
          lots: [l],
        });
      } else {
        sale.lots.push(l);
      }
    }

    for (let l of boughtLots) {
      key += l.id;

      let sale = lotsBroughtBySale.find(
        (s) => s.saleId === l.saleId && s.marketId === l.marketId
      );
      if (!sale) {
        lotsBroughtBySale.push({
          saleId: l.saleId,
          marketId: l.marketId,
          lots: [l],
        });
      } else {
        sale.lots.push(l);
      }
    }

    for (let l of soldLots) {
      key += l.id;

      let sale = lotsSoldBySale.find(
        (s) => s.saleId === l.saleId && s.marketId === l.marketId
      );
      if (!sale) {
        lotsSoldBySale.push({
          saleId: l.saleId,
          marketId: l.marketId,
          lots: [l],
        });
      } else {
        sale.lots.push(l);
      }
    }

    return {
      lotsBroughtBySale,
      lotsSoldBySale,
      key,
    };
  }, [
    temporaryAccountId,
    lotsBroughtThisSale,
    lotsSoldThisSale,
    boughtLots,
    soldLots,
  ]);

  if (!temporaryAccountId) return null;

  if (lotsBroughtBySale.length === 0 && lotsSoldBySale.length === 0) {
    return (
      <div className="flex flex-col justify-center items-center h-full pb-16">
        <Image
          src={EmptyCartIcon}
          width={300}
          height={300}
          alt=""
          className="mb-4"
          priority={true}
        />

        <h2 className="font-bold text-martEye-600 text-2xl">
          This cart is currently empty
        </h2>
        <p className="text-martEye-600 font-bold">
          This customer has no lots to check out
        </p>
      </div>
    );
  }

  return (
    <div className="px-6 mb-10">
      <LotsCheckoutTableView
        title="Purchases"
        lotsBySale={lotsBroughtBySale}
        type="Buyer"
        currency={marketDefaults?.defaultCurrency ?? "GBP"}
        actions={[
          <TransferLotsButton
            key="transfer"
            selectedLots={[]}
            currentUid={currentUid}
            clientType="Buyer"
          />,
          <Button
            key={"purchases-summary"}
            variant="tertiary"
            href={`/api/${marketId}/documents/buyers-cart-summary/casual-${temporaryAccountId}`}
            target="_blank"
          >
            Purchases Summary
          </Button>,
        ]}
      />

      <LotsCheckoutTableView
        title="Sales"
        lotsBySale={lotsSoldBySale}
        type="Seller"
        currency={marketDefaults?.defaultCurrency ?? "GBP"}
        actions={[
          <TransferLotsButton
            key="transfer"
            selectedLots={[]}
            currentUid={currentUid}
            clientType="Seller"
          />,
          <Button
            key={"purchases-summary"}
            variant="tertiary"
            href={`/api/${marketId}/documents/sellers-cart-summary/casual-${temporaryAccountId}`}
            target="_blank"
          >
            Purchases Summary
          </Button>,
        ]}
      />
    </div>
  );
};

export default CartTab;

// Components
interface LotsToCheckoutTableViewProps {
  title: string;
  type: ClientType;
  currency: Currency;
  lotsBySale: {
    saleId: string;
    marketId: string;
    lots: Lot[];
  }[];

  actions: ReactNode[];
}

function LotsCheckoutTableView(props: LotsToCheckoutTableViewProps) {
  let { lotsBySale, currency } = props;

  //let [selected, setSelected] = useState<Lot[]>([]);

  let [selected, setSelected] = useState(() => {
    let s = [] as Lot[];
    for (let sale of lotsBySale) {
      for (let l of sale.lots) {
        s.push(l);
      }
    }
    return s;
  });

  let allLots = useMemo(() => {
    return lotsBySale.flatMap((s) => s.lots);
  }, [lotsBySale]);

  let allSelectableLots = allLots;

  if (lotsBySale.every((s) => s.lots.length === 0)) {
    return null;
  }

  // pass the selected lots to the action button

  let actions = props.actions.map((a, idx) => {
    if (isValidElement(a)) {
      return cloneElement<any>(a, {
        key: `${idx}`,
        selectedLots: selected,
      });
    }
    return a;
  });

  let averages = calculateAverages(props.currency, selected);
  // must have only 4 averages
  if (averages.length > 4) {
    averages = averages.slice(0, 5);
  }
  if (averages.length < 4) {
    // add empty averages
    let emptyAverages = Array.from({ length: 4 - averages.length }, () => ({
      label: "",
      value: 0,
      formattedValue: "",
    }));
    averages = emptyAverages.concat(averages);
  }

  return (
    <>
      <div className="px-1">
        <div className="mt-4 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <div className="flex items-center justify-between border-b border-b-gray-200 bg-white px-4 py-3">
                  <h2 className="text-xl font-bold">
                    {props.title} ({selected.length})
                  </h2>
                  <div className="flex gap-x-2">{actions}</div>
                </div>
                <div className="grid w-full grid-cols-6 gap-4 border-t border-b bg-gray-50 p-4 text-martEye-700">
                  <div className="flex flex-col col-span-1 ">
                    <span className="text-sm font-normal text-gray-500 text-left">
                      Total
                    </span>
                    <span
                      className="text-xl font-bold"
                      title="Total value of all selected lots"
                    >
                      {formatAsCurrency(
                        currency,
                        selected.reduce(
                          (acc, lot) =>
                            acc + (lot.generated?.totalValueInCents ?? 0),
                          0
                        )
                      )}
                    </span>
                  </div>
                  <div className="flex flex-col col-span-1 order-last border-l border-gray-200 px-4">
                    <span className="text-sm font-normal text-gray-500 text-left">
                      Quantity
                    </span>
                    <span
                      className="font-bold"
                      title="Total value of all selected lots"
                    >
                      {selected.reduce(
                        (acc, lot) => acc + (lot.generated?.countOfItems ?? 0),
                        0
                      )}
                    </span>
                  </div>

                  {averages.map((average, idx) => (
                    <div
                      key={`${idx}`}
                      className="flex flex-col col-span-1 order-last border-l border-gray-200 pr-2"
                    >
                      <span className="text-sm font-normal text-gray-500 text-right">
                        {average.label}
                      </span>
                      <span
                        className="font-bold text-right"
                        title="Total value of all selected lots"
                      >
                        {average.formattedValue}
                      </span>
                    </div>
                  ))}
                </div>
                <table className="min-w-full">
                  <thead className="bg-white">
                    <tr className="bg-gray-50">
                      <th scope="col" className="relative py-2.5 pl-2 pr-2">
                        <span className="sr-only">Select</span>
                        <input
                          name="select-deseclect-all"
                          type="checkbox"
                          checked={
                            selected.length === allSelectableLots.length &&
                            selected.length > 0
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelected(allSelectableLots);
                            } else {
                              setSelected([]);
                            }
                          }}
                          className="absolute top-3 left-4 h-4 w-4 border-gray-300 text-green-600 focus:ring-green-500"
                        />
                      </th>
                      <th
                        scope="col"
                        className="py-2 pl-4 pr-3 text-left text-sm font-medium text-gray-500"
                      >
                        Lot
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-2 text-center text-sm font-normal text-gray-500"
                        colSpan={3}
                      >
                        Details
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-2 text-sm font-medium text-gray-500 text-center"
                      >
                        Qty
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-2 text-left text-sm font-medium text-gray-500"
                      >
                        Price
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-2 text-right text-sm font-medium text-gray-500"
                      >
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {lotsBySale
                      .filter((s) => s.lots.length > 0)
                      .map((s) => (
                        <SaleSection
                          key={`${s.marketId}-${s.saleId}`}
                          marketId={s.marketId}
                          saleId={s.saleId}
                          lots={s.lots}
                          selectableLotIds={allSelectableLots.map((l) => l.id)}
                          selected={selected}
                          setSelected={setSelected}
                        />
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

interface TransferLotsButtonProps {
  selectedLots?: Lot[];
  currentUid: string | null;
  clientType: ClientType;
}

const TransferLotsButton = (props: TransferLotsButtonProps) => {
  const { selectedLots, currentUid, clientType } = props;

  // States
  const [showTransferDialog, setShowTransferDialog] = useState(false);

  // Effects
  useEffect(() => {
    if (!showTransferDialog) {
      return;
    }
    if (selectedLots?.length == 0) {
      setShowTransferDialog(false);
    }
  }, [selectedLots, showTransferDialog]);

  return (
    <>
      <ActionButton
        title="Transfer"
        onClick={() => {
          setShowTransferDialog(true);
        }}
      />
      <TransferLotDialog
        open={showTransferDialog}
        setOpen={setShowTransferDialog}
        clientType={clientType}
        lots={selectedLots ?? []}
        currentUid={currentUid}
      />
    </>
  );
};
