import { deleteField, serverTimestamp } from "firebase/firestore";
import { Lot } from "types";
import { FullSaleContext } from "../ExecuteInSaleContext";
import { batchUpdate } from "../firebase/useFirestore";
import { groupItemCount } from "./groupItemCount";

const WeightAttributeId = "totalGroupWeightKg";

/**
 * Weight is a special case because it is stored on the lot item
 * but also how it is stored is dictated by the market settings
 */
export async function updateSaleLotWeight(
  ctx: FullSaleContext,
  lotGroupId: string,
  newWeight: number | null, // supply null to delete

  // user can set "total" or "average" weight
  inputAs: InputType = "total"
): Promise<void> {
  let { marketId, saleId, currentUid } = ctx;
  if (!marketId || !saleId) {
    throw new Error(`No market id or sale id`);
  }
  if (!currentUid) {
    throw new Error(`No current user`);
  }
  if (!lotGroupId) {
    throw new Error(
      `No group ID provided. Please provide the ID for the group of lots you want to set the total weight for.`
    );
  }
  if (newWeight !== null && isNaN(newWeight)) {
    throw new Error(`Invalid weight ${newWeight}`);
  }

  let allLotsInGroup = ctx.lots.filter((lot) => lot.group === lotGroupId);
  if (allLotsInGroup.length === 0) {
    throw new Error(`No lots found for group ${lotGroupId}`);
  }

  // Make sure we're supposed to record weight for this product code
  let productCodesInGroup = allLotsInGroup.map((lot) => lot.productCode);

  if (
    !productCodesInGroup.every((productCode) =>
      isWeightCaptured(ctx, productCode)
    )
  ) {
    console.error(
      `Weight is not captured for all products in this group ${lotGroupId}`,
      productCodesInGroup.map((productCode) =>
        ctx.studio.isWeightCaptured(productCode)
      )
    );
    throw new Error(
      `Weight is not captured for all products in this group ${lotGroupId}`
    );
  }

  let totalWeight = calculateTotalWeight(
    newWeight,
    inputAs,
    groupItemCount(ctx, lotGroupId) ?? 0
  );

  let updates = allLotsInGroup.map((lot) => ({
    docPath: `markets/${marketId}/sales/${saleId}/lots/${lot.id}`,
    update: {
      [`attributes.${WeightAttributeId}`]: totalWeight ?? deleteField(),
      updatedAt: serverTimestamp(),
      updatedBy: currentUid,
    },
  }));

  console.log(`Dispatching updates`, updates);

  await batchUpdate(updates);
}

/***
 * Returns true if the weight should be captured for this product
 * code in the currently active sale
 */
export function isWeightCaptured(
  ctx: FullSaleContext,
  productCodeOrLot: Lot | Lot[] | string | null | undefined
): boolean | null {
  if (!ctx.sale) {
    return null;
  }

  let productCode: string | null | undefined = null;
  if (Array.isArray(productCodeOrLot)) {
    if (productCodeOrLot.length > 0) {
      productCode = productCodeOrLot[0].productCode;
    }
  } else if (typeof productCodeOrLot === "string") {
    productCode = productCodeOrLot;
  } else if (
    productCodeOrLot !== null &&
    typeof productCodeOrLot === "object"
  ) {
    productCode = productCodeOrLot.productCode;
  } else {
    productCode = null;
  }

  // Use the default from the sale if none is provided
  let productCodeWithDefault = productCode ?? ctx.sale.availableProductCodes[0];

  let relevantAttributeIds =
    ctx.sale?.attributeSetByProductCode[productCodeWithDefault];
  return relevantAttributeIds?.includes(WeightAttributeId) === true;
}

export function getSaleLotWeight(
  ctx: FullSaleContext,
  groupId: string | null | undefined,
  outputAs: InputType = "total"
): number | null {
  if (!groupId) return null;

  // Find the first lot in the group
  let group = ctx.lots.find((lot) => lot.group === groupId);
  let allLotsInGroup = ctx.lots.filter((lot) => lot.group === groupId);

  if (!group) {
    return null;
  }

  switch (outputAs) {
    case "total":
      return group.attributes[WeightAttributeId] ?? null;
    case "average": {
      let totalItems = groupItemCount(ctx, groupId) ?? 0;
      let totalWeight = group.attributes[WeightAttributeId] ?? 0;

      if (totalItems === 0) {
        // normally would be 0 but this is closer to the intention
        return totalWeight;
      }

      let avg = totalWeight / totalItems;

      // clamp to 1 decimal place
      return Math.round(avg * 10) / 10;
    }
    default:
      throw new Error(`Invalid outputAs ${outputAs}`);
  }
}

type InputType = "total" | "average";

function calculateTotalWeight(
  newWeight: number | null,
  inputAs: InputType,
  totalItemsInGroup: number
): number | null {
  if (newWeight === null) {
    return null;
  }

  switch (inputAs) {
    case "total":
      return newWeight;
    case "average":
      return newWeight * totalItemsInGroup;
    default:
      throw new Error(`Invalid inputAs: ${inputAs}`);
  }
}
