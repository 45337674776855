import { CreditCardIcon } from "@/components/Icons";
import { currencyToSymbol, formatAsCurrency } from "@/data/amounts";
import { classNames } from "@/data/classnames";
import useStudio from "@/data/studio-react/useStudio";
import { useStudioStream } from "@/data/studio-react/useStudioStream";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Timestamp } from "firebase/firestore";
import { ErrorMessage, Formik } from "formik";
import { Fragment, useRef } from "react";
import { PaymentMethod, SupportedPaymentMethods } from "types";

interface RegisterPaymentDialogProps {
  customerUid: string | null;
  open: boolean;

  closeDialog: () => void;

  isPayout: boolean;
}

export function RegisterPaymentDialog(props: RegisterPaymentDialogProps) {
  const { open, closeDialog } = props;
  const cancelButtonRef = useRef(null);

  let { createPayment, createPayout } = useStudio();

  let customerUid = props.customerUid;

  let marketId = useStudioStream("session:marketId");
  let defaults = useStudioStream("marketDefaultSettings", marketId);
  let currency = defaults?.defaultCurrency || "GBP";
  let customer = useStudioStream("customer", marketId, customerUid);

  let allowedMethods: string[] = [
    ...SupportedPaymentMethods,
    // "Pay with Credit",
  ];
  if (props.isPayout) {
    // Cheque and Cash only
    allowedMethods = ["Cheque", "Cash"];
  }

  let cxTrdReceivable =
    useStudioStream("balance", marketId, {
      account: customerUid && `${customerUid}:asset:trade receivable`,
    }) || 0;
  let cxTrdPayable =
    useStudioStream("balance", marketId, {
      account: customerUid && `${customerUid}:liability:trade payable`,
    }) || 0;

  let customerBalances =
    cxTrdReceivable &&
    cxTrdPayable &&
    !cxTrdReceivable.loading &&
    !cxTrdPayable.loading
      ? {
          tradeReceivable: cxTrdReceivable.data!,
          tradePayable: cxTrdPayable.data!,
        }
      : null;

  if (!customerUid) {
    return null;
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[110]"
        initialFocus={cancelButtonRef}
        onClose={closeDialog}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-martEye-700 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-martEye-700 px-4 pb-4 pt-5 text-left text-white shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                <div>
                  <div className="flex items-start justify-between">
                    <Dialog.Title
                      as="h3"
                      className="inline-flex items-center gap-x-1.5 text-xl font-bold"
                    >
                      <CreditCardIcon className="text-martEye-400 " />
                      Record {props.isPayout ? "Payout" : "Payment"}
                    </Dialog.Title>

                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        className="rounded-full bg-martEye-500 p-1 text-martEye-100  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-martEye-400 hover:bg-martEye-400"
                        onClick={closeDialog}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>

                  <div className="flex flex-col mt-8">
                    <div className="flex flex-row justify-between">
                      <span>Credit On Account:</span>
                      <span>
                        {formatAsCurrency(
                          currency,
                          customerBalances?.tradeReceivable
                        )}
                      </span>
                    </div>

                    <div className="flex flex-row justify-between mt-2">
                      <span>Outstanding Balance:</span>
                      <span>
                        {formatAsCurrency(
                          currency,
                          customerBalances?.tradePayable
                        )}
                      </span>
                    </div>
                    <div className="flex flex-row justify-between mt-2">
                      <span>Balance:</span>
                      <span>
                        {formatAsCurrency(
                          currency,
                          (customerBalances?.tradeReceivable ?? 0) +
                            (customerBalances?.tradePayable ?? 0)
                        )}
                      </span>
                    </div>
                  </div>

                  <div>
                    {/* 
                    We want to collect: 
                     - method: "Cheque" | "BankTransfer" | "Cash" | "Card" | "Credit"
                     - amount: number
                      - date: Timestamp, default now
                      - reference - if cheque, the label should change to cheque number
                  */}

                    <Formik
                      initialValues={{
                        method: "Cheque",
                        amount: undefined as number | undefined,
                        reference: "",
                        date: new Date().toISOString().split("T")[0],
                      }}
                      validate={(values) => {
                        let errors: Record<string, string> = {};

                        let amount = values.amount || 0;

                        // Amount should be a number
                        if (isNaN(amount)) {
                          errors.amount = "Amount must be a number";
                        }

                        if (amount <= 0) {
                          errors.amount = "Amount must be greater than 0";
                        }

                        // Reference is required for cheque
                        if (values.method === "Cheque" && !values.reference) {
                          errors.reference = "Reference is required";
                        }

                        // allowedMethods
                        if (
                          !allowedMethods.includes(
                            values.method as PaymentMethod
                          )
                        ) {
                          errors.method = "Invalid method";
                        }

                        // date should be a date
                        if (isNaN(new Date(values.date).getTime())) {
                          errors.date = "Invalid date";
                        }

                        return errors;
                      }}
                      onSubmit={async (values) => {
                        if (!customerUid) {
                          alert("No customerUid supplied to RegisterPayment");
                          return;
                        }

                        if (!marketId) {
                          alert("No marketId supplied to RegisterPayment");
                          return;
                        }

                        let reference: string | null = values.reference;
                        if (reference === "") {
                          reference = null;
                        }

                        let date = new Date(values.date);
                        let transactionDate = Timestamp.fromDate(date);

                        let amount = values.amount || 0;

                        if (props.isPayout) {
                          let payout = await createPayout(
                            marketId,
                            customerUid,
                            values.method as "Cheque" | "Cash",
                            Math.round(amount * 100),
                            reference,
                            transactionDate,
                            null // notes
                          );
                          console.log(`Payout created`, payout);
                        } else {
                          let payment = await createPayment(
                            marketId,
                            customerUid,
                            values.method as PaymentMethod,
                            Math.round(amount * 100),
                            transactionDate,
                            reference,
                            []
                          );
                          console.log(`Payment created`, payment);
                        }

                        closeDialog();
                      }}
                    >
                      {({
                        values,
                        handleChange,
                        handleSubmit,
                        getFieldProps,
                        isSubmitting,
                      }) => {
                        return (
                          <form onSubmit={handleSubmit}>
                            <div className="mt-4">
                              {/* <label className="text-base font-semibold text-white">
                                Payment Method
                              </label> */}

                              <fieldset className="mt-4">
                                <legend className="sr-only">
                                  Payment Method
                                </legend>
                                <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                                  {allowedMethods.map((method) => (
                                    <div
                                      key={method}
                                      className="flex items-center"
                                    >
                                      <input
                                        name={"method"}
                                        onChange={handleChange}
                                        checked={values.method === method}
                                        value={method}
                                        type="radio"
                                        defaultChecked={method === "Cheque"}
                                        className="h-4 w-4 border-gray-300 text-green-600 focus:ring-green-600"
                                      />
                                      <label
                                        htmlFor={method}
                                        className="ml-3 block text-sm font-medium leading-6 text-white"
                                        onClick={() => {
                                          handleChange({
                                            target: {
                                              name: "method",
                                              value: method,
                                            },
                                          });
                                        }}
                                      >
                                        {method}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </fieldset>
                              <ErrorMessage
                                name="method"
                                component="div"
                                className="text-red-400 text-sm"
                              />
                            </div>

                            <div className="mt-4">
                              <label
                                htmlFor="amount"
                                className="block text-sm font-medium leading-6 text-white"
                              >
                                Amount
                              </label>
                              <div className="relative mt-1 rounded-md shadow-sm">
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                  <span className="text-gray-500 sm:text-sm">
                                    {currencyToSymbol(currency)}
                                  </span>
                                </div>
                                <input
                                  {...getFieldProps("amount")}
                                  type="number"
                                  className="block w-full rounded-md border-0 py-1.5 pl-7 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                  placeholder="0.00"
                                />
                              </div>

                              <ErrorMessage
                                name="amount"
                                component="div"
                                className="text-red-400 text-sm"
                              />
                            </div>

                            {values.method === "Cash" ? null : (
                              <div className="mt-4">
                                <label
                                  htmlFor="reference"
                                  className="block text-sm font-medium"
                                >
                                  {
                                    {
                                      Cheque: "Cheque Number",
                                      BankTransfer: "Unique Reference",
                                      Cash: "Do not use this field for cash payments",
                                      Card: "Unique Reference",
                                      BACS: "Unique Reference",
                                    }[values.method]
                                  }
                                </label>
                                <input
                                  {...getFieldProps("reference")}
                                  type="text"
                                  className="mt-1 block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                />
                                <ErrorMessage
                                  name="reference"
                                  component="div"
                                  className="text-red-400 text-sm"
                                />
                              </div>
                            )}

                            {/* Transaction Date */}
                            <div className="mt-4">
                              <label
                                htmlFor="date"
                                className="block text-sm font-medium"
                              >
                                Date of Transaction
                              </label>
                              <input
                                {...getFieldProps("date")}
                                type="date"
                                className="mt-1 block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                              />
                            </div>

                            <div className="mt-5 sm:mt-6">
                              <button
                                type="submit"
                                className={classNames(
                                  "inline-flex w-full justify-center rounded-md bg-martEye-400 px-3 py-4 text-base font-normal text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-martEye-400 hover:bg-martEye-500",
                                  isSubmitting
                                    ? "cursor-wait animate-pulse opacity-50"
                                    : "cursor-pointer"
                                )}
                                disabled={isSubmitting}
                              >
                                Record {props.isPayout ? "Payout" : "Payment"}
                              </button>
                            </div>
                          </form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default RegisterPaymentDialog;
