import { classNames } from "@/data/classnames";
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { Timestamp } from "firebase/firestore";
import { Formik } from "formik";
import { Fragment, ReactNode } from "react";
import { Media, SupportedAttributeTypes } from "types";
import { Spinner } from "../Buttons";
import { useShowDialog } from "../ConfirmationDialogProvider";
import QSCheckboxField from "../quick-sheet/fields/QSCheckboxField";
import QSInputField from "../quick-sheet/fields/QSInputField";
import { UpdatableCheckoutParams } from "./CheckoutSheetProvider";
import { CheckoutGroup } from "./checkoutGroups";

export function CheckoutInfoForm(props: {
  checkoutGroups: CheckoutGroup[];
  extraParams: UpdatableCheckoutParams;
  isOpen: boolean;
  close: () => void;
  applyParams: (params: UpdatableCheckoutParams) => Promise<void>;
}) {
  let { checkoutGroups } = props;

  let { showDialog } = useShowDialog();

  return (
    <Formik
      key={`${props.isOpen}`}
      initialValues={props.extraParams}
      validate={(values) => {
        let errors = {};
        console.log("validating", values);
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        console.log("submitting", values);
        try {
          await props.applyParams(values);
          setSubmitting(false);
          props.close();
        } catch (error) {
          setSubmitting(false);
          console.error(error);
          alert(
            "Error saving changes " + (error as any)?.message || "Unknown error"
          );
        }
      }}
    >
      {({ dirty, isSubmitting, isValid, handleSubmit, errors }) => {
        let closeDialog = () => {
          console.log("Requesting close", { dirty, isSubmitting });
          if (!isSubmitting && !dirty) {
            props.close();
            return;
          }

          if (isSubmitting) return; // force the user to wait for the submit to finish
          showDialog(
            "Unsaved Changes",
            "You have unsaved changes. Are you sure you want to close this form?",
            "Close",
            props.close,
            "Cancel",
            () => {}
          );
        };
        return (
          <CheckoutInfoFormChrome isOpen={props.isOpen} close={closeDialog}>
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col">
                <div
                  className={`grid grid-cols-${checkoutGroups.length} gap-4`}
                >
                  {checkoutGroups.map((group, idx) => {
                    return (
                      <Section
                        key={group.name}
                        title={group.name}
                        icon={group.icon}
                        first={idx === 0}
                        last={idx === checkoutGroups.length - 1}
                      >
                        {group.items.map((attribute) => {
                          return (
                            <Item
                              key={attribute.label}
                              title={attribute.label}
                              type={attribute.type}
                              value={attribute.value}
                              path={attribute.path}
                              options={attribute.options}
                            ></Item>
                          );
                        })}
                      </Section>
                    );
                  })}
                </div>

                <div
                  className={`mt-4 grid grid-cols-${checkoutGroups.length} gap-4`}
                >
                  <div
                    className={`grid grid-cols-2 gap-4 col-start-${checkoutGroups.length}`}
                  >
                    <button
                      type="button"
                      onClick={closeDialog}
                      className={`inline-flex justify-center rounded-md border border-transparent text-slate-900 bg-transparent py-2 text-base font-medium hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2`}
                    >
                      Cancel
                    </button>

                    <button
                      type="submit"
                      className={`inline-flex justify-center items-center relative rounded-md border border-transparent bg-green-600 py-2 text-base font-medium text-white hover:bg-green-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50`}
                      disabled={isSubmitting || !isValid}
                    >
                      Save
                      <div className="absolute right-4">
                        <Spinner animating={isSubmitting} />
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </CheckoutInfoFormChrome>
        );
      }}
    </Formik>
  );
}

function Section(props: {
  title: string;
  icon: any;
  first: boolean;
  last: boolean;
  children: React.ReactNode;
}) {
  return (
    <section
      className={classNames(
        "bg-white py-2 border border-gray-200/30 rounded-lg min-h-[160px] "
      )}
    >
      <div
        className="pb-2 flex flex-row justify-between items-center"
        aria-hidden="true"
      >
        <div className="flex flex-row items-center px-3 py-1">
          <props.icon />
          <h2
            className="text-lg font-semibold text-gray-800 pl-2"
            id={props.title.toLowerCase().replace(/ /g, "-")}
          >
            {props.title}
          </h2>
        </div>
      </div>
      <div className="border-b border-gray-200 mx-2 mb-3" />
      {props.children}
    </section>
  );
}

function Item(props: {
  title: string;
  type: SupportedAttributeTypes | "select";
  value: string | number | boolean | Timestamp | Media | null;
  path: string;
  options?: { value: string; label: string }[];
  error?: string;
}) {
  let { title, type, value } = props;

  let component: ReactNode = null;

  switch (type) {
    case "string": {
      component = <QSInputField name={props.path} label={title} type="text" />;
      break;
    }
    case "number": {
      component = (
        <QSInputField name={props.path} label={title} type="number" />
      );
      break;
    }
    case "boolean": {
      component = <QSCheckboxField name={props.path} label={title} />;
      break;
    }
    case "date": {
      component = (
        <pre className="bg-slate-100 py-1 px-2 rounded-md">
          Date Component needed
        </pre>
        //   <QSInputField name={props.path} label={title} type="date" />
      );
      break;
    }
    case "datetime": {
      component = (
        <pre className="bg-slate-100 py-1 px-2 rounded-md">
          Date Component needed
        </pre>
        // <QSInputField name={props.path} label={title} type="datetime" />
      );
      break;
    }
    case "select": {
      component = (
        <pre className="bg-slate-100 py-1 px-2 rounded-md">
          Combo Component needed
        </pre>
        // <QSComboBoxField
        //   name={props.path}
        //   label={title}
        //   options={props.options!}
        // />
      );
      break;
    }
    default: {
      component = `Unsupported type ${type}. Value: ${value}`;
    }
  }

  return (
    <li className={classNames("flex py-1.5 px-3 flex-col")} aria-label={title}>
      {component}
    </li>
  );
}

function CheckoutInfoFormChrome(props: {
  isOpen: boolean;
  close: () => void;
  children: React.ReactNode;
}) {
  return (
    <Transition appear show={props.isOpen === true} as={Fragment}>
      <Dialog as="div" className="relative z-60" onClose={props.close}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-6xl transform overflow-hidden rounded-2xl bg-[#F1F1F1] p-6 text-left align-middle shadow-xl transition-all">
                {props.children}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
