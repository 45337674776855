import { updateCustomer } from "@/data/customer";
import { useCurrentUid } from "@/data/studio-react/firebase/useFirestoreAuth";
import dynamic from "next/dynamic";
import { useEffect, useMemo, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { Customer } from "../../../../types";

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
];

interface SettingsNotesProps {
  customer: Customer;
  marketId: string;
}

function SettingsNotes(props: SettingsNotesProps) {
  let { customer, marketId } = props;
  let customerId = customer?.id;
  let currentUid = useCurrentUid();

  let value = customer?.notes || "";
  let [dirty, setDirty] = useState<String>();

  const ReactQuill = useMemo(
    () => dynamic(() => import("react-quill"), { ssr: false }),
    []
  );

  useEffect(() => {
    setDirty(value);
  }, []);

  return (
    <div className="grid">
      <div>
        <div className="w-full rounded-md bg-white p-4">
          <ReactQuill
            modules={modules}
            formats={formats}
            theme="snow"
            //@ts-ignore
            value={dirty}
            onChange={(value) => setDirty(value)}
          />
          <button
            className="mt-4 inline-flex items-center gap-x-1.5 rounded-md bg-martEye-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-martEye-400 disabled:cursor-not-allowed disabled:opacity-50 hover:bg-martEye-500"
            disabled={dirty ? false : true}
            onClick={() => {
              let update: Partial<Customer> = {
                notes: dirty as string,
              };
              updateCustomer(marketId, currentUid, customerId, update);
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default SettingsNotes;
