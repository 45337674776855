import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useRef } from "react"; // Add this import

import { classNames } from "@/data/classnames";
import useStudio from "@/data/studio-react/useStudio";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Fragment, useMemo } from "react";
import { Invoice } from "types";

type MenuSection = {
  buttons: {
    show?: boolean;
    disabled?: boolean;
    section: string;
    icon?: React.ReactNode;
    text?: string;
    onClick?: () => void | Promise<void>;
  }[];
};

export function InvoiceActionButton(props: { invoice: Invoice | null }) {
  let { voidInvoice } = useStudio();

  let sections: MenuSection[] = useMemo(() => {
    if (!props.invoice) return [];

    let invoice: Invoice = props.invoice;

    return [
      {
        buttons: [
          {
            show: true,
            disabled: invoice.status !== "issued" && invoice.status !== "paid",
            section: "Sorting",
            icon: (
              <XMarkIcon
                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
            ),
            text: `Void ${
              invoice.clientType === "Buyer" ? "Invoice" : "Statement"
            }`,
            onClick: async () => {
              // Get the void reason from the user
              let voidReason = prompt(
                "Please enter the reason for voiding the invoice"
              );
              if (voidReason) {
                await voidInvoice(invoice.marketId, invoice.id, voidReason);
              }
            },
          },
        ],
      },
    ];
  }, [props.invoice, voidInvoice]);

  return <InvoiceActionsButton sections={sections} />;
}

function InvoiceActionsButton(props: { sections: MenuSection[] }) {
  let menuButtonRef = useRef<HTMLButtonElement>(null);
  let { sections } = props;
  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            ref={menuButtonRef}
            className="inline-flex w-full justify-center rounded-md border-2 border-white/40 bg-transparent px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-100/40 hover:bg-gray-50/5"
          >
            Actions
            <ChevronDownIcon
              className="-mr-1 ml-2 h-5 w-5"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {sections.map((section, sectionIndex) => (
              <div key={sectionIndex} className="py-1">
                {section.buttons.map((button, buttonIndex) => (
                  <Menu.Item key={buttonIndex}>
                    {({ active }) => (
                      // horizontal line
                      <>
                        {buttonIndex !== 0 &&
                          button.section !==
                            section.buttons[buttonIndex - 1].section && (
                            <div className="border-t border-gray-100"></div>
                          )}

                        <button
                          type="button"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "group flex w-full items-center px-4 py-2 text-sm",
                            button.disabled
                              ? "cursor-not-allowed opacity-50"
                              : ""
                          )}
                          onClick={() => {
                            if (button.onClick) {
                              button.onClick();
                            }
                            if (menuButtonRef.current) {
                              menuButtonRef.current.click(); // Close the menu
                            }
                          }}
                          disabled={button.disabled}
                        >
                          {button.icon}
                          {button.text}
                        </button>
                      </>
                    )}
                  </Menu.Item>
                ))}
              </div>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
}
