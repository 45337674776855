import { useMarketId } from "@/data/useMartketId";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { DraftInvoice, Invoice } from "types";

interface PosMonitorState {
  invoice: DraftInvoice | Invoice | null;
  isDraft: boolean | null;
  qrCode: string | null;
  version: number;
}

type Actions = {
  openPosMonitor: () => void;
  showInvoiceOnPosMonitor: (invoice: DraftInvoice | Invoice) => () => void;
};

export const posMonitorChannel = new BroadcastChannel("pos_monitor");

const PosMonitorContext = React.createContext<Actions>({
  openPosMonitor: () => {},
  showInvoiceOnPosMonitor: () => {
    return () => {};
  },
});

export function usePosMonitor() {
  return React.useContext(PosMonitorContext);
}

// many monitor pages can be opened. All should receive updates from this parent page
export function PosMoitorProvider(props: { children: React.ReactNode }) {
  let state = useRef<PosMonitorState>({
    invoice: null,
    isDraft: null,
    qrCode: null,
    version: 0,
  });

  let marketId = useMarketId();

  let openNewWindow = useCallback(() => {
    window.open(
      `/${marketId}/pos-monitor`,
      "POS Display",
      "width=1280,height=720"
    );

    // send the current state to the window
    sendDataToWindows();
  }, [marketId]);

  let sendDataToWindows = useCallback(() => {
    posMonitorChannel.postMessage(state.current);
  }, []);

  // Listen for new pages that open and send them the current state
  useEffect(() => {
    let onMessage = (e: MessageEvent) => {
      if (e.data) {
        let { type } = e.data;
        if (type === "get-state") {
          setTimeout(() => {
            // Another pos window may respond with an empty state
            if (state.current.invoice) {
              sendDataToWindows();
            }
          }, 100);
        }
      }
    };

    posMonitorChannel.addEventListener("message", onMessage);
    return () => {
      posMonitorChannel.removeEventListener("message", onMessage);
    };
  }, []);

  let actions = useMemo(() => {
    return {
      openPosMonitor: openNewWindow,
      showInvoiceOnPosMonitor: (invoice: DraftInvoice | Invoice) => {
        if (!invoice) {
          return () => {};
        }

        let qrCodeLink = null;
        if (invoice.status !== "draft") {
          qrCodeLink = (invoice as Invoice).pitchPayLink;
          qrCodeLink = qrCodeLink?.replace("https://", "https://qr.") ?? null;
        }

        let version = Math.random();

        state.current = {
          invoice: invoice,
          isDraft: invoice.status === "draft",
          qrCode: qrCodeLink,
          version,
        };

        sendDataToWindows();

        // A function to remove the invoice from the monitor
        return () => {
          // Sometimes another invoice will come in quickly after, allow that to transition without a flash to the empty state
          setTimeout(() => {
            if (state.current.version === version) {
              state.current = {
                invoice: null,
                isDraft: null,
                qrCode: null,
                version: Math.random(),
              };
              sendDataToWindows();
            }
          }, 2000);
        };
      },
    };
  }, []);

  return (
    <PosMonitorContext.Provider value={actions}>
      {props.children}
    </PosMonitorContext.Provider>
  );
}
