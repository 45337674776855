import { formatAsCurrency } from "@/data/amounts";
import { classNames } from "@/data/classnames";
import { useState } from "react";
import { InvoiceLineItem, Lot } from "types";

const removeTrailingZero = false;

// Higher level component here that converts the data into a format that we need
export function LineItemTable(props: {
  isLoadingDocument: boolean;
  lotCountHint: number;
  lots?: Lot[] | null;
  lotLineItems?: InvoiceLineItem[] | null;
  commissionLineItems?: InvoiceLineItem[];
  adjustmentLineItems?: InvoiceLineItem[];
  discountByLineItem: { [lineItemId: string]: number };
  customerIsVATRegistered?: boolean;
  customerWithinTaxRegion?: boolean;
  setDiscountByLineitemInCents?: (
    lineItemId: string,
    discount: number | null
  ) => void;
  hasError: boolean;
}) {
  let {
    lots,
    lotLineItems,
    commissionLineItems,
    adjustmentLineItems,
    lotCountHint: lotCount,
    hasError,
  } = props;

  // either -
  //  - no data. Show table skeleton with no data
  //  - lots loaded in. Partially fill the table
  //  - invoice loaded in. Fill the table with the invoice data

  let [commissionPc, setCommissionPc] = useState(false);
  let toggleShowCommissionAsPercent = () => setCommissionPc(!commissionPc);
  let isLoading = false;

  let items: TableRow[] = [];
  let adjustmentItems: TableRow[] = [];
  if (!lots && !lotLineItems && !commissionLineItems) {
    // Show skeleton
    items = Array.from({ length: lotCount }, (_, i) => ({
      id: i.toString(),
      lotNumber: "",
      description: "",
      seller: "",
      quantity: "",
      price: "",
      discount: "",
      commission: "",
      tax: "",
      total: "",
    }));
    isLoading = true;
  } else if (lots && !lotLineItems && !commissionLineItems) {
    // Partially fill the table
    items = lots.map((lot) => ({
      id: lot.id,
      lotNumber: lot.lotNumber,
      description: lot.generated?.description,
      seller: lot.seller?.accountNumber,
      quantity: lot.attributes["countOfItems"] || "unknown",
      price: formatAsCurrency(null, lot.generated?.totalValueInCents || 0),
      discount: null,
      commission: null,
      tax: null,
      total: null,
    }));
    isLoading = true;
  } else if (lotLineItems && commissionLineItems && adjustmentLineItems) {
    // Fill the table with the invoice data
    let cli = commissionLineItems;
    items = lotLineItems.map((li) => {
      let lotCommissionLineItems = cli.filter((adj) => adj.lotId === li.lotId);

      let sum = (selector: (li: InvoiceLineItem) => number | undefined) => {
        return lotCommissionLineItems.reduce((acc, c) => {
          return acc + (selector(c) || 0);
        }, 0);
      };

      let totalCommissionIncVat =
        sum((li) => li.totalAmountInCentsExVat) +
        sum((li) => li.taxAmountInCents);

      let totalExVAT = sum((li) => li.totalAmountInCentsExVat);
      let discount = sum((li) => li.discountAmountInCents);

      let totalCommissionWithoutDiscount = totalExVAT + discount;

      /// this might be funny on screen when its an inclusive vat price
      let commissionAsPercent =
        (totalCommissionWithoutDiscount / li.totalAmountInCentsExVat! || 0) *
        100;
      let commissionAsPercentString = commissionAsPercent
        .toFixed(2)
        .replace(".00", "");

      let totalTaxOnCommission = lotCommissionLineItems.reduce((acc, adj) => {
        return acc + adj.taxAmountInCents;
      }, 0);

      // Calculate the commission multiplier based on the client type and VAT status CF

      let isSeller = li.clientType === "Seller";
      let isBuyer = li.clientType === "Buyer";
      let servicesVatMultiplier = 0;
      let goodsVatMultiplier = 0;
      const isVATReg = props.customerIsVATRegistered;
      const isWithinTaxRegion = props.customerWithinTaxRegion;

      // Seller - return vat if vat registered
      // keep vat if not vat registered. Should be caught by lot gremlin
      if (isSeller && !li.unitPriceIncludesVat) {
        servicesVatMultiplier = -1;
        goodsVatMultiplier = isVATReg ? 1 : 0;
      }

      if (isSeller && li.unitPriceIncludesVat) {
        servicesVatMultiplier = -1;
        goodsVatMultiplier = isVATReg ? 1 : 1;
      }

      // Buyer 0 vat if vta reg and not in tax region
      if (isBuyer && !li.unitPriceIncludesVat) {
        servicesVatMultiplier = 1;
        goodsVatMultiplier = isVATReg && !isWithinTaxRegion ? 0 : 1;
      }

      if (isBuyer && li.unitPriceIncludesVat) {
        servicesVatMultiplier = 1;
        goodsVatMultiplier = isVATReg && !isWithinTaxRegion ? 1 : 1;
      }

      // can only discount the commission
      let maxDiscountAvailable = totalCommissionWithoutDiscount / 100;

      // at the moment only allow discounting if we only have 1 commision line item
      let discountConfig = undefined;
      if (lotCommissionLineItems.length === 1) {
        let lotCommissionLineItem = lotCommissionLineItems[0];
        if (props.setDiscountByLineitemInCents && lotCommissionLineItem) {
          let setDiscountInCents = props.setDiscountByLineitemInCents;
          let value = props.discountByLineItem[lotCommissionLineItem.id] || 0;
          discountConfig = {
            setDiscount: (discount: number) => {
              if (discount !== null && discount !== undefined) {
                discount = discount * 100;
              }
              setDiscountInCents(lotCommissionLineItem!.id, discount);
            },
            max: maxDiscountAvailable,
            disabled: props.isLoadingDocument,
            value,
            hasDiscount: value > 0,
          };
        }
      }

      return {
        id: li.id,
        lotNumber: li.metadata.lotNumber,
        description: li.description,
        seller: li.metadata.seller,
        quantity: li.quantity + (li.unitOfSale === "Per KG" ? ` Kg` : "x"),
        price: formatAsCurrency(
          null,
          (li.unitPriceIncludesVat
            ? li.impreciseUnitPriceInCents - li.taxAmountInCents
            : li.impreciseUnitPriceInCents) || 0,
          removeTrailingZero
        ),
        discount: formatAsCurrency(
          null,
          li.discountAmountInCents || 0,
          removeTrailingZero
        ),
        commission: commissionPc
          ? `${commissionAsPercentString}%`
          : formatAsCurrency(
              null,
              totalCommissionWithoutDiscount * servicesVatMultiplier,
              removeTrailingZero
            ),
        discountConfig: discountConfig,
        tax: formatAsCurrency(
          null,
          li.taxAmountInCents! * goodsVatMultiplier +
            totalTaxOnCommission * servicesVatMultiplier,
          removeTrailingZero
        ),
        total: formatAsCurrency(
          null,
          li.totalAmountInCentsExVat! +
            totalCommissionIncVat * servicesVatMultiplier +
            li.taxAmountInCents! * goodsVatMultiplier,
          removeTrailingZero
        ),
      };
    });

    const mergedAdjustments = adjustmentLineItems.reduce((acc, item) => {
      const id = item.adjustmentConfig?.id || item.productId;

      if (!id) return acc;

      if (!acc[id]) {
        acc[id] = { ...item };
      } else {
        acc[id].quantity += item.quantity;
        acc[id].impreciseUnitPriceInCents = item.impreciseUnitPriceInCents;
        acc[id].taxAmountInCents =
          (acc[id].taxAmountInCents || 0) + (item.taxAmountInCents || 0);
        acc[id].totalAmountInCentsExVat =
          (acc[id].totalAmountInCentsExVat || 0) +
          (item.totalAmountInCentsExVat || 0);
      }
      return acc;
    }, {} as { [key: string]: InvoiceLineItem });

    adjustmentItems = Object.values(mergedAdjustments).map((li) => {
      let discountConfig = undefined;
      // if (props.setDiscountByLineitem) {
      //   let setDiscount = props.setDiscountByLineitem;
      //   let totalExVAT = li.totalAmountInCentsExVat || 0;
      //   let discount = li.discountAmountInCents || 0;
      //   let totalExVatIncDiscount = totalExVAT + discount;

      //   discountConfig = {
      //     setDiscount: (discount: number) => {
      //       setDiscount(li.id, discount);
      //     },
      //     max: totalExVatIncDiscount / 100,
      //     value: props.discountByLineItem[li.id] || 0,
      //     disabled: props.isLoadingDraftInvoice,
      //   };
      // }

      return {
        id: li.id,
        description: li.description,
        quantity: `${li.quantity} x`,
        price: formatAsCurrency(
          null,
          li.impreciseUnitPriceInCents || 0,
          removeTrailingZero
        ),
        tax: formatAsCurrency(
          null,
          li.taxAmountInCents || 0,
          removeTrailingZero
        ),
        discountConfig,
        total: formatAsCurrency(
          null,
          (li.totalAmountInCentsExVat || 0) + (li.taxAmountInCents || 0),
          removeTrailingZero
        ),
      };
    });
  }

  return (
    <>
      <Table
        items={items}
        adjustmentItems={[]}
        isLoading={!hasError && isLoading}
        showCommissionAsPercent={commissionPc}
        toggleShowCommissionAsPercent={toggleShowCommissionAsPercent}
      />
      <div className="mt-4">
        <Table
          items={[]}
          adjustmentItems={adjustmentItems}
          isLoading={!hasError && isLoading}
          showCommissionAsPercent={commissionPc}
          toggleShowCommissionAsPercent={toggleShowCommissionAsPercent}
        />
      </div>
    </>
  );
}

type TableRow = {
  id: string;
  lotNumber?: string | null;
  description?: string | null;
  seller?: string | null;
  quantity?: string | null;
  price?: string | null;
  discount?: string | null;
  commission?: string | null;
  tax?: string | null;
  total?: string | null;
  discountConfig?:
    | {
        setDiscount: (discount: number) => void;
        max: number;
        value: number;
        disabled: boolean;
        hasDiscount: boolean;
      }
    | undefined;
};

// The table itself
interface TableProps {
  items: TableRow[];
  adjustmentItems: TableRow[];

  isLoading: boolean;

  // Leave this undefined if the discount is not editable
  showCommissionAsPercent: boolean;
  toggleShowCommissionAsPercent: () => void;
}

function Table(props: TableProps) {
  let headings = [
    "Lot",
    "Description",
    "Seller",
    "Quantity",
    "Price",
    "Commission",
    "Discount",
    "Tax",
    "Amount",
  ];

  if (props.adjustmentItems.length > 0 && props.items.length === 0) {
    headings = [
      " ",
      "Description",
      " ",
      "Quantity",
      "Price",
      " ",
      " ",
      "Tax",
      "Amount",
    ];
  }

  if (props.adjustmentItems.length === 0 && props.items.length === 0) {
    return null;
  }

  let renderRow = (item: TableRow, idx: number) => (
    <tr
      key={item.id}
      className={classNames(
        idx === 0 ? "border-gray-300" : "border-gray-200/30",
        "border-t",
        idx % 2 !== 0 ? "bg-gray-50" : "bg-white"
      )}
    >
      <td
        className={
          "whitespace-nowrap py-2 pl-4 pr-3 text-sm font-normal text-gray-900 w-16"
        }
      >
        {item.lotNumber}
      </td>

      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-normal text-gray-900 border-l border-gray-200">
        {item.description}
      </td>

      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-normal text-gray-900 border-l border-gray-200 w-32">
        {item.seller}
      </td>

      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-light text-gray-900 border-l border-gray-200 w-10">
        {item.quantity}
      </td>

      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-light text-gray-900 border-l border-gray-200 w-28">
        {item.price}
      </td>

      <td
        className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-light text-gray-900 cursor-pointer border-l border-gray-200 w-28"
        onClick={props.toggleShowCommissionAsPercent}
      >
        {item.commission}
      </td>
      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-light text-gray-900 border-l border-gray-200 w-32">
        {item.discountConfig ? (
          <EditableDiscount {...item.discountConfig} />
        ) : (
          <span>{item.discount}</span>
        )}
      </td>

      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-light text-gray-900 border-l border-gray-200 w-24">
        {item.tax}
      </td>

      <td
        className={classNames(
          "whitespace-nowrap py-2 pl-4 pr-3 text-sm font-semibold text-gray-900 border-l border-gray-200 w-24",
          item.discountConfig?.hasDiscount ? "text-green-600 font-medium" : ""
        )}
      >
        {item.total}
      </td>
    </tr>
  );

  return (
    <table
      className={classNames(
        "min-w-full flex-grow rounded-md overflow-hidden shadow-sm",
        props.isLoading ? "animate-pulse" : ""
      )}
    >
      <thead className="bg-white">
        <tr className="bg-gray-100">
          {headings.map((h, i) => {
            return (
              <th
                key={`${h}-${i}`}
                scope="col"
                className={classNames(
                  `text-left text-sm font-medium text-gray-500`,
                  i === 0 ? `py-2 pl-4 pr-3` : `px-3 py-2`,
                  i > 0 ? `border-l border-gray-200` : ``
                )}
              >
                {h}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className="bg-white">
        {[...props.items, ...props.adjustmentItems].map(renderRow)}
      </tbody>
    </table>
  );
}

function EditableDiscount(props: {
  setDiscount: (discount: number) => void;
  max: number;
  value: number;
  disabled: boolean;
  hasDiscount: boolean;
}) {
  // Keep the state local until the field is blurred
  let [localValue, setLocalValue] = useState(`${props.value}`);
  let [isFocused, setIsFocused] = useState(false);

  let value = localValue === "0" ? "" : localValue;

  return (
    <div className="relative rounded-md shadow-sm w-24">
      <input
        type="text"
        name="discount"
        id="discount"
        className={classNames(
          "block w-full rounded-md border-0 py-1.5 pl-3 pr-3 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6",
          props.disabled ? "bg-gray-100 text-gray-500 cursor-not-allowed" : "",
          props.hasDiscount && !isFocused
            ? "text-green-600 font-medium"
            : "text-gray-900 "
        )}
        disabled={props.disabled}
        placeholder="0.00"
        min={0}
        max={props.max}
        value={value}
        onChange={(e) => {
          setLocalValue(e.target.value);
        }}
        onFocus={(e) => {
          setIsFocused(true);
          // select the text
          setTimeout(() => {
            e.target.select();
          }, 0);
        }}
        onBlur={(e) => {
          setIsFocused(false);

          let val = parseFloat(e.target.value);
          if (isNaN(val)) {
            val = 0;
          }

          // Ensure only 2 decimal places
          val = parseFloat(val.toFixed(2));
          // Ensure the discount is not more than the commission
          val = Math.min(val, props.max);
          // Ensure the discount is not less than 0
          val = Math.max(val, 0);
          setLocalValue(val.toFixed(2));

          props.setDiscount(val);
        }}
      />
    </div>
  );
}
