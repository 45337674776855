import { classNames } from "@/data/classnames";
import {
  useFirestore,
  useLoadFirestoreQuery,
} from "@/data/studio-react/firebase/useFirestore";
import { format, formatDistanceToNow } from "date-fns";
import { collection, query, Timestamp, where } from "firebase/firestore";
import { useMemo } from "react";
import { Customer, Invoice } from "../../../types";
import { InvoiceTable } from "../InvoiceTable";

import { SellersCard } from "@/components/sale-page/sellers/SellersCard";
import { AccessLevel } from "@/data/AccessLevel";
import { formatAsCurrency } from "@/data/amounts";
import { useStudioStream } from "@/data/studio-react/useStudioStream";

interface OverviewTabProps {
  customer: Customer | null;
  marketId: string;
}

interface CustomerStats {
  name: string;
  value: string;
  detail?: string;
  valueType: "positive" | "negative" | "neutral";
}

export function OverviewTab(props: OverviewTabProps) {
  let { customer, marketId } = props;
  let firestore = useFirestore();
  let customerUid = customer?.id || null;
  let defaults = useStudioStream("marketDefaultSettings", marketId);
  let currency = defaults?.defaultCurrency || "GBP";
  let accessLevel = useStudioStream("session:accessLevel");

  // Admin or Owner
  let canSeeFinanceData = accessLevel && accessLevel > AccessLevel.Editor;

  let invoicesQuery = useMemo(() => {
    if (!customerUid) {
      return null;
    }
    return query(
      collection(firestore, `markets/${marketId}/invoices`),
      where(`customerId`, `==`, customerUid)
    );
  }, [marketId, customerUid, firestore]);

  let invoicesLoadInfo = useLoadFirestoreQuery<Invoice>(invoicesQuery, {
    idField: "id",
  });

  let cxTrdReceivable =
    useStudioStream("balance", marketId, {
      account: customerUid && `${customerUid}:asset:trade receivable`,
    }) || 0;

  let cxTrdPayable =
    useStudioStream("balance", marketId, {
      account: customerUid && `${customerUid}:liability:trade payable`,
    }) || 0;

  let customerBalances =
    cxTrdReceivable &&
    cxTrdPayable &&
    !cxTrdReceivable.loading &&
    !cxTrdPayable.loading
      ? {
          tradeReceivable: cxTrdReceivable.data!,
          tradePayable: cxTrdPayable.data!,
        }
      : null;

  let currentBalance =
    (customerBalances?.tradeReceivable ?? 0) +
    (customerBalances?.tradePayable ?? 0);

  const stats: CustomerStats[] = useMemo(() => {
    let createdAtDate = customer?.createdAt?.toDate();
    let lastUpdateDate = customer?.updatedAt?.toDate();

    // TODO - get farm assurance numbers from customer and check not expired
    const { attributeDefaultsSeller } = customer || {};

    let farmAssuranceNumber =
      attributeDefaultsSeller?.farmAssuranceNumberSheep?.[0] ||
      attributeDefaultsSeller?.farmAssuranceNumberCattle?.[0];
    let farmAssuranceDate =
      attributeDefaultsSeller?.farmAssuranceExpirySheep?.[0] ||
      attributeDefaultsSeller?.farmAssuranceExpiryCattle?.[0];

    let secions = [
      canSeeFinanceData
        ? {
            name: "Current Balance",
            value: formatAsCurrency(currency, currentBalance) || "-",
            detail: "",
            valueType: currentBalance >= 0 ? "neutral" : "negative",
          }
        : {
            name: "",
            value: "-",
            detail: "",
            valueType: "neutral",
          },
      {
        name: "Farm Assured",
        value: farmAssuranceNumber ? "Yes" : "No",
        valueType: "neutral",
        detail: farmAssuranceNumber
          ? "Exp: " +
            (farmAssuranceDate
              ? format((farmAssuranceDate as Timestamp).toDate(), "dd/MM/yyyy")
              : "-")
          : "",
      },
      {
        name: "Member Since",
        value: createdAtDate ? format(createdAtDate, "dd/MM/yyyy") : "-",
        valueType: "neutral",
      },
      {
        name: "Last amendment",
        value: lastUpdateDate
          ? formatDistanceToNow(lastUpdateDate, { addSuffix: true })
          : "-",
        valueType: "neutral",
      },
    ];

    return secions.filter(Boolean) as CustomerStats[];
  }, [marketId, customerUid, customer, currentBalance, currency]);

  return (
    <>
      <div className="flex flex-wrap gap-4 p-3">
        <div className="w-full rounded-md bg-white p-4">
          <div className="gap-4 md:flex md:flex-nowrap">
            <SellersCard
              classnames="w-full md:w-[400px]"
              sellerCustomerId={customerUid}
              doNotOpenProfileOnClick={true}
            />

            <div className="md:grow">
              <dl className="mx-auto grid grid-cols-1 gap-px bg-gray-200 sm:grid-cols-2 ">
                {stats.map((stat) => (
                  <div
                    key={stat.name}
                    className="flex flex-wrap justify-center gap-x-4 gap-y-2 bg-white p-4"
                  >
                    <dt className="text-sm font-medium text-martEye-500">
                      {stat.name}
                    </dt>
                    <dd
                      className={classNames(
                        "w-full text-center text-3xl font-bold",
                        stat?.valueType === "negative"
                          ? "text-danger-300"
                          : " text-martEye-600"
                      )}
                    >
                      {stat.value}
                    </dd>
                    <dd
                      className={classNames(
                        stat?.valueType === "negative"
                          ? "text-danger-300"
                          : "text-gray-400",
                        "text-xs font-medium italic"
                      )}
                    >
                      {stat.detail}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
        <div className="w-full">
          <InvoiceTable invoices={invoicesLoadInfo.data ?? []}></InvoiceTable>
        </div>
      </div>
    </>
  );
}
