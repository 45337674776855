import { AttributeDefinition, AttributeValueType } from "types";

const DefaultsCheckboxField = ({
  attribute,
  values,
  setValues,
}: {
  attribute: AttributeDefinition;
  values: boolean[];
  setValues: (values: AttributeValueType[]) => void;
}) => {
  return (
    <div className="grow">
      <input
        type="checkbox"
        className="h-5 w-5 rounded border-gray-300 text-green-600 focus:ring-green-600"
        value="true"
        checked={values[0]}
        onChange={(event) => {
          setValues([event.target.checked]);
        }}
      />
    </div>
  );
};

export default DefaultsCheckboxField;
