import SettingsGeneral from "./SettingsGeneral";
import SettingsBanking from "./SettingsBanking";
import SettingsNotes from "./SettingsNotes";
import { SettingsAddresses } from "./SettingsAddresses";
import SettingsDefaults from "./SettingsDefaults";
export {
  SettingsGeneral,
  SettingsBanking,
  SettingsNotes,
  SettingsAddresses,
  SettingsDefaults,
};
