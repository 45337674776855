import { deleteDoc, doc, Transaction } from "firebase/firestore";
import { Lot } from "types";
import { FullSaleContext } from "../ExecuteInSaleContext";

/***
 * Transaction for removing a Lot
 */
export async function deleteLot(
  ctx: FullSaleContext,
  lotOrId: Lot | string,
  transaction?: Transaction
): Promise<void> {
  let lot = typeof lotOrId === "string" ? { id: lotOrId } : lotOrId;

  let lotDocRef = doc(
    ctx.firestore,
    `markets/${ctx.marketId}/sales/${ctx.saleId}/lots/${lot.id}`
  );

  if (transaction) {
    transaction.delete(lotDocRef);
    return;
  }

  await deleteDoc(lotDocRef);
}
