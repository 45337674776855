import { Lot } from "../types";

// Helper function to generate a sort key for a lot
export function generateLotNumberSortKey(
  lot: Lot,
  groupKey: string = ""
): string {
  let lotNumber = lot.lotNumber || "";
  let match = lotNumber.match(/^(\d+)(.*)$/);
  if (match) {
    let [, numPart, alphaPart] = match;
    return `${groupKey}${numPart.padStart(10, "0")}${alphaPart}`;
  }
  return lotNumber;
}

/***
 * Efficiently sorts an array of lots by lot number grouping by their group.
 *
 * Currently complexity is O(n log n) where n is the number of lots.
 */
export function sortByLotNumber(lots: Lot[]): Lot[] {
  // Group lots and find the minimum lot number for each group
  let groups = lots.reduce((acc, lot) => {
    if (lot.group) {
      if (!acc[lot.group]) {
        acc[lot.group] = { minSortKey: generateLotNumberSortKey(lot) };
      } else {
        let newSortKey = generateLotNumberSortKey(lot);
        if (newSortKey < acc[lot.group].minSortKey) {
          acc[lot.group].minSortKey = newSortKey;
        }
      }
    }
    return acc;
  }, {} as Record<string, { minSortKey: string }>);

  // Sort function
  let sortFn = (a: Lot, b: Lot): number => {
    let aGroupKey = a.group ? groups[a.group].minSortKey : "";
    let bGroupKey = b.group ? groups[b.group].minSortKey : "";
    let aSortKey = generateLotNumberSortKey(a, aGroupKey);
    let bSortKey = generateLotNumberSortKey(b, bGroupKey);

    let comparison = aSortKey.localeCompare(bSortKey);
    if (comparison === 0) {
      let aCreated = a.createdAt?.toMillis() ?? a.id;
      let bCreated = b.createdAt?.toMillis() ?? b.id;
      return String(aCreated).localeCompare(String(bCreated));
    }
    return comparison;
  };

  // Sort the lots
  // important not to sort in place
  return [...lots].sort(sortFn);
}

export function getLotStatus(lot: Lot, activeLotGroup?: string | null) {
  let borderColor: string = "#CBCBCB";
  let backgroundColor: string = "#FFFFFF";

  let status:
    | "incomplete"
    | "active"
    | "priced"
    | "buyer-assigned"
    | "invoiced"
    | "statemented"
    | "completed"
    | "error"
    | "void" = "incomplete";

  if (lot.unitPriceInCents !== undefined && lot.unitPriceInCents !== null) {
    status = "priced";
  }

  if (lot.buyerCustomerId !== undefined && lot.buyerCustomerId !== null) {
    status = "buyer-assigned";
  }

  let hasInvoice = false;
  if (lot.buyerInvoiceId !== undefined && lot.buyerInvoiceId !== null) {
    status = "invoiced";
    hasInvoice = true;
  }

  let hasStatement = false;
  if (lot.sellerInvoiceId !== undefined && lot.sellerInvoiceId !== null) {
    status = "statemented";
    hasStatement = true;
  }

  if (hasInvoice && hasStatement) {
    status = "completed";
    backgroundColor = "#DCFCE8";
    borderColor = "#BCEACD";
  }

  if (lot.void) {
    status = "void";
    backgroundColor = "#FFFFFF";
    borderColor = "#F1F4F6";
  }

  // This will only show blocking issues
  let hasBlockingIssues =
    Object.values(lot?.issues ?? [])?.some((issue) => issue?.blockCheckout) ??
    false;

  if (hasBlockingIssues) {
    status = "error";
    backgroundColor = "#FFE5E5";
    borderColor = "#FF6D6D";
  }

  // TODO this will change in the future to maybe orange
  // This will only show blocking issues
  let hasNonBlockingIssues =
    Object.values(lot?.issues ?? [])?.some((issue) => !issue?.blockCheckout) ??
    false;

  if (hasNonBlockingIssues) {
    status = "error";
    backgroundColor = "#FFE5E5";
    borderColor = "#FF6D6D";
  }

  if (activeLotGroup && lot.group === activeLotGroup) {
    status = "active";
    backgroundColor = "#FFF7BD";
    borderColor = "#C6AB18";
  }

  let statusIcon = `/lot-statuses/${status}.png`;

  return {
    status,
    statusIcon,
    borderColor,
    backgroundColor,
  };
}

// export function toLotWithItemsAsArray(lot: Lot) {
//   let items = Object.values(lot.itemMap ?? {}).sort((a, b) => {
//     // sort by index
//     return a.index - b.index;
//   });

//   let itermediate: Omit<Lot, "itemMap"> & {
//     itemMap?: {
//       [itemId: string]: LotItem;
//     };
//     items: LotItem[];
//   } = {
//     ...lot,
//     items,
//   };

//   delete itermediate.itemMap;

//   return itermediate as LotWithItemsAsArray;
// }

// export function fromLotWithItemsAsArray(
//   lotWithItemsAsArray: LotWithItemsAsArray
// ) {
//   let items = lotWithItemsAsArray.items;

//   let itermediate: Omit<LotWithItemsAsArray, "items"> & {
//     itemMap: {
//       [itemId: string]: LotItem;
//     };
//     items?: LotItem[];
//   } = {
//     ...lotWithItemsAsArray,
//     itemMap: {},
//   };

//   for (let item of items) {
//     itermediate.itemMap[item.id] = item;
//   }

//   delete itermediate.items;

//   return itermediate as Lot;
// }
