import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import QSHelpOnHover from "./QSHelpOnHover";
import { classNames } from "@/data/classnames";

interface FormikFieldHandler {
  label: string;
  name: string;
  description?: string | React.ReactNode;
  classes?: string;
  helpText?: string | React.ReactNode;
  hideLabel?: boolean;
}

export const QSCheckboxField = (props: FormikFieldHandler) => {
  const { label, name, classes, helpText, hideLabel } = props;
  let { getFieldProps, getFieldMeta, registerField, unregisterField } =
    useFormikContext();
  let fieldProps = getFieldProps({
    name,
    type: "checkbox",
  });
  let meta = getFieldMeta(name);

  let inputRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    // register the field
    // console.log("registering field", name);

    registerField(name, {});

    return () => {
      unregisterField(name);
    };
  }, [name, registerField, unregisterField]);

  return (
    <div className={classes}>
      <label
        htmlFor={name}
        className={classNames(
          hideLabel
            ? "sr-only"
            : "text-sm font-medium leading-6 text-gray-900 flex flex-row items-center"
        )}
      >
        {label}
        <QSHelpOnHover helpText={helpText} />
      </label>

      <div className="mt-1 flex rounded-md">
        <div className="relative flex items-center h-[40px]">
          <div className="flex items-center">
            <input
              ref={inputRef}
              aria-describedby={`${name}-description`}
              type="checkbox"
              className="h-5 w-5 rounded border-gray-300 text-green-600 focus:ring-green-600"
              {...fieldProps}
              value="true"
              onChange={(e) => {
                // Set touched, important for building the update object in submit
                // fieldProps.onBlur(e);
                fieldProps.onChange(e);

                // lose focus
                // e.target.blur();
              }}
            />
          </div>
          <div className="ml-2 text-sm leading-6">
            <p
              id={`${name}-description`}
              className="text-gray-500 hover:text-gray-700 select-none"
              onClick={() => {
                inputRef.current?.focus();
                // Tricky errors if we try and change the value of the checkbox here
              }}
            >
              {props.description}
            </p>
          </div>
        </div>
      </div>
      {meta.error && <div>{meta.error}</div>}
    </div>
  );
};

export default QSCheckboxField;
