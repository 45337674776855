// Override console.log and have it not output in production if we're clientside
if (typeof window !== "undefined" && process.env.NODE_ENV === "production") {
  const logs: any[] = [];
  let isLogging = false;

  let originalConsoleLog = console.log;

  console.log = (...args: any) => {
    if (isLogging) {
      originalConsoleLog(...args);
    } else {
      logs.push(args);
      // only let the array get so big
      if (logs.length > 100) {
        logs.shift();
      }
    }
  };

  // Allow a devloper to turn the logs on
  // @ts-ignore
  window._studio = Object.assign(window._studio || {}, {
    logs,
    startLogging: () => {
      isLogging = true;
      // re-output all the logs
      for (let log of logs) {
        originalConsoleLog(...log);
      }
      logs.length = 0;
    },
    stopLogging: () => {
      isLogging = false;
    },
  });
}

export {};
