import { DialogTitle } from "@headlessui/react";
import dynamic from "next/dynamic";
import { ReactNode } from "react";
import { useFlyout } from "./useFlyout";

const FlyoutChrome = dynamic(() => import("./FlyoutChrome"), {
  ssr: false,
});

interface FlyoutProps {
  // the params used to encode the state in the url
  // often one for the object ID (lotId, saleId, etc) and the tab index
  urlParams: string[];

  children: (
    urlParamValues: {
      [key: string]: string | null;
    } | null,
    close: () => void,
    flyoutIsOpen: boolean
  ) => ReactNode;

  // If there's another dialog that needs to open over the flyout, supply it here
  submountDialogs?: any[];
}

export default function Flyout(props: FlyoutProps) {
  let { urlParams } = props;

  let [isFlyoutOpen, _, closeFlyout, flyoutParameters] = useFlyout(urlParams);

  return (
    <FlyoutChrome
      isOpen={isFlyoutOpen}
      close={closeFlyout}
      submountDialogs={props.submountDialogs}
      titleType={Flyout.Title}
      bodyType={Flyout.Body}
    >
      {props.children(flyoutParameters, closeFlyout, isFlyoutOpen)}
    </FlyoutChrome>
  );
}

Flyout.Title = (props: { children: ReactNode }) => {
  return (
    <DialogTitle className="text-2xl font-medium text-gray-100">
      {props.children}
    </DialogTitle>
  );
};

Flyout.Body = (props: { children: ReactNode }) => {
  return <>{props.children}</>;
};
