import { format, isSameDay, isYesterday } from "date-fns";
import { Timestamp } from "firebase/firestore";
import { AttributeDefinition } from "types";
import {
  AttributeRenderTemplates,
  TemplateContext,
} from "../attributeControls";

export function getDisplayValue(
  templates: AttributeRenderTemplates,
  ctx: TemplateContext,
  attribute: AttributeDefinition,
  itemId: string | null
) {
  // if it's item level and multi then use the displayTemplateMultiValue
  // otherwise use the displayTemplate

  if (ctx.items.length === 0 && attribute.level === "item") {
    return "";
  } else if (ctx.items.length === 1 || ctx.allItemsHaveSameValue || itemId) {
    if (ctx.value === undefined || ctx.value === null) {
      // sidestep the template if it's null or undefined
      return "";
    }

    if (templates.displayCompiledTemplate) {
      return templates.displayCompiledTemplate(ctx);
    } else {
      // If it's a date format depending on the type
      if (ctx.value instanceof Timestamp) {
        if (attribute.type === "date") {
          return formatDate(ctx.value.toDate());
        }

        if (attribute.type === "time") {
          return formatTime(ctx.value.toDate());
        }

        return formatDateTime(ctx.value.toDate());
      }

      // Just return the value as a string
      return ctx.value?.toString() || "";
    }
  } else if (ctx.items.length > 1) {
    if (templates.displayCompiledTemplateMultiValue) {
      return templates.displayCompiledTemplateMultiValue(ctx);
    } else {
      if (ctx.values.length < 10) {
        return `${ctx.values.length} values`;
      }
      return "Multiple values";
    }
  }

  return "";
}

export function formatDateTime(date?: Date, includeSeconds: boolean = false) {
  if (!date) {
    return "";
  }

  let dateFormat = isSameDay(date, new Date())
    ? `h:mm${includeSeconds ? ":ss" : ""} a`
    : `d MMM yyyy h:mm${includeSeconds ? ":ss" : ""} a`;

  if (isYesterday(date)) {
    dateFormat = `'Yesterday' h:mm${includeSeconds ? ":ss" : ""} a`;
  }

  return format(date, dateFormat);
}

export function formatDate(date?: Date) {
  if (!date) {
    return "";
  }

  let dateFormat = "d MMM yyyy";
  return format(date, dateFormat);
}

export function formatTime(date?: Date, includeSeconds: boolean = false) {
  if (!date) {
    return "";
  }

  let dateFormat = `h:mm${includeSeconds ? ":ss" : ""} a`;
  return format(date, dateFormat);
}
