import { Customer } from "types";

/**
 * Returns the customer name in the format of Trading Name or First Name Last Name
 * @param customer
 * @returns The customer name as a `string`
 */

export function getDisplayName(customer: Customer | null): string;
export function getDisplayName(
  customer: Customer | null,
  defaultValue: string | null
): string | null;
export function getDisplayName(
  customer: Customer | null,
  defaultValue: string | null = "MISSINGNO"
): string | null {
  if (!customer) {
    return defaultValue;
  }

  const { tradingName, firstName, lastName } = customer;

  if (tradingName) {
    return tradingName.trim();
  }

  let displayNameParts = [firstName, lastName]
    .map((name) => name?.trim())
    .filter(Boolean);

  if (displayNameParts.length === 0) {
    return defaultValue;
  }

  return displayNameParts.join(" ").replace(/,/g, "");
}
