import {
  AttributeRenderTemplates,
  TemplateContext,
} from "../attributeControls";

export function getCartDetailValue<T extends string | number>(
  templates: AttributeRenderTemplates,
  ctx: TemplateContext
): string | null | undefined {
  // Determines if this attribute should be shown on the cart.
  // If there is no template set it's not shown

  // TODO need to work out if needed?

  // if (!templates.cartDetailCompiledTemplate) {
  //   return null;
  // }

  // return templates.cartDetailCompiledTemplate(ctx);

  return null;
}
