import { CheckIcon, FlagIcon } from "@heroicons/react/24/solid";

import { useEffect, useState } from "react";
import { LotIssue } from "../../types";

// Firestore
import { useFirestore } from "@/data/studio-react/firebase/useFirestore";
import { useMarketId } from "@/data/useMartketId";
import { doc, getDoc } from "firebase/firestore";
import { Button } from "../Buttons";

export function LotIssueAlert(props: { issue: LotIssue }) {
  let issue = props.issue;
  let [issueDisplayName, setIssueDisplayName] = useState<string | null>(null);
  let firestore = useFirestore();
  let marketId = useMarketId();

  useEffect(() => {
    const fetchMemberData = async () => {
      if (issue.createdBy) {
        let snapshot = await getDoc(
          doc(firestore, `markets/${marketId}/members/${issue.createdBy}`)
        );
        let member = snapshot.data()?.displayName ?? "";
        setIssueDisplayName(member);
      }
    };
    fetchMemberData();

    return () => {
      setIssueDisplayName(null);
    };
  }, [issue, marketId]);

  return (
    <div className="rounded-md bg-danger p-4 text-danger-400">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <span className="relative block rounded-full bg-white p-2 text-danger-400">
              <FlagIcon className="h-4 w-4" aria-hidden="true" />
            </span>
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-bold">
              {issueDisplayName} added an Issue
            </h3>
            <div className="text-sm font-medium text-gray-500">
              <p>{props.issue.description}</p>
            </div>
          </div>
        </div>
        <div>
          <Button
            variant="secondary"
            onClick={() => {
              console.log("Mark as resolved");
              alert("Mark as resolved");
            }}
          >
            <CheckIcon className="h-4 w-4" aria-hidden="true" />
            Mark as resolved
          </Button>
        </div>
      </div>
    </div>
  );
}
