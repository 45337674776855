import { CellClickedEventArgs, Item } from "@glideapps/glide-data-grid";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  DocumentDuplicateIcon,
  PencilSquareIcon,
  Square3Stack3DIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import { useCallback, useEffect, useMemo, useState } from "react";
import { classNames } from "../../data/classnames";

// Popper.js
import { usePopper } from "react-popper";
import { Portal } from "react-portal";

export function useDataGridContextMenu(
  onEdit?: (cell: Item) => void,
  onDuplicate?: (cell: Item) => void,
  onInsertAbove?: (cell: Item) => void,
  onInsertBelow?: (cell: Item) => void,
  onDelete?: (cell: Item) => void,
  onGroup?: () => void,
  onUngroup?: (shouldShowCheck?: boolean) => void,
  onRemoveFromGroup?: (shouldShowCheck?: boolean) => boolean,
  onFastFillEdit?: (cell: Item) => void
) {
  let [cell, setCell] = useState<Item>();
  let [bounds, setBounds] = useState<any>(null);
  const virtualReference = useMemo(
    () => ({
      getBoundingClientRect() {
        return {
          left: bounds?.x || 0,
          top: bounds?.y || 0,
          width: bounds?.width || 0,
          height: bounds?.height || 0,
          right: (bounds?.x || 0) + (bounds?.width || 0),
          bottom: (bounds?.y || 0) + (bounds?.height || 0),
          x: bounds?.x || 0,
          y: bounds?.y || 0,
        };
      },
    }),
    [bounds]
  );

  let onCellContextMenu = useCallback(
    (cell: Item, event: CellClickedEventArgs) => {
      event.preventDefault();
      setBounds(event.bounds);
      setCell(cell);
    },
    []
  );

  // Close the menu on page resize or click elsewhere
  let close = useCallback(() => {
    setBounds(undefined);
    setCell(undefined);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      close();
    };
    const handleClick = () => {
      close();
    };

    // On click outside of the menu, close it
    window.addEventListener("click", handleClick);

    // Add event listeners when the component mounts
    window.addEventListener("resize", handleResize);

    // Remove event listeners when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("click", handleClick);
    };
  }, [close]);

  let sections = useMemo(() => {
    return [
      {
        buttons: [
          {
            className: '',
            show: !!onFastFillEdit,
            icon: (
              <PencilSquareIcon
                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
            ),
            text: "Edit in Builder",
            onClick: () => {
              if (onFastFillEdit && cell) {
                onFastFillEdit(cell);
              }
              close();
            },
          },
          {
            className: '',
            show: !!onGroup,
            icon: (
              <Square3Stack3DIcon
                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
            ),
            text: "Group Lots",
            onClick: () => {
              if (onGroup) {
                onGroup();
              }
              close();
            },
          },

          {
            className: '',
            show: onRemoveFromGroup && onRemoveFromGroup(true),
            icon: (
              <Square3Stack3DIcon
                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
            ),
            text: "Remove From Group",
            onClick: () => {
              if (onRemoveFromGroup) {
                onRemoveFromGroup();
              }
              close();
            },
          },

          {
            className: '',
            show: onUngroup && onUngroup(true),
            icon: (
              <Square3Stack3DIcon
                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
            ),
            text: "Ungroup All",
            onClick: () => {
              if (onUngroup) {
                onUngroup();
              }
              close();
            },
          },

          {
            className: '',
            show: !!onDuplicate,
            icon: (
              <DocumentDuplicateIcon
                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
            ),
            text: "Duplicate Row",
            onClick: () => {
              if (onDuplicate && cell) {
                onDuplicate(cell);
              }
              close();
            },
          },
        ],
      },
      {
        buttons: [
          {
            className: '',
            show: !!onInsertAbove,
            icon: (
              <ArrowUpIcon
                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
            ),

            text: "Insert Row Above",
            onClick: () => {
              if (onInsertAbove && cell) {
                onInsertAbove(cell);
              }
              close();
            },
          },
          {
            className: '',
            show: !!onInsertBelow,
            icon: (
              <ArrowDownIcon
                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
            ),
            text: "Insert Row Below",
            onClick: () => {
              if (onInsertBelow && cell) {
                onInsertBelow(cell);
              }
              close();
            },
          },
        ],
      },
      {
        buttons: [
          {
            className: '!text-danger-400 !group-hover:text-red-500',
            show: !!onDelete,
            icon: (
              <TrashIcon
                className="mr-3 h-5 w-5 text-danger-400 group-hover:text-danger-500"
                aria-hidden="true"
              />
            ),

            text: "Delete Row",
            onClick: () => {
              if (onDelete && cell) {
                onDelete(cell);
              }
              close();
            },
          },
        ],
      },
    ];
  }, [
    onEdit,
    onDuplicate,
    onInsertAbove,
    onInsertBelow,
    onDelete,
    cell,
    close,
    onGroup,
    onUngroup,
    onRemoveFromGroup,
    onFastFillEdit,
  ]);

  // Has the popup menu got any buttons to show?
  let hasButtons = useMemo(() => {
    return sections.some((section) => section.buttons.some((b) => b.show));
  }, [sections]);

  // Return the Menu Component
  const MenuComponent = () => {
    let [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
      null
    );
    // @ts-ignore error with virtualReference but not sure why – no errors are defined
    let { styles, attributes } = usePopper(virtualReference, popperElement, {
      placement: "bottom-start",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 5],
          },
        },
        {
          name: "eventListeners",
          options: {
            scroll: true,
            resize: true,
          },
        },
      ],
    });

    if (!cell) return null;
    if (!hasButtons) return null;
    return (
      // <Portal>
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="z-[99999]"
        >
          <div className="relative z-50 w-56 text-left">
            <div className="divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {sections.map((section, index) => {
                let buttonSections = section.buttons.filter((b) => b.show);
                if (buttonSections.length === 0) return null;

                return (
                  <ul className="py-1" key={index}>
                    {buttonSections.map((button, index) => {
                      return (
                        <li key={index}>
                          <button
                            type="button"
                            className={classNames(
                              "text-gray-700",
                              "group flex w-full items-center px-4 py-2 text-sm",
                              "hover:bg-gray-100 hover:text-gray-900",
                              button.className
                            )}
                            onClick={button.onClick}
                          >
                            {button.icon}
                            {button.text}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                );
              })}
            </div>
          </div>
        </div>
      // </Portal>
    );
  };

  return useMemo(() => {
    return {
      MenuComponent,
      onCellContextMenu,
    };
  }, [
    onCellContextMenu,
    MenuComponent,
    virtualReference,
    sections,
    hasButtons,
    cell,
  ]);
}
