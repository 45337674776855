import { Timestamp } from "firebase-admin/firestore";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import {
  DraftInvoice,
  Invoice,
  InvoiceLineItem,
  Lot,
  Market,
  SuperType,
  SupportedCountryCode,
} from "types";
import { DocumentSpec, InvoicePDFProps } from "../_importDocs";
import {
  COLOUR_MARTEYE_500,
  MARGIN,
  PAGE_HEIGHT,
  PAGE_WIDTH,
  PTSCONVERSION,
} from "../variables";

/**
 * Movement Notification of Sheep/Goats Authorised by the relevant authority.
 * This is dictated by the arrival location of the livestock but can default to the country of the market.
 */
const MOVEMENT_AUTHORITY: {
  [key in SupportedCountryCode]: { country: string; address: string };
} = {
  "GB-ENG": {
    country: "England",
    address:
      "Livestock Information Service, C/O Defra, Chapel Bank Works, Curwen Road, Workington, Cumbria, CA14 2DD",
  },
  "GB-SCT": {
    country: "Scotland",
    address: "ScotEID, SAMU, 161 Brooms Road, Dumfries DG1 3ES",
  },
  "GB-WLS": {
    country: "Wales",
    address: "EIDCymru, Tŷ Merlin, Parc Merlin, Aberystwyth, SY23 3FF",
  },
  "GB-NIR": {
    country: "Northern Ireland",
    address: "Department of Agriculture, Environment and Rural Affairs",
  },
  IE: {
    country: "Ireland",
    address:
      "Department of Agriculture, Food and the Marine, Backweston Campus, Celbridge, Co. Kildare, W23 X3PH",
  },
};

const MovementNotificationSheepSpecGBWithTags: DocumentSpec = {
  // must be unique
  id: "movement-notification-sheep-gb-with-tags",
  objectType: "invoice",

  // displayed to the user
  name: "Movement Notification of Sheep/Goats with Tags",

  // when the PDF is downloaded, this function is called to generate the filename
  getFilename: (props) => {
    let { invoice, marketId } = props;
    let invoiceNumber =
      invoice.status === "draft" ? "Draft" : invoice.invoiceNumber;
    let filename = `movement-notification-sheep-${marketId}-${invoiceNumber}`;
    filename = filename.replace(/ /g, "-").replace(/[^a-zA-Z0-9-]/g, "");
    return `${filename}`;
  },

  // return true if this document is available for a market to generate
  isAvailableFor: (market: Market, superTypes: SuperType[]) => {
    // only available for livestock markets
    let allowedSuperTypes: SuperType[] = ["Sheep", "Goats"];

    const isAllowedSuperType = superTypes.some((superType) =>
      allowedSuperTypes.includes(superType)
    );

    const isAllowedCountry = ["GB-ENG", "GB-SCT", "GB-WLS"].includes(
      market.countryCode
    );

    const isAllowedInMarket = ["hexham", "mwalton"].includes(market.id);

    return isAllowedSuperType && isAllowedCountry && isAllowedInMarket;
  },

  // the document template
  jsPDF: (props: InvoicePDFProps) => {
    const { market, invoice } = props;

    const title = "Movement Notification of Sheep/Goats";
    const subtitle = `The Sheep and Goats (Records, Identification and Movement) Order (${
      MOVEMENT_AUTHORITY[market.countryCode]?.country ?? ""
    }) 2009, as amended.`;

    const legalText =
      "Return to " + MOVEMENT_AUTHORITY[market.countryCode]?.address ??
      "Relenvant Authority";
    const doc = new jsPDF({
      putOnlyUsedFonts: true,
      compress: true,
      unit: "mm",
      format: "a4",
    });

    // Variables
    const pageWidth = PAGE_WIDTH;
    const pageHeight = PAGE_HEIGHT;
    const margin = MARGIN;

    const defaultFontSize = 12;

    // 1. Calculate the page header height and footer height so we know the safe area to work with

    // Header height (1st Page)
    let headerHeight = getPageHeaderHeight(doc, 1, title, subtitle);
    let y = headerHeight.pageOneY;
    // Header height (2nd Page+)

    // Footer height
    let footerHeight = getPageFooterHeight(doc, 1, legalText);

    // TODO
    // 2. Need to work out the safe areas so if the document is over this then it removes the element and moves it to the next page
    let maximumHeight = footerHeight - headerHeight.pageOneY;

    // 3. Add in the content

    y = getPart1(
      doc,
      y,
      true,
      invoice,
      headerHeight.pageTwoY,
      footerHeight,
      market,
      props.lots
    );
    y = getPart2(doc, y, true, invoice, headerHeight.pageTwoY, footerHeight);
    y = getPart3(doc, y, true, invoice, headerHeight.pageTwoY, footerHeight);
    y = getPart4(doc, y, true, invoice, headerHeight.pageTwoY, footerHeight);

    // 4. Need to add running headers and footers
    getPageHeader(doc, true, 1, title, subtitle);
    getPageFooter(doc, true, 1, legalText);
    // 5. Need to add a page number

    return doc;
  },
};

// Helper Functions
function getPageHeaderHeight(
  doc: jsPDF,
  page: number,
  title: string,
  subtitle: string
) {
  return getPageHeader(doc, false, page, title, subtitle);
}

function getPageHeader(
  doc: jsPDF,
  output: boolean,
  page: number,
  title: string,
  subtitle: string
) {
  // Start at the top of the page 1
  doc.setPage(1);

  let y = 0;
  let fontSize = 20;
  let fontColor = COLOUR_MARTEYE_500;

  // Add intial spacing
  y += MARGIN + 5;

  let titleLines = doc
    .setFont("Inter", "bold")
    .setTextColor(fontColor)
    .setLineHeightFactor(0.8)
    .setFontSize(fontSize)
    .splitTextToSize(title, PAGE_WIDTH - 20);
  let titleHeight = doc.getLineHeight() * titleLines.length * PTSCONVERSION;
  if (output) doc.text(titleLines, MARGIN, y);

  // Add the title height
  y += titleHeight;

  fontSize = 10;
  fontColor = "#616161";

  let subTitleLines = doc
    .setFont("Inter", "bold")
    .setTextColor(fontColor)
    .setFontSize(fontSize)
    .setLineHeightFactor(1.2)
    .splitTextToSize(subtitle, PAGE_WIDTH - 20);

  let subTitleHeight =
    doc.getLineHeight() * subTitleLines.length * PTSCONVERSION;

  if (output) doc.text(subTitleLines, MARGIN, y);

  // Add the title height
  if (subtitle) {
    y += subTitleHeight;
  }

  let pageOneY = y;
  // Page 2 Onwards

  y = 0;
  fontSize = 10;
  fontColor = COLOUR_MARTEYE_500;

  // Add intial spacing
  y += 7.5;

  titleLines = doc
    .setFont("Inter", "bold")
    .setTextColor(fontColor)
    .setFontSize(fontSize)
    .splitTextToSize(title, PAGE_WIDTH - 20);
  titleHeight = doc.getLineHeight() * titleLines.length * PTSCONVERSION;

  // Add the title height
  y += titleHeight;

  const pageCount = doc.getNumberOfPages();
  for (var i = 2; i <= pageCount; i++) {
    doc.setPage(i);
    if (output) doc.text(titleLines, MARGIN, y);
  }

  let pageTwoY = y + 5;

  return {
    pageOneY: pageOneY,
    pageTwoY: pageTwoY,
  };
}

function getPageFooterHeight(doc: jsPDF, page: number, legalText: string) {
  return getPageFooter(doc, false, page, legalText);
}

function getPageFooter(
  doc: jsPDF,
  output: boolean,
  page: number,
  legalText: string
) {
  let y = PAGE_HEIGHT - 5;
  const pageCount = doc.getNumberOfPages();
  for (var i = 1; i <= pageCount; i++) {
    y = PAGE_HEIGHT - 5;
    let fontSize = 8;
    let fontColor = "#616161";

    // Set Page
    doc.setPage(i);

    let legalTextLines = doc
      .setFont("Inter", "normal")
      .setTextColor(fontColor)
      .setFontSize(fontSize)
      .splitTextToSize(legalText, PAGE_WIDTH - 20 - 30);

    let legalTextHeight =
      doc.getLineHeight() * legalTextLines.length * PTSCONVERSION;

    if (output) doc.text(legalTextLines, MARGIN, y - legalTextHeight);

    // minimum footer height
    if (i == 1) {
      y -= legalTextHeight;
    }

    if (output) {
      doc.setFontSize(8);
      doc.text(
        "Page " + String(i) + " of " + String(pageCount),
        PAGE_WIDTH - MARGIN,
        PAGE_HEIGHT - 8,
        {
          align: "right",
        }
      );
    }
    if (i == 1) {
      y -= 5;
    }
  }
  return y;
}

function getPart1(
  doc: jsPDF,
  y: number,
  output: boolean,
  invoice: Invoice | DraftInvoice,
  topY: number,
  bottomY: number,
  market: Market,
  lots: Lot[]
) {
  // Part 1 - Movement Details
  let rowHeight = 10;
  let columnWidth = (PAGE_WIDTH - MARGIN * 2) / 2;

  let lotId = invoice.lineItems[0].lotId;
  let lot = lots.find((lot) => lot.id === lotId);

  // We should use meta data from movement rather than market settings
  let marketCPHNumber = lot?.attributes?.["cphNumberMarket"] ?? "";

  // This is a commar seperated address
  let marketAddress = lot?.attributes?.["addressMarket"] ?? "";

  // Convert to an array and remove any empty lines
  marketAddress = marketAddress.split(",");

  // Remove any empty lines
  marketAddress = marketAddress.filter((line: string) => line.trim() !== "");

  // Now lets re-add the commas to the address still in an array except for the last line
  marketAddress = marketAddress.map((line: string) => line.trim());

  let addr = invoice.address;
  let customeraddress = [
    addr.company,
    addr.address1,
    addr.address2,
    addr.city,
    addr.province,
    addr.zip,
    addr.country,
  ].filter(Boolean) as string[];
  let usedcustomeraddressLines = customeraddress.filter((line) => line !== "");

  let cphNumberDestination = lot?.attributes?.["cphNumberDestination"] ?? "";

  const movementOutDate = lot?.attributes?.["dateOfMovementOut"]
    ? (lot?.attributes?.["dateOfMovementOut"] as Timestamp).toDate()
    : null;

  const declaration = `I declare that the details shown are correct.`;

  const data: any = [
    [
      {
        content: "CPH",
        styles: {
          textColor: "#989898",
          cellPadding: { top: 3, bottom: 0, left: 2, right: 2 },
        },
      },
      {
        content: "CPH",
        styles: {
          textColor: "#989898",
          cellPadding: { top: 3, bottom: 0, left: 2, right: 2 },
        },
      },
    ],
    [
      {
        content: marketCPHNumber,
        styles: {
          cellPadding: { top: 1, bottom: 2, left: 2, right: 2 },
        },
      },
      {
        content: cphNumberDestination,
        styles: {
          cellPadding: { top: 1, bottom: 2, left: 2, right: 2 },
        },
      },
    ],
    [
      {
        content: "NAME / ADDRESS",
        styles: {
          textColor: "#989898",
          cellPadding: { top: 1, bottom: 0, left: 2, right: 2 },
        },
      },
      {
        content: "NAME / ADDRESS",
        styles: {
          textColor: "#989898",
          cellPadding: { top: 1, bottom: 0, left: 2, right: 2 },
        },
      },
    ],
    [
      {
        content: marketAddress.join(", \n"),
        styles: {
          fontStyle: "normal",
          cellPadding: { top: 1, bottom: 3, left: 2, right: 2 },
        },
      },
      {
        content: usedcustomeraddressLines.join(", \n"),
        styles: {
          fontStyle: "normal",
          cellPadding: { top: 1, bottom: 3, left: 2, right: 2 },
        },
      },
    ],
  ];

  autoTable(doc, {
    head: [
      [
        {
          content: "Movement Details",
          colSpan: 2,
          styles: {
            halign: "left",
            fillColor: COLOUR_MARTEYE_500,
            textColor: "#FFFFFF",
          },
        },
      ],
      [
        {
          content: "FROM PREMISES OF",
          colSpan: 1,
          styles: { halign: "left" },
        },
        {
          content: "TO PREMISES OF",
          colSpan: 1,
          styles: { halign: "left" },
        },
      ],
    ],
    body: data,
    foot: [
      [
        {
          content: declaration,
          colSpan: 2,
        },
      ],
    ],
    startY: y,
    theme: "plain",
    margin: { top: topY, right: 10, bottom: PAGE_HEIGHT - bottomY, left: 10 },
    headStyles: {
      fillColor: "#EFEFEF",
      textColor: "#616161",
      fontSize: 8,
      font: "Inter",
      fontStyle: "bold",
      cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
    },
    bodyStyles: {
      fontSize: 8,
      font: "Inter",
      fontStyle: "bold",
      textColor: "#616161",
      cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
    },
    footStyles: {
      fontSize: 8,
      font: "Inter",
      fontStyle: "bold",
      textColor: "#162F27",
      cellPadding: { top: 1, bottom: 0, left: 2, right: 2 },
    },
    columnStyles: {
      0: { cellWidth: columnWidth },
      1: { cellWidth: columnWidth },
    },
  });

  // @ts-ignore
  y = doc.lastAutoTable.finalY;

  // Left and Right Columns
  const LeftData: any = [
    ["Keepers Signature", ""],
    ["Print Name", ""],
    ["Phone Number", ""],
    ["Name of Owner (If different from Keeper)", ""],
  ];

  const rightData: any = [
    [
      "Departure Date",
      movementOutDate?.toLocaleString("en-GB", { timeZone: "UTC" }),
    ],
    ["Date of Loading (if different)", ""],
    ["Time First Animal Loaded", ""],
    ["Departure Time", ""],
  ];

  columnWidth = 46.25;

  autoTable(doc, {
    body: LeftData,
    startY: y,
    theme: "plain",
    margin: {
      top: topY,
      right: PAGE_WIDTH / 2 + 2.5,
      bottom: PAGE_HEIGHT - bottomY,
      left: 10,
    },
    bodyStyles: {
      fontSize: 8,
      font: "Inter",
      fontStyle: "bold",
      textColor: "#616161",
      valign: "bottom",
      cellPadding: { top: 5, bottom: 1, left: 2, right: 2 },
    },
    columnStyles: {
      0: { cellWidth: columnWidth, valign: "bottom" },
      1: { cellWidth: columnWidth, valign: "bottom" },
    },
    didParseCell: function (data) {
      if (data.row.section === "body" && data.column.index === 0) {
        // Convert to uppercase
        let text = data.cell.text[0].toUpperCase();

        // Regular expression to find text within parentheses
        const regex = /\(([^)]+)\)/g;
        let match;
        let formattedText = text;

        // Replace all matches with the required format
        while ((match = regex.exec(text)) !== null) {
          let innerText = match[1].toLowerCase();
          let replacement = `(${innerText})`;
          formattedText = formattedText.replace(match[0], replacement);
        }

        // Update the cell text
        data.cell.text[0] = formattedText;
      }
    },
    willDrawCell: function (data) {
      // Only draw the bottom border and not if it is the last row
      if (data.row.section === "body" && data.column.index !== 0) {
        doc.setDrawColor("#CBCBCB"); // set the border color
        doc.setLineWidth(0.1); // set the border with

        // draw bottom border

        let width = data.cell.x + data.cell.width;

        doc.line(
          data.cell.x,
          data.cell.y + data.cell.height,
          width,
          data.cell.y + data.cell.height
        );
      }
    },
  });

  // @ts-ignore
  const leftY = doc.lastAutoTable.finalY + 5;

  autoTable(doc, {
    body: rightData,
    startY: y,
    theme: "plain",
    margin: {
      top: topY,
      left: PAGE_WIDTH / 2 + 2.5,
      bottom: PAGE_HEIGHT - bottomY,
      right: 10,
    },
    bodyStyles: {
      fontSize: 8,
      font: "Inter",
      fontStyle: "bold",
      textColor: "#616161",
      cellPadding: { top: 5, bottom: 1, left: 0, right: 2 },
    },
    columnStyles: {
      0: { cellWidth: columnWidth },
      1: { cellWidth: columnWidth },
    },
    didParseCell: function (data) {
      if (data.row.section === "body" && data.column.index === 0) {
        // Convert to uppercase
        let text = data.cell.text[0].toUpperCase();

        // Regular expression to find text within parentheses
        const regex = /\(([^)]+)\)/g;
        let match;
        let formattedText = text;

        // Replace all matches with the required format
        while ((match = regex.exec(text)) !== null) {
          let innerText = match[1].toLowerCase();
          let replacement = `(${innerText})`;
          formattedText = formattedText.replace(match[0], replacement);
        }

        // Update the cell text
        data.cell.text[0] = formattedText;
      }
    },

    willDrawCell: function (data) {
      // Only draw the bottom border and not if it is the last row
      if (data.row.section === "body" && data.column.index !== 0) {
        doc.setDrawColor("#CBCBCB"); // set the border color
        doc.setLineWidth(0.1); // set the border with

        // draw bottom border
        let width = data.cell.x + data.cell.width;

        doc.line(
          data.cell.x,
          data.cell.y + data.cell.height,
          width,
          data.cell.y + data.cell.height
        );
      }
    },
  });

  // @ts-ignore
  const rightY = doc.lastAutoTable.finalY + 5;

  // Get the biggest Y
  y = Math.max(leftY, rightY);

  return y;
}

function getPart2(
  doc: jsPDF,
  y: number,
  output: boolean,
  invoice: Invoice | DraftInvoice,
  topY: number,
  bottomY: number
) {
  // Part 2 - Movement Notification Details
  const data: any = [];
  invoice.lineItems.map((item: InvoiceLineItem) => {
    if (item.superType !== "Sheep" && item.superType !== "Goats") {
      return;
    }

    let earTags = Object.values(item.metadata.itemAttributesByItemId).map(
      function (item: any) {
        return item["@eartag"];
      }
    );

    data.push([
      item?.metadata?.lotNumber ?? "-",
      item.quantity,
      item.description,
      earTags.join(", "),
    ]);
  });

  console.log(data);

  const totalQuantity = invoice.lineItems.reduce((a, b) => a + b.quantity, 0);

  //Should be a double column table
  let showData = true;
  let body = data;
  let head: any = [
    [
      {
        content: "Details of Sheep / Goats",
        colSpan: 2,
        styles: {
          halign: "left",
          fillColor: COLOUR_MARTEYE_500,
          textColor: "#FFFFFF",
        },
      },
      {
        content: "",
        colSpan: 3,
        styles: {
          halign: "right",
          fontStyle: "normal",
          fillColor: COLOUR_MARTEYE_500,
          textColor: "#FFFFFF",
        },
      },
    ],
    [
      {
        content: "LOT NO.",
        colSpan: 1,
        styles: { halign: "left" },
      },
      {
        content: "QUANTITY",
        colSpan: 1,
        styles: { halign: "left" },
      },
      {
        content: "DESCRIPTION",
        colSpan: 1,
        styles: { halign: "left" },
      },
    ],
  ];

  if (!showData) {
    // Remove head  array 1
    head = [head[0]];
    body = [];
  }

  autoTable(doc, {
    head: head,
    foot: [
      [
        {
          content: `TOTAL`,
        },
        {
          content: totalQuantity,
        },
      ],
    ],

    body: body,
    startY: y,
    theme: "plain",
    margin: { top: topY, right: 10, bottom: PAGE_HEIGHT - bottomY, left: 10 },
    headStyles: {
      fillColor: "#EFEFEF",
      textColor: "#616161",
      fontSize: 8,
      font: "Inter",
      fontStyle: "bold",
      cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
    },
    bodyStyles: {
      fontSize: 8,
      font: "Inter",
      fontStyle: "normal",
      cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
    },
    footStyles: {
      fontSize: 8,
      font: "Inter",
      fontStyle: "bold",
      cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
    },
    willDrawCell: function (data) {
      // Only draw the bottom border and not if it is the last row
      if (data.row.section === "foot" && showData) {
        doc.setDrawColor("#CBCBCB"); // set the border color
        doc.setLineWidth(0.2); // set the border with

        // draw top border
        let width = data.cell.x + data.cell.width;

        doc.line(data.cell.x, data.cell.y, width, data.cell.y);
      }
    },
  });

  // @ts-ignore
  y = doc.lastAutoTable.finalY + 5;

  return y;
}

function getPart3(
  doc: jsPDF,
  y: number,
  output: boolean,
  invoice: Invoice | DraftInvoice,
  topY: number,
  bottomY: number
) {
  // Part 3 - Movement Notification Details

  // let columnWidth = (PAGE_WIDTH - MARGIN * 2) / 2;

  // const data: any = [
  //   ["NAME OF TRANSPORTER", "MEAN OF TRANSPORT"],
  //   ["VEHICLE REG NO", "TRANS. PERMIT NO"],
  //   ["DATE OF DEPARTURE", "SIGNATURE"],
  // ];

  autoTable(doc, {
    head: [
      [
        {
          content: "Transport Details",
          colSpan: 2,
          styles: {
            halign: "left",
            fillColor: COLOUR_MARTEYE_500,
            textColor: "#FFFFFF",
          },
        },
      ],
    ],
    body: [],
    startY: y,
    theme: "plain",
    margin: { top: topY, right: 10, bottom: PAGE_HEIGHT - bottomY, left: 10 },
    headStyles: {
      fillColor: "#EFEFEF",
      textColor: "#616161",
      fontSize: 8,
      font: "Inter",
      fontStyle: "bold",
      cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
    },
    bodyStyles: {
      fontSize: 8,
      font: "Inter",
      fontStyle: "bold",
      textColor: "#616161",
      cellPadding: { top: 5, bottom: 10, left: 2, right: 2 },
    },

    willDrawCell: function (data) {
      if (data.row.section === "body") {
        doc.setDrawColor("#CBCBCB"); // set the border color
        doc.setLineWidth(0.1); // set the border with

        // draw bottom border

        let width = data.cell.x + data.cell.width - 4;

        doc.line(
          data.cell.x + 2,
          data.cell.y + data.cell.height,
          width,
          data.cell.y + data.cell.height
        );
      }
    },
  });

  // @ts-ignore
  y = doc.lastAutoTable.finalY;

  // Variables
  const transportOutHaulierLicence =
    invoice.attributeValues?.["transportOutHaulierLicence"] ?? "";
  const transportOutVehicleReg =
    invoice.attributeValues?.["transportOutVehicleReg"] ?? "";
  const transportOutHaulier =
    invoice.attributeValues?.["transportOutHaulier"] ?? "";

  // Left and Right Columns
  const LeftData: any = [
    ["Haulage", transportOutHaulier],
    ["Vehicle Reg.", transportOutVehicleReg],
    ["Transport Authorisation Number", transportOutHaulierLicence],
  ];

  const rightData: any = [
    ["Haulier’s Signature", ""],
    ["Print Name", ""],
    ["Phone Number", ""],
  ];

  const columnWidth = 46.25;

  autoTable(doc, {
    body: LeftData,
    startY: y,
    theme: "plain",
    margin: {
      top: topY,
      right: PAGE_WIDTH / 2 + 2.5,
      bottom: PAGE_HEIGHT - bottomY,
      left: 10,
    },
    bodyStyles: {
      fontSize: 8,
      font: "Inter",
      fontStyle: "bold",
      textColor: "#616161",
      valign: "bottom",
      cellPadding: { top: 5, bottom: 1, left: 2, right: 2 },
    },
    columnStyles: {
      0: { cellWidth: columnWidth, valign: "bottom" },
      1: { cellWidth: columnWidth, valign: "bottom" },
    },
    didParseCell: function (data) {
      if (data.row.section === "body" && data.column.index === 0) {
        // Convert to uppercase
        let text = data.cell.text[0].toUpperCase();

        // Regular expression to find text within parentheses
        const regex = /\(([^)]+)\)/g;
        let match;
        let formattedText = text;

        // Replace all matches with the required format
        while ((match = regex.exec(text)) !== null) {
          let innerText = match[1].toLowerCase();
          let replacement = `(${innerText})`;
          formattedText = formattedText.replace(match[0], replacement);
        }

        // Update the cell text
        data.cell.text[0] = formattedText;
      }
    },
    willDrawCell: function (data) {
      // Only draw the bottom border and not if it is the last row
      if (data.row.section === "body" && data.column.index !== 0) {
        doc.setDrawColor("#CBCBCB"); // set the border color
        doc.setLineWidth(0.1); // set the border with

        // draw bottom border

        let width = data.cell.x + data.cell.width;

        doc.line(
          data.cell.x,
          data.cell.y + data.cell.height,
          width,
          data.cell.y + data.cell.height
        );
      }
    },
  });

  // @ts-ignore
  const leftY = doc.lastAutoTable.finalY + 5;

  autoTable(doc, {
    body: rightData,
    startY: y,
    theme: "plain",
    margin: {
      top: topY,
      left: PAGE_WIDTH / 2 + 2.5,
      bottom: PAGE_HEIGHT - bottomY,
      right: 10,
    },
    bodyStyles: {
      fontSize: 8,
      font: "Inter",
      fontStyle: "bold",
      textColor: "#616161",
      cellPadding: { top: 5, bottom: 1, left: 0, right: 2 },
    },
    columnStyles: {
      0: { cellWidth: columnWidth },
      1: { cellWidth: columnWidth },
    },
    didParseCell: function (data) {
      if (data.row.section === "body" && data.column.index === 0) {
        // Convert to uppercase
        let text = data.cell.text[0].toUpperCase();

        // Regular expression to find text within parentheses
        const regex = /\(([^)]+)\)/g;
        let match;
        let formattedText = text;

        // Replace all matches with the required format
        while ((match = regex.exec(text)) !== null) {
          let innerText = match[1].toLowerCase();
          let replacement = `(${innerText})`;
          formattedText = formattedText.replace(match[0], replacement);
        }

        // Update the cell text
        data.cell.text[0] = formattedText;
      }
    },

    willDrawCell: function (data) {
      // Only draw the bottom border and not if it is the last row
      if (data.row.section === "body" && data.column.index !== 0) {
        doc.setDrawColor("#CBCBCB"); // set the border color
        doc.setLineWidth(0.1); // set the border with

        // draw bottom border
        let width = data.cell.x + data.cell.width;

        doc.line(
          data.cell.x,
          data.cell.y + data.cell.height,
          width,
          data.cell.y + data.cell.height
        );
      }
    },
  });

  // @ts-ignore
  const rightY = doc.lastAutoTable.finalY + 5;

  // Get the biggest Y
  y = Math.max(leftY, rightY);

  return y;
}
function getPart4(
  doc: jsPDF,
  y: number,
  output: boolean,
  invoice: Invoice | DraftInvoice,
  topY: number,
  bottomY: number
) {
  // Part 4 -  Destination Details

  autoTable(doc, {
    head: [
      [
        {
          content: "Destination Details",
          colSpan: 2,
          styles: {
            halign: "left",
            fillColor: COLOUR_MARTEYE_500,
            textColor: "#FFFFFF",
          },
        },
      ],
    ],
    body: [],
    startY: y,
    theme: "plain",
    margin: { top: topY, right: 10, bottom: PAGE_HEIGHT - bottomY, left: 10 },
    headStyles: {
      fillColor: "#EFEFEF",
      textColor: "#616161",
      fontSize: 8,
      font: "Inter",
      fontStyle: "bold",
      cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
    },
    bodyStyles: {
      fontSize: 8,
      font: "Inter",
      fontStyle: "bold",
      textColor: "#616161",
      cellPadding: { top: 5, bottom: 10, left: 2, right: 2 },
    },

    willDrawCell: function (data) {
      if (data.row.section === "body") {
        doc.setDrawColor("#CBCBCB"); // set the border color
        doc.setLineWidth(0.1); // set the border with

        // draw bottom border

        let width = data.cell.x + data.cell.width - 4;

        doc.line(
          data.cell.x + 2,
          data.cell.y + data.cell.height,
          width,
          data.cell.y + data.cell.height
        );
      }
    },
  });

  // @ts-ignore
  y = doc.lastAutoTable.finalY;

  // Left and Right Columns
  const LeftData: any = [
    ["Number Received", ""],
    ["Keeper’s Signature", ""],
    ["Print Name", ""],
  ];

  const rightData: any = [
    ["Arrival Date", ""],
    ["Date/Time of Unloading", ""],
    ["Phone Number", ""],
  ];

  const columnWidth = 46.25;

  autoTable(doc, {
    body: LeftData,
    startY: y,
    theme: "plain",
    margin: {
      top: topY,
      right: PAGE_WIDTH / 2 + 2.5,
      bottom: PAGE_HEIGHT - bottomY,
      left: 10,
    },
    bodyStyles: {
      fontSize: 8,
      font: "Inter",
      fontStyle: "bold",
      textColor: "#616161",
      valign: "bottom",
      cellPadding: { top: 5, bottom: 1, left: 2, right: 2 },
    },
    columnStyles: {
      0: { cellWidth: columnWidth, valign: "bottom" },
      1: { cellWidth: columnWidth, valign: "bottom" },
    },
    didParseCell: function (data) {
      if (data.row.section === "body" && data.column.index === 0) {
        // Convert to uppercase
        let text = data.cell.text[0].toUpperCase();

        // Regular expression to find text within parentheses
        const regex = /\(([^)]+)\)/g;
        let match;
        let formattedText = text;

        // Replace all matches with the required format
        while ((match = regex.exec(text)) !== null) {
          let innerText = match[1].toLowerCase();
          let replacement = `(${innerText})`;
          formattedText = formattedText.replace(match[0], replacement);
        }

        // Update the cell text
        data.cell.text[0] = formattedText;
      }
    },
    willDrawCell: function (data) {
      // Only draw the bottom border and not if it is the last row
      if (data.row.section === "body" && data.column.index !== 0) {
        doc.setDrawColor("#CBCBCB"); // set the border color
        doc.setLineWidth(0.1); // set the border with

        // draw bottom border

        let width = data.cell.x + data.cell.width;

        doc.line(
          data.cell.x,
          data.cell.y + data.cell.height,
          width,
          data.cell.y + data.cell.height
        );
      }
    },
  });

  // @ts-ignore
  const leftY = doc.lastAutoTable.finalY + 5;

  autoTable(doc, {
    body: rightData,
    startY: y,
    theme: "plain",
    margin: {
      top: topY,
      left: PAGE_WIDTH / 2 + 2.5,
      bottom: PAGE_HEIGHT - bottomY,
      right: 10,
    },
    bodyStyles: {
      fontSize: 8,
      font: "Inter",
      fontStyle: "bold",
      textColor: "#616161",
      cellPadding: { top: 5, bottom: 1, left: 0, right: 2 },
    },
    columnStyles: {
      0: { cellWidth: columnWidth },
      1: { cellWidth: columnWidth },
    },
    didParseCell: function (data) {
      if (data.row.section === "body" && data.column.index === 0) {
        // Convert to uppercase
        let text = data.cell.text[0].toUpperCase();

        // Regular expression to find text within parentheses
        const regex = /\(([^)]+)\)/g;
        let match;
        let formattedText = text;

        // Replace all matches with the required format
        while ((match = regex.exec(text)) !== null) {
          let innerText = match[1].toLowerCase();
          let replacement = `(${innerText})`;
          formattedText = formattedText.replace(match[0], replacement);
        }

        // Update the cell text
        data.cell.text[0] = formattedText;
      }
    },

    willDrawCell: function (data) {
      // Only draw the bottom border and not if it is the last row
      if (data.row.section === "body" && data.column.index !== 0) {
        doc.setDrawColor("#CBCBCB"); // set the border color
        doc.setLineWidth(0.1); // set the border with

        // draw bottom border
        let width = data.cell.x + data.cell.width;

        doc.line(
          data.cell.x,
          data.cell.y + data.cell.height,
          width,
          data.cell.y + data.cell.height
        );
      }
    },
  });

  // @ts-ignore
  const rightY = doc.lastAutoTable.finalY + 5;

  // Get the biggest Y
  y = Math.max(leftY, rightY);

  return y;
}

export default MovementNotificationSheepSpecGBWithTags;
