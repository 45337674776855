import { classNames } from "@/data/classnames";
import { useMarket } from "@/data/useMartketId";
import { MinusIcon } from "@heroicons/react/20/solid";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid";
import { Timestamp } from "firebase/firestore";
import { ReactNode } from "react";
import { Media, SupportedAttributeTypes } from "types";
import { CheckoutGroup } from "./checkoutGroups";

interface CheckoutInfoProps {
  checkoutGroups: CheckoutGroup[];
  openForm?: () => void;
  allowEdit: boolean;
}

// The forms to collect the user's details at checkout
// the collapsed state
export function CheckoutInfo(props: CheckoutInfoProps) {
  let { checkoutGroups, allowEdit, openForm } = props;

  return (
    <div
      className={`grid grid-cols-${checkoutGroups.length} gap-2 items-start`}
    >
      {checkoutGroups.map((group, idx) => {
        return (
          <Section
            key={group.name}
            title={group.name}
            icon={group.icon}
            first={idx === 0}
            last={idx === checkoutGroups.length - 1}
            openForm={allowEdit ? openForm : null}
          >
            {group.items.map((attribute) => {
              return (
                <Item
                  key={attribute.label}
                  title={attribute.label}
                  type={attribute.type}
                  value={attribute.value}
                  options={attribute.options}
                  required={false}
                ></Item>
              );
            })}
          </Section>
        );
      })}
    </div>
  );
}

function Section(props: {
  title: string;
  icon: any;
  first: boolean;
  last: boolean;
  children: React.ReactNode;
  openForm?: (() => void) | null;
}) {
  return (
    <section
      className={classNames(
        "bg-white py-2 border border-gray-200/30 rounded-lg min-h-[160px] h-full"
      )}
    >
      <div
        className="border-b border-gray-200 mb-2 pb-2 flex flex-row justify-between items-center"
        aria-hidden="true"
      >
        <div className="flex flex-row items-center pl-2">
          <props.icon />
          <h2
            className="text-lg font-semibold text-gray-800 pl-2"
            id={props.title.toLowerCase().replace(/ /g, "-")}
          >
            {props.title}
          </h2>
        </div>
        {props.openForm ? (
          <button
            type="button"
            className="px-1 py-1 rounded-lg border-gray-300 border mr-2 hover:opacity-60 hover:cursor-pointer hover:bg-gray-100"
            onClick={props.openForm}
          >
            <EllipsisHorizontalIcon className="h-4 w-4 text-gray-900" />
          </button>
        ) : null}
      </div>
      {props.children}
    </section>
  );
}

function Item(props: {
  title: string;
  type: SupportedAttributeTypes | "select";
  value: string | number | boolean | Timestamp | Media | null;
  collapsedValue?: string | null;
  required: boolean;
  options?: { label: string; value: string }[];
}) {
  let { title, type, value, collapsedValue } = props;

  let market = useMarket();
  let countryCode = market?.countryCode || "GB-ENG";
  let locale = `en-${countryCode.split("-")[0]}`;

  let valueToRender: ReactNode = null;
  let isLong = false;
  switch (type) {
    case "string": {
      valueToRender = value as string;
      isLong = ((value || "") as string).length > 20;
      break;
    }
    case "number": {
      valueToRender = value as number;
      break;
    }
    case "boolean": {
      valueToRender = value === true ? "Yes" : "No";
      break;
    }
    case "date": {
      let date = validDateOrNull(value);
      if (date) {
        // print short date without time
        valueToRender = Intl.DateTimeFormat(locale, {
          dateStyle: "short",
        }).format(date);
      }
      break;
    }
    case "datetime": {
      let date = validDateOrNull(value);
      if (date) {
        // print short date without time
        valueToRender = Intl.DateTimeFormat(locale, {
          dateStyle: "short",
          timeStyle: "short",
        }).format(date);
      }
      break;
    }
    case "time": {
      let date = validDateOrNull(value);
      if (date) {
        // print short date without time
        valueToRender = Intl.DateTimeFormat(locale, {
          timeStyle: "short",
        }).format(date);
      }
      break;
    }
    case "select": {
      let option = props.options?.find((option) => option.value === value);
      valueToRender = option?.label;
      break;
    }
    default: {
      valueToRender = `Unsupported type ${type}. Value: ${value}`;
    }
  }

  if (valueToRender === null) {
    valueToRender = <MinusIcon className="h-4 w-4 text-gray-400 opacity-20" />;
  }

  if (collapsedValue) {
    valueToRender = collapsedValue;
  }

  return (
    <li
      className={classNames("flex py-1.5 px-3 flex-row justify-between")}
      aria-label={title}
    >
      <span className="text-gray-500 font-sans font-normal text-xs flex-grow whitespace-nowrap">
        {title}
        {props.required ? " *" : ""}
      </span>
      <span className="text-black text-sm font-normal flex-grow-0 text-right w-3/6 self-end">
        {valueToRender}
      </span>
    </li>
  );
}

function validDateOrNull(timestamp: Timestamp | null | any): Date | null {
  if (
    typeof timestamp === "object" &&
    timestamp !== null &&
    "toDate" in timestamp
  ) {
    let date = timestamp.toDate();
    if (!isNaN(date.getTime())) {
      return date;
    }
  }

  return null;
}
