import { Timestamp } from "firebase-admin/firestore";

import { PaymentMethod, Payout } from "types";
import { cloudFunction } from "../helpers/cloudfunction";

interface CreatePayoutData {
  marketId: string;
  customerId: string;

  method: string;
  amountInCents: number;

  transactionDate?: string; // ISO 8601 date string

  reference: string | null; // use the cheque number for cheques and the reference number for BACS

  notes?: string | null;
}

export async function createPayout(
  marketId: string,
  customerId: string,
  // Credit intentionally excluded as credit is created by creating a sellers invoice
  method: Exclude<PaymentMethod, "Credit">,
  amountInCents: number,
  reference: string | null,
  transactionDate: Timestamp | null,
  notes: string | null
) {
  const CreatePayoutHttpCall = cloudFunction<CreatePayoutData, Payout>(
    "CreatePayoutHttpCall"
  );

  return CreatePayoutHttpCall({
    marketId,
    customerId,
    method,
    amountInCents,
    transactionDate: transactionDate?.toDate().toISOString(),
    reference,
    notes,
  });
}

interface CompletePayoutData {
  marketId: string;
  payoutId: string;
  dateCleared?: string; // ISO 8601 date string
}

export async function completePayout(
  marketId: string,
  payoutId: string,
  dateCleared: Timestamp | null
) {
  const CompletePayoutHttpCall = cloudFunction<CompletePayoutData, Payout>(
    "CompletePayoutHttpCall"
  );

  return CompletePayoutHttpCall({
    marketId,
    payoutId,
    dateCleared: dateCleared?.toDate().toISOString(),
  });
}

interface VoidPayoutData {
  marketId: string;
  payoutId: string;

  voidReason: string;
  dateVoided?: string; // ISO 8601 date string
}

export async function voidPayout(
  marketId: string,
  payoutId: string,
  voidReason: string,
  dateVoided: Timestamp | null
) {
  const VoidPayoutHttpCall = cloudFunction<VoidPayoutData, Payout>(
    "VoidPayoutHttpCall"
  );

  return VoidPayoutHttpCall({
    marketId,
    payoutId,
    voidReason,
    dateVoided: dateVoided?.toDate().toISOString(),
  });
}
