import { sub } from "date-fns";
import { cloudFunction } from "../helpers/cloudfunction";

interface SendEmailData {
  marketId: string;
  customerId: string; // customer receiving the email
  invoiceId: string;
  documentIds: string[];
  subdomain?: string;
}

export async function sendInvoiceEmail(
  marketId: string,
  params: {
    customerId: string;
    invoiceId: string;
    documentIds: string[];
    subdomain?: string;
  }
) {
  const sendInvoiceEmailCall = cloudFunction<SendEmailData, {}>(
    "sendInvoiceEmailCall"
  );

  return sendInvoiceEmailCall({
    marketId,
    customerId: params.customerId,
    invoiceId: params.invoiceId,
    documentIds: params.documentIds,
    subdomain: params.subdomain,
  });
}
