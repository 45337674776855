export default function PaymentsIcon() {
  return (
    <span className="inline-block h-8 w-8 overflow-hidden rounded-full bg-[#DFEDF3] p-2">
      <svg
        viewBox="0 0 14 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="h-4 w-4 fill-[#39687C]"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.88164 12.8227C5.35652 13.059 7.81001 13.0596 11.3298 12.8216C12.204 12.7625 12.8968 12.207 12.9692 11.5096C13.0187 11.0327 13.0606 10.58 13.0945 10.1435H11.6C10.2259 10.1435 9.06671 9.09037 9.06671 7.73809C9.06671 6.38582 10.2259 5.33265 11.6 5.33265H13.1105C13.0769 4.89147 13.0335 4.43441 12.9801 3.95287C12.9036 3.26313 12.2154 2.71715 11.3506 2.65744C10.5085 2.59929 9.72699 2.55515 8.97666 2.52498C6.6 2.42943 4.53579 2.47417 1.84688 2.65893C0.993571 2.71757 0.310705 3.24943 0.22152 3.9272L0.219351 3.94472C-0.1069 6.75618 -0.043589 8.7267 0.240903 11.5055C0.312488 12.2047 1.00531 12.7632 1.88164 12.8227ZM3.1111 4.69814C2.78894 4.69814 2.52777 4.95056 2.52777 5.26192C2.52777 5.57329 2.78894 5.8257 3.1111 5.8257H6.99999C7.32215 5.8257 7.58332 5.57329 7.58332 5.26192C7.58332 4.95056 7.32215 4.69814 6.99999 4.69814H3.1111Z"
        />
        <path d="M13.2 6.23468H11.6C10.7164 6.23468 10 6.90778 10 7.73809C10 8.5684 10.7164 9.24149 11.6 9.24149H13.2C13.6418 9.24149 14 8.90495 14 8.48979V6.98639C14 6.57123 13.6418 6.23468 13.2 6.23468Z" />
        <path d="M6.40642 0.0115793L2.25344 0.445164C1.38782 0.535538 0.680564 1.14146 0.534302 1.94822C0.497764 2.14975 0.46408 2.34283 0.433322 2.52499C0.655599 2.36193 1.24949 2.02704 1.84684 1.99201C2.59352 1.94822 6.99608 1.80347 8.74801 1.89774L8.5194 1.27049C8.21585 0.4376 7.33762 -0.0856416 6.40642 0.0115793Z" />
      </svg>
    </span>
  );
}
