import { Timestamp } from "firebase/firestore";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { Address, Customer, Lot, Market, Sale, SuperType } from "types";
import { CartDocumentSpec, CartPDFProps } from "../_importDocs";
import {
  COLOUR_MARTEYE_500,
  MARGIN,
  PAGE_HEIGHT,
  PAGE_WIDTH,
  PTSCONVERSION,
} from "../variables";
import { getDisplayName } from "@/data/customerUtils";
import { formatAsCurrency } from "@/data/amounts";
import { getCustomer } from "@/data/customer";

const SellersCartSummarySpec: CartDocumentSpec = {
  // must be unique
  id: "sellers-cart-summary",
  objectType: "cart",

  // displayed to the user
  name: "Sellers Cart Summary",

  // when the PDF is downloaded, this function is called to generate the filename
  getFilename: (props) => {
    let { marketId } = props;
    let filename = `sellers-cart-summary-${marketId}`;
    filename = filename.replace(/ /g, "-").replace(/[^a-zA-Z0-9-]/g, "");
    return `${filename}`;
  },

  // return true if this document is available for a market to generate
  isAvailableFor: (market: Market, superTypes: SuperType[]) => {
    // only available for livestock markets
    return true;
  },

  // the document template
  jsPDF: (props: CartPDFProps) => {
    let { sales, lotsSoldBySale, customer, customerId, market } = props;

    const customerName = getDisplayName(customer);

    const title =
      "Sales Summary" + (customerName ? ` for ${customerName}` : "");
    const subtitle = "";
    const legalText = ``;
    const doc = new jsPDF({
      putOnlyUsedFonts: true,
      compress: true,
      unit: "mm",
      format: "a4",
    });

    // Variables
    const pageWidth = PAGE_WIDTH;
    const pageHeight = PAGE_HEIGHT;
    const margin = MARGIN;
    const defaultFontSize = 12;

    // 1. Calculate the page header height and footer height so we know the safe area to work with

    // Header height (1st Page)
    let headerHeight = getPageHeaderHeight(doc, 1, title, subtitle);
    let y = headerHeight.pageOneY;
    // Header height (2nd Page+)

    // Footer height
    let footerHeight = getPageFooterHeight(doc, 1, legalText);

    // 2. Need to work out the safe areas so if the document is over this then it removes the element and moves it to the next page
    let maximumHeight = footerHeight - headerHeight.pageOneY;

    // 3. Add in the content
    y = getPart1(
      doc,
      y,
      headerHeight.pageTwoY,
      footerHeight,
      customer,
      customerId,
      market
    );

    y = getPart2(
      doc,
      y,
      headerHeight.pageTwoY,
      footerHeight,
      sales,
      lotsSoldBySale
    );

    // 4. Need to add running headers and footers
    getPageHeader(doc, true, 1, title, subtitle);
    getPageFooter(doc, true, 1, legalText);

    return doc;
  },
};

// Helper Functions

function getPageHeaderHeight(
  doc: jsPDF,
  page: number,
  title: string,
  subtitle: string
) {
  return getPageHeader(doc, false, page, title, subtitle);
}

function getPageHeader(
  doc: jsPDF,
  output: boolean,
  page: number,
  title: string,
  subtitle: string
) {
  // Start at the top of the page 1
  doc.setPage(1);

  let y = 0;
  let fontSize = 24;
  let fontColor = COLOUR_MARTEYE_500;

  // Add intial spacing
  y += MARGIN + 5;

  let titleLines = doc
    .setFont("Inter", "bold")
    .setTextColor(fontColor)
    .setFontSize(fontSize)
    .splitTextToSize(title, (PAGE_WIDTH - 20) * 0.75);
  let titleHeight = doc.getLineHeight() * titleLines.length * PTSCONVERSION;
  if (output) doc.text(titleLines, MARGIN, y);

  // Add the title height
  y += titleHeight;

  fontSize = 10;
  fontColor = "#616161";

  let subTitleLines = doc
    .setFont("Inter", "bold")
    .setTextColor(fontColor)
    .setFontSize(fontSize)
    .splitTextToSize(subtitle, PAGE_WIDTH - 20);

  let subTitleHeight =
    doc.getLineHeight() * subTitleLines.length * PTSCONVERSION;

  if (output) doc.text(subTitleLines, MARGIN, y);

  // Add the title height
  if (subtitle) {
    y += subTitleHeight;
  }

  let pageOneY = y;
  // Page 2 Onwards

  y = 0;
  fontSize = 10;
  fontColor = COLOUR_MARTEYE_500;

  // Add intial spacing
  y += 7.5;

  titleLines = doc
    .setFont("Inter", "bold")
    .setTextColor(fontColor)
    .setFontSize(fontSize)
    .splitTextToSize(title, (PAGE_WIDTH - 20) * 0.75);
  titleHeight = doc.getLineHeight() * titleLines.length * PTSCONVERSION;

  // Add the title height
  y += titleHeight;

  const pageCount = doc.getNumberOfPages();
  for (var i = 2; i <= pageCount; i++) {
    doc.setPage(i);
    if (output) doc.text(titleLines, MARGIN, y);
  }

  let pageTwoY = y + 5;

  return {
    pageOneY: pageOneY,
    pageTwoY: pageTwoY,
  };
}

function getPageFooterHeight(doc: jsPDF, page: number, legalText: string) {
  return getPageFooter(doc, false, page, legalText);
}

function getPageFooter(
  doc: jsPDF,
  output: boolean,
  page: number,
  legalText: string
) {
  let y = PAGE_HEIGHT - 5;
  const pageCount = doc.getNumberOfPages();
  for (var i = 1; i <= pageCount; i++) {
    y = PAGE_HEIGHT - 5;
    let fontSize = 8;
    let fontColor = "#616161";

    // Set Page
    doc.setPage(i);

    let legalTextLines = doc
      .setFont("Inter", "normal")
      .setTextColor(fontColor)
      .setFontSize(fontSize)
      .splitTextToSize(legalText, PAGE_WIDTH - 20 - 30);

    let legalTextHeight =
      doc.getLineHeight() * legalTextLines.length * PTSCONVERSION;

    if (output) doc.text(legalTextLines, MARGIN, y - legalTextHeight);

    // minimum footer height
    if (i == 1) {
      y -= legalTextHeight;
    }

    if (output) {
      doc.setFontSize(8);
      doc.text(
        "Page " + String(i) + " of " + String(pageCount),
        PAGE_WIDTH - MARGIN,
        PAGE_HEIGHT - 8,
        {
          align: "right",
        }
      );
    }
    if (i == 1) {
      y -= 5;
    }
  }
  return y;
}

function getPart1(
  doc: jsPDF,
  y: number,
  topY: number,
  bottomY: number,
  customer: Customer | null,
  customerId: string,
  market: Market
) {
  // Part 1 - Addresses
  let columnWidth = (PAGE_WIDTH - MARGIN * 2) / 3;

  const marketAddress = market.address ? (market.address as Address) : null;

  let martaddress = [
    marketAddress?.address1 ?? "",
    marketAddress?.address2 ?? "",
    marketAddress?.city ?? "",
    marketAddress?.province ?? "",
    marketAddress?.zip ?? "",
    marketAddress?.country ?? "",
  ];

  let usedMarketAddress = martaddress.filter((line) => line !== "");

  let customeraddress: string[] = [];

  if (customer) {
    customeraddress = [
      customer.address.address.company ? customer.address.address.company : "",
      customer.address.address.address1
        ? customer.address.address.address1
        : "",
      customer.address.address.address2
        ? customer.address.address.address2
        : "",
      customer.address.address.city ? customer.address.address.city : "",
      customer.address.address.province
        ? customer.address.address.province
        : "",
      customer.address.address.zip ? customer.address.address.zip : "",
      customer.address.address.country ? customer.address.address.country : "",
    ];
  }
  let usedcustomeraddressLines = customeraddress.filter((line) => line !== "");

  const data: any = [
    [
      {
        content: usedMarketAddress.join(", \n"),
        styles: {
          fontStyle: "normal",
          cellPadding: { top: 1, bottom: 3, left: 2, right: 2 },
        },
      },
      {
        content: usedcustomeraddressLines.join(", \n"),
        styles: {
          fontStyle: "normal",
          cellPadding: { top: 1, bottom: 3, left: 2, right: 2 },
        },
      },
    ],
  ];

  doc.setLineHeightFactor(1.4);

  autoTable(doc, {
    body: data,

    head: [
      [
        {
          content: market.name ?? "Market",
        },

        {
          content: customer?.accountNumber ?? `Customer: ${customerId}`,
        },
      ],
    ],
    startY: y,
    theme: "plain",
    margin: { top: topY, right: 10, bottom: PAGE_HEIGHT - bottomY, left: 10 },
    headStyles: {
      textColor: COLOUR_MARTEYE_500,
      fontSize: 10,
      font: "Inter",
      fontStyle: "bold",
      cellPadding: { top: 0, bottom: 0, left: 2, right: 2 },
    },
    bodyStyles: {
      fontSize: 10,
      font: "Inter",
      fontStyle: "bold",
      textColor: "#616161",
      cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
    },
    columnStyles: {
      0: { cellWidth: columnWidth },
      1: { cellWidth: columnWidth },
    },
  });

  doc.setLineHeightFactor(1.25);

  // @ts-ignore
  y = doc.lastAutoTable.finalY + 5;

  return y;
}

function getPart2(
  doc: jsPDF,
  y: number,
  topY: number,
  bottomY: number,
  sales: Sale[],
  lotsSoldBySale: { [key: string]: Lot[] }
) {
  // Add intial spacing
  y += 5;

  // Loop through the sales
  let totalWidth = PAGE_WIDTH - 20;

  let columnLotWidth = 30;
  let columnQuantityWidth = 30;
  let itemsColumnWidth = totalWidth - columnLotWidth - columnQuantityWidth;

  sales.forEach((sale) => {
    // Add the table

    let lots = lotsSoldBySale[sale.id];

    if (lots) {
      let lotsSoldAndSubject = lots.filter(
        (lot) =>
          !["Rerun", "Resold", "Unsold"].includes(lot.saleStatus ?? "") &&
          (lot.unitPriceInCents ?? 0) > 0
      );
      let totalQuantity = lotsSoldAndSubject.length;
      let totalPrice = lotsSoldAndSubject.reduce(
        (acc, lot) => acc + (lot?.unitPriceInCents ?? 0),
        0
      );

      let data = lots.map((lot) => {
        let quantity = lot.generated?.countOfItems ?? 0;
        let lotNumber = lot?.lotNumber ?? "";

        // let description = lot.generated?.description ?? "";

        let items = Object.values(lot.itemMap || {});
        let itemDescriptions = [] as string[];

        // for (let item of items) {
        //   let attributes = item.attributes;
        //   let itemDescription = "";

        //   if ("@eartag" in attributes) {
        //     itemDescription += `${attributes["@eartag"]} `;
        //   }
        //   itemDescriptions.push(itemDescription);
        // }

        // description += itemDescriptions.join("\n");

        const formattedCurrencyValue = formatAsCurrency(
          lot.currency,
          lot.unitPriceInCents ?? 0
        );

        const buyerName = lot.buyer?.displayName ?? "";

        const saleStatus = lot.saleStatus ?? "Subject";

        let description = `${formattedCurrencyValue} - ${buyerName} - ${saleStatus}`;

        return [
          {
            content: lotNumber,
          },
          {
            content: quantity,
          },
          {
            content: description,
          },
        ];
      });

      let issuedAt = sale.startsAt as Timestamp;
      let saleDate = issuedAt.toDate().toDateString();

      autoTable(doc, {
        head: [
          [
            {
              content: `${sale.name}`,
              colSpan: 1,
              styles: {
                fontSize: 10,
                halign: "left",
                textColor: COLOUR_MARTEYE_500,
                fillColor: "#FFFFFF",
                cellPadding: { top: 3, bottom: 2, left: 1, right: 2 },
              },
            },
            {
              content: `${saleDate}`,
              colSpan: 2,
              styles: {
                fontSize: 10,
                halign: "right",
                textColor: COLOUR_MARTEYE_500,
                fillColor: "#FFFFFF",
                cellPadding: { top: 3, bottom: 2, left: 1, right: 2 },
              },
            },
          ],
          [
            {
              content: "LOT",
              styles: { halign: "left" },
            },
            {
              content: "QUANTITY",
              styles: { halign: "left" },
            },
            {
              content: "DESCRIPTION",
              styles: { halign: "left" },
            },
          ],
        ],
        body: data,
        foot: [
          [
            {
              content: "TOTAL",
              styles: {
                halign: "left",
                textColor: COLOUR_MARTEYE_500,
                fontStyle: "bold",
                fillColor: "#EFEFEF",
              },
            },
            {
              content:
                formatAsCurrency(lots[0].currency, totalPrice) +
                ` (${totalQuantity})`,
              styles: {
                halign: "left",
                fontStyle: "bold",
                textColor: COLOUR_MARTEYE_500,
                fillColor: "#EFEFEF",
              },
            },
            {
              content: `AVERAGE: ${
                formatAsCurrency(
                  lots[0].currency,
                  totalQuantity > 0 ? totalPrice / totalQuantity : 0
                ) ?? ""
              }`,
              styles: {
                halign: "right",
                fontStyle: "bold",
                textColor: COLOUR_MARTEYE_500,
                fillColor: "#EFEFEF",
              },
            },
          ],
        ],
        startY: y,
        theme: "plain",
        margin: {
          top: topY,
          right: 10,
          bottom: PAGE_HEIGHT - bottomY,
          left: 10,
        },
        headStyles: {
          fillColor: "#EFEFEF",
          textColor: "#616161",
          fontSize: 8,
          font: "Inter",
          fontStyle: "bold",
          cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
        },
        bodyStyles: {
          fontSize: 10,
          font: "Inter",
          fontStyle: "normal",
          cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
        },
        footStyles: {
          fontSize: 10,
          font: "Inter",
          fontStyle: "bold",
          cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
        },
        columnStyles: {
          0: { cellWidth: columnLotWidth },
          1: { cellWidth: columnQuantityWidth },
          2: { cellWidth: itemsColumnWidth },
        },
      });

      // @ts-ignore
      y = doc.lastAutoTable.finalY + 5;
    }
  });

  return y;
}

export default SellersCartSummarySpec;
