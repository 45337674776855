import { Timestamp } from "firebase/firestore";
import {
  AttributeRenderTemplates,
  TemplateContext,
} from "../attributeControls";

export function getCopyValue(
  templates: AttributeRenderTemplates,
  ctx: TemplateContext
): any {
  // if (templates.copyCompiledTemplate) {
  //   return templates.copyCompiledTemplate(ctx);
  // }

  if (ctx.values.length === 1) {
    // Just return the value if its not falsy

    if (ctx.value) {
      // if it's a timestamp then ISO date
      if (ctx.value instanceof Timestamp) {
        return ctx.value.toDate().toISOString();
      }

      // if it's a boolean then return true or false
      if (typeof ctx.value === "boolean") {
        return ctx.value ? "true" : "false";
      }

      return ctx.value;
    } else {
      return "";
    }
  }

  // Comma separate the values
  return ctx.values
    .filter((v) => !!v)
    .map((v) => v.toString())
    .join(", ");
}
